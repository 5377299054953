import { VCheckbox } from 'vuetify/lib/components/VCheckbox';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "card",
    on: {
      "click": function ($event) {
        return _setup.emit('row-click');
      },
      "dblclick": function ($event) {
        return _setup.emit('row-dblclick');
      }
    }
  }, _vm._l(_setup.rows, function (row, rowIndex) {
    var _row$, _row$2, _row$3;
    return _c('div', {
      key: rowIndex,
      staticClass: "row"
    }, [rowIndex === 0 && _vm.canSelect ? _c('div', {
      staticClass: "column-select"
    }, [_c(VCheckbox, {
      staticClass: "checkbox",
      attrs: {
        "input-value": !_vm.selectionDisabled && _vm.isSelected,
        "disabled": _vm.selectionDisabled,
        "hide-details": "",
        "data-cy": "uc-list-row-base-checkbox"
      },
      on: {
        "change": function ($event) {
          return _setup.emit('toggle-select');
        },
        "click": function ($event) {
          $event.stopPropagation();
        }
      }
    })], 1) : rowIndex > 0 && _vm.canSelect ? _c('div', {
      staticClass: "column-select"
    }) : _vm._e(), _c('div', {
      staticClass: "content-column-container"
    }, [_c('div', {
      staticClass: "column-one"
    }, [_c('uc-rows-cell-wrapper', {
      attrs: {
        "column": row[0],
        "size": "card"
      }
    }, [_vm._t((_row$ = row[0]) === null || _row$ === void 0 ? void 0 : _row$.templateName)], 2)], 1), _c('div', {
      staticClass: "column-two"
    }, [row[1] ? _c('div', {
      staticClass: "fill-width"
    }, [rowIndex === 0 && _setup.props.showActions ? _c('div', {
      staticClass: "actions-split-container"
    }, [_c('div', {
      staticClass: "actions-split-content"
    }, [_c('uc-rows-cell-wrapper', {
      attrs: {
        "column": row[1],
        "size": "card"
      }
    }, [_vm._t((_row$2 = row[1]) === null || _row$2 === void 0 ? void 0 : _row$2.templateName)], 2)], 1), _c('div', {
      staticClass: "actions-split-actions"
    }, [_c('uc-rows-cell-wrapper', {
      attrs: {
        "column": _setup.ACTIONS_COL,
        "size": "card"
      }
    }, [_vm._t(_setup.ACTIONS_COL.templateName)], 2)], 1)]) : _c('uc-rows-cell-wrapper', {
      attrs: {
        "column": row[1],
        "size": "card"
      }
    }, [_vm._t((_row$3 = row[1]) === null || _row$3 === void 0 ? void 0 : _row$3.templateName)], 2)], 1) : _vm._e()])])]);
  }), 0);
};
var staticRenderFns = [];
export { render, staticRenderFns };