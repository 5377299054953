import { getDynamicComponent } from '@/components'
import { DetailsPanelVisibility } from '@/types/enums'
import { ref, computed, reactive } from 'vue'

const globalDetailsPanel = reactive({
  component: null as any,
  componentName: '',
  props: { visibility: DetailsPanelVisibility.Hide },
  onMountedFunctionName: '',
  isVisible: false,
  fnParams: null,
  eventListeners: null
})

const globalDetailsPanelRef = ref<any>()

const isADetailsPanelOpen = ref<DetailsPanelVisibility>(DetailsPanelVisibility.Hide)

/*
Takes a string for the component name that we want to load
Takes props to v-bind to the component
Takes a function name that will fire after the component mounts
*/

const openGlobalDetailsPanel = async (component: string, props: any, functionName: string, functionParameters: any, eventListeners: any = null) => {
  globalDetailsPanel.componentName = component
  globalDetailsPanel.component = await getDynamicComponent(component)
  globalDetailsPanel.props = { visibility: DetailsPanelVisibility.Fullscreen, hideFullScreen: true, ...props }
  globalDetailsPanel.onMountedFunctionName = functionName
  globalDetailsPanel.isVisible = true
  globalDetailsPanel.fnParams = functionParameters || {}
  globalDetailsPanel.eventListeners = eventListeners
  isADetailsPanelOpen.value = DetailsPanelVisibility.Fullscreen
}

/*
This Prevents remounting the component again
Its Used to update the details panel without mounting again
*/
const updateGlobalDetailsPanel = async (props: any, functionName = '', functionParameters: any = null, newComponent = '') => {
  // only execute if component is being updated.
  if (newComponent && globalDetailsPanel.componentName !== newComponent) {
    globalDetailsPanel.componentName = newComponent
    globalDetailsPanel.component = await getDynamicComponent(newComponent)
    globalDetailsPanel.props = { visibility: DetailsPanelVisibility.Fullscreen, hideFullScreen: true, ...props }
  } else {
    globalDetailsPanel.props = { ...globalDetailsPanel.props, ...props }
  }
  if (functionName !== globalDetailsPanel.onMountedFunctionName) {
    globalDetailsPanel.onMountedFunctionName = functionName
  }
  if (functionParameters !== globalDetailsPanel.fnParams) {
    globalDetailsPanel.fnParams = functionParameters || {}
  }
  if (globalDetailsPanel.onMountedFunctionName) {
    globalDetailsPanelRef.value[globalDetailsPanel.onMountedFunctionName](globalDetailsPanel.fnParams)
  }
}

const closeGlobalDetailsPanel = () => {
  globalDetailsPanel.component = null
  globalDetailsPanel.props = { visibility: DetailsPanelVisibility.Hide }
  globalDetailsPanel.onMountedFunctionName = ''
  globalDetailsPanel.isVisible = false
  globalDetailsPanel.fnParams = null
  isADetailsPanelOpen.value = DetailsPanelVisibility.Hide
}

export const useDetailsPanel = (router: any = null) => {
  let actions = [] as any
  const detailsPanelVisibility = ref<DetailsPanelVisibility>(DetailsPanelVisibility.Hide)

  const hidePanel = (callback: any = () => null) => {
    detailsPanelVisibility.value = DetailsPanelVisibility.Hide
    callback()
  }

  /*
  ==============================
  Dynamic Modals for confirmation
  ==============================
  modalConfirmationHandler: fires when the confirm event (delete, void, save, etc) is fired
  modalFooterButtons: buttons to render in the confirmation modal

  Note: this was built way too complexly and supports multiple levels of nesting and dynamicism.
  TODO: simplify with only one dynamic component
  */
  const showOuterModalComponent = ref(false)
  const additionalModalConfigRef = ref<any>(null)
  const modalFooterIsDisabled = ref<boolean>(false)
  const modalComponentRef = ref<any>(null)
  const statefulEmit = ref<any>()
  const modalFooterButtonHidden = ref<boolean>(false)

  const modalFooterButtons = computed(() => {
    return [
      {
        type: 'outlined',
        text: 'CANCEL'
      },
      {
        ...dynamicModalData.value.confirmButtonProps,
        disabled: modalFooterIsDisabled.value,
        eventName: 'confirm',
        hidden: modalFooterButtonHidden.value
      }
    ]
  })

  const dynamicModalData = ref<any>(
    {
      confirmButtonProps: {
        text: '',
        state: 'primary',
        disabled: false
      },
      dynamicContentProps: { } as any,
      advancedModal: '',
      advancedModalIsVisible: false,
      modalCardProps: {
        headerText: '',
        headerIcon: '',
        size: 'md',
        dynamicHeader: null as ((params: any) => string) | null
      },
      modalContentComponent: ''
    }
  )

  const additionalDataFromActionModal = ref<any>({})
  const pendingActionData = reactive({
    action: '',
    resources: [] as any[]
  })

  const modalKey = ref(1)
  const resetModal = () => {
    setTimeout(() => {
      modalKey.value++
    }, 400)
  }
  const modalConfirmationHandler = async (additionalParams: any) => {
    additionalDataFromActionModal.value = {
      ...(additionalModalConfigRef.value?.getInputs?.() || {}),
      ...(modalComponentRef.value?.getInputs?.() || {})
    }

    if (showOuterModalComponent.value) {
      showOuterModalComponent.value = false
      const curAction = actions.find((action: any) => {
        return action.value === pendingActionData.action
      })
      if (!curAction) {
        console.error('Unable to process action')
        return
      }
      if (curAction.redirect) {
        router.push(curAction.redirectUrl(pendingActionData.resources[0].id))
      } else {
        await curAction.actionFn(
          pendingActionData.resources,
          additionalParams()
        )
        statefulEmit.value('success')
      }
    }
  }

  /*
  ==============================
  Event Listeners and Actions (functions to run) Generation
  ==============================
  generateListenersAndActions: builds the object of event names and associated functions so that we can capture action emits and run the appropriate functions.
    - requiresConfirmation will open a modal (see above section for dynamic modals)
    - if there is no defined actionFn we just emit the same event again to the parent
    - if there is a defined actionFn (and no confirmation required), run that and conditionally emit ('success') on completion - some events should not emit success (print, get share link, etc)
  */

  const generateListenersAndActions = (selectedResource:any, actionsForEmit: any, rowType: string, emit: any, additionalParams: any) => {
    statefulEmit.value = emit
    actions = actionsForEmit
    let obj: any = {}
    actionsForEmit.forEach((action: any) => {
      const listenerToKebabCase = action.value
      obj = {
        ...obj,
        [listenerToKebabCase]: async (resources: any[] = [selectedResource()]) => {
          if (action.requiresConfirmation) {
            dynamicModalData.value = {
              ...action.modal
            }
            if (action.advancedModal) {
              dynamicModalData.value.dynamicContentProps = {
                ...action.dynamicContentProps,
                resources,
                action: action.value
              }
              dynamicModalData.value.advancedModal = action.advancedModal
              dynamicModalData.value.advancedModalIsVisible = true
            } else {
              dynamicModalData.value = {
                ...action.modal
              }
              dynamicModalData.value.advancedModal = ''
              dynamicModalData.value.dynamicContentProps = {
                ...dynamicModalData.value.dynamicContentProps,
                resources,
                action: action.value,
                type: rowType
              }
            }
            // modalKey.value++
            if (dynamicModalData.value.modalCardProps?.dynamicHeader) {
              dynamicModalData.value.modalCardProps.headerText = dynamicModalData.value.modalCardProps.dynamicHeader(resources)
            }

            setTimeout(() => {
              if (!dynamicModalData.value.advancedModal) {
                showOuterModalComponent.value = true
              }
            }, 0)
            pendingActionData.resources = resources
            pendingActionData.action = listenerToKebabCase
            return
          }
          if (!action.actionFn) {
            emit(listenerToKebabCase)
            return
          }
          if (!action.requiresConfirmation) {
            try {
              if (!action.actionFn) return
              await action.actionFn(
                resources,
                additionalParams()
              )
              if (!action.noSuccessEmit) {
                emit('success')
              }
            } catch (err) {
              emit('error', err)
            }
          }
        }
      }
    })
    return obj
  }

  return {
    detailsPanelVisibility,
    hidePanel,
    generateListenersAndActions,
    modalFooterButtons,
    dynamicModalData,
    additionalModalConfigRef,
    modalComponentRef,
    modalConfirmationHandler,
    resetModal,
    modalKey,
    openGlobalDetailsPanel,
    closeGlobalDetailsPanel,
    globalDetailsPanel,
    globalDetailsPanelRef,
    modalFooterIsDisabled,
    additionalDataFromActionModal,
    modalFooterButtonHidden,
    updateGlobalDetailsPanel,
    isADetailsPanelOpen,
    showOuterModalComponent
  }
}
