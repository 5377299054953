import {
  SITE_REGION,
  SITE_NAME
} from '@/constants/filters/resources/site'

import {
  PROPOSAL_NUMBER,
  PROPOSAL_CUSTOM_NAME,
  PROPOSAL_STATUS,
  PROPOSAL_DATE_OF_ISSUE,
  PROPOSAL_EXPIRATION_DATE,
  PROPOSAL_PO_NUMBER,
  PROPOSAL_DEPARTMENT
} from '@/constants/filters/resources/proposal'

import {
  WORK_ORDER_NUMBER
} from '@/constants/filters/resources/workOrder'
import { VENDOR_NAME } from '../resources/vendor'
import { CLIENT } from '@/constants/permissions'
import { useInboundProposals } from '@/use/proposals/inbound'
import { AutocompleteResponse, AutocompletePayload } from '@/types/interfaces/api-v2/autocomplete'

export const inboundConvertProposalFilters = () => {
  const { inboundProposalsAutoComplete } = useInboundProposals()

  const inboundProposalsAutoCompleteFetchFn = async (params: AutocompletePayload) => {
    params.finderParams = { status_in: ['accepted'] }
    const { data, nextPage } = await inboundProposalsAutoComplete(params) as { data: AutocompleteResponse[], nextPage: number }
    return {
      data: data.map((item: AutocompleteResponse, index: number) => {
        return {
          id: `${index}-${item.text}`,
          text: item.text,
          value: item.text
        }
      }),
      nextPage
    }
  }

  return {
    filters: [
      VENDOR_NAME({ pinned: true, isPrimary: true }, { field: 'vendor', tab: 'active' }, inboundProposalsAutoCompleteFetchFn),
      SITE_NAME({ pinned: true, isPrimary: true }, { field: 'site', invoiceables: false }, inboundProposalsAutoCompleteFetchFn),
      PROPOSAL_NUMBER(false, { pinned: true, isPrimary: true }, { field: 'proposal_number' }, inboundProposalsAutoCompleteFetchFn),
      PROPOSAL_CUSTOM_NAME(false, { pinned: false, isPrimary: false }, { field: 'custom_proposal_name' }, inboundProposalsAutoCompleteFetchFn),
      PROPOSAL_STATUS(false, { pinned: true, isPrimary: true }),
      PROPOSAL_DATE_OF_ISSUE({ pinned: false, isPrimary: false }),
      PROPOSAL_EXPIRATION_DATE({ pinned: false, isPrimary: false }),
      PROPOSAL_DEPARTMENT(false, { pinned: false, isPrimary: false }, { field: 'department' }, inboundProposalsAutoCompleteFetchFn),
      WORK_ORDER_NUMBER({ pinned: true, isPrimary: true }, { field: 'wo_id', invoiceables: false }, inboundProposalsAutoCompleteFetchFn)
    ],
    sortBy: {
      value: 'date_of_issue',
      direction: {
        text: 'Descending',
        value: 'desc'
      },
      items: [
        {
          label: 'Status',
          value: 'status'
        },
        {
          label: 'Proposal Date',
          value: 'proposal_date'
        },
        {
          label: 'Date of Issue',
          value: 'date_of_issue'
        },
        {
          label: 'Proposal #',
          value: 'estimate_number'
        },
        {
          label: 'Amount',
          value: 'total_cents'
        }
      ]
    },
    fieldMapping: {
      'vendor-name': {
        name: 'vendor_name',
        field: 'value'
      },
      'site-name': {
        name: 'location_name',
        field: 'value'
      },
      'site-region': {
        name: 'location_cc_territory_name',
        field: 'text'
      },
      'proposal-number': {
        name: 'estimate_number',
        field: 'value'
      },
      'proposal-custom-name': {
        name: 'custom_proposal_name',
        field: 'value'
      },
      'proposal-status': {
        name: 'status',
        field: 'value'
      },
      'proposal-date-of-issue': {
        name: 'date_of_issue',
        field: 'value'
      },
      'proposal-expiration-date': {
        name: 'proposal_date',
        field: 'value'
      },
      'proposal-client-name': {
        name: 'company_id',
        field: 'value'
      },
      'proposal-site-name': {
        name: 'location_id',
        field: 'value'
      },
      'po-number': {
        name: 'annexed_po_number',
        field: 'value'
      },
      department: {
        name: 'department_id',
        field: 'id'
      },
      'work-order-number': {
        name: 'work_order_id',
        field: 'value'
      }
    }
  }
}
