import { reactive, ref, computed } from 'vue'
import repositories from '@/repositories'
import { crewsColumns } from '@/constants'
import { AutocompletePayload, AutocompleteResponse } from '@/types/interfaces/api-v2/autocomplete'
type Crews = {
  loading: boolean,
  list: Array<any>,
  latest:any,
  meta: {
    currentPage: 1,
    nextPage: 2,
    prevPage: null,
    totalCount: 0,
    totalPages: 0
  },
  alert: any
}
const crews = reactive<Crews>({
  loading: false,
  list: [],
  latest: [],
  meta: {
    currentPage: 1,
    nextPage: 2,
    prevPage: null,
    totalCount: 0,
    totalPages: 0
  },
  alert: {
    icon: 'mdi-check-circle-outline',
    color: '#0FB700',
    show: false,
    message: ''
  }
})

interface CrewsParams {
  page: number
  perPage?: any
  q?: any,
  s?: any,
  include?: any
}

export const useCrews = () => {
  const availableColumns = ref<any[]>(crewsColumns)
  const perPage = ref<number>(25)
  const page = ref<number>(1)

  const getCrews = async (params: any) => {
    try {
      const res: any = await repositories.crews.get(params)
      return {
        data: res.crews,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as any
    } catch (err) {
      console.log(err)
    }
  }

  const postCrews = async (params: any, requestBody: any) => {
    try {
      const res = await repositories.crews.post(params, requestBody)
      return res
    } catch (err: any) {
      console.error(err)
    }
  }

  const deleteCrewWithId = async (crewIds: Array<string>, q = {}) => {
    try {
      const response = await repositories.crews.delete(crewIds, q)
      return response
    } catch (error) {
      return { error }
    }
  }

  const updateCrew = async (params: any, crewId: any, requestBody: any) => {
    try {
      const res = await repositories.crews.patch(params, crewId, requestBody)
      return res
    } catch (err) {
      console.error(err)
      return false
    }
  }

  // Below API's are Deprecated

  const loadCrews = async ({ page, perPage, q, s }: CrewsParams) => {
    try {
      crews.loading = true
      const res = await repositories.crews.get({ page, perPage, q })
      res.result = res.result.filter((crew:any) => crew.type === 'crew')
      if (page === 1) {
        crews.list = res.result.length === 0 ? [] : [...res.result]
        crews.latest = res.result
      } else {
        const newCrewItems: any[] = []

        res.result.forEach((eachItem: any) => {
          const existingItemIndex = crews.list.findIndex((crew) => crew.id === eachItem.id)
          if (existingItemIndex < 0) {
            newCrewItems.push(eachItem)
          }
        })
        crews.list = [...crews.list, ...newCrewItems]
        crews.latest = res.result
      }
      crews.meta = { ...res.meta }
    } catch (err) {
      console.log(err)
    } finally {
      crews.loading = false
    }
  }
  const search = async ({ page, perPage, q, s }: CrewsParams) => {
    try {
      const res = await repositories.crews.get({ page, perPage, q })
      res.result = res.result.filter((crew:any) => crew.type === 'crew')
      return res
    } catch (err) {
      return {}
    }
  }
  const handleAppendSearchItem = async (crew: any) => {
    await appendCrew(crew)
    const element = document.getElementById(`crew-item-${crew.id}`) as HTMLElement
    if (element) {
      const tableRow = element.closest('tr')
      tableRow ? tableRow.classList.add('highlight') : element.classList.add('highlight')
      element.scrollIntoView({ behavior: 'smooth' })
      setTimeout(() => {
        tableRow ? tableRow.classList.remove('highlight') : element.classList.remove('highlight')
      }, 3000)
    }
  }
  const appendCrew = (item: any) => {
    if (!crews.list.find((eachItem) => eachItem.id === item.id)) {
      crews.list = [{ ...item }, ...crews.list]
    }
  }
  const handleSelect = (selected: any) => {
    const { item, value } = selected
    const findIndex = crews.list.findIndex((eachItem) => eachItem.id === item.id)
    crews.list[findIndex].selected = value
    crews.list = [...crews.list]
  }
  const handleSelectAll = (selected: any) => {
    const { value } = selected
    const items = [...crews.list as any[]]
    items.forEach((eachItem) => {
      eachItem.selected = value
    })
    crews.list = items
  }
  const handleInitAvailableColumns = () => {
    availableColumns.value = [...availableColumns.value]
  }
  const handleLoadMoreCrews = () => {
    page.value += 1
    loadCrews({ page: page.value, perPage: perPage.value })
  }

  const selectedItems = computed(() => {
    return (crews.list as any[]).filter((item: any) => item.selected)
  })
  const init = async () => {
    await loadCrews({ page: 1, perPage: perPage.value })
    handleInitAvailableColumns()
  }
  const getCrewById = async (crewId: string, include = 'team_members') => {
    try {
      const response = await repositories.crews.getById(crewId, include)
      return response
    } catch (error) {
      return error
    }
  }
  const crewsAutoComplete = async (params: AutocompletePayload) => {
    try {
      const res = await repositories.crews.crewsAutoComplete(params)
      return {
        data: res.results,
        nextPage: res.nextPage
      } as { data: AutocompleteResponse[], nextPage: number }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const crewsSearch = async (params: any) => {
    try {
      const res: any = await repositories.crews.crewsSearch(params)
      return {
        data: res.crews,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as any
    } catch (err) {
      console.log(err)
    }
  }

  return {
    init,
    crews,
    selectedItems,
    loadCrews,
    search,
    handleInitAvailableColumns,
    availableColumns,
    perPage,
    handleLoadMoreCrews,
    deleteCrewWithId,
    handleSelect,
    handleSelectAll,
    handleAppendSearchItem,
    getCrewById,
    getCrews,
    postCrews,
    updateCrew,
    crewsAutoComplete,
    crewsSearch
  }
}
