var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('uc-text', {
    attrs: {
      "size": 1,
      "type": 'body',
      "text": _vm.title,
      "bold": true
    }
  }), _c('uc-text', {
    staticClass: "my-2",
    staticStyle: {
      "line-height": "1.2"
    },
    attrs: {
      "size": 2,
      "type": 'body',
      "text": _vm.description,
      "bold": false
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };