import { apis } from '@/api'
import qs from 'qs'

export interface GetWorkOrderContacts {
  workOrderId: number | string
  locationId?: number
  tripId?: number
  clientId?: number
  vendorId?: number
  contactType?: string
  scopeContext?: string
  perPage?: number
  include?: string
  page?: number
  q?: any
  updateContent?: any
}

export default {
  // Contact Type Drop Down List
  getContactTypesByWorkOrderId: ({ workOrderId, scopeContext, perPage, include, page, q, tripId }: GetWorkOrderContacts) => apis.$get(`/work_orders/${workOrderId}/contact_types`, {
    params: {
      scopeContext,
      include,
      page,
      perPage,
      q,
      tripId
    },
    paramsSerializer: (params: any) => {
      return qs.stringify(params)
    }
  }),
  // Client WO Contacts
  getClientWOContact: ({ workOrderId, scopeContext, perPage, include, page, q }: GetWorkOrderContacts) => {
    return apis.$get(`/work_orders/${workOrderId}`, {
      params: {
        scope_context: scopeContext,
        include,
        page,
        per_page: perPage,
        q
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },

  // Trip's Team Member
  getWOTripCrewAndTeamMembersContacts: ({ workOrderId, tripId, perPage, include, page, q }: GetWorkOrderContacts) => apis.$get(`/work_orders/${workOrderId}/trips/${tripId}`, {
    params: {
      include,
      page,
      per_page: perPage,
      q
    },
    paramsSerializer: params => {
      return qs.stringify(params)
    }
  }),

  // Update Work Order Manager only for Broker:
  updateWorkOrderManagerContacts: (params : GetWorkOrderContacts) => {
    const { workOrderId, updateContent }: GetWorkOrderContacts = params
    return apis.$patch(`/work_orders/${workOrderId}`, {
      workOrder: {
        assignedUserIds: updateContent
      },
      paramsSerializer: (params:any) => {
        return qs.stringify(params)
      }
    })
  },

  // Update Client WO Contact only for Broker:
  updateWorkOrderClientContacts: (params : GetWorkOrderContacts) => {
    const { workOrderId, updateContent }: GetWorkOrderContacts = params
    return apis.$patch(`/work_orders/${workOrderId}`, {
      workOrder: {
        clientContactId: updateContent
      },
      paramsSerializer: (params:any) => {
        return qs.stringify(params)
      }
    })
  }
}
