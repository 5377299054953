import { ref } from 'vue'

declare global {
  interface Window {
    zEmbed: any;
    zE: any;
    $zopim: any;
    zESettings: any;
  }
}
window.zEmbed = null
window.zE = null
window.$zopim = undefined

const embedZendesk = (url: string, host:string) => {
  const queue = [] as any
  let dom: any
  let doc
  let where
  const iframe = document.createElement('iframe') as any

  window.zEmbed = function () {
    queue.push()
  }

  window.zE = window.zE || window.zEmbed
  window.zESettings = {
    webWidget: {
      helpCenter: {
        suppress: false
      }
    }
  }

  iframe.src = 'javascript:false'
  iframe.title = ''; iframe.role = 'presentation'; // a11y
  (iframe.frameElement || iframe).style.cssText = 'display: none'
  where = document.getElementsByTagName('script')
  where = where[where.length - 1]
  where?.parentNode?.insertBefore(iframe, where)

  const iWin = iframe.contentWindow as any
  const iDoc = iWin?.document as any

  try {
    doc = iDoc
  } catch (e) {
    dom = document.domain
    iframe.src = 'javascript:var d=document.open();d.domain="' + dom + '";void(0);'
    doc = iDoc
  }
  doc.open()._l = function () {
    const js = this.createElement('script')
    if (dom) this.domain = dom
    js.id = 'js-iframe-async'
    js.src = url
    this.t = +new Date()
    this.zendeskHost = host
    this.zEQueue = queue
    this.body.appendChild(js)
  }
  doc.write('<body onload="document._l();">')
  doc.close()
}

const watchForButton = ref<any>(null)
const zendeskWebWidget = () => {
  try {
    watchForButton.value = null
    embedZendesk('//assets.zendesk.com/embeddable_framework/main.js', 'raelfireprotection.zendesk.com')
    watchForButton.value = setInterval(() => {
      const launcher:any = document.getElementById('launcher')
      if (launcher !== null) {
        launcher.style.display = 'none'
        clearInterval(watchForButton.value)
      }
    }, 500)
  } catch (err) {
    console.error('Failed to initalize zendesk')
  }
}

const activateZendesk = () => {
  window.zE.activate()
}

export const useZendesk = () => {
  return {
    activateZendesk,
    zendeskWebWidget
  }
}
