import { apis } from '@/api'
import { SnoozeResource } from '@/use/compliance'
import qs from 'qs'

export default {
  snoozeVendors: (params: SnoozeResource[]) => {
    return apis.$post('/suppressed_alerts/bulk_create', {
      suppressed_alerts: params
    }, {
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  }
}
