import { api } from '@/api'
import qs from 'qs'

interface GetLaborRateListParams {
  perPage: number
  page: number
  q?: any
}

export default {
  get: ({ perPage, page, q }: GetLaborRateListParams) => api.$get('/labor_rates', {
    params: {
      page,
      per_page: perPage,
      q
    },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  getById: (id: number, params: any) => api.$get(`/labor_rates/${id}`, {
    params,
    paramsSerializer: (params: any) => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  patch: (id: number, include: string, params: any) => api.$patch(`/labor_rates/${id}`, {
    laborRate: params,
    include,
    paramsSerializer: (params: any) => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  // Depricated APIs : check specific (*_rates.ts) for APIs
  getRatesPerHour: (params:any) => {
    const { page, perPage, q }:GetLaborRateListParams = params
    return api.$get('/labor_rates', {
      params: {
        page,
        per_page: perPage,
        q
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getRatesPerService: (params:any) => {
    const { page, perPage, q }:GetLaborRateListParams = params
    return api.$get('/service_rates', {
      params: {
        page,
        per_page: perPage,
        q
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getRatesPerTask: (params:any) => {
    const { page, perPage, q }:GetLaborRateListParams = params
    return api.$get('/task_rates', {
      params: {
        include: 'task',
        page,
        per_page: perPage,
        q
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  deletePerHourRates: (params:any) => {
    return api.$delete('/labor_rates/bulk_destroy', {
      params: {
        ids: params
      }
    })
  },
  deletePerServiceRates: (params:any) => {
    return api.$delete('/service_rates/bulk_destroy', {
      params: {
        ids: params
      }
    })
  },
  deletePerTaskRates: (params:any) => {
    return api.$delete('/task_rates/bulk_destroy', {
      params: {
        ids: params
      }
    })
  }
}
