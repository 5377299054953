import { defineComponent, ref, watch, computed } from 'vue';
import { cloneDeep, kebabCase } from 'lodash';
import { useFilters } from '@/use/filters';
import { useInfiniteScroll } from '@vueuse/core';
export default defineComponent({
  props: {
    data: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Object,
      default: () => {}
    },
    currentPage: {
      type: Number,
      required: true
    },
    totalPages: {
      type: Number,
      required: true
    },
    selectedResources: {
      type: Array,
      default: () => []
    },
    selectedId: {
      type: Number,
      default: -1
    },
    listeners: {
      type: Array,
      default: () => []
    },
    hideHeader: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Congrats! No work to be done here!'
    },
    message: {
      type: String,
      default: ''
    },
    animationData: {
      type: [Function, String],
      default: () => require('@/assets/animations/DancingOtto.lottie')
    },
    iconName: {
      type: String,
      default: ''
    },
    noFilters: {
      type: Boolean,
      default: false
    },
    allowCards: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      required: true
    },
    animationDataHeight: {
      type: String,
      default: 'md'
    }
  },
  setup: (props, {
    emit
  }) => {
    const localSelectedResources = ref(cloneDeep(props.selectedResources));
    watch(() => props.selectedResources, () => {
      localSelectedResources.value = cloneDeep(props.selectedResources);
    });
    const init = () => {
      return props.listeners.reduce((acc, text) => {
        acc[kebabCase(text)] = event => {
          emit(`${kebabCase(text)}`, event);
        };
        return acc;
      }, {});
    };
    const listenerObj = ref(init());
    const emitSelectedResources = () => {
      setTimeout(() => {
        emit('update:selected-resources', localSelectedResources.value);
      });
    };
    const {
      currentRansack
    } = useFilters();
    const getHeight = computed(() => {
      if (props.animationDataHeight === 'xs') return '80px';
      if (props.animationDataHeight === 'sm') return '160px';
      if (props.animationDataHeight === 'md') return '240px';
      return '320px';
    });
    const dataIteratorRef = ref(null);
    useInfiniteScroll(dataIteratorRef, () => {
      loadMore();
    });
    const canLoadMore = computed(() => {
      return props.data.length && props.currentPage < props.totalPages && props.totalPages !== -1;
    });
    const loadMore = () => {
      if (canLoadMore.value) emit('load-more');
    };
    return {
      listenerObj,
      localSelectedResources,
      emitSelectedResources,
      currentRansack,
      getHeight,
      canLoadMore,
      dataIteratorRef
    };
  }
});