import {
  VENDOR_APPLICATION_STATUS_FILTER,
  VENDOR_APPLICATION_CREATED_DATE
} from '@/constants/filters/resources/vendor'

export const vendorApplicationFilters = () => {
  return {
    vendor: {
      filters: [
        VENDOR_APPLICATION_STATUS_FILTER({ pinned: true, isPrimary: true }),
        VENDOR_APPLICATION_CREATED_DATE({ pinned: true, isPrimary: true })
      ],
      sortBy: {
        value: 'id',
        direction: {
          text: 'Ascending',
          value: 'asc'
        },
        items: [
          { label: 'Application #', value: 'id' },
          { label: 'Status', value: 'label' }
        ]
      },
      fieldMapping: {
        'vendor-application-status': {
          name: 'label',
          field: 'text',
          transform: (text: string) => text.toLowerCase()
        },
        'vendor-application-created-date': {
          name: 'created_at'
        }
      }
    }
  }
}
