import { api } from '@/api'
import qs from 'qs'
import { AutocompletePayload } from '@/types/interfaces/api-v2/autocomplete'

interface GetRolesListParams {
  perPage: number
  page: number
  q?: any
  s?: any
}

export default {
  get: ({ perPage, page, q, s }: GetRolesListParams) => {
    return api.$get('/roles', {
      params: {
        include: 'accessible_features,features',
        page,
        per_page: perPage,
        q,
        s
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  list: ({ perPage, page, q, s }: GetRolesListParams) => {
    return api.$get('/roles', {
      params: {
        include: 'accessible_features,features,package',
        page,
        per_page: perPage,
        q,
        s
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  delete: (Ids: Array<string>) => {
    return api.$delete('/roles/bulk_destroy', {
      params: {
        ids: Ids.join(',')
      }
    })
  },
  getFeatures: (q = {}, id: number) => {
    return api.$get(`/companies/${id}/package_features_details`, {
      params: {
        q
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  postRole: (param: any) => {
    return api.$post('/roles', {
      role: param,
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  editRole: (param: any, roleID: number) => {
    return api.$patch(`/roles/${roleID}`, {
      role: param,
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  rolesAutoComplete: (params: AutocompletePayload) => {
    const { field, terms, predicate, page, perPage } = params
    return api.$get('/roles/autocomplete', {
      params: {
        field,
        terms,
        predicate,
        page,
        perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  rolesSearch: ({ perPage, page, q, s }: GetRolesListParams) => {
    return api.$post('/roles/search', {
      include: 'accessible_features,features',
      page,
      per_page: perPage,
      q,
      s
    },
    {
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  }
}
