export const analyticsReceivableFilters = () => {
  return {
    analytics: {
      filters: [],
      sortBy: {
        value: 'date_of_issue',
        direction: {
          text: 'Ascending',
          value: 'asc'
        },
        items: []
      },
      fieldMapping: {
      // ...
      }
    }
  }
}
