import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "d-flex flex-column",
    class: _vm.labelVariant ? 'py-1' : ''
  }, [!_vm.labelVariant && _vm.label ? _c('uc-text', {
    staticClass: "ma-1",
    attrs: {
      "size": 2,
      "text": _vm.label,
      "type": 'body',
      "bold": false
    }
  }) : _vm._e(), _c(VRadioGroup, {
    staticClass: "mt-0",
    attrs: {
      "column": "",
      "hide-details": "",
      "disabled": _vm.disabled
    },
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, _vm._l(_vm.buttons, function (button, index) {
    return _c(VRadio, _vm._b({
      key: index,
      attrs: {
        "disabled": !!button.disabled,
        "data-cy": "uc-radio-button"
      }
    }, 'v-radio', {
      ...button
    }, false));
  }), 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };