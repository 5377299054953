import { DetailsPanelButtonState } from '@/types/enums'
import { ResourceAction } from '@/types/interfaces'

export const inboundActionData: ResourceAction[] = [
  {
    name: 'Approve',
    textColor: 'success',
    validStatuses: [
      'sent',
      'evaluating'
    ],
    primaryAction: [
    ],
    detailsPanel: DetailsPanelButtonState.Expose
  },
  {
    name: 'Edit',
    validStatuses: [
      'draft',
      'rejected'
    ],
    primaryAction: [
      'draft'
    ]
  },
  {
    name: 'Evaluate',
    textColor: 'primary',
    validStatuses: [
      'sent'
    ],
    primaryAction: [
    ]
  },
  {
    name: 'Reject',
    textColor: 'error',
    validStatuses: [
      'sent',
      'evaluating'
    ],
    primaryAction: [
    ],
    detailsPanel: DetailsPanelButtonState.Expose
  },
  {
    name: 'Save And Approve',
    validStatuses: ['unsaved'],
    primaryAction: [
    ]
  },
  {
    name: 'Save And Receive',
    validStatuses: ['unsaved'],
    primaryAction: [
    ]
  },
  {
    name: 'Save',
    primaryAction: [
      'draft'
    ],
    validStatuses: ['draft', 'rejected'],
    detailsPanel: DetailsPanelButtonState.Only
  },
  {
    name: 'Skip',
    primaryAction: [
      'skip'
    ],
    validStatuses: true,
    textColor: 'error',
    detailsPanel: DetailsPanelButtonState.Only
  }
]
