import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.isEditMode ? _c('div', {
    staticClass: "d-flex flex-column",
    class: _vm.labelVariant ? 'py-1' : '',
    style: {
      maxWidth: _vm.maxWidth,
      minWidth: _vm.minWidth
    }
  }, [!_vm.labelVariant && _vm.label ? _c('uc-text', {
    staticClass: "ma-1",
    attrs: {
      "size": 2,
      "text": `${_vm.label}${_vm.required ? '*' : ''}`,
      "type": 'body',
      "bold": false
    }
  }) : _vm._e(), _c('uc-tooltip', {
    attrs: {
      "disabled": !_vm.tooltip.show || _vm.multiple,
      "text": _vm.tooltip.text,
      "overflow-hidden": false,
      "position": "top"
    }
  }, [_c(VSelect, {
    ref: "inputRef",
    class: ['uc-select', {
      'uc-select--dense': _vm.dense
    }, {
      'uc-select--multiple': _vm.multiple
    }, {
      'dark': _vm.$vuetify.theme.dark
    }, ..._vm.classes],
    attrs: {
      "value": _vm.localValue,
      "label": `${_vm.labelVariant && _vm.label ? _vm.label : ''}${_vm.labelVariant && _vm.label && _vm.required ? '*' : ''}`,
      "rules": _vm.localRules,
      "required": _vm.required,
      "items": _vm.localItems,
      "color": "text-disabled",
      "hide-details": _vm.hideDetails,
      "height": !_vm.multiple ? _vm.dense ? 30 : 43 : '',
      "menu-props": {
        offsetY: true,
        bottom: _vm.positionMenuBottom,
        contentClass: !_vm.$vuetify.theme.dark ? `uc-select__menu` : `uc-select__menu uc-select__menu--dark`,
        closeOnContentClick: false,
        maxWidth: _vm.menuMaxWidth || _vm.maxWidth || '500px',
        width: _vm.maxWidth || '500px'
      },
      "item-text": _vm.itemText,
      "item-value": _vm.itemValue,
      "dense": "",
      "outlined": "",
      "disabled": _vm.disabled,
      "readonly": _vm.readonly,
      "hint": _vm.hint,
      "persistent-hint": _vm.persistentHint,
      "chips": _vm.multiple,
      "return-object": _vm.returnObject,
      "multiple": _vm.multiple,
      "clearable": _vm.clearable,
      "error": _vm.error,
      "autofocus": _vm.autofocus,
      "placeholder": _vm.placeholder
    },
    on: {
      "input": function ($event) {
        _vm.handleSelection($event);
        _vm.multiple && _vm.$emit('input', $event);
        _vm.multiple && _vm.inputRef.focus();
      },
      "change": function ($event) {
        _vm.$emit('input', $event);
        _vm.$emit('change', $event);
        _vm.handleChange($event);
        _vm.inputRef.focus();
      },
      "click": function ($event) {
        _vm.$emit('click-input');
        _vm.resetSearch();
        _vm.fetchMeta.totalPages === -1 && _vm.loadData();
      },
      "blur": function ($event) {
        _vm.$emit('blur');
        _vm.hovering = false;
        !_vm.autocomplete && _vm.canDoubleClickToEdit && _vm.toggleEditMode();
      },
      "click:clear": function ($event) {
        return _vm.$emit('click:clear');
      }
    },
    scopedSlots: _vm._u([_vm.$scopedSlots.item ? {
      key: "item",
      fn: function (data) {
        return [_vm._t("item", null, {
          "data": data.item
        })];
      }
    } : !_vm.multiple ? {
      key: "item",
      fn: function ({
        item
      }) {
        return [_c('div', {
          staticClass: "d-flex flex-column overflow-hidden fill-width"
        }, [_c('uc-text', {
          attrs: {
            "data-cy": "select-row",
            "type": "body",
            "size": 1,
            "text": typeof item === 'string' ? item : item[_vm.itemText],
            "ellipses": true,
            "bold": true
          }
        }), item !== null && item !== void 0 && item[_vm.itemSubtext] ? _c('uc-text', {
          attrs: {
            "data-cy": "select-row",
            "type": "body",
            "size": 3,
            "text": _vm.startCase(item[_vm.itemSubtext]),
            "ellipses": true
          }
        }) : _vm._e()], 1)];
      }
    } : null, _vm.$slots.selection ? {
      key: "selection",
      fn: function () {
        return [_vm._t("selection")];
      },
      proxy: true
    } : _vm.$scopedSlots.selection ? {
      key: "selection",
      fn: function ({
        item,
        index
      }) {
        return [_vm._t("selection", null, {
          "item": item,
          "index": index
        })];
      }
    } : _vm.numberOfChips !== -1 && _vm.multiple === true ? {
      key: "selection",
      fn: function ({
        item,
        index
      }) {
        return [_c('div', [index < _vm.numberOfChips ? _c('uc-chip', {
          attrs: {
            "size": _vm.chipSize,
            "closeable": _vm.multiple,
            "text": typeof item === 'string' ? item : item[_vm.itemText]
          },
          on: {
            "click": function ($event) {
              return _vm.$emit('click', typeof item === 'string' ? item : item[_vm.itemText]);
            },
            "click:close": function ($event) {
              _vm.localValue.splice(index, 1);
              _vm.$emit('change', _vm.localValue);
              _vm.handleChange(_vm.localValue);
            }
          }
        }) : _vm._e()], 1), index === _vm.numberOfChips ? _c('uc-tooltip', {
          attrs: {
            "disabled": false,
            "position": "top",
            "array": index === _vm.numberOfChips ? _vm.getChipsArray() : null,
            "is-content-clickable": ""
          },
          on: {
            "click": function ($event) {
              return _vm.$emit('click', $event);
            }
          }
        }, [_c('uc-text', {
          attrs: {
            "size": 1,
            "type": "body",
            "text": `(+${+_vm.value.length - _vm.numberOfChips} others)`
          }
        })], 1) : _vm._e()];
      }
    } : _vm.multiple === true ? {
      key: "selection",
      fn: function ({
        item
      }) {
        return [_c('div', [_c('uc-chip', {
          attrs: {
            "size": "xs",
            "text": typeof item === 'string' ? item : item[_vm.itemText]
          }
        })], 1)];
      }
    } : {
      key: "selection",
      fn: function ({
        item
      }) {
        return [_c('div', {
          staticClass: "d-flex flex-row overflow-hidden fill-width"
        }, [_c('uc-text', {
          attrs: {
            "size": 1,
            "type": "body",
            "text": typeof item === 'string' ? item : item[_vm.itemText],
            "ellipses": true
          },
          on: {
            "click": function ($event) {
              return _vm.$emit('click', typeof item === 'string' ? item : item[_vm.itemText]);
            }
          }
        })], 1)];
      }
    }, _vm.autocomplete ? {
      key: "prepend-item",
      fn: function () {
        return [_c('uc-search', {
          ref: "searchRef",
          staticClass: "pa-2",
          attrs: {
            "autofocus": true,
            "data-cy": "search-data",
            "placeholder": _vm.canAddNewItem ? `Search List or click + Add New ${_vm.startCase(_vm.addNewItemLabel || _vm.label)} to add ${_vm.startCase(_vm.addNewItemLabel || _vm.label)}` : _vm.searchPlaceholder,
            "token-separators": _vm.tokenSeparators
          },
          on: {
            "search-input": _vm.reset,
            "blur": function ($event) {
              _vm.canDoubleClickToEdit && _vm.toggleEditMode();
            }
          },
          model: {
            value: _vm.searchValue,
            callback: function ($$v) {
              _vm.searchValue = $$v;
            },
            expression: "searchValue"
          }
        })];
      },
      proxy: true
    } : null, {
      key: "append-item",
      fn: function () {
        return [_vm.skeletonLoader ? _c('uc-list-row-loading', {
          attrs: {
            "first-row": false,
            "last-row": true,
            "size": 'sm'
          },
          on: {
            "intersect": function ($event) {
              _vm.fetchFn && _vm.hasNextPage && _vm.loadData(_vm.searchValue);
            }
          }
        }) : !_vm.skeletonLoader && _vm.localItems.length === 0 && !_vm.canAddNewItem ? _c('div', {
          staticClass: "d-flex justify-center"
        }, [_c('uc-text', {
          staticClass: "mx-2 mt-2 mb-3",
          attrs: {
            "size": 1,
            "type": 'body',
            "text": 'No Data Found'
          }
        })], 1) : _vm.canAddNewItem ? _c('div', {
          staticClass: "d-flex justify-center",
          on: {
            "click": function ($event) {
              return _vm.$emit('add-new-item', _vm.searchRef.localValue);
            }
          }
        }, [_c('uc-text', {
          staticClass: "mx-2 mt-2 mb-3",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "size": 1,
            "type": 'body',
            "text": `+ Add New ${_vm.startCase(_vm.addNewItemLabel || _vm.label)}`,
            "bold": true
          }
        })], 1) : _vm._e()];
      },
      proxy: true
    }, {
      key: "no-data",
      fn: function () {
        return [_c('div')];
      },
      proxy: true
    }, {
      key: "prepend-inner",
      fn: function () {
        return [_vm._t("prepend-inner")];
      },
      proxy: true
    }], null, true)
  })], 1)], 1) : _c('div', _vm._g({
    staticClass: "no-select d-flex flex-column align-start justify-center pa-1 overflow-hidden",
    class: _vm.hovering && (!_vm.disabled || !_vm.readonly) ? 'editable-hover' : '',
    style: {
      maxWidth: _vm.maxWidth
    },
    on: {
      "dblclick": function ($event) {
        $event.stopPropagation();
        _vm.canDoubleClickToEdit && !_vm.disabled && _vm.toggleEditMode();
      },
      "mouseenter": function ($event) {
        _vm.canDoubleClickToEdit && !_vm.disabled ? _vm.hovering = true : _vm.hovering = false;
      },
      "mouseleave": function ($event) {
        _vm.hovering = false;
      }
    }
  }, _vm.$listeners), [_vm.showLabelOnReadOnly ? _c('uc-text', {
    attrs: {
      "size": 3,
      "type": 'body',
      "darken": true,
      "text": _vm.label
    }
  }) : _vm._e(), _c('div', {
    staticClass: "d-flex flex-row align-center justify-space-between fill-width fill-height"
  }, [_vm.readOnlyDisplayType === 'chip' && _vm.multiple ? _c('div', {
    staticClass: "d-flex flex-row align-center gap-md flex-wrap mt-1"
  }, [_vm._l(_vm.localValue, function (item, index) {
    return [index < _vm.numberOfChips ? _c('div', {
      key: index
    }, [_c('uc-chip', {
      attrs: {
        "size": _vm.chipSize,
        "text": typeof item === 'string' ? item : item[_vm.itemText],
        "tooltip-position": "top",
        "prepend-icon": item.icon,
        "tooltip-text": typeof item === 'string' ? item : item[_vm.itemText]
      },
      on: {
        "click": function ($event) {
          return _vm.$emit('click', typeof item === 'string' ? item : item[_vm.itemText]);
        }
      }
    })], 1) : _vm._e()];
  }), _vm.showToolTipWhenInMultiTextMode && _vm.localValue.length > _vm.numberOfChips ? _c('uc-tooltip', {
    attrs: {
      "disabled": false,
      "position": "top",
      "array": _vm.getChipsArray(),
      "is-content-clickable": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click', $event);
      }
    }
  }, [_c('uc-text', {
    attrs: {
      "size": 3,
      "bold": true,
      "type": "body",
      "text": ` (+${+_vm.localValue.length - _vm.numberOfChips} others)`
    }
  })], 1) : _vm._e(), !_vm.localValue.length ? _c('uc-text', {
    ref: "displayTextRef",
    staticClass: "my-1",
    class: _vm.showToolTipWhenInMultiTextMode ? 'mr-1' : '',
    attrs: {
      "size": _vm.displayTextSize,
      "type": 'body',
      "bold": true,
      "ellipses": true,
      "text": _vm.displayText()
    },
    on: {
      "click": function ($event) {
        _vm.$emit('click', _vm.displayText());
      }
    }
  }) : _vm._e()], 2) : _vm._e(), !_vm.multiple && _vm.readOnlyDisplayType === 'chip' ? _c('div', [_c('uc-chip', {
    attrs: {
      "size": _vm.chipSize,
      "text": typeof _vm.localValue === 'string' ? _vm.localValue : _vm.localValue[_vm.itemText],
      "tooltip-position": "top",
      "prepend-icon": _vm.localValue.icon,
      "tooltip-text": typeof _vm.localValue === 'string' ? _vm.localValue : _vm.localValue[_vm.itemText]
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click', typeof _vm.localValue === 'string' ? _vm.localValue : _vm.localValue[_vm.itemText]);
      }
    }
  })], 1) : _vm._e(), _vm.readOnlyDisplayType === 'text' ? _c('div', {
    staticClass: "d-flex flex-row justify-start align-center overflow-hidden",
    style: _vm.isContentClickable ? 'cursor: pointer' : ''
  }, [_c('uc-tooltip', {
    staticClass: "overflow-hidden",
    attrs: {
      "disabled": _vm.isEllipses(_vm.$refs.displayTextRef) || _vm.showToolTipWhenInMultiTextMode,
      "position": "top",
      "text": _vm.displayText()
    }
  }, [_c('uc-text', {
    ref: "displayTextRef",
    staticClass: "my-1",
    class: _vm.showToolTipWhenInMultiTextMode ? 'mr-1' : '',
    attrs: {
      "size": _vm.displayTextSize,
      "type": 'body',
      "bold": true,
      "ellipses": true,
      "text": _vm.displayText()
    },
    on: {
      "click": function ($event) {
        _vm.$emit('click', _vm.displayText());
      }
    }
  })], 1), _vm.showToolTipWhenInMultiTextMode ? _c('uc-tooltip', {
    attrs: {
      "disabled": false,
      "position": "top",
      "array": _vm.getChipsArray(),
      "is-content-clickable": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click', $event);
      }
    }
  }, [_c('uc-text', {
    attrs: {
      "size": 3,
      "bold": true,
      "type": "body",
      "text": ` (+${+_vm.localValue.length - 1} others)`
    }
  })], 1) : _vm._e()], 1) : _vm._e(), _c('div', {
    staticClass: "d-flex flex-row align-center gap-md"
  }, [_vm.showCopyIcon ? _c('uc-copy-button', {
    attrs: {
      "value": _vm.displayText(),
      "disabled": !_vm.localValue
    }
  }) : _vm._e(), _vm.infoToolTipObject || _vm.infoToolTipText ? _c('uc-tooltip', {
    attrs: {
      "position": "bottom",
      "object": _vm.infoToolTipObject,
      "text": _vm.infoToolTipText
    }
  }, [_c('uc-icon', {
    attrs: {
      "value": _vm.INFO_OUTLINE,
      "size": 'sm'
    }
  })], 1) : _vm._e()], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };