import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import Intersect from 'vuetify/lib/directives/intersect';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('uc-list-row-base', {
    directives: [{
      def: Intersect,
      name: "intersect",
      rawName: "v-intersect",
      value: {
        handler: _vm.emitIntersect
      },
      expression: "{\n    handler: emitIntersect,\n  }"
    }],
    ref: "listRow",
    staticClass: "uc-list-row-loading d-flex flex-1",
    attrs: {
      "can-select": false,
      "can-expand": false,
      "is-expanded": false,
      "is-selected": false,
      "first-row": _vm.firstRow,
      "last-row": _vm.lastRow,
      "column-defs": _vm.columnDefs,
      "show-actions": _vm.columnDefs.showActions,
      "size": _vm.size,
      "height": '44px'
    },
    scopedSlots: _vm._u([_vm._l(_vm.loaderColumnNames, function (columnName) {
      return {
        key: columnName,
        fn: function () {
          return [_c(VSkeletonLoader, {
            key: columnName,
            staticClass: "flex-1 mx-2",
            attrs: {
              "type": "table-cell"
            }
          })];
        },
        proxy: true
      };
    }), _vm.loaderColumnNames.length ? {
      key: _vm.ACTIONS_COL.templateName,
      fn: function () {
        return [_c(VSkeletonLoader, {
          staticClass: "flex-1 mx-2",
          attrs: {
            "type": "table-cell"
          }
        })];
      },
      proxy: true
    } : null, {
      key: "columns",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex justify-space-around",
          staticStyle: {
            "flex": "1",
            "gap": "40px",
            "width": "100%"
          }
        }, [_c(VSkeletonLoader, {
          staticClass: "flex-1",
          attrs: {
            "type": 'table-cell',
            "width": _vm.size !== 'xs' ? 'auto !important' : ''
          }
        }), _vm.size !== 'sm' ? _c(VSkeletonLoader, {
          staticClass: "flex-1",
          attrs: {
            "type": "table-cell",
            "width": 'auto !important'
          }
        }) : _vm._e(), _vm.size !== 'sm' ? _c(VSkeletonLoader, {
          staticClass: "flex-1",
          attrs: {
            "type": "table-cell",
            "width": 'auto !important'
          }
        }) : _vm._e(), _vm.size === 'lg' ? _c(VSkeletonLoader, {
          staticClass: "flex-1",
          attrs: {
            "type": "table-cell",
            "width": 'auto !important'
          }
        }) : _vm._e(), _vm.size === 'lg' ? _c(VSkeletonLoader, {
          staticClass: "flex-1",
          attrs: {
            "type": "table-cell",
            "width": 'auto !important'
          }
        }) : _vm._e(), _vm.size === 'lg' ? _c(VSkeletonLoader, {
          staticClass: "flex-1",
          attrs: {
            "type": "table-cell",
            "width": 'auto !important'
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }], null, true)
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };