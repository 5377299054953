import "core-js/modules/es.array.push.js";
import { defineComponent, ref, watch } from 'vue';
import { ATTACHMENTS_HEADERS } from '@/constants/headers/attachments';
import { upperFirst, lowerCase, cloneDeep } from 'lodash';
import { EXPORT, INBOX } from '@/constants/icons';
import { PROPOSAL_RESOURCE_TYPE } from '@/constants/resource-types';
import { imageDisplayOptionsForPDF, imageMimeTypes } from '@/constants/attachments';
export default defineComponent({
  props: {
    attachment: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'lg'
    },
    canSelect: {
      type: Boolean,
      default: false
    },
    showActions: {
      type: Boolean,
      default: false
    },
    canExpand: {
      type: Boolean,
      default: false
    },
    isExpanded: {
      type: Boolean,
      default: false
    },
    columnDefs: {
      type: Object,
      default: () => ATTACHMENTS_HEADERS()
    },
    visibilityKeys: {
      type: Array,
      default: () => []
    },
    resourceType: {
      type: String,
      required: true
    }
  },
  setup: (props, {
    emit
  }) => {
    const thumbnailSize = ref('40px');
    const downloadAttachment = () => {
      window.open(props.attachment.downloadUrl);
    };
    const localAttachment = ref(cloneDeep(props.attachment));
    const attachmentEdit = ref(cloneDeep(props.attachment));
    watch(() => props.attachment, (value, oldValue) => {
      localAttachment.value = cloneDeep(props.attachment);
      // check if the updated attachment is the same object
      if (value.id === oldValue.id) {
        emit('update', localAttachment.value);
      }
    });
    const getCleanedText = text => {
      // key always starts with "make" and we want to remove that text
      return upperFirst(lowerCase(text.split('make')[1]));
    };
    const cancel = () => {
      localAttachment.value = cloneDeep(props.attachment);
    };
    const attachmentModalRef = ref(false);
    let menuActions = [];
    if (!String(props.attachment.id).includes('temp')) {
      menuActions.push({
        text: 'DOWNLOAD',
        icon: '',
        textColor: ''
      });
    }
    if (props.visibilityKeys.length) {
      menuActions = menuActions.concat([{
        text: 'Edit',
        icon: '',
        textColor: ''
      }, {
        text: 'Delete',
        icon: '',
        textColor: 'error'
      }]);
    }
    const handleEditCancel = refs => {
      cancel();
      attachmentEdit.value = cloneDeep(props.attachment);
      refs.attachmentModalRef.close();
    };
    const handleEditSave = refs => {
      emit('save', attachmentEdit.value);
      refs.attachmentModalRef.close();
    };
    return {
      downloadAttachment,
      thumbnailSize,
      INBOX,
      upperFirst,
      lowerCase,
      menuActions,
      attachmentModalRef,
      localAttachment,
      getCleanedText,
      cancel,
      EXPORT,
      attachmentEdit,
      handleEditCancel,
      handleEditSave,
      PROPOSAL_RESOURCE_TYPE,
      imageDisplayOptionsForPDF,
      imageMimeTypes
    };
  }
});