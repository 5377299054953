import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    size: {
      type: Number,
      default: 1
    },
    vertical: {
      type: Boolean,
      default: false
    }
  }
});