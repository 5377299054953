import { api } from '@/api'
import qs from 'qs'

interface GetNotesListParams {
  resourceId: string,
  resourceType: string,
  perPage?: number,
  page?: number,
  q?: any,
  s?: any,
  include?: any
}
// resourceType: work_orders, invoices, inbound_invoices, etc
// resourceId: workOrder.id, invoice.id (selectedInvoice.value.id)

export default {
  get: ({ resourceId, resourceType, perPage, page, include, q, s }:GetNotesListParams) => {
    return api.$get(`/${resourceType}/${resourceId}/notes`, {
      params: {
        resourceId,
        resourceType,
        include,
        q,
        page,
        per_page: perPage,
        s
      },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    })
  },
  post: (params: any, note: any) => {
    return api.$post(`/${params.resourceType}/${params.resourceId}/notes`, {
      note,
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  delete: (params: any) => api.$delete(`/${params.resourceType}/${params.resourceId}/notes/${params.id}`)
}
