import { RESET_PASSWORD_REDIRECT_URL } from '@/constants/env'
import { apis } from '@/api'
import qs from 'qs'

interface userEmail {
  email: string
}
interface passwords {
  password: string
  // eslint-disable-next-line camelcase
  password_confirmation: string
  // eslint-disable-next-line camelcase
  reset_password_token: string
}

interface SendOTP {
  type?: string
  value?: string
  name?: string
  otpFor?: string
  recaptchaToken?: string
  uuid?: string
  provider?: string
}
interface userEmailOrPhone {
  emailOrPhone: string
  recaptchaToken: string
}
interface User {
  newPassword?: string
  confirmNewPassword?: string
  mode?: string
  otpPin?: number
  value?: string
}
interface ssoUser {
  uuid: string | number
  accessToken: string
  subDomain: string
}

export default {
  login: (user: any) =>
    apis.$post('/users/session', {
      user
    }),

  signInViaRails: (user:any) =>
    apis.$post('/login', {
      user
    }, { headers: { Accept: 'application/json' } }),

  signOutViaRails: () =>
    apis.$delete('/sign_out', { headers: { Accept: 'application/json' } }),
  ssoAuth: (provider: string, userObj: ssoUser) => {
    return apis.$post(`/sso/${provider}/auth`, { user: { access_token: userObj.accessToken, uuid: userObj.uuid, sub_domain: userObj.subDomain } })
  },
  logout: () => apis.$get('/users/profile'),

  forgot: (user: userEmail) =>
    apis.$post('/users/reset-password', {
      user: { ...user, url: RESET_PASSWORD_REDIRECT_URL }
    }),

  updatePassword: (passwordData: passwords) =>
    apis.$put('/users/update-password', {
      user: passwordData
    }),

  userProfile: ({ include }: any) => apis.$get('/users/profile', {
    params: {
      include
    },
    paramsSerializer: params => {
      return qs.stringify(params)
    }
  }),
  signup: (user: any) => {
    return apis.post('/users/registration', user, {
      headers: {
        content_type: 'multipart/form-data'
      }
    })
  },
  sendOtp: ({ type, value, name, otpFor, recaptchaToken, uuid, provider }: SendOTP) =>
    apis.$post('/users/send_otp', {
      recipient_type: type,
      recipient_value: value,
      recipient_name: name,
      otp_for: otpFor,
      'g-recaptcha-response': recaptchaToken,
      uuid,
      provider
    }),
  checkContactDetails: ({ emailOrPhone, recaptchaToken }: userEmailOrPhone) => {
    return apis.post('/users/profile/check_contact_details', { emailOrPhone, 'g-recaptcha-response': recaptchaToken })
  },
  resetPassword: ({ newPassword, confirmNewPassword, mode, otpPin, value }: User, recaptchaToken: string) => apis.$put('/users/update-password', {
    user: {
      password: newPassword,
      password_confirmation: confirmNewPassword,
      communication_mode: mode,
      pin: otpPin,
      email_or_phone: value
    },
    'g-recaptcha-response': recaptchaToken
  }),
  getInvitedMemberDetails: (token: any) => apis.$get(`/user/${token}`),
  saveInviteTeamMemberDetails: ({ payload }: any) =>
    apis.$patch('/users/invitation', payload)
}
