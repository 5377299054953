
import {
  PO_NUMBER,
  PO_CUSTOM_NUMBER,
  PO_STATUS,
  PO_DATE_OF_ISSUE
} from '@/constants/filters/resources/purchaseOrder'
import { VENDOR_NAME } from '@/constants/filters/resources/vendor'
import { useOutboundPurchaseOrders } from '@/use/purchase-orders/outbound'
import { AutocompleteResponse, AutocompletePayload } from '@/types/interfaces/api-v2/autocomplete'
import { WORK_ORDER_NUMBER } from '../resources/workOrder'

const { purchaseOrdersAutoComplete } = useOutboundPurchaseOrders()

export const allOutboundPurchaseOrderFilters = () => {
  const purchaseOrdersAutoCompleteFetchFn = async (params: AutocompletePayload) => {
    const { data, nextPage } = await purchaseOrdersAutoComplete(params) as { data: AutocompleteResponse[], nextPage: number }
    return {
      data: data.map((item: AutocompleteResponse, index: number) => {
        return {
          id: `${index}-${item.text}`,
          text: item.text,
          value: item.text
        }
      }),
      nextPage
    }
  }

  return {
    filters: [
      PO_NUMBER(true, { pinned: true, isPrimary: true }, { field: 'po_number' }, purchaseOrdersAutoCompleteFetchFn),
      PO_CUSTOM_NUMBER(true, { pinned: false, isPrimary: false }, { field: 'custom_po_number' }, purchaseOrdersAutoCompleteFetchFn),
      PO_STATUS(true, { pinned: true, isPrimary: true }),
      PO_DATE_OF_ISSUE(true, { pinned: true, isPrimary: true }),
      WORK_ORDER_NUMBER({ pinned: true, isPrimary: true }, { field: 'wo_id', invoiceables: false }, purchaseOrdersAutoCompleteFetchFn),
      VENDOR_NAME({ pinned: true, isPrimary: true }, { field: 'vendor', tab: 'active' }, purchaseOrdersAutoCompleteFetchFn)
    ],
    sortBy: {
      value: 'po_number',
      direction: {
        text: 'Ascending',
        value: 'asc'
      },
      items: [
        {
          label: 'PO #',
          value: 'po_number'
        },
        {
          label: 'Status',
          value: 'status'
        },
        {
          label: 'Date of Issue',
          value: 'date_of_issue'
        },
        {
          label: 'Amount',
          value: 'amount_cents'
        }
      ]
    },
    fieldMapping: {
      'purchase-order-number': {
        name: 'po_number',
        field: 'value'
      },
      'purchase-order-custom-number': {
        name: 'custom_po_number',
        field: 'value'
      },
      'purchase-order-status': {
        name: 'status',
        field: 'value'
      },
      'purchase-order-date-of-issue': {
        name: 'date_of_issue',
        field: 'value'
      },
      'work-order-number': {
        name: 'work_order_id',
        field: 'value'
      },
      'vendor-name': {
        name: 'vendor_id',
        field: 'value'
      }
    }
  }
}
