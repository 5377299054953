import { VCheckbox } from 'vuetify/lib/components/VCheckbox';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_vm.label ? _c('uc-text', {
    attrs: {
      "text": `${_vm.label}${_vm.required ? '*' : ''}`,
      "type": "body",
      "size": 3,
      "palette-color": _vm.hasError ? 'error' : ''
    }
  }) : _vm._e(), _vm.canSelectAll ? _c(VCheckbox, {
    staticClass: "ma-2",
    attrs: {
      "label": 'Select All',
      "hide-details": true
    },
    on: {
      "change": function ($event) {
        return _vm.updateSelectAll();
      }
    },
    model: {
      value: _vm.allSelected,
      callback: function ($$v) {
        _vm.allSelected = $$v;
      },
      expression: "allSelected"
    }
  }) : _vm._e(), _vm._l(_vm.localCheckboxes, function (check) {
    return _c('div', {
      key: check.value
    }, [_c('div', {
      class: _vm.customLabel ? 'd-flex' : ''
    }, [_c(VCheckbox, {
      class: [_vm.canSelectAll ? 'ma-2' : 'ma-0'],
      attrs: {
        "input-value": _vm.selected,
        "disabled": _vm.disabled,
        "label": _vm.customLabel ? '' : check[_vm.itemText] || check[_vm.itemValue],
        "value": check[_vm.itemValue],
        "hide-details": true,
        "readonly": _vm.readonly,
        "error": _vm.hasError,
        "required": _vm.required,
        "rules": _vm.required ? [v => !!v.length || 'Field is required'] : []
      },
      on: {
        "change": function ($event) {
          return _vm.handleChange($event);
        }
      }
    }), _vm.customLabel ? _c('div', [_c('uc-input', {
      staticClass: "ma-1",
      attrs: {
        "hide-details": "true"
      },
      model: {
        value: check[_vm.itemText],
        callback: function ($$v) {
          _vm.$set(check, _vm.itemText, $$v);
        },
        expression: "check[itemText]"
      }
    })], 1) : _vm._e()], 1)]);
  })], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };