import { useLocations } from '@/use/locations'
import COUNTRY_MAP from '@/constants/countries'
import { useCurrentLocation } from '@/use/current_location'
import { useApp } from '@/use/app'
import { BROKER, CLIENT, VENDOR } from '@/constants/permissions'
import { computed } from 'vue'
import { useRegions } from '@/use/regions'
import { useUsersCompanies } from '@/use/users-companies'
import { AutocompletePayload, AutocompleteResponse } from '@/types/interfaces/api-v2/autocomplete'
import { FilterResources } from '@/types/enums/filter-types'

const { getLocations, getLocationsNearBy, locationsAutoComplete } = useLocations()
const { currentLocation } = useCurrentLocation()
const { companyType } = useApp()
const { getRegions } = useRegions()
const { isPaidCompany } = useUsersCompanies()
const getSitesExportQuery = () => {
  const exportQuery = {
    tmiwo: true,
    lat: currentLocation.latitude,
    lng: currentLocation.longitude,
    with_extended_status: true
  }
  return exportQuery
}

const getLocationParamsForSiteName = computed(() => {
  if (companyType.value === CLIENT) {
    return {
      tab: 'own_sites',
      roles: ['client']
    }
  } else if (companyType.value === VENDOR) {
    return {
      roles: ['contractor']
    }
  } else {
    if (!isPaidCompany && companyType.value === BROKER) {
      return {
        roles: ['contractor']
      }
    }
    return {}
  }
})

const getLocationParamsForSiteCountry = computed(() => {
  return companyType.value === CLIENT
    ? {
        include: 'address',
        q: { ...getSitesExportQuery(), s: 'site_address_country asc' },
        tab: 'own_sites',
        roles: ['client']
      }
    : {
        include: 'address',
        q: { s: 'site_address_country asc' }
      }
})

const getLocationParamsForSiteCity = computed(() => {
  return companyType.value === CLIENT
    ? {
        include: 'address',
        q: { ...getSitesExportQuery(), s: 'site_address_city asc' },
        tab: 'own_sites',
        roles: ['client']
      }
    : {
        include: 'address',
        q: { s: 'site_address_city asc' }
      }
})

const getLocationParamsForSiteZip = computed(() => {
  return companyType.value === CLIENT
    ? {
        include: 'address',
        q: { ...getSitesExportQuery(), s: 'site_address_zip_code asc' },
        tab: 'own_sites',
        roles: ['client']
      }
    : {
        include: 'address',
        q: { s: 'site_address_zip_code asc' }
      }
})

const getLocationParamsForSiteAddress = computed(() => {
  return companyType.value === CLIENT
    ? {
        include: 'address',
        q: { ...getSitesExportQuery(), s: 'site_address_full_address asc' },
        tab: 'own_sites',
        roles: ['client']
      }
    : {
        include: 'address',
        q: { s: 'site_address_full_address asc' }
      }
})

const getLocationParamsForSiteRegion = computed(() => {
  return companyType.value === CLIENT
    ? {
        include: 'territory',
        q: { ...getSitesExportQuery(), s: 'territory_name_cont asc', territory_name_present: true },
        tab: 'own_sites',
        roles: ['client']
      }
    : {
        include: 'territory',
        q: { s: 'territory_name_cont asc', territory_name_present: true }
      }
})

const locationsAutoCompleteFetchFn = async (params: AutocompletePayload) => {
  const { data, nextPage } = await locationsAutoComplete({ ...params }) as { data: AutocompleteResponse[], nextPage: number}
  return {
    data: data.map((item: AutocompleteResponse, index: number) => {
      return {
        id: `${index}-${item.text}`,
        text: item.text,
        value: item.text
      }
    }),
    nextPage
  }
}

const SITE_NAME = (config = {}, params = { field: 'location', invoiceables: false }, fetchFn: any = locationsAutoCompleteFetchFn) => {
  return {
    text: 'Name',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'site-name-filter',
      headerText: 'Site Name',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params: {
        ...params, ...getLocationParamsForSiteName?.value
      },
      predicate: '_cont',
      searchKeys: ['name']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.SITE,
    ...config
  }
}

const SITE_COUNTRY = (config = {}, params = { field: 'country', tab: 'active' }, fetchFn: any = locationsAutoCompleteFetchFn) => {
  return {
    text: 'Country',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'site-country-filter',
      headerText: 'Site Country',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: []
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.SITE,
    ...config
  }
}

const SITE_STATE = (config = {}, params = { field: 'state', tab: 'active' }, fetchFn: any = locationsAutoCompleteFetchFn) => {
  return {
    text: 'State',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'site-state-filter',
      headerText: 'Site State',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['address_state']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.SITE,
    ...config
  }
}

const SITE_CITY = (config = {}, params = { field: 'city', tab: 'active' }, fetchFn: any = locationsAutoCompleteFetchFn) => {
  return {
    text: 'City',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'site-city-filter',
      headerText: 'Site City',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: []
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.SITE,
    ...config
  }
}

const SITE_ZIP = (config = {}, params = { field: 'zip', tab: 'active' }, fetchFn: any = locationsAutoCompleteFetchFn) => {
  return {
    text: 'Zip',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'site-zip-filter',
      headerText: 'Site Zip',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: []
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.SITE,
    ...config
  }
}

const SITE_ADDRESS = (config = {}, params = { field: 'address1', tab: 'active' }, fetchFn: any = locationsAutoCompleteFetchFn) => {
  return {
    text: 'Address',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'site-address-filter',
      headerText: 'Site Address',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: []
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.SITE,
    ...config
  }
}

const SITE_REGION = (config = {}, params = { field: 'site_region', invoiceables: false }, fetchFn: any = locationsAutoCompleteFetchFn) => {
  return {
    text: 'Region',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'site-region-filter',
      headerText: 'Site Region',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: []
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.SITE,
    ...config
  }
}

const SITE_IN_CARE_OF = (config = {}, params = { field: 'in_care_of', tab: 'active' }, fetchFn: any = locationsAutoCompleteFetchFn) => {
  return {
    text: 'In Care Of',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'site-in-care-of-filter',
      headerText: 'Site In Care Of',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: []
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.SITE,
    ...config
  }
}

const SITE_CUSTOM_ID = (config = {}, params = { field: 'custom_id', tab: 'active' }, fetchFn: any = locationsAutoCompleteFetchFn) => {
  return {
    text: 'Custom Id',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'site-custom-id-filter',
      headerText: 'Site Custom Id',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: []
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.SITE,
    ...config
  }
}

export {
  SITE_NAME,
  SITE_COUNTRY,
  SITE_CITY,
  SITE_ZIP,
  SITE_ADDRESS,
  SITE_REGION,
  SITE_STATE,
  SITE_IN_CARE_OF,
  SITE_CUSTOM_ID
}
