import { api } from '@/api'
import qs from 'qs'

interface getWorkOrdersParams {
  scope?: string
  include?: string
  q?: any
  page?: number
  perPage?: number
}

export default {
  getOutBoundWorkOrders: ({ scope, include, q, page, perPage }: getWorkOrdersParams) => {
    return api.$get('/work_orders', {
      params: {
        scope,
        include,
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },
  getWorkOrderById: ({ id, include }: { id: string, include: string }) => {
    return api.$get(`/work_orders/${id}`, {
      params: {
        include
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  }
}
