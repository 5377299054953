import { api } from '@/api'
import qs from 'qs'
import { NewBackgroundTask } from '@/types/interfaces'

export default {
  post: (backgroundTaskParams :NewBackgroundTask, status: string) => {
    return api.$post(
      '/bulk_action_tasks',
      { bulk_action_task: { ...backgroundTaskParams }, status }
    )
  },
  confirm: (taskId: number, bulkActionConfirmParams?: any) => {
    return api.$patch(`/bulk_action_tasks/${taskId}/confirm`, {
      bulk_action_task: { ...bulkActionConfirmParams }
    }, {
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  cancel: (taskId: number) => {
    return api.$patch(`/bulk_action_tasks/${taskId}/cancel`)
  },
  getStats: (taskId: number) => {
    return api.$get(`/bulk_action_tasks/${taskId}/stats`)
  }
}
