import { api } from '@/api'
import qs from 'qs'
import { PostWorkOrderMaterialParams, UpdateWorkOrderMaterialParams, GetWorkOrderMaterialsParams } from '@/types/interfaces/api-v2/work-order-material-resource'

export default {
  get: (params: GetWorkOrderMaterialsParams) => {
    const { workOrderId, include, q, page, perPage } = params
    return api.$get(`/work_orders/${workOrderId}/work_order_materials`, {
      params: {
        include,
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    })
  },
  delete: (workOrderId: number, materialId: number) => api.$delete(
    `/work_orders/${workOrderId}/work_order_materials/${materialId}`
  ),
  patch: (workOrderId: number, materialId: number, params: UpdateWorkOrderMaterialParams) => api.$patch(`/work_orders/${workOrderId}/work_order_materials/${materialId}`, {
    workOrderMaterial: params,
    paramsSerializer: (params: any) => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  bulkAddWorkOrderMaterials: (workOrderId: number, params: PostWorkOrderMaterialParams[]) => api.$post(`/work_orders/${workOrderId}/work_order_materials/bulk_create`, {
    workOrderMaterials: params,
    paramsSerializer: (params: any) => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  })
}
