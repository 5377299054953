export const allowedRoutes: string[] = [
  '/pricing',
  '/login',
  '/signup',
  '/join-confirmation',
  '/claim-confirmation',
  '/onboarding',
  '/company/edit',
  '/app-store',
  '/ar-ap-proto',
  '/pending',
  '/approval/pending',
  '/sso',
  '/lock',
  '/work-orders'
]
export const allowedRoutesStartsWith: string[] = [
  '/login',
  '/signup',
  '/onboarding',
  '/company/edit',
  '/app-store',
  '/ar-ap-proto',
  '/pending',
  '/approval/pending',
  '/sso',
  '/lock',
  '/work-orders'
]
