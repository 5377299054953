import "core-js/modules/es.array.push.js";
import { defineComponent, computed, nextTick, onMounted, onUnmounted, reactive, ref, watch } from 'vue';
import { useApp } from '@/use/app';
import events from '@/events';
import { generateRows, canInjectIntoTable, fetchAndGenerateChildDataWithFailure } from '@/utils/table';
import { cloneDeep, debounce, isEqual, kebabCase, startCase, throttle } from 'lodash';
import { useFilters } from '@/use/filters';
import { actionToastProps } from '@/constants/toast';
import { onKeyStroke, onKeyDown, onKeyUp, onClickOutside } from '@vueuse/core';
import { useTransactionResources } from '@/use/transactional-resources';
import { getDynamicComponent } from '@/components';
import { useRouter, useRoute } from 'vue-router/composables';
import { CHECK } from '@/constants/icons';
import { useSerialQueue } from '@/use/serial-queue';
import { useObserveAndComputeAtEndOfResizing, useResponsiveness } from '@/use/responsiveness';
import { DetailsPanelVisibility, ModalWidthSize } from '@/types/enums';
import { ErrorStateAction, ErrorStateTitle } from '@/types/enums/empty-state';
export default defineComponent({
  props: {
    actions: {
      type: Array,
      required: true
    },
    fetchFn: {
      type: Function,
      default: null
    },
    items: {
      type: Array,
      default: () => []
    },
    rowType: {
      type: String,
      required: true
    },
    params: {
      type: Object,
      default: () => ({})
    },
    rowProps: {
      type: Object,
      default: () => ({})
    },
    rowHeight: {
      type: Number,
      default: () => 52
    },
    resourceUpdateFn: {
      type: Function,
      required: true
    },
    detailsPanelComponent: {
      type: String,
      default: ''
    },
    detailsPanelComponentProps: {
      type: Object,
      default: () => {}
    },
    modalComponent: {
      type: String,
      default: ''
    },
    columns: {
      type: Object,
      default: () => ({})
    },
    noFilters: {
      type: Boolean,
      default: false
    },
    hideHeader: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Congrats! No work to be done here!'
    },
    message: {
      type: String,
      default: ''
    },
    animationData: {
      type: [Function, String],
      default: () => require('@/assets/animations/DancingOtto.lottie')
    },
    iconName: {
      type: String,
      default: ''
    },
    showSearch: {
      type: Boolean,
      default: false
    },
    bypassBulkAction: {
      type: Boolean,
      default: false
    },
    showHeaderDivider: {
      type: Boolean,
      default: true
    },
    actionableFetchFn: {
      type: Function,
      default: null
    },
    allowCards: {
      type: Boolean,
      default: false
    },
    showOnlyTotal: {
      type: Boolean,
      default: false
    },
    persistent: {
      type: Boolean,
      default: false
    }
  },
  setup: (props, {
    emit,
    slots
  }) => {
    const keyboardCommandsBlocked = ref(false);
    const router = useRouter();
    const route = useRoute();
    const {
      fetchResourceTypeConfigObj
    } = useTransactionResources();
    const modalRef = ref(null);
    const advancedModalRef = ref(null);
    let selectedIndexFrom = null;
    let selectedIndexTo = null;
    const {
      getContainerSize,
      xsContainerSize
    } = useResponsiveness();
    const ucTableContainerRef = ref(null);
    const containerSize = ref('lg');
    let resizeObserver = null;
    const isApiFailed = ref(false);
    const errorStateDetails = ref({
      title: ErrorStateTitle.SOMETHING_WENT_WRONG,
      actionText: ErrorStateAction.RELOAD_PAGE
    });
    onMounted(() => {
      // we need set timeout to let the modal transition
      setTimeout(() => {
        if (ucTableContainerRef.value) {
          var _ucTableContainerRef$;
          containerSize.value = getContainerSize((_ucTableContainerRef$ = ucTableContainerRef.value) === null || _ucTableContainerRef$ === void 0 ? void 0 : _ucTableContainerRef$.offsetWidth);
          resizeObserver = new ResizeObserver(debounce(entries => {
            containerSize.value = getContainerSize(entries[0].contentRect.width);
          }, 500));
          resizeObserver.observe(ucTableContainerRef.value);
        }
      }, 1000);
    });
    const defaultBulkSelectionData = {
      isSelectAll: false,
      isBulkExclusive: false
    };
    const bulkSelectionData = reactive({
      ...defaultBulkSelectionData
    });
    const modalData = ref({
      confirmButtonProps: {
        text: '',
        state: 'primary',
        disabled: false
      },
      dynamicContentProps: {},
      advancedModal: '',
      advancedModalIsVisible: false,
      modalCardProps: {
        headerText: '',
        headerIcon: '',
        size: ModalWidthSize.MD,
        dynamicHeader: null
      },
      modalContentComponent: '',
      modalContentComponentProps: {}
    });
    const rowsRef = ref(null);
    const size = ref('lg');
    useObserveAndComputeAtEndOfResizing(rowsRef, observedWidth => {
      size.value = getContainerSize(observedWidth, props.allowCards);
    });
    const isAppendButtonHidden = computed(() => xsContainerSize(size.value));
    const isBulkActionsAvailable = computed(() => {
      return !!props.actions.filter(action => !!action.bulkConfig).length;
    });
    const isAppendButtonSlotAvailable = computed(() => {
      return slots['append-button'];
    });
    const isPrependButtonSlotAvailable = computed(() => {
      return slots['prepend-button'];
    });
    const modalFooterIsDisabled = ref(false);
    const modalFooterButtonHidden = ref(false);
    const modalFooterButtons = computed(() => {
      return [{
        type: 'outlined',
        text: 'CANCEL',
        location: 'right'
      }, {
        ...modalData.value.confirmButtonProps,
        disabled: modalFooterIsDisabled.value,
        eventName: 'confirm',
        location: 'right',
        hidden: modalFooterButtonHidden.value
      }];
    });
    const modalKey = ref(1);
    const resetModal = () => {
      setTimeout(() => {
        modalKey.value++;
      }, 400);
    };
    const additionalModalConfigRef = ref(null);
    const modalComponentRef = ref(null);
    let additionalDataFromActionModal = {};
    const modalConfirmationHandler = async () => {
      var _additionalModalConfi, _additionalModalConfi2, _modalComponentRef$va, _modalComponentRef$va2;
      additionalDataFromActionModal = {
        ...(((_additionalModalConfi = additionalModalConfigRef.value) === null || _additionalModalConfi === void 0 ? void 0 : (_additionalModalConfi2 = _additionalModalConfi.getInputs) === null || _additionalModalConfi2 === void 0 ? void 0 : _additionalModalConfi2.call(_additionalModalConfi)) || {}),
        ...(((_modalComponentRef$va = modalComponentRef.value) === null || _modalComponentRef$va === void 0 ? void 0 : (_modalComponentRef$va2 = _modalComponentRef$va.getInputs) === null || _modalComponentRef$va2 === void 0 ? void 0 : _modalComponentRef$va2.call(_modalComponentRef$va)) || {})
      };
      if (modalRef.value.modalOpen) {
        modalRef.value.close();
        const curAction = props.actions.find(action => {
          return action.value === pendingActionData.action;
        });
        if (curAction.redirect) {
          router.push(curAction.redirectUrl(pendingActionData.resources[0].id));
        } else {
          await curAction.actionFn(pendingActionData.resources, getAdditionalParams(pendingActionData.resources));
          saveSelectedResourceData({
            keepDetailsOpenOnSave: false,
            resourceId: pendingActionData.resources[0].id
          });
        }
      }
    };
    const selectedResources = ref([]);
    const selectedResourcesWithQuery = ref('');
    const removeRows = resources => {
      var _selectedResources$va;
      resources.forEach(resource => {
        const index = resourceData.value.rows.findIndex(r => {
          return r.id === resource.id;
        });
        resourceData.value.rows.splice(index, 1);
        removeSelectedResource(resource.id);
        removeTrackedResource(resource.id);
        resourceData.value.totalCount--;
      });
      if (currentRowIndex.value > -1) {
        highlightedRow.value = resourceData.value.rows[currentRowIndex.value];
        changeFocus(resourceData.value.rows[currentRowIndex.value].props[props.rowType]);
      }
      const ids = resources.map(item => {
        return item.id;
      });
      emit('remove:row', ids);
      if ((_selectedResources$va = selectedResources.value) !== null && _selectedResources$va !== void 0 && _selectedResources$va.length) {
        // did we take a bulk action ? if so, handleBulkSelection and clear selection
        handleBulkSelection(true);
      }
    };
    const detailsRef = ref(null);
    const detailsRefIsVisible = computed(() => {
      var _detailsRef$value, _detailsRef$value$det, _detailsRef$value2;
      // support old format (nested show card) and new format (visibility key)
      return ((_detailsRef$value = detailsRef.value) === null || _detailsRef$value === void 0 ? void 0 : (_detailsRef$value$det = _detailsRef$value.detailsRef) === null || _detailsRef$value$det === void 0 ? void 0 : _detailsRef$value$det.showCard) || ((_detailsRef$value2 = detailsRef.value) === null || _detailsRef$value2 === void 0 ? void 0 : _detailsRef$value2.detailsPanelVisibility) !== DetailsPanelVisibility.Hide;
    });
    const detailsPanelResources = ref([]);
    const getAdditionalParams = resources => {
      var _detailsRef$value3, _localDetailsPanelCom;
      const canInjectParams = {
        ...props.params,
        perPage: 1
      };
      let totalSelectedCount;
      // If we select 10 records and clicked the action of row action instead of bulk action, That time we have 10 records selected
      // If the resources length is 1 then its called from row otherwise from the bulk action
      if (resources.length === 1) {
        totalSelectedCount = 1;
      } else if (bulkSelectionData.isBulkExclusive) {
        totalSelectedCount = resourceData.value.totalCount - trackedResourceIds.value.length;
      } else {
        totalSelectedCount = resources.length;
      }
      delete canInjectParams.include;
      return {
        actionToastRef: actionToastRef.value,
        trackedResourceIds: trackedResourceIds.value,
        resourceIds: resourceIds.value,
        bulkSelectionData,
        exportQuery: bulkActionQuery,
        detailsRef: detailsRef.value,
        additionalDataFromActionModal,
        removeRows,
        updateRow,
        canUndo: canUndo.value,
        showUndo: showUndo.value,
        rowProps: props.rowProps,
        canRemoveRowProps: {
          fetchFn: props.fetchFn,
          params: canInjectParams
        },
        bypassBulkAction: props.bypassBulkAction,
        isFromTable: true,
        // Will match between details panel and table view
        pdfViewerRef: pdfViewerRef.value,
        toggleFullScreenView: () => {
          localDetailsPanelComponentProps.value.visibility = DetailsPanelVisibility.Fullscreen;
        },
        setSelectedResources: detailsRef === null || detailsRef === void 0 ? void 0 : (_detailsRef$value3 = detailsRef.value) === null || _detailsRef$value3 === void 0 ? void 0 : _detailsRef$value3.setSelectedResources,
        hidePanel: () => {
          localDetailsPanelComponentProps.value.visibility = DetailsPanelVisibility.Hide;
        },
        rowType: props.rowType || ((_localDetailsPanelCom = localDetailsPanelComponentProps.value) === null || _localDetailsPanelCom === void 0 ? void 0 : _localDetailsPanelCom.resourceType),
        localDetailsPanelComponentProps: localDetailsPanelComponentProps.value,
        detailsPanelResources: detailsPanelResources.value,
        totalCount: resourceData.value.totalCount,
        totalSelectedCount,
        selectAllParams: {
          ...props.params,
          q: {
            ...getFilterQuery(),
            ...bulkActionQuery()
          },
          perPage: resourceData.value.totalCount
        },
        fetchFn: props.fetchFn,
        router,
        route
      };
    };
    events.on('updated:table-data', obj => {
      const idsToRemove = obj.fn(obj.values, resourceData.value.rows);
      if (idsToRemove && idsToRemove.length) {
        var _detailsRef$value4, _detailsRef$value4$hi;
        (_detailsRef$value4 = detailsRef.value) === null || _detailsRef$value4 === void 0 ? void 0 : (_detailsRef$value4$hi = _detailsRef$value4.hidePanel) === null || _detailsRef$value4$hi === void 0 ? void 0 : _detailsRef$value4$hi.call(_detailsRef$value4);
        if (!bulkSelectionData.isBulkExclusive) {
          idsToRemove.forEach(id => {
            removeTrackedResource(id);
            removeSelectedResource(id);
          });
        }
        removeRows(resourceData.value.rows.filter(row => idsToRemove.includes(row.id)));
      }
    });
    const removeTrackedResource = id => {
      const foundIndex = trackedResourceIds.value.findIndex(resourceId => {
        return resourceId === id;
      });
      if (foundIndex !== -1) {
        trackedResourceIds.value.splice(foundIndex, 1);
      }
    };
    const removeSelectedResource = id => {
      selectedResources.value = selectedResources.value.filter(resource => resource.id !== id);
    };
    const {
      pdfViewerRef
    } = useApp();
    const actionToastRef = ref(null);
    const pendingActionData = reactive({
      action: '',
      resources: []
    });
    const highlightedRow = ref(null);
    const updateSelectedResource = e => {
      var _localDetailsPanelCom2;
      if ((_localDetailsPanelCom2 = localDetailsPanelComponentProps.value) !== null && _localDetailsPanelCom2 !== void 0 && _localDetailsPanelCom2.resourceType) {
        localDetailsPanelComponentProps.value.resourceType = e.type;
      }
      highlightedRow.value = resourceData.value.rows[e.index].props[props.rowType].id;
      detailsPanelResources.value = [resourceData.value.rows[e.index].props[props.rowType]];
      changeFocus(resourceData.value.rows[e.index].props[props.rowType]);
    };
    const toggleFullScreenView = e => {
      updateSelectedResource(e);
      localDetailsPanelComponentProps.value.visibility = DetailsPanelVisibility.Fullscreen;
    };
    const resourceData = ref({
      rows: [],
      onMounted: () => null,
      page: 0,
      totalPages: -1,
      totalCount: 0
    });
    const updateSelectedResources = resources => {
      selectedResources.value = resources;
      bulkSelectionData.isSelectAll = selectedResources.value.length === resourceData.value.rows.length;
    };
    const {
      sortByObject,
      createRansackFilterObject
    } = useFilters();
    const getFilterQuery = () => {
      if (props.noFilters) {
        return {
          ...props.params.q
        };
      }
      return {
        // ...sortByObject.value,
        ...props.params.q
        // ...(!props.noFilters ? createRansackFilterObject() : {})
      };
    };

    watch(() => props.items, () => {
      if (props.items.length) {
        invokeGetResourceData();
      }
    }, {
      deep: true
    });
    watch(() => selectedResources.value, value => {
      resourceIds.value = value.map(resource => resource.id);
    });
    const invokeGetResourceDataQueue = useSerialQueue();
    // getResourceData mutates state, and may be called in parallel to fetch more pages
    // we must guarantee that those invocations happen in the correct order, so we instead invoke it
    // in a serial queue
    const invokeGetResourceData = (newSearch = false) => {
      invokeGetResourceDataQueue(() => getResourceData(newSearch));
    };
    const getResourceData = async (newSearch = false) => {
      const params = {
        ...props.params,
        q: {
          ...getFilterQuery()
        }
      };
      let newRows = [];
      const totalRows = [...selectedResources.value];
      if (props.fetchFn && typeof props.fetchFn === 'function') {
        try {
          newRows = await fetchAndGenerateChildDataWithFailure(resourceData.value, params, props.rowType, {
            ...props.rowProps
          }, props.fetchFn);
          isApiFailed.value = false;
        } catch (err) {
          isApiFailed.value = true;
        }
      } else {
        newRows = generateRows(resourceData.value, props.items, props.rowType, {
          ...props.rowProps
        }, () => null, () => null);
        resourceData.value.totalPages = 0;
        resourceData.value.rows = newRows;
      }
      emit('total-wos-count', resourceData.value);
      if (bulkSelectionData.isBulkExclusive || bulkSelectionData.isSelectAll) {
        totalRows.push(...newRows);
        selectedResources.value = [...totalRows];
      }
    };
    const loadData = () => {
      invokeGetResourceData();
    };
    const trackedResourceIds = ref([]);
    const resourceIds = ref();
    const updateTrackedResourceState = params => {
      const item = params.item ? params.item : params.currentItem;
      const value = params.value;
      if (selectedIndexFrom === null) {
        selectedIndexTo = null;
        selectedIndexFrom = resourceData.value.rows.findIndex(x => x.id === item.id);
      } else if (pressedKey === 'Shift') {
        selectedIndexTo = resourceData.value.rows.findIndex(x => x.id === item.id);
        if (selectedIndexFrom != null && selectedIndexTo != null) {
          if (selectedIndexFrom < selectedIndexTo) {
            const arr = resourceData.value.rows.slice(selectedIndexFrom, selectedIndexTo + 1);
            arr.forEach(element => {
              if (!trackedResourceIds.value.includes(element.id)) {
                trackedResourceIds.value.push(element.id);
              }
            });
            emit('total-selected', trackedResourceIds.value.length);
          } else {
            const arr = resourceData.value.rows.slice(selectedIndexTo, selectedIndexFrom);
            arr.forEach(element => {
              if (!trackedResourceIds.value.includes(element.id)) {
                trackedResourceIds.value.push(element.id);
              }
            });
            emit('total-selected', trackedResourceIds.value.length);
          }
          if (selectedIndexTo != null) {
            selectedIndexFrom = selectedIndexTo;
          }
        }
        return;
      }
      const foundIndex = trackedResourceIds.value.findIndex(resourceId => {
        return resourceId === item.id;
      });
      if (bulkSelectionData.isBulkExclusive) {
        // when a user has previously checked the bulk select, we are tracking which invoices they have deselected.
        // if we are deselecting, we want to push invoice id onto our list
        // if we're selecting, we want to remove this invoice from our list
        if (!value && foundIndex === -1) {
          trackedResourceIds.value.push(item.id);
          bulkSelectionData.isSelectAll = false;
        } else if (foundIndex !== -1) {
          trackedResourceIds.value.splice(foundIndex, 1);
        }
        if (trackedResourceIds.value.length === 0) {
          bulkSelectionData.isSelectAll = true;
        }
      } else {
        // when a user has previously unchecked the bulk select or has not yet interacted with bulk select, we want to track the invoices they are selecting
        // if we're selecting, we want to push this invoice id to our list
        // if we're deselecting, we want to remove this invoice id from our list
        if (value && foundIndex === -1) {
          trackedResourceIds.value.push(item.id);
        } else if (foundIndex !== -1) {
          trackedResourceIds.value.splice(foundIndex, 1);
        }
      }
    };
    const canUndo = ref(false);
    const showUndo = ref(false);
    const originalResource = ref(null);
    const originalResourceIndex = ref(-1);
    const undoResourceAction = async () => {
      const res = await props.resourceUpdateFn({
        invoiceId: originalResource.value.id,
        updateContent: originalResource.value,
        include: props.params.include || ''
      });
      showUndo.value = false;
      let toastDetails;
      if (res[props.rowType].id) {
        const index = resourceData.value.rows.findIndex(resource => {
          return resource.id === originalResource.value.id;
        });
        if (index === -1) {
          resourceData.value.rows.splice(originalResourceIndex.value, 0, {
            ...resourceData.value.rows[0],
            ...props.rowProps,
            props: {
              [props.rowType]: {
                ...originalResource.value
              }
            },
            firstRow: originalResourceIndex.value === 0,
            lastRow: originalResourceIndex.value === resourceData.value.rows.length - 1,
            id: originalResource.value.id
          });
        } else {
          const found = resourceData.value.rows[index];
          found.props[props.rowType] = originalResource.value;
        }
        toastDetails = {
          color: actionToastProps.success.state,
          message: `Successfully restored original ${props.rowType}`,
          prependIcon: actionToastProps.success.icon
        };
      } else {
        toastDetails = {
          color: actionToastProps.error.state,
          message: 'Error',
          prependIcon: actionToastProps.error.icon
        };
      }
      if (detailsRefIsVisible.value) {
        detailsRef.value.localSelectedResource = {
          ...detailsRef.value.localSelectedResource,
          ...res
        };
      }
      actionToastRef.value.show({
        ...toastDetails
      });
    };
    const generateCleanResourcesArr = resources => {
      var _resources$;
      if ((_resources$ = resources[0]) !== null && _resources$ !== void 0 && _resources$.props) {
        resources = resources.map(resource => resource.props[props.rowType]);
      }
      return resources;
    };
    // localDetailPanelProps MUST be a deep clone of props.detailsPanelProps. Otherwise, we lose state management of canDoubleClickToEdit
    const localDetailsPanelComponentProps = ref(cloneDeep(props.detailsPanelComponentProps || {}));
    watch(() => props.detailsPanelComponentProps, () => {
      localDetailsPanelComponentProps.value = cloneDeep(props.detailsPanelComponentProps || {});
    }, {
      deep: true
    });
    const initListeners = () => {
      const result = props.actions.reduce((acc, action) => {
        const listenerToKebabCase = kebabCase(action.value);
        acc[listenerToKebabCase] = (resources = selectedResources.value) => {
          if (action.updateSelectedResource) {
            detailsPanelResources.value = generateCleanResourcesArr(resources);
            if (currentRowIndex.value === -1) {
              const currRow = resourceData.value.rows.find(resource => resource.id === resources[0].id).props[kebabCase(props.rowType)];
              highlightedRow.value = currRow.id;
              changeFocus(currRow);
            } else {
              highlightedRow.value = resourceData.value.rows[currentRowIndex.value].props[props.rowType].id;
              changeFocus(resourceData.value.rows[currentRowIndex.value].props[props.rowType]);
            }
          }
          if (action.requiresConfirmation) {
            const r = generateCleanResourcesArr(resources);
            if (action.advancedModal) {
              modalData.value.dynamicContentProps = {
                ...action.dynamicContentProps,
                resources,
                action: action.value
              };
              if (action.exportQueryRequired) {
                modalData.value.dynamicContentProps.exportQuery = getAdditionalParams(resources).exportQuery();
              }
              modalData.value.advancedModal = action.advancedModal;
              modalData.value.advancedModalIsVisible = true;
            } else {
              modalData.value = {
                ...action.modal
              };
              modalData.value.advancedModal = '';
              modalData.value.dynamicContentProps = {
                ...modalData.value.dynamicContentProps,
                resources: r,
                action: action.value,
                type: props.rowType
              };
            }
            modalKey.value++;
            if (modalData.value.modalCardProps.dynamicHeader) {
              modalData.value.modalCardProps.headerText = modalData.value.modalCardProps.dynamicHeader(resources);
            }
            setTimeout(() => {
              if (!modalData.value.advancedModal) {
                modalRef.value.show();
              }
            }, 0);
          }
          // will work for single invoice action only.
          showUndo.value = false;
          if (action.canUndo && resources.length === 1) {
            canUndo.value = true;
            originalResourceIndex.value = resourceData.value.rows.findIndex(resource => {
              return resource.id === resources[0].id;
            });
            originalResource.value = cloneDeep(resources[0]);
          } else {
            canUndo.value = false;
          }
          pendingActionData.resources = resources;
          pendingActionData.action = listenerToKebabCase;
          // non modal action functions called right away.
          // actions that open the confirmation modal have their action function called on confirmation
          if (!action.requiresConfirmation) {
            if (!action.actionFn) return;
            action.actionFn(resources, getAdditionalParams(resources));
          }
        };
        return acc;
      }, {});
      return result;
    };
    const listenerObj = initListeners();
    const saveSelectedResourceData = async ({
      keepDetailsOpenOnSave = false,
      resourceId = null
    }) => {
      // we now need to cleverly handle generating new rows to our resourceData/uc-table for creating resources/editing resources with statuses typically filtered out
      // for example, if we create an inv from payable/pay and we save and mark sent, it should not be added to the current table data bc this tab is filtered on only status "approved"
      // when we emit('save') up from the details panel, we will optionally pass the new status of the locally selected resource
      // if the new status does not match our props.params.q (status_in || status_not_in), skip generating new ros
      const resourceFromDetailsPanel = resourceData.value.rows.find(row => resourceId === row.id);
      // optimistic handling validity check
      const params = {
        ...props.params,
        perPage: 1
      };
      // if there is no id we cannot save this data to the table since it doesnt exist
      if (!resourceId) return;
      // if there is an id check if it should be appear in the table
      // length === 1 means it should be in the table
      // length === 0 means it should not be in the table
      const injectData = await canInjectIntoTable(resourceId, props.fetchFn, getFilterQuery, params);
      const foundIndex = resourceData.value.rows.findIndex(resource => {
        return resource.id === resourceId;
      });
      // also need to check if the element is already in the table
      // if so and the injectData length is 0 we must remove
      // if so and the injectData length is 1 we must update the row
      // if not and the injectData length is 1 we must create the row
      // else nothing to do since its not in the table and it shouldnt be
      if (!injectData.length) {
        if (foundIndex > -1) {
          removeRows([resourceFromDetailsPanel]);
        }
      } else {
        if (foundIndex > -1) {
          resourceData.value.rows[foundIndex].props[kebabCase(props.rowType)] = {
            ...resourceData.value.rows[foundIndex].props[kebabCase(props.rowType)],
            ...injectData[0]
          };
        } else {
          var _resourceData$value;
          // we just created a new resource
          const newRow = generateRows({}, [injectData[0]], props.rowType, {
            ...props.rowProps
          }, () => null, () => null);
          (_resourceData$value = resourceData.value) === null || _resourceData$value === void 0 ? void 0 : _resourceData$value.rows.unshift(...newRow);
          resourceData.value.totalCount += 1;
        }
      }
      if (detailsRef.value) {
        var _detailsRef$value5, _detailsRef$value5$cl, _detailsRef$value6, _detailsRef$value6$hi;
        (_detailsRef$value5 = detailsRef.value) === null || _detailsRef$value5 === void 0 ? void 0 : (_detailsRef$value5$cl = _detailsRef$value5.closeCard) === null || _detailsRef$value5$cl === void 0 ? void 0 : _detailsRef$value5$cl.call(_detailsRef$value5);
        (_detailsRef$value6 = detailsRef.value) === null || _detailsRef$value6 === void 0 ? void 0 : (_detailsRef$value6$hi = _detailsRef$value6.hidePanel) === null || _detailsRef$value6$hi === void 0 ? void 0 : _detailsRef$value6$hi.call(_detailsRef$value6);
        if (keepDetailsOpenOnSave) {
          detailsPanelResources.value = injectData;
          localDetailsPanelComponentProps.value.canDoubleClickToEdit = true;
          localDetailsPanelComponentProps.value.visibility = DetailsPanelVisibility.Fullscreen;
        } else {
          detailsPanelResources.value = [];
          // make sure local state here gets cleaned up or prop changes to children will not fire
          localDetailsPanelComponentProps.value.canDoubleClickToEdit = true;
          localDetailsPanelComponentProps.value.visibility = DetailsPanelVisibility.Hide;
        }
      }
      emit('change:row', resourceId);
    };
    const updateRow = resource => {
      var _localDetailsPanelCom3;
      const foundIndex = resourceData.value.rows.findIndex(inv => inv.id === resource.id);
      if (foundIndex > -1) {
        resourceData.value.rows[foundIndex].props[kebabCase(props.rowType)] = {
          ...resourceData.value.rows[foundIndex].props[kebabCase(props.rowType)],
          ...resource
        };
      }
      if ((_localDetailsPanelCom3 = localDetailsPanelComponentProps.value) !== null && _localDetailsPanelCom3 !== void 0 && _localDetailsPanelCom3.resourceType) {
        var _fetchResourceTypeCon, _localDetailsPanelCom4, _localDetailsPanelCom5;
        const editableStatuses = (_fetchResourceTypeCon = fetchResourceTypeConfigObj((_localDetailsPanelCom4 = localDetailsPanelComponentProps.value) === null || _localDetailsPanelCom4 === void 0 ? void 0 : _localDetailsPanelCom4.resourceType)) === null || _fetchResourceTypeCon === void 0 ? void 0 : _fetchResourceTypeCon.editableStatuses;
        if (!((_localDetailsPanelCom5 = localDetailsPanelComponentProps.value) !== null && _localDetailsPanelCom5 !== void 0 && _localDetailsPanelCom5.canDoubleClickToEdit) && !editableStatuses.indexOf(resource.status)) localDetailsPanelComponentProps.value.canDoubleClickToEdit = true;
      }
      resetSelections();
    };
    const setDisabled = valid => {
      modalData.value.confirmButtonProps = {
        ...modalData.value.confirmButtonProps,
        disabled: !valid
      };
    };
    const handleBulkSelection = (event, allRecords = false) => {
      if (!event) {
        bulkSelectionData.isSelectAll = true;
        if (allRecords) bulkSelectionData.isBulkExclusive = true;
        selectAll(true);
      } else {
        bulkSelectionData.isSelectAll = false;
        bulkSelectionData.isBulkExclusive = false;
        selectAll(false);
      }
    };
    const selectAll = select => {
      if (select) {
        selectedResources.value = resourceData.value.rows;
      } else {
        selectedResources.value = [];
        selectedResourcesWithQuery.value = '';
      }
      trackedResourceIds.value = [];
    };
    const selectAllWithQuery = select => {
      if (select) {
        selectedResourcesWithQuery.value = 'all';
      } else {
        selectedResources.value = [];
      }
      trackedResourceIds.value = [];
    };
    const totalSelected = computed(() => {
      if (!resourceData.value.totalCount) {
        return -1;
      } else if (bulkSelectionData.isBulkExclusive) {
        return resourceData.value.totalCount - trackedResourceIds.value.length;
      } else if (selectedResourcesWithQuery.value !== '') {
        return resourceData.value.totalCount;
      } else {
        return selectedResources.value.length;
      }
    });
    const menuActions = computed(() => props.actions.filter(action => {
      var _action$bulkConfig, _action$bulkConfig2;
      return ((_action$bulkConfig = action.bulkConfig) === null || _action$bulkConfig === void 0 ? void 0 : _action$bulkConfig.type) === 'menu' || (xsContainerSize(size.value) ? ((_action$bulkConfig2 = action.bulkConfig) === null || _action$bulkConfig2 === void 0 ? void 0 : _action$bulkConfig2.type) === 'icon' : false);
    }).map(action => ({
      text: startCase(action.value),
      textColor: action.bulkConfig.textColor || ''
    })));
    const bulkActionIcons = computed(() => {
      if (xsContainerSize(size.value)) {
        return [];
      } else {
        return props.actions.filter(action => {
          var _action$bulkConfig3;
          return ((_action$bulkConfig3 = action.bulkConfig) === null || _action$bulkConfig3 === void 0 ? void 0 : _action$bulkConfig3.type) === 'icon';
        }).map(action => ({
          iconName: action.bulkConfig.icon,
          toolTip: action.bulkConfig.text,
          eventName: action.value
        }));
      }
    });
    const buttonMenuProps = computed(() => {
      const primaryAction = props.actions.find(action => {
        var _action$bulkConfig4;
        return ((_action$bulkConfig4 = action.bulkConfig) === null || _action$bulkConfig4 === void 0 ? void 0 : _action$bulkConfig4.type) === 'primary';
      });
      return {
        menuData: {
          header: '',
          height: null,
          rows: menuActions.value
        },
        menuProps: {
          right: true,
          offsetX: true
        },
        hideButtonOnMobile: isAppendButtonHidden.value || !primaryAction,
        buttonProps: (() => {
          if (primaryAction) {
            return {
              text: startCase(primaryAction.value).toUpperCase(),
              state: 'primary',
              size: 'sm'
            };
          } else {
            return {};
          }
        })(),
        activatorButtonProps: {
          size: 'sm',
          iconName: 'mdi-dots-vertical',
          state: 'primary'
        }
      };
    });
    const runFilter = () => {
      if (props.fetchFn) {
        refreshTable();
      }
    };
    events.on('run-filters', runFilter);
    const refreshTable = () => {
      resourceData.value = {
        rows: [],
        onMounted: () => null,
        page: 0,
        totalPages: -1,
        totalCount: 0
      };
      if (detailsRef !== null && detailsRef !== void 0 && detailsRef.value) {
        var _detailsRef$value7, _detailsRef$value7$hi;
        detailsRef === null || detailsRef === void 0 ? void 0 : (_detailsRef$value7 = detailsRef.value) === null || _detailsRef$value7 === void 0 ? void 0 : (_detailsRef$value7$hi = _detailsRef$value7.hidePanel) === null || _detailsRef$value7$hi === void 0 ? void 0 : _detailsRef$value7$hi.call(_detailsRef$value7);
      }
      localDetailsPanelComponentProps.value.visibility = DetailsPanelVisibility.Hide;
      highlightedRow.value = null;
      loadData();
    };
    const resetSelections = () => {
      bulkSelectionData.isSelectAll = false;
      selectedResources.value = [];
      selectedResourcesWithQuery.value = '';
    };
    const closeCard = () => {
      localDetailsPanelComponentProps.value = cloneDeep(props.detailsPanelComponentProps);
      localDetailsPanelComponentProps.value.visibility = DetailsPanelVisibility.Hide;
      if (!detailsRefIsVisible.value) {
        detailsPanelResources.value = [];
      }
    };
    const currentRowIndex = computed(() => {
      return resourceData.value.rows.findIndex(row => row.id === highlightedRow.value);
    });
    // MAKE ROW HEIGHT PROP
    const RESOURCE_ROW_HEIGHT = props.rowHeight || 42;
    let pressedKey = '';
    const focusSelectedResource = () => {
      nextTick(() => {
        var _document$getElementB;
        (_document$getElementB = document.getElementById(`${resourceData.value.rows[currentRowIndex.value].id}`)) === null || _document$getElementB === void 0 ? void 0 : _document$getElementB.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      });
    };
    const setSelectedResource = debounce(() => {
      if (detailsRefIsVisible.value) {
        detailsPanelResources.value = [resourceData.value.rows.find(row => row.id === highlightedRow.value).props[props.rowType]];
        focusSelectedResource();
      }
    }, 750);
    // We are managing the state of three refs depending on the click/keyboard functionality and actionable touchpoint
    // selectedResources which is the list of "checked" or "unchecked in bulk" invoices
    // detailsPanelResources are fed into the detailsPanel for rendering -- ONLY UPDATED ON ROW CLICK, ACTION BUTTON CLICK, ARROOW KEYS AND SHOWCARD === TRUE
    // highlightedRow is the id of the row that was selected/currently highlighted (clicked on OR arrow keys)-- this updates on all arrow key and clicks
    // highlightedRow allows us to accurately index against the total resourceRows. It can be pushed into selectedResources or set as the detailsPanelResources thereby maintaining mututal exclusivity between click/keyboard/event listener functionality
    const throttledArrowFn = key => {
      if (detailsPanelResources.value.length <= 1) {
        if (currentRowIndex.value > 0 && key === 'ArrowUp') {
          const currRow = resourceData.value.rows[currentRowIndex.value - 1].props[props.rowType];
          highlightedRow.value = currRow.id;
          changeFocus(currRow);
          rowsRef.value.$el.scrollTop -= RESOURCE_ROW_HEIGHT;
        }
        if (currentRowIndex.value < resourceData.value.rows.length - 1 && key === 'ArrowDown') {
          const currRow = resourceData.value.rows[currentRowIndex.value + 1].props[props.rowType];
          highlightedRow.value = currRow.id;
          changeFocus(currRow);
          rowsRef.value.$el.scrollTop += RESOURCE_ROW_HEIGHT;
        }
        setSelectedResource();
      } else if (localDetailsPanelComponentProps.value.visibility === DetailsPanelVisibility.Show || localDetailsPanelComponentProps.value.visibility === DetailsPanelVisibility.Fullscreen) {
        if (key === 'ArrowUp') {
          detailsRef.value.navigateResources(-1);
        }
        if (key === 'ArrowDown') {
          detailsRef.value.navigateResources(1);
        }
      }
    };
    const throttledArrowFnWrap = throttle(() => {
      throttledArrowFn(pressedKey);
    }, 250, {
      trailing: false,
      leading: true
    });
    onClickOutside(ucTableContainerRef, () => {
      keyboardCommandsBlocked.value = true;
    });
    onMounted(() => {
      onKeyDown(['Shift'], e => {
        e.preventDefault();
        pressedKey = e.key;
      });
      onKeyUp(['Shift'], () => {
        pressedKey = '';
      });
      onKeyUp(['ArrowUp', 'ArrowDown'], () => {
        throttledArrowFnWrap.cancel();
      });
      onKeyDown(['ArrowUp', 'ArrowDown'], e => {
        if (!highlightedRow.value || keyboardCommandsBlocked.value) {
          return;
        }
        e.preventDefault();
        pressedKey = e.key;
        if (e.metaKey) {
          if (e.key === 'ArrowUp') {
            if (highlightedRow.value === resourceData.value.rows[0].props[props.rowType].id) {
              return;
            }
            highlightedRow.value = resourceData.value.rows[0].props[props.rowType].id;
            changeFocus(resourceData.value.rows[0].props[props.rowType]);
          } else {
            if (highlightedRow.value === resourceData.value.rows[resourceData.value.rows.length - 1].props[props.rowType].id) {
              return;
            }
            const currRow = resourceData.value.rows[resourceData.value.rows.length - 1].props[props.rowType];
            highlightedRow.value = currRow.id;
            changeFocus(currRow);
          }
          focusSelectedResource();
          return;
        }
        throttledArrowFnWrap();
      });
      onKeyStroke('Enter', () => {
        // we only want to enable enter key stroke when our detailsPanel is closed so we can toggle selecting invoices for bulk selectedResources actions
        // when the the detailsPanel is closed, we have to ensure to reset "edit" mode by setting canDoubleClickToEdit to be true.
        // this way, if we enable editing mode to one invoice, it does not enable editing capabilities to the next invoice
        if (!highlightedRow.value) return;
        const selectedResourceRow = resourceData.value.rows.find(resource => {
          return resource.id === highlightedRow.value;
        });
        if (selectedResourceRow) {
          const indexInSelectedlist = selectedResources.value.findIndex(resource => {
            return resource.id === highlightedRow.value;
          });
          if (indexInSelectedlist > -1) {
            selectedResources.value.splice(indexInSelectedlist, 1);
            const params = {
              item: {
                id: highlightedRow.value
              },
              value: false
            };
            updateTrackedResourceState(params);
          } else {
            selectedResources.value.push(selectedResourceRow.props[props.rowType]);
            const params = {
              item: {
                id: highlightedRow.value
              },
              value: false
            };
            updateTrackedResourceState(params);
          }
        }
      });
      onKeyStroke(['o', 'ArrowRight'], () => {
        if (detailsRefIsVisible.value || detailsRef.value === null) return;
        localDetailsPanelComponentProps.value = cloneDeep(props.detailsPanelComponentProps);
        const row = resourceData.value.rows.find(resource => {
          return resource.id === highlightedRow.value;
        });
        if (!row || !row.props) {
          return;
        }
        detailsPanelResources.value = [row.props[props.rowType]];
      });
      onKeyStroke('Escape', () => {
        var _detailsRef$value8, _detailsRef$value8$hi;
        (_detailsRef$value8 = detailsRef.value) === null || _detailsRef$value8 === void 0 ? void 0 : (_detailsRef$value8$hi = _detailsRef$value8.hidePanel) === null || _detailsRef$value8$hi === void 0 ? void 0 : _detailsRef$value8$hi.call(_detailsRef$value8);
        localDetailsPanelComponentProps.value = {
          ...localDetailsPanelComponentProps.value,
          visibility: DetailsPanelVisibility.Hide
        };
      });
      loadData();
    });
    const advancedModalComponent = computed(() => {
      if (!modalData.value.advancedModal) return null;
      return getDynamicComponent(`${modalData.value.advancedModal}`);
    });
    onUnmounted(() => {
      events.off('run-filters', runFilter);
    });
    const outerModalComponent = computed(() => getDynamicComponent(`${props.modalComponent}`));
    const innerModalComponent = computed(() => getDynamicComponent(`${modalData.value.modalContentComponent}`));
    const detailsComponent = computed(() => getDynamicComponent(`${props.detailsPanelComponent}`));
    const advancedModalEventHandler = id => {
      var _modalData$value$dyna;
      modalData.value.dynamicContentProps = {
        ...modalData.value.dynamicContentProps
      };
      modalData.value.advancedModalIsVisible = false;
      modalData.value.advancedModal = '';
      const openDetailsPanel = Boolean((_modalData$value$dyna = modalData.value.dynamicContentProps) === null || _modalData$value$dyna === void 0 ? void 0 : _modalData$value$dyna.keepDetailsOpenOnSave) || false;
      if (id) {
        saveSelectedResourceData({
          keepDetailsOpenOnSave: openDetailsPanel,
          resourceId: id
        });
      }
    };
    watch(() => props.columns, (oldVal, newVal) => {
      if (!isEqual(oldVal, newVal)) {
        refreshTable();
      }
    });
    const changeFocus = focusedRow => {
      unblockKeyboardCommands();
      emit('change-focus', focusedRow);
    };
    const isBulkSelected = computed(() => {
      var _resourceData$value2;
      if ((_resourceData$value2 = resourceData.value) !== null && _resourceData$value2 !== void 0 && _resourceData$value2.totalCount) {
        return bulkSelectionData.isSelectAll || selectedResources.value.length === resourceData.value.rows.length;
      } else {
        return false;
      }
    });
    const isPartialSelected = computed(() => {
      var _resourceData$value3;
      return selectedResources.value.length === (resourceData === null || resourceData === void 0 ? void 0 : (_resourceData$value3 = resourceData.value) === null || _resourceData$value3 === void 0 ? void 0 : _resourceData$value3.rows.length) ? false : selectedResources.value.length > 0;
    });
    const bulkActionQuery = () => {
      /*
      trackedResourceIds are getting set properly when the select all is checked and we unselect
      some records, but it's not getting set properly when we are selecting the records without
      checking the select all check box, so we are using both trackedResourceIds and resourceIds
      based on whether the select all is checked till we fix trackedResourceIds.
      */
      return {
        ...getFilterQuery(),
        [bulkSelectionData.isBulkExclusive ? 'id_not_in' : 'id_in']: bulkSelectionData.isBulkExclusive ? trackedResourceIds.value : resourceIds.value
      };
    };
    watch(() => props.params, (oldVal, newVal) => {
      if (!isEqual(oldVal, newVal)) {
        refreshTable();
      }
    });
    const createRows = resources => {
      var _resourceData$value4;
      const newRows = generateRows({}, resources, props.rowType, {
        ...props.rowProps
      }, () => null, () => null);
      (_resourceData$value4 = resourceData.value) === null || _resourceData$value4 === void 0 ? void 0 : _resourceData$value4.rows.unshift(...newRows);
      resourceData.value.totalCount += newRows.length;
    };
    const unfocusRow = () => {
      highlightedRow.value = null;
    };
    const unblockKeyboardCommands = () => {
      keyboardCommandsBlocked.value = false;
    };
    return {
      modalRef,
      modalData,
      resetModal,
      modalKey,
      undoResourceAction,
      modalConfirmationHandler,
      updateTrackedResourceState,
      updateSelectedResource,
      updateSelectedResources,
      selectedResources,
      detailsPanelResources,
      selectAll,
      resourceData,
      showUndo,
      saveSelectedResourceData,
      setDisabled,
      modalFooterButtons,
      invokeGetResourceData,
      listenerObj,
      bulkSelectionData,
      handleBulkSelection,
      buttonMenuProps,
      bulkActionIcons,
      actionToastRef,
      detailsRef,
      additionalModalConfigRef,
      totalSelected,
      rowsRef,
      throttledArrowFn,
      toggleFullScreenView,
      highlightedRow,
      canUndo,
      localDetailsPanelComponentProps,
      isAppendButtonSlotAvailable,
      isBulkActionsAvailable,
      closeCard,
      modalFooterIsDisabled,
      refreshTable,
      modalComponentRef,
      updateRow,
      containerSize,
      ucTableContainerRef,
      outerModalComponent,
      innerModalComponent,
      detailsComponent,
      isPrependButtonSlotAvailable,
      removeRows,
      DetailsPanelVisibility,
      modalFooterButtonHidden,
      isBulkSelected,
      isPartialSelected,
      bulkActionQuery,
      selectAllWithQuery,
      CHECK,
      size,
      createRows,
      advancedModalComponent,
      advancedModalRef,
      advancedModalEventHandler,
      unfocusRow,
      keyboardCommandsBlocked,
      unblockKeyboardCommands,
      isApiFailed,
      errorStateDetails
    };
  }
});