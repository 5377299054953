import { api } from '@/api'
import qs from 'qs'
export default {
  getLegalTaxClassification: () => api.$get('/legal_tax_classifications'),
  getStates: (params:any) => {
    const { q, perPage } = params
    return api.$get('/states', {
      params: {
        q,
        perPage
      },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    })
  }
}
