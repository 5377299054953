import { api } from '@/api'
import qs from 'qs'

interface GetSurveyTemplateParams {
  perPage: number
  page: number
  q?: any
  include: string
}

export default {
  get: ({ perPage, page, q, include }: GetSurveyTemplateParams) => api.$get('/survey_templates', {
    params: {
      include,
      page,
      per_page: perPage,
      q
    },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  getById: (id: string | number, { include }: any) => api.$get(`/survey_templates/${id}`, {
    params: {
      include
    },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  post: (surveyTemplate: any, { include }: any) => api.$post('/survey_templates', { survey_template: surveyTemplate }, { params: { include } }),
  patch: (id: string | number, surveyTemplate: any, params: any) => api.$patch(`/survey_templates/${id}`, { survey_template: surveyTemplate }, {
    params: { ...params },
    paramsSerializer: (params: any) => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  patchQuestion: (surveyTemplateId: string | number, id: string | number, questionObj: any, params: any) => api.$patch(`/survey_templates/${surveyTemplateId}/questions/${id}`, { question: questionObj }, {
    params: { ...params },
    paramsSerializer: (params: any) => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  postQuestion: (surveyTemplateId: string | number, questionObj: any, params: any) => api.$post(`/survey_templates/${surveyTemplateId}/questions`, { question: questionObj }, {
    params: { ...params },
    paramsSerializer: (params: any) => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  deleteQuestion: (surveyTemplateId: string | number, id: string | number) => api.$delete(`/survey_templates/${surveyTemplateId}/questions/${id}`),
  getQuestions: (surveyTemplateId: string | number, params: any) => api.$post(`/survey_templates/${surveyTemplateId}/questions`, {
    params: { ...params },
    paramsSerializer: (params: any) => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  getQuestionById: (surveyTemplateId: string | number, id: string | number, params: any) => api.$post(`/survey_templates/${surveyTemplateId}/questions/${id}`, {
    params: { ...params },
    paramsSerializer: (params: any) => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  getAnswerTypes: (params: any) => api.$get('/answer_types', {
    params: { ...params },
    paramsSerializer: (params: any) => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  patchQuestionAnswerChoices: (questionId: string | number, id: string | number, choices: any, params: any) => api.$patch(`/questions/${questionId}/answer_choices/${id}`, choices, {
    params: { ...params },
    paramsSerializer: (params: any) => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  postQuestionAnswerChoices: (questionId: string | number, choices: any, params: any) => api.$post(`/questions/${questionId}/answer_choices`, choices, {
    params: { ...params },
    paramsSerializer: (params: any) => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  deleteQuestionAnswerChoice: (questionId: string | number, id: string | number) => api.$delete(`/questions/${questionId}/answer_choices/${id}`),
  getQuestionAnswerChoices: (questionId: string | number, params: any) => api.$post(`/questions/${questionId}/answer_choices`, {
    params: { ...params },
    paramsSerializer: (params: any) => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  })
}
