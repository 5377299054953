var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "uc-button-group d-flex flex-1 justify-space-between"
  }, [_c('div', _vm._l(_vm.leftButtons, function (button) {
    return _c('uc-button', _vm._b({
      key: button.text,
      on: {
        "click": function ($event) {
          _vm.$emit(button.eventName || _vm.buttonEmitName(button.text));
          button.click && button.click();
        }
      }
    }, 'uc-button', {
      ...button
    }, false));
  }), 1), _c('div', _vm._l(_vm.rightButtons, function (button) {
    return _c('uc-button', _vm._b({
      key: button.text,
      staticClass: "ml-4",
      on: {
        "click": function ($event) {
          _vm.$emit(button.eventName || _vm.buttonEmitName(button.text));
          button.click && button.click();
        }
      }
    }, 'uc-button', {
      ...button
    }, false));
  }), 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };