import { defineComponent, ref, onMounted, watch, nextTick, computed } from 'vue';
import { startCase, cloneDeep, isEqual } from 'lodash';
import { validate } from '@/utils/';
import { INFO_OUTLINE } from '@/constants/icons';
export default defineComponent({
  props: {
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    prependIcon: {
      type: String,
      default: null
    },
    appendIcon: {
      type: String,
      default: null
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    readonly: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    hideDetails: {
      type: [Boolean, String],
      default: false
    },
    labelVariant: {
      type: Boolean,
      default: false
    },
    prefix: {
      type: String,
      default: null
    },
    increment: {
      type: Number,
      default: 1
    },
    tabIndex: {
      type: [String, Number],
      default: null
    },
    items: {
      type: Array,
      default: () => []
    },
    autocomplete: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    itemText: {
      type: String,
      default: 'text'
    },
    itemSubtext: {
      type: String,
      default: ''
    },
    itemValue: {
      type: String,
      default: 'value'
    },
    value: {
      type: [String, Object, Array],
      default: ''
    },
    fetchFn: {
      type: Function,
      default: null
    },
    fetchParams: {
      type: Object,
      default: () => {}
    },
    searchKey: {
      type: String,
      default: ''
    },
    hint: {
      type: String,
      default: ''
    },
    persistentHint: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: true
    },
    returnObject: {
      type: Boolean,
      default: true
    },
    canDoubleClickToEdit: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    showCopyIcon: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: String,
      default: ''
    },
    positionMenuBottom: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: false
    },
    classes: {
      type: Array,
      default: () => []
    },
    error: {
      type: Boolean,
      default: false
    },
    numberOfChips: {
      type: Number,
      default: -1
    },
    displayTextSize: {
      type: Number,
      default: 2
    },
    minWidth: {
      type: String,
      default: ''
    },
    isContentClickable: {
      type: Boolean,
      default: false
    },
    readModeEmptyState: {
      type: String,
      default: null
    },
    maxSelections: {
      type: Number,
      default: -1
    },
    searchWithRansack: {
      type: Boolean,
      default: true
    },
    selectOnPaste: {
      type: Boolean,
      default: false
    },
    menuMaxWidth: {
      type: String,
      default: ''
    },
    tokenSeparators: {
      type: Array,
      default: () => ['\t', '\n']
    },
    canAddNewItem: {
      type: Boolean,
      default: false
    },
    addNewItemLabel: {
      type: String,
      default: null
    },
    infoToolTipText: {
      type: String,
      default: ''
    },
    infoToolTipObject: {
      type: Object,
      default: null
    },
    readOnlyDisplayType: {
      type: String,
      default: 'text'
    },
    showLabelOnReadOnly: {
      type: Boolean,
      default: true
    },
    chipSize: {
      type: String,
      default: 'xs'
    },
    dataKey: {
      type: String,
      default: ''
    },
    searchPlaceholder: {
      type: String,
      default: 'Search List'
    }
  },
  setup: (props, {
    emit
  }) => {
    var _localValue$value, _localValue$value2, _localValue$value2$;
    const {
      isEllipses
    } = validate;
    const displayTextRef = ref(null);
    const localValue = ref(props.value);
    const inputRef = ref(null);
    const hovering = ref(false);
    const skeletonLoader = ref(true);
    const isPaste = ref(false);
    const fetchMeta = ref({
      page: 0,
      totalPages: -1,
      nextPage: 1
    });
    let tempItems = [];
    if (!props.multiple && (typeof localValue.value === 'string' && localValue.value || (_localValue$value = localValue.value) !== null && _localValue$value !== void 0 && _localValue$value[props.itemText])) {
      tempItems = [props.value];
    } else if (props.multiple && (typeof localValue.value[0] === 'string' && localValue.value[0] || (_localValue$value2 = localValue.value) !== null && _localValue$value2 !== void 0 && (_localValue$value2$ = _localValue$value2[0]) !== null && _localValue$value2$ !== void 0 && _localValue$value2$[props.itemText])) {
      tempItems = [...props.value.map(val => val)];
    }
    const localItems = ref(tempItems);
    let propChanged = false;
    const localRules = ref(cloneDeep(props.rules));
    const addRequiredRule = () => {
      if (props.required) {
        var _localRules$value;
        (_localRules$value = localRules.value) === null || _localRules$value === void 0 ? void 0 : _localRules$value.unshift(v => {
          var _Object$keys;
          return !!v && !!v.length || v && ((_Object$keys = Object.keys(v)) === null || _Object$keys === void 0 ? void 0 : _Object$keys.length) && !!v[props.itemValue] || '';
        });
      }
    };
    watch(() => props.rules, () => {
      localRules.value = cloneDeep(props.rules);
      addRequiredRule();
    }, {
      deep: true
    });
    const isEditMode = ref(!props.canDoubleClickToEdit);
    const toggleEditMode = () => {
      if (propChanged) {
        propChanged = false;
        return;
      }
      isEditMode.value = !isEditMode.value;
      nextTick(() => {
        var _inputRef$value;
        if ((_inputRef$value = inputRef.value) !== null && _inputRef$value !== void 0 && _inputRef$value.$el) {
          var _inputRef$value2, _inputRef$value2$$el;
          if (fetchMeta.value.page === 0) {
            init();
          }
          const [input] = [...((_inputRef$value2 = inputRef.value) === null || _inputRef$value2 === void 0 ? void 0 : (_inputRef$value2$$el = _inputRef$value2.$el) === null || _inputRef$value2$$el === void 0 ? void 0 : _inputRef$value2$$el.querySelectorAll('input'))];
          if (isEditMode.value) {
            input.tabIndex = 1;
            setTimeout(() => {
              input.focus();
              input.click();
            }, 0);
          }
        }
      });
    };
    const init = () => {
      if (isEditMode.value) {
        if (hasNextPage.value) {
          loadData();
        }
      }
    };
    const resetSearch = () => {
      if (searchRef.value && searchRef.value.localValue !== '') {
        searchRef.value.localValue = '';
        reset();
      }
    };
    const setSelectedValue = () => {
      var _props$value;
      if (Array.isArray(props.value) && typeof props.value === 'object' && (_props$value = props.value) !== null && _props$value !== void 0 && _props$value.value) {
        const value = props.items.find(item => {
          return item[props.itemValue] === props.value[props.itemValue];
        });
        nextTick(() => {
          if (value) localValue.value = Object.assign({}, value);
        });
      }
    };
    onMounted(() => {
      if (isEditMode.value) {
        if (inputRef.value) {
          var _inputRef$value3;
          const [input] = [...((_inputRef$value3 = inputRef.value) === null || _inputRef$value3 === void 0 ? void 0 : _inputRef$value3.$el.querySelectorAll('input'))];
          input.tabIndex = 1;
          checkTooltip();
        }
      }
      if (props.items) {
        loadData();
      }
    });
    watch(() => props.items, () => {
      nextTick(() => {
        localValue.value = props.value;
        setSelectedValue();
      });
    }, {
      deep: true
    });
    const showToolTipWhenInMultiTextMode = computed(() => {
      var _localValue$value3;
      return props.numberOfChips !== -1 && props.multiple === true && Array.isArray(localValue.value) && ((_localValue$value3 = localValue.value) === null || _localValue$value3 === void 0 ? void 0 : _localValue$value3.length) > 1;
    });
    /*
    watch if the fetch function changes, fetch params, the value, or items change
    examples would be if this dropdown populates because of a different dropdown value
    edit a new invoice but don't remount component
    */
    watch(() => props.canDoubleClickToEdit, () => {
      isEditMode.value = !props.canDoubleClickToEdit;
      propChanged = true;
    });
    watch([() => {
      var _inputRef$value4, _inputRef$value4$$el;
      return (_inputRef$value4 = inputRef.value) === null || _inputRef$value4 === void 0 ? void 0 : (_inputRef$value4$$el = _inputRef$value4.$el) === null || _inputRef$value4$$el === void 0 ? void 0 : _inputRef$value4$$el.scrollHeight;
    }, () => hovering.value], (newVal, oldVal) => {
      if (inputRef.value) {
        if (!isEqual(newVal, oldVal)) {
          var _inputRef$value5, _inputRef$value5$$el;
          inputRef.value.$el.scrollTop = (_inputRef$value5 = inputRef.value) === null || _inputRef$value5 === void 0 ? void 0 : (_inputRef$value5$$el = _inputRef$value5.$el) === null || _inputRef$value5$$el === void 0 ? void 0 : _inputRef$value5$$el.scrollHeight;
        }
      }
    });
    watch([() => props.fetchParams, () => props.items], (newVal, oldVal) => {
      if (!isEqual(newVal, oldVal)) {
        if (!isEqual(newVal[1], oldVal[1]) && !props.items.some(item => isEqual(localValue.value, item))) {
          localValue.value = '';
        }
        reset();
      }
    }, {
      deep: true
    });
    watch(() => props.value, (newVal, oldVal) => {
      // we need to make sure the new and old values are not equal
      // and that the value being passed down is not equal to the already set localValue
      if (!isEqual(newVal, oldVal)) {
        var _localValue$value4, _localValue$value7, _localValue$value7$;
        searchValue.value = '';
        localValue.value = props.value;
        setSelectedValue();
        // if we are changing value and its not going to empty string/null
        // clear the selected options with just the value to make sure it shows as "selected"
        // --- the value must be in the list of items to render
        if ((typeof localValue.value === 'string' && localValue.value || (_localValue$value4 = localValue.value) !== null && _localValue$value4 !== void 0 && _localValue$value4[props.itemText]) && !localItems.value.length) {
          var _localValue$value5, _localValue$value6;
          localItems.value = typeof localValue.value === 'string' ? [localValue.value] : [{
            ...localValue.value,
            [props.itemValue]: (_localValue$value5 = localValue.value) === null || _localValue$value5 === void 0 ? void 0 : _localValue$value5[props.itemValue],
            [props.itemText]: (_localValue$value6 = localValue.value) === null || _localValue$value6 === void 0 ? void 0 : _localValue$value6[props.itemText]
          }];
        }
        if (props.multiple && (typeof localValue.value[0] === 'string' && localValue.value[0] || (_localValue$value7 = localValue.value) !== null && _localValue$value7 !== void 0 && (_localValue$value7$ = _localValue$value7[0]) !== null && _localValue$value7$ !== void 0 && _localValue$value7$[props.itemText]) && !localItems.value.length) {
          if (typeof localValue.value[0] === 'string') {
            localItems.value = localValue.value;
          } else {
            localItems.value = localValue.value.map(local => {
              return {
                ...local,
                [props.itemValue]: local[props.itemValue],
                [props.itemText]: local[props.itemText]
              };
            });
          }
        }
        if (!props.multiple) {
          cleanUpEditMode();
        }
        checkTooltip();
      }
    }, {
      deep: true
    });
    // This function is responsible for setting the collection using the value set by user
    // so that you don't have to pass the same value as the collection object from callee function
    const setLocalItems = (data = []) => {
      var _localValue$value8;
      if (typeof localValue.value === 'string' && localValue.value || (_localValue$value8 = localValue.value) !== null && _localValue$value8 !== void 0 && _localValue$value8[props.itemText] || props.multiple && localValue.value.length) {
        if (props.multiple) {
          localItems.value = [...localValue.value, ...localItems.value, ...data].filter(val => typeof val === 'string' || (val === null || val === void 0 ? void 0 : val[props.itemText]));
        } else {
          localItems.value = [localValue.value, ...localItems.value, ...data].filter(val => typeof val === 'string' || (val === null || val === void 0 ? void 0 : val[props.itemText]));
        }
      } else {
        localItems.value = [...localItems.value, ...data].filter(val => typeof val === 'string' || (val === null || val === void 0 ? void 0 : val[props.itemText]));
      }
    };
    // if we are passed an array of data use that
    // filter this if there is search
    const setSearchedValues = (data = [], search = '') => {
      if (props.items && props.items.length) {
        var _props$items;
        const lowerCaseSearch = search.toLowerCase();
        data = props === null || props === void 0 ? void 0 : (_props$items = props.items) === null || _props$items === void 0 ? void 0 : _props$items.filter(item => {
          var _item$props$itemText$, _item$props$itemText;
          return typeof item === 'string' && item.toLowerCase().includes(lowerCaseSearch) || typeof item[props.itemText] === 'number' && ((_item$props$itemText$ = item[props.itemText].toString()) === null || _item$props$itemText$ === void 0 ? void 0 : _item$props$itemText$.includes(lowerCaseSearch)) || ((_item$props$itemText = item[props.itemText]) === null || _item$props$itemText === void 0 ? void 0 : _item$props$itemText.toLowerCase().includes(lowerCaseSearch)) || '';
        });
      }
      setLocalItems(data);
    };
    watch(() => props.fetchFn, (oldVal, newVal) => {
      if (newVal !== oldVal) {
        loadData();
      }
    });
    const loading = ref(false);
    const loadData = async (search = '') => {
      if (loading.value || props.disabled || props.readonly || !isEditMode.value) {
        setSearchedValues([], search);
        return;
      }
      loading.value = true;
      let data;
      if (props.fetchFn) {
        var _searchRef$value;
        skeletonLoader.value = true;
        // fetch data using passed params, search value
        // Added props searchWithRansack to add terms and q conditionlly
        // this changes are made for advance filters in advance filters we are using terms instead of q
        // Search is expected as string some times and array some times, when ever some thing is pasted into search field then its converts to array to handel that we have taken the [0] value of search
        const fetchFnParams = {
          ...props.fetchParams,
          page: ++fetchMeta.value.page
        };
        if (props.searchWithRansack && props.autocomplete) {
          var _props$fetchParams;
          if (Array.isArray(search) && search.length) {
            search = search[0];
          }
          fetchFnParams.q = {
            ...((_props$fetchParams = props.fetchParams) === null || _props$fetchParams === void 0 ? void 0 : _props$fetchParams.q),
            [props.searchKey]: search
          };
        } else {
          fetchFnParams.terms = search;
          if (Array.isArray(search) && search.length) {
            fetchFnParams.predicate = 'cont_any';
          }
        }
        const res = await props.fetchFn(fetchFnParams);
        if ((!res || !res.data.length) && !(props.dataKey && res.data[props.dataKey].length)) {
          skeletonLoader.value = false;
          loading.value = false;
          localItems.value = [];
          return;
        }
        // this means we are on the first fetch (or resetting fetch)
        if (fetchMeta.value.totalPages === -1) {
          localItems.value = [];
          fetchMeta.value.totalPages = res.totalPages;
        }
        fetchMeta.value.nextPage = res.nextPage;
        if (fetchMeta.value.totalPages <= fetchMeta.value.page || fetchMeta.value.totalPages === 0 || fetchMeta.value.nextPage === null) skeletonLoader.value = false;
        // transform the data, array of string or array of objects are both valid
        const responseData = props.dataKey ? res.data[props.dataKey] : res.data;
        data = responseData.map(dat => typeof dat === 'string' ? dat : {
          ...dat,
          [props.itemValue]: dat[props.itemValue],
          [props.itemText]: dat[props.itemText]
        });
        (_searchRef$value = searchRef.value) === null || _searchRef$value === void 0 ? void 0 : _searchRef$value.setLoading(false);
        // v-model items are required to be in array for v-select to work so below correctly add items to array
        setLocalItems(data);
      } else {
        skeletonLoader.value = false;
        setSearchedValues(data, search);
      }
      loading.value = false;
    };
    const searchValue = ref('');
    const searchRef = ref(null);
    const reset = (search = [], pasted = {
      isPasted: false
    }) => {
      // if we are resetting the input we should either:
      // clear the selected options if we have no value
      // or clear the selected options with just the value to make sure it shows as "selected"
      // --- the value must be in the list of items to render
      if (pasted.isPasted) {
        if (props.selectOnPaste) {
          const dataObj = search.map((item, index) => {
            return {
              id: `${index}-${item}`,
              [props.itemValue]: item,
              [props.itemText]: item
            };
          });
          localItems.value = dataObj;
          emit('input', dataObj);
        }
      } else {
        var _localValue$value9;
        if (typeof localValue.value === 'string' && localValue.value || (_localValue$value9 = localValue.value) !== null && _localValue$value9 !== void 0 && _localValue$value9[props.itemText]) {
          var _localValue$value10, _localValue$value11;
          localItems.value = typeof localValue.value === 'string' ? [localValue.value] : [{
            ...localValue.value,
            [props.itemValue]: (_localValue$value10 = localValue.value) === null || _localValue$value10 === void 0 ? void 0 : _localValue$value10[props.itemValue],
            [props.itemText]: (_localValue$value11 = localValue.value) === null || _localValue$value11 === void 0 ? void 0 : _localValue$value11[props.itemText]
          }];
        } else if (props.multiple && localValue.value.length) {
          localItems.value = localValue.value;
        } else {
          localItems.value = [];
        }
        fetchMeta.value.page = 0;
        fetchMeta.value.totalPages = -1;
        loadData(typeof search === 'string' ? search : search[0]);
      }
    };
    const displayText = () => {
      var _localValue$value12;
      if (typeof localValue.value === 'string' && localValue.value) {
        return localValue.value;
      } else if ((_localValue$value12 = localValue.value) !== null && _localValue$value12 !== void 0 && _localValue$value12[props.itemText]) {
        var _localValue$value13;
        return ((_localValue$value13 = localValue.value) === null || _localValue$value13 === void 0 ? void 0 : _localValue$value13[props.itemText]) || `No ${props.label.toLowerCase()}`;
      } else if (Array.isArray(localValue.value)) {
        var _localValue$value14;
        if ((_localValue$value14 = localValue.value) !== null && _localValue$value14 !== void 0 && _localValue$value14.length) {
          const textArr = localValue.value.map(val => val[props.itemText]);
          let returnText = `${textArr.join(',')}`;
          if (props.multiple && props.numberOfChips !== -1) {
            returnText = `${textArr[0]}`;
          }
          return returnText;
        }
      }
      let noDataText;
      if (props.readModeEmptyState) {
        noDataText = props.readModeEmptyState;
      } else if (props.label) {
        noDataText = `No ${props.label.toLowerCase()}`;
      }
      return noDataText;
    };
    const include = () => {
      if (isEditMode.value) {
        return [...document.querySelectorAll('.menuable__content__active *'), ...document.getElementsByClassName('menuable__content__active'), ...document.querySelectorAll('.v-dialog__content--active *'), ...document.getElementsByClassName('v-dialog__content--active'), ...document.getElementsByClassName('v-overlay')];
      } else return [];
    };
    const getChipsArray = () => {
      var _localValue$value15, _localValue$value15$m, _array, _array$splice;
      let array = [];
      array = (_localValue$value15 = localValue.value) === null || _localValue$value15 === void 0 ? void 0 : (_localValue$value15$m = _localValue$value15.map) === null || _localValue$value15$m === void 0 ? void 0 : _localValue$value15$m.call(_localValue$value15, item => {
        return item[props.itemText];
      });
      return (_array = array) === null || _array === void 0 ? void 0 : (_array$splice = _array.splice) === null || _array$splice === void 0 ? void 0 : _array$splice.call(_array, props.numberOfChips, localValue.value.length);
    };
    const tooltip = ref({
      show: false,
      text: ''
    });
    const handleChange = e => {
      localValue.value = e;
      checkTooltip();
    };
    const checkTooltip = () => {
      nextTick(() => {
        var _inputRef$value6, _inputRef$value6$$el, _inputRef$value6$$el$, _inputRef$value6$$el$2, _inputRef$value6$$el$3, _inputRef$value6$$el$4, _inputRef$value6$$el$5, _inputRef$value6$$el$6, _inputRef$value6$$el$7, _inputRef$value6$$el$8, _inputRef$value6$$el$9;
        const textElement = (_inputRef$value6 = inputRef.value) === null || _inputRef$value6 === void 0 ? void 0 : (_inputRef$value6$$el = _inputRef$value6.$el) === null || _inputRef$value6$$el === void 0 ? void 0 : (_inputRef$value6$$el$ = _inputRef$value6$$el.children) === null || _inputRef$value6$$el$ === void 0 ? void 0 : (_inputRef$value6$$el$2 = _inputRef$value6$$el$[0]) === null || _inputRef$value6$$el$2 === void 0 ? void 0 : (_inputRef$value6$$el$3 = _inputRef$value6$$el$2.children) === null || _inputRef$value6$$el$3 === void 0 ? void 0 : (_inputRef$value6$$el$4 = _inputRef$value6$$el$3[0]) === null || _inputRef$value6$$el$4 === void 0 ? void 0 : (_inputRef$value6$$el$5 = _inputRef$value6$$el$4.children) === null || _inputRef$value6$$el$5 === void 0 ? void 0 : (_inputRef$value6$$el$6 = _inputRef$value6$$el$5[1]) === null || _inputRef$value6$$el$6 === void 0 ? void 0 : (_inputRef$value6$$el$7 = _inputRef$value6$$el$6.children) === null || _inputRef$value6$$el$7 === void 0 ? void 0 : (_inputRef$value6$$el$8 = _inputRef$value6$$el$7[1]) === null || _inputRef$value6$$el$8 === void 0 ? void 0 : (_inputRef$value6$$el$9 = _inputRef$value6$$el$8.children) === null || _inputRef$value6$$el$9 === void 0 ? void 0 : _inputRef$value6$$el$9[0];
        tooltip.value.show = (textElement === null || textElement === void 0 ? void 0 : textElement.offsetWidth) < (textElement === null || textElement === void 0 ? void 0 : textElement.scrollWidth);
        tooltip.value.text = displayText();
      });
    };
    const handleSelection = event => {
      if (props.maxSelections !== -1 && (event === null || event === void 0 ? void 0 : event.length) > props.maxSelections) {
        event.pop();
      }
    };
    const hasNextPage = computed(() => {
      return fetchMeta.value.page < fetchMeta.value.totalPages || fetchMeta.value.nextPage;
    });
    const cleanUpEditMode = () => {
      isEditMode.value = !props.canDoubleClickToEdit;
      propChanged = true;
    };
    return {
      inputRef,
      searchRef,
      localItems,
      searchValue,
      loadData,
      skeletonLoader,
      fetchMeta,
      localValue,
      reset,
      isEditMode,
      toggleEditMode,
      displayText,
      include,
      resetSearch,
      localRules,
      hovering,
      getChipsArray,
      checkTooltip,
      tooltip,
      handleChange,
      showToolTipWhenInMultiTextMode,
      isEllipses,
      displayTextRef,
      handleSelection,
      hasNextPage,
      startCase,
      INFO_OUTLINE,
      cleanUpEditMode
    };
  }
});