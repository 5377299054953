import { apis } from '@/api'
export default {
  paymentMethodStatus: () => apis.$get('/subscription_payment_methods/status'),
  paymentStatus: () => apis.$get('/subscription_invoices/status'),
  paymentStatusCheck: () => apis.$get('/subscription_invoices/updated_status'),
  paymentMethodStatusCheck: () => apis.$get('subscription_payment_methods/updated_status'),
  getChargebeeSubscriptionDetails: () => apis.$get('chargebee_subscriptions/subscription_details'),
  createPortalSession: () => apis.$post('/chargebee_subscriptions/portal_session'),
  getInvoiceOverageCharge: () => apis.$get('chargebee_subscriptions/current_term_overage_charge'),
  upgradeSubscriptionHostedPage: (param: any) => apis.$post('/chargebee_subscriptions/checkout_existing_for_items', { subscriptionItems: param })
}
