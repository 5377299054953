import { api } from '@/api'
import qs from 'qs'
import { TerritoryUsersParams } from '@/use/territories'

export default {
  users: ({
    territoryId, include, q, page, perPage,
    clientBillingMethod, locationId
  }: TerritoryUsersParams) => {
    return api.$get(`/territories/${territoryId}/users`, {
      params: {
        clientBillingMethod,
        locationId,
        include,
        q,
        page,
        perPage
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  }
}
