import repositories from '@/repositories'
import { reactive } from 'vue'
import { AutocompletePayload, AutocompleteResponse } from '@/types/interfaces/api-v2/autocomplete'

const makePaymentData: {
  loading:boolean,
  errorMessage: string,
  status: number | any
  list: any
} = reactive({
  loading: false,
  errorMessage: '',
  status: null,
  list: []
})
const billPayment: {
  loading: boolean,
  list: any
} = reactive({
  loading: false,
  list: {}
})

export const useMakePayment = () => {
  const generateMakePayment = async (params: any) => {
    try {
      makePaymentData.loading = true
      makePaymentData.errorMessage = ''
      makePaymentData.status = null
      const response = await repositories.makePayment.createMakePayment(params)
      makePaymentData.list = response.billPayment
    } catch (error: any) {
      makePaymentData.errorMessage = error?.data?.errors
      makePaymentData.status = error?.status
    } finally {
      makePaymentData.loading = false
    }
  }
  const loadBillPayment = async (params: any) => {
    try {
      billPayment.loading = true
      const response = await repositories.makePayment.getBillPayment(params)
      billPayment.list = response.billPayment
    } catch (err) {
      console.log(err)
    } finally {
      billPayment.loading = false
    }
  }
  const madePaymentsAutoComplete = async (params: AutocompletePayload) => {
    try {
      const res = await repositories.makePayment.madePaymentsAutoComplete(params)
      return {
        data: res.results,
        nextPage: res.nextPage
      } as { data: AutocompleteResponse[], nextPage: number }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  return {
    generateMakePayment,
    makePaymentData,
    loadBillPayment,
    billPayment,
    madePaymentsAutoComplete
  }
}
