import { api } from '@/api'

export default {
  verifyService: (tripId: number, serviceId: number, verifyTrip: boolean) => {
    return api.$patch(`/trips/${tripId}/work_order_services/${serviceId}/verify`,
      { verifyTrip }
    )
  },
  unverifyService: (tripId: number, serviceId: number) => {
    return api.$patch(`/trips/${tripId}/work_order_services/${serviceId}/unverify`
    )
  }
}
