import { api } from '@/api'
import qs from 'qs'

interface GetTaskRateListParams {
  perPage: number
  page: number
  q?: any
}

export default {
  get: ({ perPage, page, q }: GetTaskRateListParams) => api.$get('/task_rates', {
    params: {
      page,
      per_page: perPage,
      q
    },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  getById: (id: number, params: any) => api.$get(`/task_rates/${id}`, {
    params,
    paramsSerializer: (params: any) => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  patch: (id: number, include: string, params: any) => api.$patch(`/task_rates/${id}`, {
    TaskRate: params,
    include,
    paramsSerializer: (params: any) => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  })
}
