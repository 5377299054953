import { AttachmentTripCategory, AttachmentWorkOrderCategory } from '@/types/enums/work-order/attachment'

export const mimeTypes = [
  'application/pdf',
  'application/msexcel',
  'application/x-msexcel',
  'application/x-ms-excel',
  'application/x-excel',
  'application/x-dos_ms_excel',
  'application/x-xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  '.doc',
  '.docx',
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/tiff',
  'text/csv',
  '.eml',
  '.msg',
  'message/rfc822'
]

export const imageDisplayOptionsForPDF = [
  {
    color: 'primary',
    value: true,
    label: 'Full Width Photos'
  },
  {
    color: 'primary',
    value: false,
    label: '1/2 Width Photos'
  }
]

export const imageMimeTypes = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/tiff'
]

export const workOrderAttachmentCategories = [
  { name: AttachmentWorkOrderCategory.PHOTO_OR_DOCUMENT },
  { name: AttachmentWorkOrderCategory.CLIENT_INVOICE },
  { name: AttachmentWorkOrderCategory.CLIENT_PROPOSAL },
  { name: AttachmentWorkOrderCategory.CLIENT_PURCHASE_ORDER },
  { name: AttachmentWorkOrderCategory.VENDOR_INVOICE },
  { name: AttachmentWorkOrderCategory.VENDOR_PROPOSAL },
  { name: AttachmentWorkOrderCategory.VENDOR_PURCHASE_ORDER }
]

export const tripAttachmentCategories = [
  { name: AttachmentTripCategory.PHOTO },
  { name: AttachmentTripCategory.ATTACHMENT_FROM_WO },
  { name: AttachmentTripCategory.INSPECTION_FORM },
  { name: AttachmentTripCategory.REPAIR_FORM },
  { name: AttachmentTripCategory.OTHER },
  { name: AttachmentTripCategory.INVOICE },
  { name: AttachmentTripCategory.PROPOSAL }
]

export const DEFAULT_ATTACHMENT = {
  id: '',
  attachedFile: [],
  name: '',
  description: '',
  status: '',
  totalCents: 0,
  makeFileAvailableToClient: false,
  makeFileAvailableToVendor: false
}
