import { api } from '@/api'
import qs from 'qs'

interface GetLedgerAccountListParams {
  perPage: number
  page: number
  q?: any
}

export default {
  getLedgerAccountList: ({ perPage, page, q }: GetLedgerAccountListParams) => api.$get('/ledger_accounts', {
    params: {
      page,
      per_page: perPage,
      q
    },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  })
}
