import { api } from '@/api'
import qs from 'qs'
import { FetchUserParams } from '@/use/users'

export default {
  show: ({ id, include, q, page, perPage }: FetchUserParams) => {
    return api.$get(`/users/${id}`, {
      params: {
        include,
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  }
}
