import { api } from '@/api'
import qs from 'qs'

interface GetRegionListParams {
  perPage?: number
  page?: number
  q?: any
}

export default {
  getRegionList: ({ perPage, page, q }: GetRegionListParams) => api.$get('/territories', {
    params: {
      page,
      per_page: perPage,
      q
    },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  deleteRegionWithId: (regionId: string) => api.$delete(`/territories/${regionId}`)
}
