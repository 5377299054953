import repositories from '@/repositories'
import { ref } from 'vue'

const exportModalRef = ref<any>(null)
const exportToastRef = ref<any>(null)

// {
//   "id": 0,
//   "name": "string",
//   "report_type": "string",
//   "custom_headers": [
//     "string"
//   ],
//   "tab": "string",
//   "company_id": 0,
//   "user_id": 0
// }

export const useExports = () => {
  const getTemplates = async (params: any) => {
    try {
      const res = await repositories.exports.getTemplates(params)
      return {
        data: res[`${params.type}ReportTemplates`],
        page: res.meta.currentPage,
        totalPages: res.meta.totalPages
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }
  const createTemplate = async (type: string, body: any) => {
    try {
      const res = await repositories.exports.createTemplate(type, body)
      return res
    } catch (err) {
      console.log(err)
    }
  }

  return {
    getTemplates,
    exportModalRef,
    exportToastRef,
    createTemplate
  }
}
