import { ref } from 'vue'
import { Toast } from '@/components/uc/toast/definitions'
import { max } from 'lodash'

interface ToastQueueEntry extends Toast {
  id?: number
}

// had to use the any type here because apparently a ref of an extending interface confused the ts compiler
// not to worry, we validate the type when we insert.
const toasts = ref<any[]>([])
const TOAST_TRANSITION_TIME = 150
export const useToasts = () => {
  const addToast = (toastConfig: Toast) => {
    const id = (max(toasts.value.map(t => t?.id || 0)) || 0) + 1 as number
    const queueEntry: ToastQueueEntry = { ...toastConfig, id }
    if (toasts.value.length && toastConfig.color) {
      let added = false
      for (let i = 0; i < toasts.value.length; i++) {
        if (toastConfig.color === toasts.value[i].color) {
          toasts.value.splice(i, 1, queueEntry)
          added = true
          break
        }
      }
      if (!added) {
        toasts.value.push(queueEntry)
      }
    } else {
      toasts.value?.push(queueEntry)
    }
  }

  const closeToast = () => {
    setTimeout(() => {
      toasts.value.splice(0, 1)
    }, TOAST_TRANSITION_TIME)
  }

  return {
    toasts,
    addToast,
    closeToast
  }
}
