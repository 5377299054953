import { VCheckbox } from 'vuetify/lib/components/VCheckbox';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c(VCheckbox, _vm._b({
    class: {
      'remove-margin-top': _vm.removeMargin
    },
    attrs: {
      "label": _vm.label,
      "disabled": _vm.disabled
    },
    on: {
      "change": function ($event) {
        _vm.confirmationRequired ? _setup.openConfirmationModal() : _setup.handleChange($event);
      }
    },
    model: {
      value: _setup.isChecked,
      callback: function ($$v) {
        _setup.isChecked = $$v;
      },
      expression: "isChecked"
    }
  }, 'v-checkbox', _vm.$attrs, false)), _c('uc-modal', {
    attrs: {
      "closable": true,
      "persistent": _vm.confirmationModalProps.persistent,
      "modal-card-props": {
        headerText: _vm.confirmationModalProps.headerText,
        size: _vm.confirmationModalProps.size
      }
    },
    on: {
      "close:cancel": function ($event) {
        return _setup.processUserConfirmation(!_setup.isChecked);
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex flex-column"
        }, [_c('uc-text', {
          attrs: {
            "text": _vm.confirmationModalProps.bodyText,
            "type": "body",
            "size": 1
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function () {
        return [_c('uc-button-group', {
          attrs: {
            "buttons": [{
              type: 'outlined',
              text: _vm.confirmationModalProps.cancelLabel,
              eventName: 'cancel'
            }, {
              text: _vm.confirmationModalProps.saveLabel,
              state: 'primary',
              eventName: 'save'
            }]
          },
          on: {
            "cancel": function ($event) {
              return _setup.processUserConfirmation(!_setup.isChecked);
            },
            "save": function ($event) {
              return _setup.processUserConfirmation(!!_setup.isChecked);
            }
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _setup.showConfirmationModal,
      callback: function ($$v) {
        _setup.showConfirmationModal = $$v;
      },
      expression: "showConfirmationModal"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };