import { api } from '@/api'
import qs from 'qs'
interface Params {
  include?: string
  q?: any
  page?: number
  perPage?: number
  id?: number
}

export default {
  getClientNetTerms: () => api.$get('/clients/net_terms'),
  getVendorNetTerms: () => api.$get('/vendors/net_terms'),
  getPaymentTermsDetails: (params:any) => {
    const parameters:Params = params
    const { include, q, page, perPage } = parameters
    return api.$get('/payment_terms', {
      params: {
        include,
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  }
}
