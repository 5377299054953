import { api } from '@/api'
import qs from 'qs'
import { AutocompletePayload } from '@/types/interfaces'

interface GetCrewListParams {
  perPage: number
  page: number
  q?: any
  include?: any
}

export default {
  get: ({ perPage, page, q, include }: GetCrewListParams) => {
    return api.$get('/crews', {
      params: {
        page,
        per_page: perPage,
        include,
        q
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  delete: (teamMemberIds: Array<string>, q = {}) => {
    return api.$delete('/crews/bulk_destroy', {
      params: {
        crew_ids: teamMemberIds.join(','),
        q
      },
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' })
    })
  },
  post: (params: any, requestBody: any) => {
    return api.$post('/crews', {
      crew: { ...requestBody }
    }, {
      params,
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  patch: (params : any, crewId: any, requestBody: any) => {
    return api.$patch(`/crews/${crewId}`, { crew: { ...requestBody } }, {
      params,
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: (params:any) => {
        return qs.stringify(params)
      }
    })
  },
  getById: (crewId: string, include: string) => {
    return api.$get(`/crews/${crewId}`, {
      params: {
        include
      }
    })
  },
  crewsAutoComplete: (params: AutocompletePayload) => {
    const { field, terms, predicate, page, perPage } = params
    return api.$get('/crews/autocomplete', {
      params: {
        field,
        terms,
        predicate,
        page,
        perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  crewsSearch: ({ perPage, page, q, include }: GetCrewListParams) => {
    return api.$post('/crews/search', {
      page,
      per_page: perPage,
      include,
      q
    },
    {
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  }
}
