import repositories from '@/repositories'
import { cloneDeep } from 'lodash'

export const useSurveys = () => {
  const getSurveys = async (params:any) => {
    try {
      const res = await repositories.surveys.get(params)
      return {
        data: res.surveys,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }
  const postSurvey = async (surveyObj: any, params:any) => {
    try {
      const res = await repositories.surveys.post(surveyObj, params)
      return {
        data: res['survey/survey']
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const patchSurvey = async (id: string | number, surveyObj: any, params:any) => {
    try {
      const res = await repositories.surveys.patch(id, surveyObj, params)
      return {
        data: res['survey/surveys']
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  // Map observation in survey
  const addAnswersToSurvey = (survey: any, observations: any) => {
    observations.forEach((observation: any) => {
      const question = survey.questions.find((q: any) => q.id === observation.questionId)
      if (question) {
        if (observation.observationDocuments?.length > 0) {
          question.defaultAnswer = observation.observationDocuments
          // Added documentId to update posted document only
          question.documentId = observation.observationDocuments[0].id
        } else if (observation.answerChoices.length) {
          question.defaultAnswer = observation.answerChoices.map((answer: any) => { return answer.id })
        } else if (observation?.answerToDate) {
          // answerToDate we are using for date/time picker which is coming in UTC
          question.defaultAnswer = observation.answerToDate
        } else {
          question.defaultAnswer = observation.answer
        }
        question.observationId = observation.id
      }
    })
    return survey
  }

  // Map public observation in local observations
  const mapObservationsToLocalObservations = (localObservation: any, publicObservations: any) => {
    const newObservations = [] as any
    if (localObservation.length) {
      // Need to replace observation ID
      publicObservations.forEach((observation: any) => {
        const question = localObservation.find((b: any) => b.questionId === observation.questionId)
        if (question) {
          const newObj = { ...observation }
          newObj.id = question.id
          newObj.surveyId = question.surveyId
          // Need to keep local document observation Id
          if (question.observationDocuments[0]?.id) {
            newObj.observationDocuments[0].id = question.observationDocuments[0].id
          }
          newObservations.push(newObj)
        }
      })
    } else {
      // there on no local document
      // Need to remove observation ID
      publicObservations.forEach((observation: any) => {
        const newObj = { ...observation }
        newObj.id = null
        newObj.surveyId = null
        newObservations.push(newObj)
      })
    }
    return newObservations
  }

  const reviewSurvey = async (surveyId: number) => {
    try {
      const res = await repositories.surveys.reviewSurvey(surveyId)
      return {
        data: res
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  return {
    getSurveys,
    postSurvey,
    patchSurvey,
    addAnswersToSurvey,
    reviewSurvey,
    mapObservationsToLocalObservations
  }
}
