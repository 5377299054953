import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('uc-list-row-base', _vm._g({
    staticClass: "uc-list-row-attachment ma-2",
    attrs: {
      "is-expanded": _vm.isExpanded,
      "is-selected": false,
      "can-select": _vm.canSelect,
      "can-expand": _vm.canExpand,
      "column-defs": _vm.columnDefs,
      "size": _vm.size,
      "height": '100%',
      "background-color": '',
      "show-actions": _vm.showActions
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex flex-row align-center overflow-hidden pt-2"
        }, [_c('uc-avatar', {
          staticClass: "mr-2",
          style: {
            background: `url(${_vm.localAttachment.attachmentUrl})`,
            backgroundSize: _vm.thumbnailSize
          },
          attrs: {
            "size": _vm.thumbnailSize,
            "rounded": '0%',
            "avatar-style": 'border-radius: 4px',
            "avatar": _vm.localAttachment.attachmentUrl
          }
        }), _c('uc-chip', {
          staticClass: "overflow-hidden ml-4",
          attrs: {
            "size": 'sm',
            "state": 'layer-secondary',
            "text": _vm.localAttachment.name,
            "can-select": false,
            "tooltip-position": 'top'
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "drawer-content",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex flex-column mx-4 my-2"
        }, [_c('div', {
          staticClass: "d-flex justify-start flex-1 align-center",
          class: _vm.localAttachment.description && 'mb-2'
        }, [_c('uc-text', {
          attrs: {
            "size": 1,
            "type": 'body',
            "text": _vm.localAttachment.description ? _vm.localAttachment.description : ''
          }
        })], 1), _vm._l(_vm.visibilityKeys, function (key, index) {
          return _c('div', {
            key: index,
            staticClass: "d-flex flex-row align-center"
          }, [_c(VIcon, {
            attrs: {
              "color": _vm.localAttachment[key] ? 'success' : 'error'
            }
          }, [_vm._v(" " + _vm._s(_vm.localAttachment[key] ? 'mdi-check-bold' : 'mdi-close-thick') + " ")]), _c('uc-text', {
            staticClass: "ml-2",
            attrs: {
              "type": "body",
              "size": 1,
              "text": _vm.getCleanedText(key)
            }
          })], 1);
        }), _c('div', {
          staticClass: "d-flex justify-end"
        }, [_vm.menuActions.length > 1 ? _c('uc-button-menu', {
          attrs: {
            "hide-button-on-mobile": true,
            "button-props": {
              text: _vm.menuActions[0].text.toUpperCase(),
              size: 'sm'
            },
            "activator-button-props": {
              text: '',
              size: 'sm',
              iconName: 'mdi-dots-vertical'
            },
            "menu-data": {
              header: '',
              height: null,
              rows: _vm.menuActions.slice(1)
            },
            "menu-props": {
              offsetY: true,
              nudgeLeft: '154px',
              closeOnContentClick: true
            }
          },
          on: {
            "download": _vm.downloadAttachment,
            "delete": function ($event) {
              return _vm.$emit('attachment:deleted', _vm.localAttachment.id);
            },
            "edit": function ($event) {
              return _vm.attachmentModalRef.show();
            }
          }
        }) : _c('uc-button', {
          staticClass: "px-2",
          attrs: {
            "icon-name": _vm.EXPORT
          },
          on: {
            "click": _vm.downloadAttachment
          }
        })], 1)], 2), _c('uc-modal', {
          ref: "attachmentModalRef",
          attrs: {
            "closable": true,
            "persistent": false,
            "modal-card-props": {
              headerText: 'Attachment Settings',
              size: 'lg'
            }
          },
          on: {
            "modal-closed": function ($event) {
              return _vm.cancel();
            }
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function () {
              return [_c('div', {
                staticClass: "d-flex flex-column"
              }, [_c('uc-text', {
                attrs: {
                  "type": "body",
                  "size": 1,
                  "text": "Control who has access to the attachment, as well as provide information of its contents to the user who has access to it."
                }
              }), _c('uc-input', {
                staticClass: "mt-4",
                attrs: {
                  "label": "Name",
                  "label-variant": true,
                  "hide-details": true
                },
                model: {
                  value: _vm.attachmentEdit.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.attachmentEdit, "name", $$v);
                  },
                  expression: "attachmentEdit.name"
                }
              }), _c('uc-input', {
                staticClass: "mt-4",
                attrs: {
                  "label": "Description",
                  "label-variant": true,
                  "hide-details": true
                },
                model: {
                  value: _vm.attachmentEdit.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.attachmentEdit, "description", $$v);
                  },
                  expression: "attachmentEdit.description"
                }
              })], 1), _c('div', {
                staticClass: "d-flex flex-column"
              }, _vm._l(_vm.visibilityKeys, function (key, index) {
                return _c('div', {
                  key: index,
                  staticClass: "mt-4"
                }, [_c('uc-checkbox-group', {
                  attrs: {
                    "value": _vm.attachmentEdit[key],
                    "hide-details": true,
                    "can-select-all": false,
                    "checkboxes": [{
                      label: _vm.upperFirst(_vm.lowerCase(key))
                    }]
                  },
                  on: {
                    "change": function ($event) {
                      _vm.attachmentEdit[key] = !_vm.attachmentEdit[key];
                    }
                  }
                })], 1);
              }), 0), _vm.resourceType === _vm.PROPOSAL_RESOURCE_TYPE && _vm.imageMimeTypes.includes(_vm.attachmentEdit.attachmentContentType) ? _c('div', {
                staticClass: "d-flex flex-column"
              }, [_c('uc-radio-group', {
                staticClass: "mt-4",
                attrs: {
                  "buttons": _vm.imageDisplayOptionsForPDF,
                  "label": 'Image Display'
                },
                model: {
                  value: _vm.attachmentEdit.attachmentFullWidth,
                  callback: function ($$v) {
                    _vm.$set(_vm.attachmentEdit, "attachmentFullWidth", $$v);
                  },
                  expression: "attachmentEdit.attachmentFullWidth"
                }
              })], 1) : _vm._e(), _c('div', {
                staticClass: "mt-4"
              }, [_c('uc-chip', {
                staticClass: "overflow-hidden",
                attrs: {
                  "size": 'md',
                  "state": 'layer-secondary',
                  "text": _vm.attachmentEdit.name,
                  "can-select": false,
                  "tooltip-position": 'top'
                }
              })], 1)];
            },
            proxy: true
          }, {
            key: "footer",
            fn: function () {
              return [_c('uc-button-group', {
                attrs: {
                  "buttons": [{
                    type: 'outlined',
                    text: 'CANCEL'
                  }, {
                    type: 'primary',
                    text: 'SAVE',
                    eventName: 'confirm'
                  }]
                },
                on: {
                  "cancel": function ($event) {
                    return _vm.handleEditCancel(_vm.$refs);
                  },
                  "confirm": function ($event) {
                    return _vm.handleEditSave(_vm.$refs);
                  }
                }
              })];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, _vm.$listeners));
};
var staticRenderFns = [];
export { render, staticRenderFns };