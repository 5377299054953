var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "d-flex flex-row align-center justify-space-between my-3 mx-2"
  }, [_vm.$vuetify.breakpoint.mdAndUp ? _c('div', {
    staticClass: "d-flex flex-row align-center justify-start"
  }, [_c('uc-text', {
    staticClass: "mr-1",
    attrs: {
      "size": 1,
      "type": 'body',
      "text": _vm.serviceType,
      "bold": ""
    }
  }), _c('uc-icon', {
    attrs: {
      "value": _setup.INFO_OUTLINE,
      "size": "sm",
      "tooltip-position": 'bottom',
      "tooltip-text": `Please note, some ${_vm.serviceType.toLowerCase()} may have an additional third party fee associated to them. This fee will not be reflected in your plan total.`
    }
  })], 1) : _vm._e(), _c('uc-text', {
    attrs: {
      "size": 2,
      "type": 'body',
      "text": _setup.serviceSelectionInfo()
    }
  }), _c('uc-text', {
    attrs: {
      "data-cy": "selected-integration-apps-count",
      "size": 2,
      "type": 'body',
      "text": _setup.selectedServicesText(),
      "bold": ""
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };