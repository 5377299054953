import repositories from '@/repositories'
import { computed, ref } from 'vue'
import { useApp } from '@/use/app'
import { findCompanyType } from '@/utils/company'

const currentUsersCompany = ref<any>(null)
const accessibleFeatures = ref<any>(null)
export const useUsersCompanies = () => {
  const { companyType, topLevelTabs } = useApp()
  const getUsersCompany = async ({ perPage, page, q, id }: any) => {
    try {
      const res = await repositories.usersCompanies.getUserCompany({ perPage, page, q, id })
      if (!res || !res.company) {
        throw new Error('error loading current company')
      }
      currentUsersCompany.value = res.company
      companyType.value = findCompanyType(currentUsersCompany.value.roles)
      return true
    } catch (err) {
      console.log(err)
      return false
    }
  }
  const getUsersCompanies = async (params: any) => {
    try {
      const res = await repositories.usersCompanies.get(params)
      return {
        data: res.companies,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as any
    } catch (err: any) {
      console.error(err)
    }
  }
  // DO NOT USE BELOW FUNCTION IT IS DEPRECATED. USE THE ONE ABOVE
  const getUserCompanies = async (params: any) => {
    try {
      return await repositories.usersCompanies.get(params)
    } catch (err) {
      return false
    }
  }
  const sendInvitationToUser = async (payload: any) => {
    try {
      return await repositories.usersCompanies.sendInvitationToUser(payload)
    } catch (err) {
      return false
    }
  }
  const getAccessibleFeatures = async ({ page, perPage, include, q }: any) => {
    try {
      accessibleFeatures.value = await repositories.userCompany.listAccessibleFeatures({
        page,
        perPage,
        include,
        q
      })
      return accessibleFeatures.value
    } catch (err: any) {
      console.log(err)
      return false
    }
  }

  const switchCompanies = async (companyId: number | string) => {
    try {
      const res = await repositories.usersCompanies.switchCompanies(companyId)
      return res
    } catch (err) {
      console.error(err)
      return false
    }
  }
  const isPaidCompany = computed(() => {
    if (!currentUsersCompany.value) return false
    else {
      return !!currentUsersCompany.value.approvedAt
    }
  })

  return {
    getUsersCompany,
    currentUsersCompany,
    isPaidCompany,
    getAccessibleFeatures,
    accessibleFeatures,
    sendInvitationToUser,
    switchCompanies,
    getUsersCompanies,
    getUserCompanies
  }
}
