import _ from 'lodash'
import { ref } from 'vue'

const palettes: Record<string, string[]> = {
  greyBlue: [
    '#FBFDFF',
    '#CFE0FD',
    '#A2C2F6',
    '#80A8EB',
    '#6994DE',
    '#335DA3',
    '#294C8A',
    '#22437B',
    '#19335F',
    '#122647'
  ],
  grey: [
    '#E4EDFE',
    '#D4E0F6',
    '#ABB6C9',
    '#8895AC',
    '#677690',
    '#31425E',
    '#21304B',
    '#132037',
    '#0F1C31',
    '#0B172B'
  ],
  text: [
    '#000000',
    '#010306',
    '#2C4267',
    '#536B95',
    '#637AA0',
    '#728CB7',
    '#859ABF',
    '#AFC2E3',
    '#EFF2F6',
    '#FFFFFF'
  ],
  primary: [
    '#7EA0DB',
    '#5B94F3',
    '#3981FB',
    '#2776FB',
    '#1A6EFB',
    '#176AF8',
    '#1868EF',
    '#1863E1',
    '#1851B2',
    '#143977'
  ],
  error: [
    '#FFECEF',
    '#FFCED4',
    '#F99C9D',
    '#F37476',
    '#FF5252',
    '#F73536',
    '#E42930',
    '#D72028',
    '#C8111C',
    '#A81824'
  ],
  success: [
    '#C8E6C9',
    '#A5D6A7',
    '#81C784',
    '#66BB69',
    '#4CAF4F',
    '#47A74A',
    '#43A046',
    '#388E3B',
    '#2E7D31',
    '#1B5E1F'
  ],
  warning: [
    '#FFF3E0',
    '#FFDFB2',
    '#FFCB7F',
    '#FFA525',
    '#FFA525',
    '#FF9600',
    '#FB8A00',
    '#F57A01',
    '#EF6A01',
    '#E64F01'
  ]
}
const reversedPalettes = (() => {
  const rp: any = {}
  Object.keys(palettes).forEach((p: any) => {
    rp[p] = [...palettes[p]].reverse()
  })
  return rp
})()
palettes.text.push('#FFF')
reversedPalettes.text.push('#FFF')

const colorCodes: any = {
  primary: {
    light: {
      palette: 'primary',
      base: 4,
      disabled: 1,
      extraColors: true
    },
    dark: {
      palette: 'primary',
      base: 5,
      disabled: 1,
      extraColors: true
    }
  },
  warning: {
    light: {
      palette: 'warning',
      base: 4,
      disabled: 1,
      extraColors: true
    },
    dark: {
      palette: 'warning',
      base: 5,
      disabled: 1,
      extraColors: true
    }
  },
  success: {
    light: {
      palette: 'success',
      base: 4,
      disabled: 1,
      extraColors: true
    },
    dark: {
      palette: 'success',
      base: 5,
      disabled: 1,
      extraColors: true
    }
  },
  background: {
    palette: 'grey',
    base: 0,
    secondary: 1,
    extraColors: false
  },
  layer: {
    palette: 'greyBlue',
    base: 0,
    secondary: 1,
    extraColors: true
  },
  text: {
    palette: 'text',
    base: 1,
    disabled: 3,
    extraColors: false
  },
  error: {
    light: {
      palette: 'error',
      base: 4,
      disabled: 1,
      extraColors: true
    },
    dark: {
      palette: 'error',
      base: 5,
      disabled: 1,
      extraColors: true
    }
  }
}

const HOVER_OFFSET = 1
const SELECTED_OFFSET = 2
const ACTIVE_OFFSET = 3

const generateCssVar = (colorName: string, key: string, value: number, palette: any) => {
  const c: any = {}
  if (key === 'base') {
    c[colorName] = palette[value]
  } else {
    c[colorName + '-' + key] = palette[value]
  }
  return c
}

const generateCssVars = (paletteObj: any, theme: string, colorCodesToCopy: any, colorKey: string) => {
  // deep copy the color codes so that we don't affect original object
  let colorCodesCopy = _.cloneDeep(colorCodesToCopy)
  // if light or dark are defined as a key on the colorCodes then the index of the desired color in the given palette is different in light and dark
  if (colorCodesCopy[theme]) {
    colorCodesCopy = _.cloneDeep(colorCodesCopy[theme])
  }
  const palette = colorCodesCopy.palette
  delete colorCodesCopy.palette
  // pass extraColors: true in order to generate the action-based variants for the color type
  if (colorCodesCopy.extraColors) {
    colorCodesCopy.hover = HOVER_OFFSET + colorCodesCopy.base
    colorCodesCopy.selected = SELECTED_OFFSET + colorCodesCopy.base
    colorCodesCopy.active = ACTIVE_OFFSET + colorCodesCopy.base
  }
  delete colorCodesCopy.extraColors
  Object.keys(colorCodesCopy).forEach((j: string) => {
    const color = generateCssVar(colorKey, j, colorCodesCopy[j], theme === 'light' ? palettes[palette] : reversedPalettes[palette])
    paletteObj.value = {
      ...paletteObj.value,
      ...color
    }
  })
}

const lightModeColors = ref<any>({})
Object.keys(colorCodes).forEach((k: string) => {
  generateCssVars(lightModeColors, 'light', colorCodes[k], k)
})

const darkModeColors = ref<any>({})
Object.keys(colorCodes).forEach((k: string) => {
  // color codes contains each color type along with the config for which variants should be created / the colors which those variants should use
  generateCssVars(darkModeColors, 'dark', colorCodes[k], k)
})

export const useTheme = () => {
  return {
    lightModeColors,
    darkModeColors,
    palettes,
    reversedPalettes
  }
}
