import "core-js/modules/es.array.push.js";
import { defineComponent, onMounted, ref, computed, watch } from 'vue';
import { useAttachments, attachmentRequestResponseKeys } from '@/use/attachments';
import { fetchAndGenerateOnMounted, fetchAndGenerateChildData, generateRows } from '@/utils/table';
import { ERROR } from '@/constants/icons';
import { useToasts } from '@/use/toasts';
import { upperFirst, lowerCase, cloneDeep } from 'lodash';
import { PROPOSAL_RESOURCE_TYPE } from '@/constants/resource-types';
import { mimeTypes, imageDisplayOptionsForPDF, imageMimeTypes } from '@/constants/attachments';
export default defineComponent({
  props: {
    resourceType: {
      type: String,
      required: true
    },
    resourceTypeApiPath: {
      type: String,
      required: true
    },
    resourceId: {
      type: Number,
      required: true
    },
    accept: {
      type: String,
      default: () => mimeTypes.join(',')
    },
    disabled: {
      type: Boolean,
      default: false
    },
    visibilityKeys: {
      type: Array,
      default: () => []
    }
  },
  setup: (props, {
    emit
  }) => {
    const fileInput = ref(null);
    const {
      getAttachments,
      postAttachments,
      deleteAttachments,
      patchAttachment
    } = useAttachments();
    const attachmentsActionModal = ref(null);
    const selectedAttachment = ref(null);
    const isDraging = ref(false);
    const attachmentsData = ref({
      rows: [],
      onMounted: () => null,
      page: 0,
      totalPages: -1,
      totalCount: 0
    });
    const {
      addToast
    } = useToasts();
    const attachmentSettingModalRef = ref(null);
    // Counter is just a temperory variable to update the UI only while dragging
    // Actually the isDragging value need to reset only when number of times leave called is equals to number of times entered
    // https://stackoverflow.com/questions/7110353/html5-dragleave-fired-when-hovering-a-child-element
    let counter = 0;
    onMounted(() => {
      if (!props.disabled) {
        // Prevent browser from loading a drag-and-dropped file
        window.addEventListener('dragenter', e => {
          counter++;
          isDraging.value = true;
          e = e || Event;
          e.preventDefault();
        }, false);
        window.addEventListener('dragover', e => {
          e = e || Event;
          e.preventDefault();
        }, false);
        window.addEventListener('drop', e => {
          isDraging.value = false;
          e = e || Event;
          e.preventDefault();
        }, false);
        window.addEventListener('dragleave', e => {
          counter--;
          if (counter === 0) {
            isDraging.value = false;
          }
          e = e || Event;
          e.preventDefault();
        }, false);
      }
    });
    watch(attachmentsData.value.rows, rows => {
      // set number of attachments to totalCount to prevent count mismatch
      attachmentsData.value.totalCount = rows.length;
      emit('attachments-count', rows.length);
    });
    const getAttachmentData = async () => {
      if (props.resourceId !== -1) {
        const params = {
          resourceType: props.resourceTypeApiPath,
          resourceId: props.resourceId
        };
        await fetchAndGenerateChildData(attachmentsData.value, params, 'attachment', {
          hasBorder: false,
          canExpand: false
        }, getAttachments);
      }
      emit('attachments-count', attachmentsData.value.totalCount);
    };
    // handling smooth transition of #no-data loading state
    // we use a boolean and a setTimeout to control the transition time from the skeleton loading to "No attachments" text
    const loading = ref(true);
    const generateLoadingState = () => {
      setTimeout(() => {
        loading.value = false;
      }, 250);
    };
    // init onMounted
    attachmentsData.value.onMounted = getAttachmentData;
    fetchAndGenerateOnMounted(attachmentsData.value);
    const resetAttachmentData = () => {
      preResourceCreationAttachments.value = [];
      attachmentsData.value = {
        rows: [],
        onMounted: () => null,
        page: 0,
        totalPages: -1,
        totalCount: 0
      };
    };
    const getValidFiles = files => {
      const acceptedFileTypes = props.accept.split(',');
      const validFiles = [];
      // check for valid file formats
      files.forEach(file => {
        const fileSize = file.size / 1024 / 1024; // getting the file size in MB
        if (fileSize > 25.0) {
          return addToast({
            timeout: 5000,
            color: 'error',
            message: 'Unsupported file size upload, allowed only 25 MB max',
            prependIcon: ERROR
          });
        }
        if (acceptedFileTypes.includes(file.type) || acceptedFileTypes.includes(`${file.type.split('/')[0]}/*`)) {
          validFiles.push(file);
        } else {
          addToast({
            timeout: 5000,
            color: 'error',
            message: `Unsupported file extension upload: ${file.name}`,
            prependIcon: ERROR
          });
        }
      });
      return validFiles;
    };
    // Call Invoice Attachment Post API
    const processFiles = files => {
      if (files.length === 1) {
        initializeAttachmentModal(files[0]);
      } else {
        files.forEach(element => handlePostAttachment(element, true));
      }
    };
    /**
     * onClickUpload - get files from local
     */
    const onClickUpload = eventTarget => {
      const target = eventTarget;
      if (eventTarget === null || target.files === null) {
        return;
      }
      const fileDataArray = Array.from(target.files);
      const files = getValidFiles(fileDataArray);
      processFiles(files);
    };
    /**
     * onDrop - function call when drad and drop any file.
     */
    const onDrop = event => {
      if (props.disabled) {
        return;
      }
      counter = 0;
      isDraging.value = false;
      const dt = event.dataTransfer;
      const files = getValidFiles(Array.from(dt.files));
      processFiles(files);
    };
    /**
     * postAttachment - function for post attachment
     */
    const {
      responseKey,
      requestPayloadKey
    } = attachmentRequestResponseKeys[props.resourceTypeApiPath.split('inbound_').slice(-1)];
    const preResourceCreationAttachments = ref([]);
    const postAttachment = async (attachment, id) => {
      var _attachment$data, _attachment$data2, _attachment$data3, _attachment$data4, _attachment$data5;
      const formData = new FormData();
      const attachmentKey = requestPayloadKey;
      formData.append(`${attachmentKey}[name]`, (attachment === null || attachment === void 0 ? void 0 : (_attachment$data = attachment.data) === null || _attachment$data === void 0 ? void 0 : _attachment$data.name) || attachment.file.name);
      formData.append(`${attachmentKey}[attachment]`, attachment.file);
      formData.append(`${attachmentKey}[description]`, (attachment === null || attachment === void 0 ? void 0 : (_attachment$data2 = attachment.data) === null || _attachment$data2 === void 0 ? void 0 : _attachment$data2.description) || '');
      formData.append(`${attachmentKey}[make_file_available_to_client]`, attachment === null || attachment === void 0 ? void 0 : (_attachment$data3 = attachment.data) === null || _attachment$data3 === void 0 ? void 0 : _attachment$data3.makeFileAvailableToClient);
      formData.append(`${attachmentKey}[make_file_available_to_vendor]`, attachment === null || attachment === void 0 ? void 0 : (_attachment$data4 = attachment.data) === null || _attachment$data4 === void 0 ? void 0 : _attachment$data4.makeFileAvailableToVendor);
      formData.append(`${attachmentKey}[attachment_full_width]`, attachment === null || attachment === void 0 ? void 0 : (_attachment$data5 = attachment.data) === null || _attachment$data5 === void 0 ? void 0 : _attachment$data5.attachmentFullWidth);
      const params = {
        resourceType: props.resourceTypeApiPath,
        resourceId: id,
        payload: formData,
        responseKey
      };
      try {
        const fileUploadDetails = await postAttachments(params);
        if (fileUploadDetails) {
          // Add Page, totalPages, totalCount values = 1 when we dont have any or we are goign to upload first attachment
          if (attachmentsData.value.rows.length === 0) {
            attachmentsData.value.page = 1;
            attachmentsData.value.totalPages = 1;
            attachmentsData.value.totalCount = 1;
          }
          const tempdrawer = {
            rows: [],
            onMounted: () => null,
            page: 0,
            totalPages: -1,
            totalCount: 0
          };
          const rows = generateRows(tempdrawer, [(fileUploadDetails === null || fileUploadDetails === void 0 ? void 0 : fileUploadDetails.data) || {}], 'attachment', {}, () => null, () => null);
          // Add Uploaded attachment in already availabel attachment array
          attachmentsData.value.rows.push(...rows);
          emit('change', attachmentsData.value.rows);
          return fileUploadDetails;
        }
      } catch (err) {
        console.error(err);
      }
    };
    const handlePostAttachmentInMemory = () => {
      var _newUploadObj$value;
      preResourceCreationAttachments.value.push(cloneDeep(newUploadObj.value));
      if (attachmentsData.value.rows.length === 0) {
        attachmentsData.value.page = 1;
        attachmentsData.value.totalPages = 1;
        attachmentsData.value.totalCount = 1;
      }
      const tempdrawer = {
        rows: [],
        onMounted: () => null,
        page: 0,
        totalPages: -1,
        totalCount: 0
      };
      const rows = generateRows(tempdrawer, [((_newUploadObj$value = newUploadObj.value) === null || _newUploadObj$value === void 0 ? void 0 : _newUploadObj$value.data) || {}], 'attachment', {}, () => null, () => null);
      // Add Uploaded attachment in already availabel attachment array
      attachmentsData.value.rows.push(...rows);
      emit('attachments-count', attachmentsData.value.totalCount);
    };
    const deleteSelectedAttachment = async id => {
      const found = attachmentsData.value.rows.find(attachment => attachment.props.attachment.id === id);
      selectedAttachment.value = found.props.attachment;
      if (selectedAttachment.value) {
        attachmentsActionModal.value.show();
      }
    };
    const selectedAttachmentName = computed(() => {
      var _selectedAttachment$v, _selectedAttachment$v2;
      if ((_selectedAttachment$v = selectedAttachment.value) !== null && _selectedAttachment$v !== void 0 && _selectedAttachment$v.id && (_selectedAttachment$v2 = selectedAttachment.value) !== null && _selectedAttachment$v2 !== void 0 && _selectedAttachment$v2.name) {
        var _selectedAttachment$v3;
        return (_selectedAttachment$v3 = selectedAttachment.value) === null || _selectedAttachment$v3 === void 0 ? void 0 : _selectedAttachment$v3.name;
      } else return 'selected attachment';
    });
    const confirmDeleteAttachment = async () => {
      var _attachmentsActionMod;
      if ((_attachmentsActionMod = attachmentsActionModal.value) !== null && _attachmentsActionMod !== void 0 && _attachmentsActionMod.modalOpen) {
        var _attachmentsActionMod2;
        (_attachmentsActionMod2 = attachmentsActionModal.value) === null || _attachmentsActionMod2 === void 0 ? void 0 : _attachmentsActionMod2.close();
        let removeAtIndex = -1;
        if (props.resourceId === -1) {
          removeAtIndex = preResourceCreationAttachments.value.findIndex(attachmentData => {
            return selectedAttachment.value.id === attachmentData.data.id;
          });
        } else {
          const res = await deleteAttachments({
            resourceId: props.resourceId,
            resourceType: props.resourceTypeApiPath,
            id: selectedAttachment.value.id
          });
          if (res.success !== undefined) {
            removeAtIndex = attachmentsData.value.rows.findIndex(attachmentData => {
              return selectedAttachment.value.id === attachmentData.id;
            });
          } else {
            console.error('Failed to delete attachment');
            return;
          }
        }
        if (removeAtIndex > -1) {
          // optimistic update
          if (props.resourceId === -1) {
            // we need to manage precreated attachments twice - first in preResourceCreationAttachments arr, and also in attachmentsData.value.rows
            // if we dont splice from attachmentsData.value.rows, the attachment wont appear as deleted in the ux
            preResourceCreationAttachments.value.splice(removeAtIndex, 1);
          }
          attachmentsData.value.rows.splice(removeAtIndex, 1);
          emit('deleted', removeAtIndex);
          selectedAttachment.value = null;
        }
      }
    };
    const saveAttachment = async attachment => {
      if (props.resourceId === -1) {
        // if no resource id (unsaved local invoice) update the table for UI purposes
        // and update the list of saved to send attachments with updated data
        const tableUpdateIndex = attachmentsData.value.rows.findIndex(attachmentData => {
          return attachment.id === attachmentData.id;
        });
        const preSaveUpdateIndex = preResourceCreationAttachments.value.findIndex(attachmentData => {
          return attachment.id === attachmentData.data.id;
        });
        if (tableUpdateIndex !== -1 && preSaveUpdateIndex !== -1) {
          // optimistic update
          attachmentsData.value.rows[tableUpdateIndex].props.attachment = attachment;
          preResourceCreationAttachments.value[preSaveUpdateIndex].data = attachment;
        } else {
          console.error('Failed to save attachment');
        }
        return;
      }
      const payload = {
        [requestPayloadKey]: attachment
      };
      const res = await patchAttachment({
        payload,
        attachmentId: attachment.id,
        resourceId: props.resourceId,
        resourceType: props.resourceTypeApiPath,
        responseKey
      });
      if (res.data) {
        const updateIndex = attachmentsData.value.rows.findIndex(attachmentData => {
          return res.data.id === attachmentData.id;
        });
        if (updateIndex > -1) {
          // optimistic update
          attachmentsData.value.rows[updateIndex].props.attachment = res.data;
        }
      } else {
        console.error('Failed to save attachment');
      }
    };
    const computedAttachmentRows = computed(() => {
      var _attachmentsData$valu;
      if (!((_attachmentsData$valu = attachmentsData.value) !== null && _attachmentsData$valu !== void 0 && _attachmentsData$valu.rows.length)) return [];else {
        var _attachmentsData$valu2;
        const mapped = (_attachmentsData$valu2 = attachmentsData.value) === null || _attachmentsData$valu2 === void 0 ? void 0 : _attachmentsData$valu2.rows.map(row => {
          return row.props.attachment;
        });
        return mapped;
      }
    });
    const modalFooterButtons = [{
      type: 'outlined',
      text: 'CANCEL',
      size: 'md',
      eventName: 'cancel'
    }, {
      type: 'primary',
      state: 'error',
      text: 'DELETE',
      size: 'md',
      eventName: 'delete'
    }];
    const handleAttachmentChange = attachment => {
      emit('update', attachment);
    };
    const newUploadObj = ref({
      data: {
        attachmentUrl: '',
        description: '',
        id: `temp-${attachmentsData.value.rows.length + 1}`,
        makeFileAvailableToClient: true,
        makeFileAvailableToVendor: true,
        name: '',
        attachmentFullWidth: true
      },
      file: null,
      fileName: ''
    });
    const initializeAttachmentModal = file => {
      attachmentSettingModalRef.value.show();
      initializeAttachmentObject(file);
    };
    const initializeAttachmentObject = file => {
      newUploadObj.value.data.name = file.name.replace(/.([^.]*)$/, '-$1');
      newUploadObj.value.file = file;
      newUploadObj.value.data.description = '';
      newUploadObj.value.data.makeFileAvailableToClient = true;
      newUploadObj.value.data.makeFileAvailableToVendor = true;
      newUploadObj.value.fileName = file.name;
      newUploadObj.value.data.attachmentFullWidth = true;
    };
    const handlePostAttachment = (file, initializeObject) => {
      if (initializeObject) initializeAttachmentObject(file);
      if (!file) file = newUploadObj.value.file;
      if (props.resourceId === -1) {
        handlePostAttachmentInMemory();
      } else {
        postAttachment({
          data: {
            makeFileAvailableToClient: newUploadObj.value.data.makeFileAvailableToClient,
            makeFileAvailableToVendor: newUploadObj.value.data.makeFileAvailableToVendor,
            name: newUploadObj.value.data.name,
            description: newUploadObj.value.data.description,
            attachmentFullWidth: newUploadObj.value.data.attachmentFullWidth
          },
          file
        }, props.resourceId);
      }
    };
    return {
      isDraging,
      attachmentsData,
      getAttachmentData,
      resetAttachmentData,
      generateLoadingState,
      loading,
      onClickUpload,
      onDrop,
      postAttachment,
      modalFooterButtons,
      attachmentsActionModal,
      deleteSelectedAttachment,
      selectedAttachment,
      confirmDeleteAttachment,
      selectedAttachmentName,
      preResourceCreationAttachments,
      saveAttachment,
      computedAttachmentRows,
      handleAttachmentChange,
      fileInput,
      processFiles,
      attachmentSettingModalRef,
      upperFirst,
      lowerCase,
      newUploadObj,
      initializeAttachmentModal,
      handlePostAttachment,
      imageDisplayOptionsForPDF,
      imageMimeTypes,
      PROPOSAL_RESOURCE_TYPE
    };
  }
});