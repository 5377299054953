import { api } from '@/api'
import { AutocompletePayload } from '@/types/interfaces'
import { PostTripNoteParams, tripCancelParams, Trip, NewTrip } from '@/types/interfaces/api-v2/trip'
import qs from 'qs'
import { DefaultBillingMethodParams, TripReportParams, TripReportTemplateParams } from '@/use/trips'
interface Params {
  include?: string
  q?: any
  page?: number
  perPage?: number
  workOrderId: number | string
}

interface getAllTripsParams {
  scope?: string
  include?: string
  q?: any
  page?: number
  perPage?: number
  workOrderId?: string
  clientId?: number | string
  vendorId?: number | string,
  workOrderIds?: Array<string>
  assigneeDetailsRequired?: boolean
  includeCompliance?: boolean
  includeContactDetails?: boolean,
  code: number[],
  includeInvoiceStatus?: boolean
  includePhotosCount?: boolean
  includeCanApproveNonCompliantVendor?: boolean
}

interface technicianTripsParams {
  tripId: string
  include?: string
  q?: any
  page?: number
  perPage?: number
}

interface fetchResendVendorEmailsParams {
  id: number
  include?: string
  q?: any
  page?: number
  perPage?: number
}

interface sendEmailParams {
  id: number
  to: string[],
  cc?: string[]
  bcc?: string[]
}

export interface CreateOrUpdateTripParams {
  createMode: boolean
  trip: Trip | NewTrip,
  include?: string,
  assigneeDetailsRequired?: boolean,
  includeCompliance?: boolean,
  withoutAssignee?: boolean,
  includeCanApproveNonCompliantVendor?: boolean
}

export default {
  get: ({ include, q, page, perPage, workOrderId } : Params) => {
    return api.$get(`work_orders/${workOrderId}/trips`, {
      params: {
        include,
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },
  getPhotos: (tripId: string | number, { include, q, page, perPage } : Params) => {
    return api.$get(`trips/${tripId}/photos`, {
      params: {
        include,
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },
  assign: (params: any, workOrderId: any, tripId: any) => {
    return api.$patch(`work_orders/${workOrderId}/trips/${tripId}`, params)
  },
  getAllTrips: ({ scope, include, q, page, perPage, assigneeDetailsRequired, includeCompliance, includeContactDetails, code, includeInvoiceStatus, includePhotosCount, includeCanApproveNonCompliantVendor }: getAllTripsParams) => {
    return api.$get('/trips', {
      params: {
        scope,
        include,
        q,
        page,
        per_page: perPage,
        assignee_details_required: assigneeDetailsRequired,
        include_compliance: includeCompliance,
        include_contact_details: includeContactDetails,
        code,
        include_invoice_status: includeInvoiceStatus,
        includePhotosCount,
        includeCanApproveNonCompliantVendor
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getAllTripsCount: ({ workOrderIds }: { workOrderIds?: (string | number)[] | null }) => {
    const params: Record<string, any> = {}
    if (workOrderIds) {
      params.workOrderIds = workOrderIds
    }

    return api.$get('/trips/counts', { params })
  },
  getTechnicianTrips: ({ tripId, include, q, page, perPage }: technicianTripsParams) => {
    return api.$get(`/trips/${tripId}/technician_trips`, {
      params: {
        include,
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getTripsPhotos: (workOrderId: string | number, tripId: string | number, { include, q, page, perPage }: Params) => {
    return api.$get(`trips/${tripId}/photos/index_v2`, {
      params: {
        include,
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },
  tripsAutoComplete: (params: AutocompletePayload) => {
    const { field, terms, predicate, page } = params
    return api.$get('/trips/autocomplete', {
      params: {
        field,
        terms,
        predicate,
        page
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  createTripNote: (workOrderId: string, params: PostTripNoteParams) => {
    return api.$post(`/work_orders/${workOrderId}/notes/`, params)
  },
  acceptTrip: (workOrderId: string, tripId: number) => {
    return api.$patch(
      `/work_orders/${workOrderId}/trips/${tripId}/accept`,
      { context: { returnTrip: true } }
    )
  },
  rejectTrip: (workOrderId: string, tripId: number, vendorTripRejectionReason: string) => {
    return api.$patch(
      `/work_orders/${workOrderId}/trips/${tripId}/reject`,
      { trip: { vendorTripRejectionReason }, context: { returnTrip: true } }
    )
  },
  vendorTeamMemberEmails: ({ id, include, q, page, perPage }: fetchResendVendorEmailsParams) => {
    return api.$get(`/trips/${id}/vendor_team_member_emails`, {
      params: {
        include,
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  resendVendorEmail: ({ id, to, cc, bcc }: sendEmailParams) => {
    return api.$post(`/trips/${id}/resend_vendor_dispatch_email`, {
      resend_vendor_email: {
        to,
        cc,
        bcc
      }
    })
  },
  cancelTrip: (params: tripCancelParams, workOrderId: number|string, tripId: number) => {
    return api.$patch(`work_orders/${workOrderId}/trips/${tripId}/cancel`, params)
  },
  getById: (workOrderId: number, tripId: number, params?: { include?: string, assigneeDetailsRequired?: boolean }) => {
    return api.$get(`/work_orders/${workOrderId}/trips/${tripId}`, {
      params
    })
  },
  verifyTrip: (workOrderId:number, tripId: number, params?: { exceptionManager: boolean }) => {
    return api.$patch(`/work_orders/${workOrderId}/trips/${tripId}/verify`, params)
  },

  unverifyTrip: (workOrderId:number, tripId: number, params?: { exceptionManager: boolean, unverifyServices: boolean }) => {
    return api.$patch(`/work_orders/${workOrderId}/trips/${tripId}/unverify`, params)
  },

  getNoteTypes: (workOrderId:number, tripId: number) => {
    return api.$get(`/work_orders/${workOrderId}/trips/${tripId}/list_note_type_and_broker`)
  },
  getTripCategories: ({ q, page, perPage }: getAllTripsParams) => {
    return api.$get('/companies/trip_categories', {
      params: {
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  createOrUpdateTrip: (params: CreateOrUpdateTripParams) => {
    const {
      createMode, trip, include, assigneeDetailsRequired,
      includeCompliance, withoutAssignee, includeCanApproveNonCompliantVendor
    } = params
    const requestType = createMode ? api.$post : api.$patch
    let url = `work_orders/${params.trip.workOrderId}/trips`
    url = createMode ? url : url.concat(`/${trip.id}`)

    return requestType.call(api, url, {
      trip,
      include,
      assigneeDetailsRequired,
      includeCompliance,
      withoutAssignee,
      includeCanApproveNonCompliantVendor,
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getDefaultVendorBillingMethod: (tripId: number, params: { tradeServiceId: number, contractorId: number }) => {
    return api.$get(`/trips/${tripId}/default_vendor_billing_method`, {
      params
    })
  },
  getLaborRateSchedule: (tripId: number) => {
    return api.$get(`/trips/${tripId}/labor_rate_schedule`)
  },
  copyAndCreateTrip: (workOrderId:number, tripId: number) => {
    return api.$post(`/work_orders/${workOrderId}/trips/${tripId}/copy_and_create`)
  },
  copyTrip: (workOrderId:number, tripId: number, params: { include?: string, assigneeDetailsRequired?: boolean }) => {
    return api.$get(`/work_orders/${workOrderId}/trips/${tripId}/copy_trip`, {
      params
    })
  },
  defaultBillingMethod: (params: DefaultBillingMethodParams) => {
    return api.$get(`/work_orders/${params.workOrderId}/trips/default_billing_method`, {
      params,
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  // only for -> api/v2/trips/tripID
  getTripByTripId: (tripId: number) => {
    return api.$get(`trips/${tripId}`)
  },
  generateTripReports: ({ contentTypes, reportFormat, exportType, workOrderId, tripIds, workOrderIds, workOrderTripReport }: TripReportParams) => {
    return api.$post('/trip_reports', {
      tripReport: { contentTypes, reportFormat, exportType, workOrderId, tripIds, workOrderIds, workOrderTripReport }
    })
  },
  getTripReportTemplates: ({ include, q, page, perPage }: Params) => {
    return api.$get('report_templates/trip_report_templates', {
      params: {
        include,
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  postTripReportTemplate: ({ reportType, name, headers, userId }: TripReportTemplateParams) => {
    return api.$post('report_templates/trip_report_templates', {
      tripReportTemplate: { reportType, name, headers, userId }
    })
  },
  vendorDispatchApproval: (tripId: number) => {
    return api.$patch((`trips/${tripId}/vendor_dispatch_approval`))
  }
}
