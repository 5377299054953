import { reactive, ref } from 'vue'
import repositories from '@/repositories'
import { useOnboarding } from './onboarding'
import { useCompanyClassifications } from '@/use/company-classifications'
import { findCompanyType } from '@/utils/company'
import { AutocompletePayload } from '@/types/interfaces'
import { AutocompleteResponse } from '@/types/interfaces/api-v2/autocomplete'

const companies: {
  loading: boolean
  list: any
  currentCompany: any
  meta: any
} = reactive({
  loading: false,
  list: [],
  currentCompany: null,
  meta: {
    currentPage: 0,
    nextPage: 1,
    prevPage: null,
    totalPages: null,
    totalCount: null
  }
})

const complianceModalRef = ref<any>(null)

const compliance: { loading: boolean } = reactive({
  loading: false
})
export const useCompanies = () => {
  const loadCompanies = async (params: any) => {
    try {
      companies.loading = true
      const res = await repositories.companies.getCompanies(params)
      companies.list = [...companies.list, ...res.companies]
      companies.meta = res.meta
      return res.companies
    } catch (err) {
      console.log(err)
    } finally {
      companies.loading = false
    }
  }
  const getCompanyProfile = async (params: any) => {
    try {
      const res = await repositories.companies.getProfile(params)
      return { data: res.company }
      return res
    } catch (err) {
      console.error('Failed to get company profile', err)
      return { data: false }
    }
  }
  const searchCompanyList = async (params: any) => {
    try {
      companies.loading = true
      const res = await repositories.companies.searchCompanies(params)
      companies.list = res.companies
      return res.companies
    } catch (err) {
      console.log(err)
    } finally {
      companies.loading = false
    }
  }
  const joinCompany = async (id: any) => {
    try {
      companies.loading = true
      const res = await repositories.companies.joinCompany(id)
      return res
    } catch (err) {
      console.log(err)
    } finally {
      companies.loading = false
    }
  }
  const claimCompany = async (id: any) => {
    try {
      companies.loading = true
      const res = await repositories.companies.claimCompany(id)
      return res
    } catch (err) {
      console.log(err)
    } finally {
      companies.loading = false
    }
  }
  const loadCurrentCompany = async (companyID: number) => {
    try {
      companies.loading = true
      const res = await repositories.companies.getCompany(companyID)
      companies.currentCompany = res.company
      return res.company
    } catch (error) {
      console.error(error)
      return ''
    } finally {
      companies.loading = false
    }
  }

  const suggestSubdomain = async (companyName: string) => {
    try {
      const res = await repositories.companies.getSuggestedSubdomain(
        companyName
      )
      return res
    } catch (error) {
      return false
    }
  }
  const uploadCompanyDocument = async (data: FormData) => {
    try {
      compliance.loading = true
      const res = await repositories.companies.uploadCompanyDocument(data)
      if (res && res.documents) {
        return res.documents
      } else {
        return false
      }
    } catch (err) {
      console.log(err)
      return false
    } finally {
      compliance.loading = false
    }
  }

  const deleteCompanyDocument = async (id: number) => {
    try {
      compliance.loading = true
      const res = await repositories.companies.deleteCompanyDocument(id)
      if (res && res.success) {
        return true
      } else {
        return false
      }
    } catch (err) {
      console.log(err)
      return false
    } finally {
      compliance.loading = false
    }
  }
  const getCompanyDocuments = async (params: any) => {
    try {
      compliance.loading = true
      const res = await repositories.companies.getCompanyDocumnets(params)
      return res.documents || []
    } catch (err) {
      console.log(err)
      return []
    } finally {
      compliance.loading = false
    }
  }

  // const createOrUpdateCompany = async (token?: string) => {
  //   const { state, updateUserData } = useOnboarding()
  //   const onboardData = state.onboardFlowData
  //   const userData = state.userData
  //   // set the current classification id to be the classification which has the same category id as the one in our state
  //   if (
  //     onboardData.companyCategoryId &&
  //     !userData?.companyInfo?.companyClassificationId
  //   ) {
  //     const { get } = useCompanyClassifications()
  //     const classifications: any = await get({
  //       include: 'package_category/default_package'
  //     })
  //     updateUserData({
  //       companyInfo: {
  //         ...state.userData.companyInfo,
  //         companyClassificationId: classifications.companyClassifications.find(
  //           (classification: any) => {
  //             return (
  //               classification.packageCategoryId ===
  //               onboardData.companyCategoryId
  //             )
  //           }
  //         ).id
  //       }
  //     })
  //   } else if (
  //     !onboardData.companyCategoryId &&
  //     !userData?.companyInfo?.companyClassificationId &&
  //     !state.onboardFlowData.token
  //   ) {
  //     console.error(
  //       'Need the company classification ID or the company category ID to create a company'
  //     )
  //     return false
  //   }
  //   try {
  //     const requestObj: any = {
  //       name: state.userData.companyInfo?.legalName,
  //       legal_tax_classification: state.userData.companyInfo?.legalTax,
  //       state_id: state.userData.companyInfo?.stateId,
  //       current_step: 'registration',
  //       recommended_ein: state.userData.companyInfo?.ein,
  //       email: state.userData.companyInfo?.email,
  //       phone: state.userData.companyInfo?.phone,
  //       subdomain: state.userData.subdomain,
  //       dba_attributes: { dba_name: state.userData.companyInfo?.DBA },
  //       legal_address_attributes: state.userData.companyInfo?.legalAddressData,
  //       company_classification_id:
  //         state.userData.companyInfo?.companyClassificationId,
  //       roles:
  //         onboardData.companyType === 'broker'
  //           ? ['client', 'vendor']
  //           : onboardData.companyType === 'provider'
  //             ? ['vendor']
  //             : ['client']
  //     }
  //     let res
  //     if (state.userData?.companyInfo?.id) {
  //       // we want to delete this key because the default package should already be initialized in the db
  //       delete requestObj.company_classification_id
  //       res = await repositories.companies.updateCompanyInfoUsingToken({
  //         token,
  //         payload: requestObj
  //       })
  //     } else {
  //       if (onboardData?.selectedPlan?.plan?.id) {
  //         requestObj.package_id = onboardData.selectedPlan.plan.id
  //       }
  //       res = await repositories.companies.createCompany(requestObj)
  //     }
  //     return res
  //   } catch (error) {
  //     console.error('not creating company')
  //     return false
  //   }
  // }

  const createOrUpdateCompanyOnboarding = async (token?: string) => {
    const { onboardingState } = useOnboarding()
    const { getClassifications } = useCompanyClassifications()
    let res
    if (
      !onboardingState.companyInfo.companyCategoryId &&
        !onboardingState.companyInfo.classificationId &&
        !onboardingState.token
    ) {
      console.error(
        'Need the company classification ID or the company category ID to create a company'
      )
      return false
    }
    if (!onboardingState.companyInfo.classificationId) {
      try {
        onboardingState.companyInfo.classificationId = (await getClassifications({
          package_id: 1,
          include: 'package_category/default_package',
          q: {
            name_eq: onboardingState.classification
          }
        }))?.companyClassifications?.[0].id
      } catch (err) {
        console.error('Failed to fetch classification during company create', err)
        return false
      }
    }

    try {
      const requestObj: any = {
        name: onboardingState.companyInfo?.legalName,
        legal_tax_classification: onboardingState.companyInfo?.legalTax,
        state_id: onboardingState.companyInfo?.stateId,
        current_step: 'registration',
        recommended_ein: onboardingState.companyInfo?.ein,
        email: onboardingState.companyInfo?.email,
        phone: onboardingState.companyInfo?.phone,
        subdomain: onboardingState.companyInfo?.subdomain,
        dba_attributes: { dba_name: onboardingState.companyInfo?.DBA },
        legal_address_attributes: onboardingState.companyInfo?.legalAddressData,
        docu_sign_signer_attributes: { name: onboardingState.companyInfo?.authorizedCompanySignerName, email: onboardingState.companyInfo?.authorizedCompanySignerEmail },
        company_classification_id:
            onboardingState.companyInfo?.classificationId,
        requested_integrations: onboardingState.integrations || []
      }

      if (onboardingState.classification === 'Broker Service') {
        requestObj.roles = ['client', 'vendor']
      } else if (onboardingState.classification === 'Provide Service') {
        requestObj.roles = ['vendor']
      } else if (onboardingState.classification === 'Request Service') {
        requestObj.roles = ['client']
      }

      if (onboardingState.companyInfo?.id) {
        delete requestObj.company_classification_id
        res = await repositories.companies.updateCompanyInfoUsingToken({
          token,
          payload: requestObj
        })
      } else {
        if (onboardingState.subscription.selectedPlan?.plan.id) {
          requestObj.package_id = onboardingState.subscription.selectedPlan.plan.id
        }
        res = await repositories.companies.createCompany(requestObj)
      }
    } catch (err) {
      console.error('Failed to create company', err)
    } finally {
      res = res || false
    }
    return res
  }

  const getInvitedCompanyDetails = async (params: any) => {
    try {
      const response: any =
        await repositories.companies.getInvitedCompanyDetails(params)
      return response?.company
    } catch (err: any) {
      return err?.data
    }
  }

  const hasRole = (roleName: string) => {
    return roleName === companies.currentCompany?.role?.name
  }
  const getSurveyDetails = async (params: any) => {
    try {
      const response = await repositories.companies.getSurvey(params)
      return response
    } catch (err) {
      return err
    }
  }

  const updateCompanySurvey = async (surveyData: any) => {
    try {
      const response = await repositories.companies.updateCompanySurvey(surveyData)
      return response
    } catch (err) {
      return err
    }
  }

  const getCompanies = async (params: any) => {
    try {
      const res = await repositories.companies.get(params)
      return {
        data: res.companies,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages
      }
    } catch (err) {
      console.error('err', err)
      return { data: false }
    }
  }

  const getUsersByCompany = async (brokerCompanyId: number | string, params?: any) => {
    try {
      const res = await repositories.companies.getUsersByCompany(brokerCompanyId, params)
      return {
        data: res.users,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages
      }
    } catch (err) {
      console.error('err ', err)
      return {
        data: false
      }
    }
  }
  const getCompanyTypeById = async (companyId: number) => {
    try {
      const company = await getCompanies({ include: 'roles', q: { id_eq: companyId } })
      if (company?.data?.length) {
        const companyRoles = company?.data?.roles
        if (!companyRoles) {
          return null
        }

        return findCompanyType(companyRoles)
      } else {
        return null
      }
    } catch (err) {
      return null
    }
  }
  const getAddressTypeByCompany = async (params: any) => {
    try {
      const res = await repositories.companies.getAddressTypeByCompany(params)
      return {
        data: res.addressTypes,
        totalCount: res.addressTypes.length
      }
    } catch (err) {
      console.error('err ', err)
      return {
        data: false
      }
    }
  }

  const getVendorCompanies = async (include: any) => {
    try {
      const res = await repositories.companies.getVendorCompanies(include)
      return {
        data: res.companies,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      }
    } catch (err) {
      console.error('err', err)
      return { data: false }
    }
  }

  const getCompanyLicense = async () => {
    try {
      const res = await repositories.companies.getCompanyLicense()
      return {
        data: res.licenses,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as any
    } catch (err) {
      return {
        data: [],
        totalPages: 0,
        totalCount: 0
      } as any
    }
  }

  const addCompanyLicense = async (payload: any) => {
    try {
      const res = await repositories.companies.addCompanyLicense(payload)
      return res
    } catch (err: any) {
      console.log(err)
      return { error: err?.data?.errors }
    }
  }

  const editCompanyLicense = async (payload: any, licenseId: number) => {
    try {
      const res = await repositories.companies.editCompanyLicense(payload, licenseId)
      return res
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const deleteCompanyLicense = async (id: number) => {
    try {
      const res = await repositories.companies.deleteCompanyLicense(id)
      if (res && res.success) {
        return true
      } else {
        return false
      }
    } catch (err) {
      console.log(err)
      return false
    }
  }

  const getLicenseCode = async () => {
    try {
      const res = await repositories.companies.getLicenseCode()
      return {
        data: res.licenseCodes,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as any
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getCompanyComplianceDetails = async (params: any) => {
    try {
      const res = await repositories.companies.getCompanyComplianceDetails(params)
      return res
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const updateComplianceDetails = async (surveyObject: any, params: any) => {
    try {
      const res = await repositories.companies.updateComplianceDetails(surveyObject, params)
      return res
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const postComplianceDetails = async (surveyObject: any, documentType: any) => {
    try {
      const res = await repositories.companies.postComplianceDetails(surveyObject, documentType)
      return res
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getCompanyComplianceList = async () => {
    try {
      let res = await repositories.companies.getCompanyComplianceList()
      res = res?.map((e: any, index: number) => {
        return {
          name: e.name,
          id: index,
          documentType: e.documentType
        }
      })
      return {
        data: res,
        totalPages: 1,
        totalCount: 1
      }
    } catch (err) {
      console.error(err)
      return { data: false }
    }
  }

  const updateCompanyLogo = async (params: any) => {
    try {
      const res = await repositories.companies.updateCompanyLogo(params)
      return res.company
    } catch (err) {
      return err
    }
  }

  const updateCompanyProfile = async (params: any) => {
    try {
      const res = await repositories.companies.updateCompanyProfile(params)
      return {
        data: res.company
      }
    } catch (err) {
      console.error(err)
      return { data: false }
    }
  }

  const getCompanyAddresses = async (params: any) => {
    try {
      const res = await repositories.companies.getAddresses(params)
      return { data: res.addresses }
    } catch (err) {
      console.error('Failed to get company addresses', err)
      return { data: false }
    }
  }

  const getContacts = async (params: any) => {
    try {
      const res = await repositories.companies.getContacts(params)
      return {
        data: res.contacts,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as any
    } catch (err) {
      console.log(err)
      return {
        data: [],
        totalPages: 0,
        totalCount: 0
      } as any
    }
  }

  const addCompanyContact = async (param: any) => {
    try {
      const res = await repositories.companies.addCompanyContact(param)
      return {
        data: res
      } as any
    } catch (err: any) {
      console.log(err)
      return { error: err?.data?.errors }
    }
  }

  const getClientCompanies = async (params: any) => {
    try {
      const res = await repositories.companies.searchCompanies(params)
      return {
        data: res.companies,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      }
    } catch (err) {
      console.error('err', err)
      return { data: false }
    }
  }
  const updateCompanyContact = async (payload: any) => {
    try {
      const res = await repositories.companies.updateCompanyContact(payload)
      return {
        data: res
      } as any
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const deleteCompanyContact = async (params: any) => {
    try {
      const res = await repositories.companies.deleteCompanyContact(params)
      return res
    } catch (err) {
      console.log(err)
      return false
    }
  }

  const getCompanyTrades = async (params: any) => {
    try {
      const res = await repositories.companies.getCompanyTrades(params)
      return {
        data: res.trades,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as any
    } catch (err) {
      return {
        data: [],
        totalPages: 0,
        totalCount: 0
      } as any
    }
  }

  const addCompanyTrade = async (payload: any) => {
    try {
      const res = await repositories.companies.addCompanyTrade(payload)
      return res
    } catch (err: any) {
      console.log(err)
      return { error: err?.data?.errors }
    }
  }

  const editCompanyTrade = async (payload: any, tradeId: number) => {
    try {
      const res = await repositories.companies.editCompanyTrade(payload, tradeId)
      return res
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const deleteCompanyTrade = async (params: any) => {
    try {
      const res = await repositories.companies.deleteCompanyTrade(params)
      return res
    } catch (err: any) {
      console.log(err)
      if (err?.data) {
        return err.data
      }
      return false
    }
  }

  const getCompanyRegions = async (params: any) => {
    try {
      const res = await repositories.companies.getCompanyRegions(params)
      return {
        data: res.regions,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as any
    } catch (err) {
      return {
        data: [],
        totalPages: 0,
        totalCount: 0
      } as any
    }
  }

  const addCompanyRegion = async (payload: any) => {
    try {
      const res = await repositories.companies.addCompanyRegion(payload)
      return res
    } catch (err: any) {
      console.log(err)
      return { error: err?.data?.errors }
    }
  }

  const editCompanyRegion = async (payload: any, regionId: number) => {
    try {
      const res = await repositories.companies.editCompanyRegion(payload, regionId)
      return res
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getCompanyTradeSuggestions = async () => {
    try {
      const res = await repositories.companies.getCompanyTradeSuggestions()
      return {
        data: res.tradeSuggestions.map((tradeName: string) => {
          return { name: tradeName, id: tradeName }
        }),
        totalPages: 1,
        totalCount: 1
      } as any
    } catch (err) {
      return {
        data: [],
        totalPages: 0,
        totalCount: 0
      } as any
    }
  }

  const getCompanyById = async (companyId: number) => {
    try {
      const res = await getCompanies({ q: { id_eq: companyId } })
      return {
        data: res.data,
        totalCount: res.totalCount,
        totalPages: res.totalPages
      }
    } catch (err) {
      console.error('err ', err)
      return {
        data: false
      }
    }
  }

  const getCompanyPaymentMethods = async (companyId: number) => {
    try {
      const res = await repositories.companies.getCompanyPaymentMethods(companyId)
      return res
    } catch (err) {
      console.error('err ', err)
      return {
        data: false
      }
    }
  }

  const companiesAutoComplete = async (params: AutocompletePayload) => {
    try {
      const res = await repositories.companies.companiesAutoComplete(params)
      return {
        data: res.results,
        nextPage: res.nextPage
      } as { data: AutocompleteResponse[], nextPage: number }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  return {
    getInvitedCompanyDetails,
    getCompanies,
    getCompanyProfile,
    compliance,
    companies,
    loadCompanies,
    searchCompanyList,
    joinCompany,
    claimCompany,
    loadCurrentCompany,
    suggestSubdomain,
    uploadCompanyDocument,
    createOrUpdateCompany: (test: any) => {},
    createOrUpdateCompanyOnboarding,
    deleteCompanyDocument,
    getCompanyDocuments,
    hasRole,
    getSurveyDetails,
    updateCompanySurvey,
    getUsersByCompany,
    getCompanyTypeById,
    getAddressTypeByCompany,
    addCompanyContact,
    getVendorCompanies,
    getCompanyLicense,
    addCompanyLicense,
    editCompanyLicense,
    deleteCompanyLicense,
    getLicenseCode,
    getCompanyComplianceDetails,
    updateComplianceDetails,
    postComplianceDetails,
    getCompanyComplianceList,
    complianceModalRef,
    updateCompanyLogo,
    updateCompanyProfile,
    getCompanyAddresses,
    getContacts,
    updateCompanyContact,
    deleteCompanyContact,
    getClientCompanies,
    getCompanyTrades,
    addCompanyTrade,
    editCompanyTrade,
    deleteCompanyTrade,
    getCompanyRegions,
    addCompanyRegion,
    editCompanyRegion,
    getCompanyTradeSuggestions,
    getCompanyPaymentMethods,
    getCompanyById,
    companiesAutoComplete
  }
}
