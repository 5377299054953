import { defineComponent, ref, computed, watchEffect } from 'vue';
import { useResponsiveness } from '@/use/responsiveness';
import { CHECKBOX_COL, ACTIONS_COL, DRAWER_CONTENT, DRAWER_CONTENT_EXPANDER_COL, REMOVE_COL, ADD_COL } from '@/types/interfaces';
import { DRAWER_CLOSED } from '@/constants/icons';
export default defineComponent({
  props: {
    isSelected: {
      type: Boolean,
      required: true
    },
    isExpanded: {
      type: Boolean,
      required: true
    },
    canSelect: {
      type: Boolean,
      default: true
    },
    canExpand: {
      type: Boolean,
      default: true
    },
    height: {
      type: String,
      default: '75px'
    },
    borderColor: {
      type: String,
      default: ''
    },
    tabText: {
      type: String,
      default: ''
    },
    tabIcon: {
      type: String,
      default: ''
    },
    createdAt: {
      type: String,
      default: ''
    },
    firstRow: {
      type: Boolean,
      default: true
    },
    lastRow: {
      type: Boolean,
      default: true
    },
    hasBorder: {
      type: Boolean,
      default: false
    },
    backgroundColor: {
      type: String,
      default: 'layer'
    },
    hasHover: {
      type: Boolean,
      default: true
    },
    columnDefs: {
      type: Object,
      required: true
    },
    size: {
      type: String,
      default: 'lg'
    },
    showActions: {
      type: Boolean,
      default: true
    },
    selectionDisabled: {
      type: Boolean,
      default: false
    },
    canRemove: {
      type: Boolean,
      default: false
    },
    isTile: {
      type: Boolean,
      default: false
    },
    showFooter: {
      type: Boolean,
      default: false
    },
    canAdd: {
      type: Boolean,
      default: false
    }
  },
  setup: (props, {
    emit
  }) => {
    const {
      getFlexRatioByScrenSize
    } = useResponsiveness();
    const hovering = ref(false);
    const borderThickness = '4px';
    const beenExpanded = ref(false);
    const loading = ref(false);
    const containerRef = ref(null);
    const setLoading = () => {
      // beenExpanded.value = true
      // loading.value = true
    };
    const emitRowClick = e => {
      if (!props.selectionDisabled) emit('row-click');
    };
    const computedNumberOfColumns = computed(() => {
      var _props$columnDefs;
      return getFlexRatioByScrenSize(props.size, (_props$columnDefs = props.columnDefs) === null || _props$columnDefs === void 0 ? void 0 : _props$columnDefs.headers);
    });
    const computedNumberDrawerOfColumns = computed(() => {
      var _props$columnDefs2;
      return getFlexRatioByScrenSize(props.size, ((_props$columnDefs2 = props.columnDefs) === null || _props$columnDefs2 === void 0 ? void 0 : _props$columnDefs2.drawer) || []);
    });
    const computedNumberFooterColumns = computed(() => {
      var _props$columnDefs3;
      return getFlexRatioByScrenSize(props.size, ((_props$columnDefs3 = props.columnDefs) === null || _props$columnDefs3 === void 0 ? void 0 : _props$columnDefs3.footer) || []);
    });
    // non-card renderer will use this. The goal of this variable is to be smart about changing the size, and ignore
    // changes to card size. This means that when we come into/out of card sizing, the components that need to render
    // as a hidden for non-card layouts, will not need to re-compute their sizing.
    const nonCardSize = ref();
    watchEffect(() => {
      if (!nonCardSize.value && props.size === 'card') {
        // we need a default...
        nonCardSize.value = 'lg';
      }
      // if we're changing sizes and it isn't a card, remember it. We will NOT react to card size changes, that way
      // we can avoid a bunch of render events.
      if (props.size !== 'card') {
        nonCardSize.value = props.size;
      }
    });
    return {
      hovering,
      borderThickness,
      loading,
      setLoading,
      beenExpanded,
      emitRowClick,
      containerRef,
      computedNumberOfColumns,
      CHECKBOX_COL,
      ACTIONS_COL,
      DRAWER_CONTENT,
      DRAWER_CONTENT_EXPANDER_COL,
      REMOVE_COL,
      computedNumberDrawerOfColumns,
      DRAWER_CLOSED,
      computedNumberFooterColumns,
      ADD_COL,
      nonCardSize
    };
  }
});