import { useToasts } from '@/use/toasts'
import { defaults } from 'lodash'

type Action = () => Promise<any>
type SerialQueueOptions = {
  toastOnFailure?: boolean
  continueOnFailure?: boolean
}
const TOAST_MESSAGE = 'An unexpected error occurred, please refresh the page to try again'

/**
 * This will return a function which when invoked will throw the action into a serial queue (linked-list).
 *
 * It will guarantee all actions are invoked in the exact order they are given, and only after the previous
 * action has completed.
 *
 * Exception behaviors:
 * - an option may be passed to toast on failure (default: true)
 * - an option controls if the error blocks the rest of the chain, or just continues
 */
export function useSerialQueue (options: SerialQueueOptions = {}) {
  options = defaults(options, {
    toastOnFailure: true,
    continueOnFailure: false
  })
  const { toasts, addToast } = useToasts()

  let nextPromise: Promise<any> | null = null

  // if someone is waiting on me, then they need to wait on whoever i am waiting for, and then my action
  const awaitNextInQueue = async (awaitingOn: Promise<any> | null, action: Action) => {
    try {
      // if i am not waiting on anyone, then just continue...
      // if this action is already resolved, this will just noop.
      if (awaitingOn !== null) {
        // first i wait on whoever i was waiting for
        await awaitingOn
      }

      // then i wait on my action
      await action()

      // at this point i am done, and the next in queue can execute
    } catch (e) {
      if (options.toastOnFailure) {
        // there may be multiple failures in the chain, only one needs to toast
        if (!toasts.value.find((toast) => toast.message === TOAST_MESSAGE)) {
          addToast({
            message: TOAST_MESSAGE,
            color: 'error'
          })
        }
      }
      nextPromise = null
      if (!options.continueOnFailure) {
        throw e // propagate the error, causing the rest of the chain to not execute and die
      }
    }
  }

  return (action: Action) => {
    nextPromise = awaitNextInQueue(nextPromise, action)
  }
}
