import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    image: {
      type: String,
      required: true
    },
    size: {
      type: Number,
      default: 56
    },
    backdropColor: {
      type: String,
      default: '#FFF'
    },
    elevation: {
      type: Number,
      default: 0
    }
  }
});