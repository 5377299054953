import { BROKER, CLIENT } from '@/constants/permissions'
import { useProposals } from '.'
import { useTransactionResources } from '../transactional-resources'
import repositories from '@/repositories'
import { useBackgroundTasks } from '../background-tasks'
import { PROPOSAL_RESOURCE_TYPE } from '@/constants/resource-types'
import { useApp } from '../app'
import { Proposal } from '@/types/interfaces/api-v2/proposal'
import { PROPOSAL_INCLUDE_DERIVED_ITEM } from '@/constants/include'
import { AutocompletePayload, AutocompleteResponse } from '@/types/interfaces/api-v2/autocomplete'
import { SEND } from '@/constants/icons'
import { useDetailsPanel } from '../details-panel'

export const useOutboundProposals = () => {
  const { proposalActionListenersAndFunctions, shared, proposalActionModalProps } = useProposals()
  const { companyType } = useApp()
  const { basicOutboundActions } = useTransactionResources()
  const basicProposalOutboundActions = (role:string) => {
    return [
      ...basicOutboundActions(proposalActionListenersAndFunctions),
      {
        value: 'generate-invoice',
        actionFn: async (proposals: any[], additionalParams: any) => {
          proposalActionListenersAndFunctions['generate-invoice'](
            proposals,
            additionalParams,
            true
          )
        }
      },
      {
        value: 'resend',
        bulkConfig: {
          type: 'icon',
          icon: SEND,
          text: 'Send'
        },
        actionFn: async (proposals: any[], additionalParams: any) => {
          proposalActionListenersAndFunctions.resend(
            proposals,
            additionalParams,
            true
          )
        }
      },
      {
        value: 'save',
        bulkConfig: {
        }
      },
      {
        value: 'save-as-draft',
        bulkConfig: {
        }
      },
      {
        value: 'save-and-mark-sent',
        bulkConfig: {
        }
      },
      {
        value: 'save-and-send',
        bulkConfig: {
        }
      },

      ...shared(true)
    ].filter((action:any) => role === CLIENT ? !['generate-invoice'].includes(action.value) : action)
  }

  const proposalOutboundModalRequiredActions = [
    {
      value: 'close',
      requiresConfirmation: true,
      modal: proposalActionModalProps(true).close,
      actionFn: async (proposals: any[], additionalParams: any) => {
        await updateProposalStatus({ action: 'close', proposalId: proposals[0].id, reason: additionalParams.additionalDataFromActionModal.note, include: PROPOSAL_INCLUDE_DERIVED_ITEM })
      }
    },
    {
      value: 'draft',
      requiresConfirmation: true,
      modal: proposalActionModalProps(true).draft,
      actionFn: async (proposals: Proposal[], additionalParams: any) => {
        await updateProposalStatus({ action: 'draft', proposalId: proposals[0].id, reason: additionalParams.additionalDataFromActionModal.note, include: PROPOSAL_INCLUDE_DERIVED_ITEM })
      }
    },
    {
      value: 'ready-for-review',
      requiresConfirmation: true,
      modal: proposalActionModalProps(true)['ready-for-review'],
      actionFn: async (proposals: Proposal[], additionalParams: any) => {
        await updateProposalStatus({ action: 'ready_for_review', proposalId: proposals[0].id, reason: additionalParams.additionalDataFromActionModal.note, include: PROPOSAL_INCLUDE_DERIVED_ITEM })
      }
    },
    {
      value: 'approve',
      requiresConfirmation: true,
      modal: proposalActionModalProps(true).approve,
      actionFn: async (proposals: any[], additionalParams: any) => {
        await updateProposalStatus({ action: 'approve', proposalId: proposals[0].id, reason: additionalParams.additionalDataFromActionModal.note, include: PROPOSAL_INCLUDE_DERIVED_ITEM, annexedPoNumber: additionalParams.additionalDataFromActionModal['annexed-po-number'] })
      }
    },
    {
      value: 'evaluate',
      requiresConfirmation: true,
      modal: proposalActionModalProps(true).evaluate,
      actionFn: async (proposals: any[], additionalParams: any) => {
        await updateProposalStatus({ action: 'evaluate', proposalId: proposals[0].id, reason: additionalParams.additionalDataFromActionModal.note, include: PROPOSAL_INCLUDE_DERIVED_ITEM })
      }

    },
    {
      value: 'reject',
      requiresConfirmation: true,
      modal: proposalActionModalProps(true).reject,
      actionFn: async (proposals: any[], additionalParams: any) => {
        await updateProposalStatus({ action: 'decline', proposalId: proposals[0].id, reason: additionalParams.additionalDataFromActionModal.note, include: PROPOSAL_INCLUDE_DERIVED_ITEM, annexedPoNumber: additionalParams.additionalDataFromActionModal['annexed-po-number'] })
      }
    },
    {
      value: 'delete',
      requiresConfirmation: true,
      modal: proposalActionModalProps(true).delete,
      actionFn: async (proposals: any[]) => {
        await deleteOutboundProposal(proposals[0].id)
      }
    },
    {
      value: 'create-work-order',
      requiresConfirmation: true,
      modal: proposalActionModalProps(true)['create-work-order'],
      redirect: true,
      redirectUrl: (proposalId: number) => `/company/wizard/work_orders/new?header=false step=details&proposal_id=${proposalId}&redirect_to=/app/sent-proposals`
    },
    {
      value: 'view',
      updateSelectedResource: true,
      actionFn: (proposals: any) => {
        const { openGlobalDetailsPanel } = useDetailsPanel()
        openGlobalDetailsPanel('uc/resource-details', {
          actions: [
            ...basicProposalOutboundActions(companyType.value).map((item: any) => {
              const temp = Object.assign({}, item)
              delete temp.bulkConfig
              return temp
            }),
            ...proposalOutboundModalRequiredActions
          ],
          selectedResources: proposals,
          resourceType: PROPOSAL_RESOURCE_TYPE,
          canDoubleClickToEdit: true,
          outbound: true
        }, '', { }, { })
      },
      noSuccessEmit: true
    }

  ].filter((action:any) => {
    return companyType.value === BROKER ? action : ['close', 'delete', 'ready-for-review'].includes(action.value)
  })

  const getProposals = async (params: any) => {
    try {
      const res = await repositories.proposals.get(params)
      return {
        data: res.proposals,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const { addBackgroundTask } = useBackgroundTasks()
  const sendProposalEmails = async (body: any) => {
    try {
      // if (!ids) throw new Error('Id array required in params')
      const res = await repositories.proposals.sendEmail(body)
      // if (proposalEmail.backgroundTaskId) {
      //   addBackgroundTask(proposalEmail.backgroundTaskId)
      // } else {
      //   console.error('Background Task ID not received  from send email response, unable to create background task')
      // }
      return { data: res.proposalEmail.success || false }
    } catch (err: any) {
      console.error(err)
      return { data: false }
    }
  }

  // THIS IS NOT SET UP YET
  const postProposalsSendBulkEmail = async (body: any) => {
    try {
      // if (!ids) throw new Error('Id array required in params')
      const res = await repositories.proposals.postSendBulkEmail(body)
      if (res.backgroundTaskId) {
        addBackgroundTask(res.backgroundTaskId)
      } else {
        console.error('Background Task ID not received  from send email response, unable to create background task')
      }
      return { data: res.successProposals || false }
    } catch (err: any) {
      console.error(err)
      return { data: false }
    }
  }

  const updateProposalStatus = async (params:any) => {
    try {
      return await repositories.proposals.updateProposalStatus(params)
    } catch (err) {
      console.error(err)
      return false
    }
  }

  const updateProposal = async (params: any) => {
    try {
      return await repositories.proposals.patch(params)
    } catch (err) {
      console.error(err)
      return false
    }
  }
  const bulkDestroyProposals = async (params: any) => {
    try {
      if (!params.query) throw new Error('(Id array or q) is required to call this endpoint')
      const res = await repositories.proposals.bulkDestroy(params)
      return res
    } catch (err: any) {
      console.error(err)
      return false
    }
  }
  const deleteOutboundProposal = async (proposalId: string, objectScope = 'both') => {
    try {
      const res = await repositories.proposals.deleteOutboundProposal(proposalId, objectScope)
      return res
    } catch (err) {
      return false
    }
  }
  const postProposals = async (params: any, requestBody: any) => {
    try {
      const res = await repositories.proposals.post(params, requestBody)
      return res.proposal
    } catch (err: any) {
      console.error(err)
    }
  }
  const getProposalById = async (id: number, params: any) => {
    try {
      const res = await repositories.proposals.getById(id, { include: params })
      return { data: res.proposal }
    } catch (err: any) {
      console.error(err)
      return { data: false }
    }
  }

  const proposalsAutoComplete = async (params: AutocompletePayload) => {
    try {
      const res = await repositories.proposals.proposalsAutoComplete(params)
      return {
        data: res.results,
        nextPage: res.nextPage
      } as { data: AutocompleteResponse[], nextPage: number }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const newV2Proposals = async (params: any, requestBody: any) => {
    try {
      const res = await repositories.proposals.newV2(params, requestBody)
      return res
    } catch (err: any) {
      console.error(err)
    }
  }

  const forwardProposal = async (proposalId: number, markupMultiplier: number, addMarkupToProposal: string, include = '') => {
    try {
      const res = await repositories.proposals.forwardProposal(proposalId, markupMultiplier, addMarkupToProposal, include)
      return { data: res.proposal }
    } catch (err: any) {
      console.error(err)
      return { data: false }
    }
  }

  const proposalsSearch = async (params: any) => {
    try {
      const res = await repositories.proposals.proposalsSearch(params)
      return {
        data: res.proposals,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  return {
    basicProposalOutboundActions,
    updateProposalStatus,
    bulkDestroyProposals,
    getProposals,
    updateProposal,
    sendProposalEmails,
    postProposals,
    postProposalsSendBulkEmail,
    proposalOutboundModalRequiredActions,
    getProposalById,
    deleteOutboundProposal,
    proposalsAutoComplete,
    newV2Proposals,
    forwardProposal,
    proposalsSearch
  }
}
