import { api } from '@/api'
import qs from 'qs'

interface GetActivitiesListParams {
  resourceId: string,
  resourceType: string,
  perPage?: number,
  page?: number,
  q?: any,
  s?: any,
  include?: any,
  objectScope?: 'both' | 'multi' | 'single'
}
// resourceType: work_orders, invoices, inbound_invoices, etc
// resourceId: workOrder.id, invoice.id (selectedInvoice.value.id)

export default {
  get: ({ resourceId, resourceType, perPage, page, include, q, s, objectScope }:GetActivitiesListParams) => {
    return api.$get(`/${resourceType}/${resourceId}/activities`, {
      params: {
        resourceId,
        resourceType,
        objectScope,
        include,
        q,
        page,
        perPage,
        s,
        include_grouped: true
      },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    })
  }
}
