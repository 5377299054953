import { Column } from '@/types/interfaces'
export const WORK_ORDER_GENERATE_HEADERS = (showNTE = true) => {
  return {
    fontSize: 'lg',
    border: false,
    canSelect: true,
    showActions: true,
    canExpand: true,
    headers: [
      {
        label: '',
        hideTextOnMobile: false,
        templateName: 'id',
        resourceField: 'estimate_number',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: {
          xs: '180',
          sm: '180',
          md: '180',
          lg: '280'

        }
      },
      {
        label: '',
        hideTextOnMobile: false,
        templateName: 'priority',
        resourceField: 'priority',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: {
          xs: 'hide',
          sm: 'hide',
          md: '180',
          lg: '180'

        }
      },
      {
        label: '',
        hideTextOnMobile: false,
        templateName: 'description',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: {
          xs: 'hide',
          sm: 'hide',
          md: 'hide',
          lg: 'flex'

        }
      },
      {
        label: '',
        hideTextOnMobile: false,
        templateName: 'proposal-po-details',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'center'
        },
        visibility: {
          xs: 'flex',
          sm: 'flex',
          md: 'flex',
          lg: 'flex'
        }
      },
      {
        label: '',
        hideTextOnMobile: false,
        templateName: 'nte',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: {
          xs: 'hide',
          sm: 'hide',
          md: 'flex',
          lg: 'flex'
        }
      },
      {
        label: '',
        hideTextOnMobile: false,
        templateName: 'trips',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: {
          xs: 'hide',
          sm: 'hide',
          md: 'flex',
          lg: 'flex'

        }
      },
      {
        label: '',
        hideTextOnMobile: false,
        templateName: 'assignees',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: {
          xs: 'hide',
          sm: 'hide',
          md: 'flex',
          lg: 'flex'

        }
      },
      {
        label: '',
        hideTextOnMobile: false,
        templateName: 'age',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: {
          xs: 'hide',
          sm: 'hide',
          md: 'hide',
          lg: 'flex'

        }
      }
    ].filter((column: Column) => showNTE || column.templateName !== 'nte')
  }
}

export const CONTACTS_HEADERS = () => {
  return {
    fontSize: 'lg',
    border: false,
    canExpand: false,
    showActions: false,
    headers: [
      {
        label: 'Contact',
        hideTextOnMobile: true,
        templateName: 'profile',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'start',
          justify: 'start'
        },
        visibility: {
          xs: 'flex',
          sm: 'flex',
          md: 'flex',
          lg: 'flex'

        }
      }
    ]
  }
}

export const WORK_ORDER_HEADERS = () => {
  return {
    fontSize: 'lg',
    border: false,
    canSelect: true,
    showActions: true,
    canExpand: false,
    headers: [
      {
        label: 'WO #',
        hideTextOnMobile: false,
        templateName: 'id',
        resourceField: 'estimate_number',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: {
          card: 'hide',
          xs: '195',
          sm: '195',
          md: '195',
          lg: '195'

        }
      },
      {
        label: 'Trips',
        hideTextOnMobile: false,
        templateName: 'trips',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'center'
        },
        visibility: {
          card: 'hide',
          xs: 'hide',
          sm: '80',
          md: '80',
          lg: '100'

        }
      },
      {
        label: 'For',
        hideTextOnMobile: false,
        templateName: 'for-details',
        resourceField: 'company_id',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: {
          card: 'hide',
          xs: 'hide',
          sm: 'flex',
          md: 'flex',
          lg: 'flex'

        }
      },
      {
        label: 'Site',
        hideTextOnMobile: false,
        templateName: 'location',
        resourceField: 'location_id',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: {
          card: 'hide',
          xs: 'hide',
          sm: '300',
          md: '300',
          lg: '300'

        }
      },
      {
        label: 'Description',
        hideTextOnMobile: false,
        templateName: 'description',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: {
          card: 'hide',
          xs: 'hide',
          sm: 'hide',
          md: 'flex',
          lg: 'flex'

        }
      },
      {
        label: 'Schedule',
        hideTextOnMobile: false,
        templateName: 'schedule',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: {
          card: 'hide',
          xs: 'hide',
          sm: 'hide',
          md: 'flex',
          lg: 'flex'
        }
      },
      {
        label: 'Status',
        hideTextOnMobile: false,
        templateName: 'statuses',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: {
          card: 'hide',
          xs: 'flex',
          sm: 'flex',
          md: 'flex',
          lg: 'flex'
        }
      },
      {
        label: 'WO #',
        hideTextOnMobile: false,
        templateName: 'id',
        resourceField: 'estimate_number',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: {
          card: 'flex',
          xs: 'hide',
          sm: 'hide',
          md: 'hide',
          lg: 'hide'

        }
      },
      {
        label: 'Trips',
        hideTextOnMobile: false,
        templateName: 'trips-card',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'center'
        },
        visibility: {
          card: 'flex',
          xs: 'hide',
          sm: 'hide',
          md: 'hide',
          lg: 'hide'

        }
      },
      {
        label: 'For',
        hideTextOnMobile: false,
        templateName: 'for-details',
        resourceField: 'company_id',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: {
          card: 'flex',
          xs: 'hide',
          sm: 'hide',
          md: 'hide',
          lg: 'hide'

        }
      },
      {
        label: 'Status',
        hideTextOnMobile: false,
        templateName: 'statuses-card',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'center',
          justify: 'end'
        },
        visibility: {
          card: 'flex',
          xs: 'hide',
          sm: 'hide',
          md: 'hide',
          lg: 'hide'

        }
      },
      {
        label: 'Priority',
        hideTextOnMobile: false,
        templateName: 'priority',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'center',
          justify: 'start'
        },
        visibility: {
          card: 'flex',
          xs: 'hide',
          sm: 'hide',
          md: 'hide',
          lg: 'hide'

        }
      }
    ]
  }
}

export const WORK_ORDER_INVOICE_HEADERS = () => {
  return {
    fontSize: 'lg',
    border: false,
    canSelect: false,
    showActions: false,
    canExpand: true,
    headers: [
      {
        label: '',
        hideTextOnMobile: false,
        templateName: 'id',
        resourceField: 'estimate_number',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: {
          xs: 'flex',
          sm: 'flex',
          md: 'flex',
          lg: 'flex'

        }
      },
      {
        label: '',
        hideTextOnMobile: false,
        templateName: 'billing-method',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: {
          xs: '70',
          sm: '70',
          md: 'flex',
          lg: 'flex'

        }
      }
    ]
  }
}

export const WORK_ORDER_NOTES = () => {
  return {
    fontSize: 'lg',
    border: false,
    headers: [
      {
        label: '',
        hideTextOnMobile: false,
        templateName: 'notes',
        resourceField: 'notes',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: {
          xs: 'flex',
          sm: 'flex',
          md: 'flex',
          lg: 'flex'
        }
      }
    ]
  }
}

export const RECENT_WORK_ORDER_HEADERS = () => {
  return {
    fontSize: 'lg',
    border: false,
    canSelect: false,
    canExpand: false,
    showActions: false,
    headers: [
      {
        label: 'WO# and Name',
        hideTextOnMobile: false,
        templateName: 'wo_and_name',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: {
          xs: 'flex',
          sm: 'flex',
          md: 'flex',
          lg: 'flex'
        }
      },
      {
        label: 'Service and Trade',
        hideTextOnMobile: false,
        templateName: 'service_and_trade',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: {
          xs: 'flex',
          sm: 'flex',
          md: 'flex',
          lg: 'flex'
        }
      },
      {
        label: 'Type',
        hideTextOnMobile: false,
        templateName: 'type',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: {
          xs: 'hide',
          sm: '100',
          md: '100',
          lg: '100'
        }
      },
      {
        label: 'Creation',
        hideTextOnMobile: false,
        templateName: 'creation',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: {
          xs: 'hide',
          sm: '100',
          md: '100',
          lg: '100'

        }
      },
      {
        label: 'Description',
        hideTextOnMobile: false,
        templateName: 'description',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: {
          xs: 'hide',
          sm: '150',
          md: '150',
          lg: '150'

        }
      }
    ]
  }
}
