import { useRoles } from '@/use/roles'
import { params } from '@/utils'
import { AutocompletePayload, AutocompleteResponse } from '@/types/interfaces/api-v2/autocomplete'

const { rolesAutoComplete } = useRoles(params)

const ROLES_NAME_FILTER = (config = {}) => {
  return {
    text: 'Name',
    canSelect: false,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'roles-name-filter',
      headerText: 'Roles Name',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn: async (params: AutocompletePayload) => {
        const { data, nextPage } = await rolesAutoComplete({ ...params }) as {data: AutocompleteResponse[], nextPage: number}
        return {
          data: data.map((item: AutocompleteResponse, index: number) => {
            return {
              id: `${index}-${item.text}`,
              text: item.text,
              value: item.text
            }
          }),
          nextPage
        }
      },
      params: { field: 'name' },
      searchKeys: ['name']
    },
    pinned: false,
    isPrimary: false,
    values: [],
    ...config
  }
}

export {
  ROLES_NAME_FILTER
}
