var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    ref: "ucTableContainerRef",
    staticClass: "d-flex flex-1 uc-table",
    on: {
      "click": _vm.unblockKeyboardCommands
    }
  }, [_c(_vm.advancedModalComponent, _vm._b({
    tag: "component",
    on: {
      "close": function ($event) {
        return _vm.advancedModalEventHandler();
      },
      "success": function ($event) {
        return _vm.advancedModalEventHandler($event);
      }
    }
  }, 'component', {
    ..._vm.modalData.dynamicContentProps,
    visible: _vm.modalData.advancedModalIsVisible
  }, false)), _c('uc-modal', {
    key: _vm.modalKey,
    ref: "modalRef",
    attrs: {
      "closable": true,
      "persistent": _vm.persistent,
      "modal-card-props": _vm.modalData.modalCardProps
    },
    on: {
      "modal-closed": _vm.resetModal
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c(_vm.outerModalComponent, _vm._b({
          ref: "modalComponentRef",
          tag: "component",
          on: {
            "disabled": function ($event) {
              _vm.modalFooterIsDisabled = $event;
            },
            "action-validity-changed": function ($event) {
              _vm.modalData.confirmButtonProps.disabled = !$event;
            }
          },
          scopedSlots: _vm._u([_vm.modalData.modalContentComponent ? {
            key: "dynamic-content",
            fn: function (scopedSlots) {
              return [_c(_vm.innerModalComponent, _vm._b({
                ref: "additionalModalConfigRef",
                tag: "component",
                on: {
                  "disabled": function ($event) {
                    _vm.modalFooterIsDisabled = $event;
                  },
                  "hidden": function ($event) {
                    _vm.modalFooterButtonHidden = $event;
                  }
                }
              }, 'component', {
                ...scopedSlots,
                ...(_vm.modalData.modalContentComponentProps || {})
              }, false))];
            }
          } : null], null, true)
        }, 'component', {
          ..._vm.modalData.dynamicContentProps,
          isBulkExclusive: _vm.bulkSelectionData.isBulkExclusive,
          totalSelected: _vm.totalSelected,
          actionableFetchFn: _vm.actionableFetchFn,
          bulkActionQuery: _vm.bulkActionQuery()
        }, false))];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function () {
        return [_c('uc-modal-footer', {
          attrs: {
            "buttons": _vm.modalFooterButtons
          },
          on: {
            "cancel": function ($event) {
              return _vm.modalRef.close();
            },
            "confirm": _vm.modalConfirmationHandler
          }
        })];
      },
      proxy: true
    }])
  }), _c('div', {
    staticClass: "d-flex flex-column flex-1 overflow-hidden"
  }, [(_vm.isBulkActionsAvailable || _vm.isAppendButtonSlotAvailable || _vm.isPrependButtonSlotAvailable) && (_vm.detailsRef || !_vm.detailsPanelComponent) ? _c('uc-tabs-prepend-actions', _vm._g(_vm._b({
    attrs: {
      "none-selected": _vm.selectedResources.length === 0,
      "show-select-all": _vm.isBulkActionsAvailable,
      "is-bulk-selected": _vm.isBulkSelected,
      "is-any-selected": _vm.isPartialSelected
    },
    on: {
      "bulk-select": function ($event) {
        return _vm.handleBulkSelection($event);
      },
      "update:bulk-selection-data": function ($event) {
        _vm.bulkSelectionData.isSelectAll = $event.isSelectAll;
      }
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_vm._t("prepend-button"), _c('div', {
          staticClass: "flex-1"
        }), _c('uc-table-header-selection-indicator', {
          staticClass: "mx-2",
          attrs: {
            "total-selected": _vm.totalSelected,
            "total-count": _vm.resourceData.totalCount,
            "is-bulk-actions-available": _vm.isBulkActionsAvailable,
            "show-only-total": _vm.showOnlyTotal,
            "size": _vm.size
          },
          on: {
            "select-none": function ($event) {
              return _vm.handleBulkSelection(true);
            },
            "select-all": function ($event) {
              return _vm.handleBulkSelection(false, true);
            }
          }
        }), _vm._t("append-button")];
      },
      proxy: true
    }], null, true)
  }, 'uc-tabs-prepend-actions', _vm.isBulkActionsAvailable ? {
    buttonMenuProps: _vm.buttonMenuProps,
    actions: _vm.bulkActionIcons
  } : null, false), _vm.listenerObj)) : _vm._e(), _vm.showHeaderDivider ? _c('uc-divider') : _vm._e(), _c('div', {
    staticClass: "d-flex overflow-hidden pa-2",
    style: {
      height: '100%'
    }
  }, [_vm.isApiFailed ? _c('uc-error-state', _vm._b({}, 'uc-error-state', _vm.errorStateDetails, false)) : _vm.detailsRef || !_vm.detailsPanelComponent ? _c('uc-rows', _vm._g({
    ref: "rowsRef",
    staticClass: "flex-1 d-flex",
    attrs: {
      "row-name": _vm.rowType,
      "data": _vm.resourceData.rows,
      "columns": _vm.columns,
      "current-page": _vm.resourceData.page,
      "total-pages": _vm.resourceData.totalPages,
      "selected-resources": _vm.selectedResources,
      "selected-id": _vm.highlightedRow,
      "hide-header": _vm.hideHeader,
      "listeners": _vm.actions.map(action => action.value),
      "title": _vm.title,
      "message": _vm.message,
      "animation-data": _vm.animationData,
      "icon-name": _vm.iconName,
      "no-filters": _vm.noFilters,
      "allow-cards": _vm.allowCards,
      "size": _vm.size
    },
    on: {
      "load-more": function ($event) {
        _vm.fetchFn && _vm.invokeGetResourceData();
      },
      "row-click": function ($event) {
        _vm.updateSelectedResource($event);
        _vm.$emit('row-click', $event);
        _vm.localDetailsPanelComponentProps.visibility = _vm.DetailsPanelVisibility.Show;
        _vm.localDetailsPanelComponentProps.isPublicTab = false;
      },
      "row-dblclick": function ($event) {
        _vm.toggleFullScreenView($event);
        _vm.$emit('row-dblclick', $event);
      },
      "item-selected": function ($event) {
        _vm.updateTrackedResourceState($event);
        _vm.$emit('item-selected', $event);
      },
      "update:selected-resources": function ($event) {
        return _vm.updateSelectedResources($event);
      }
    },
    scopedSlots: _vm._u([{
      key: "empty-state",
      fn: function () {
        return [_vm._t("empty-state")];
      },
      proxy: true
    }], null, true)
  }, _vm.listenerObj)) : _c('div', {
    staticClass: "flex-1 d-flex"
  }, [_c('uc-list-row-loading', {
    attrs: {
      "first-row": true,
      "last-row": true,
      "size": 'sm'
    }
  })], 1), _vm.detailsPanelComponent ? _c(_vm.detailsComponent, _vm._g(_vm._b({
    ref: "detailsRef",
    tag: "component",
    attrs: {
      "actions": _vm.actions
    },
    on: {
      "save": function ($event) {
        return _vm.saveSelectedResourceData($event);
      },
      "change-resource": function ($event) {
        return _vm.throttledArrowFn($event);
      },
      "close-card": function ($event) {
        return _vm.closeCard();
      }
    }
  }, 'component', {
    selectedResources: _vm.detailsPanelResources,
    size: _vm.containerSize,
    ..._vm.localDetailsPanelComponentProps
  }, false), _vm.listenerObj)) : _vm._e()], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };