import { api } from '@/api'
import qs from 'qs'

interface GetSurvyeParams {
  perPage: number
  page: number
  q?: any
  include: string
}

export default {
  get: ({ perPage, page, q, include }: GetSurvyeParams) => api.$get('/surveys', {
    params: {
      include,
      page,
      per_page: perPage,
      q
    },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  post: (survey: any, { include }: any) => api.$post('/surveys', survey, {
    headers: {
      content_type: 'multipart/form-data'
    },
    params: { include }
  }),
  patch: (id: string | number, survey: any, params: any) => api.$patch(`/surveys/${id}`, survey, {
    params: { ...params },
    paramsSerializer: (params: any) => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  reviewSurvey: (surveyId: number) => api.$get(`/surveys/${surveyId}/review_surveys`, {
    params: {
      include: 'survey_template, survey_template/questions, survey_template/questions/answer_type, survey_template/questions/answer_choices, observations, observations/answer_choice, observations/answer_choices, observations/question, observations/user'
    },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  })
}
