import { api } from '@/api'
import qs from 'qs'

interface GetAlertsListParams {
  resourceId: string,
  resourceType: string,
  perPage?: number,
  page?: number,
  q?: any,
  s?: any,
  include?: any,
  active?: boolean
}

export default {
  get: ({ resourceId, resourceType, perPage, page, include, q, s, active }:GetAlertsListParams) => {
    return api.$get(`/${resourceType}/${resourceId}/alerts`, {
      params: {
        resourceId,
        resourceType,
        include,
        q,
        page,
        per_page: perPage,
        s,
        active
      },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    })
  }
}
