import { api } from '@/api'
import qs from 'qs'

interface Params {
  scope: string
  include: string
  q: any
  page: number
  perPage: number,
  id?: string,
  departmentId?: number | string
}

export default {
  getItemTypes: (params:any) => {
    const parameters:Params = params
    const { scope, include, q, page, perPage } = parameters
    return api.$get('/item_types', {
      params: {
        scope,
        include,
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },
  getUnitsOfMeasure: (params:any) => {
    const parameters:Params = params
    const { scope, include, q, page, perPage } = parameters
    return api.$get('/measure_units', {
      params: {
        scope,
        include,
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  }
}
