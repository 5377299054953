export const ATTACHMENTS_HEADERS = () => {
  return {
    fontSize: 'lg',
    border: false,
    headers: [
      {
        label: '',
        hideTextOnMobile: false,
        templateName: 'content',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: {
          xs: 'flex',
          sm: 'flex',
          md: 'flex',
          lg: 'flex'

        }
      }
    ]
  }
}
