import { reactive } from 'vue'

type StorageValue = {
  [key: string]: any
}

const storageValue = reactive<StorageValue>({})

export const useLocalStorage = () => {
  const setStorageValue = (key: string, value: any) => {
    storageValue[`${key}`] = value
    localStorage.setItem(key, JSON.stringify(value))
  }
  const getStorageValue = (key: string) => {
    const value = localStorage.getItem(key)
    storageValue[key] = value ? JSON.parse(value) : undefined
    return storageValue[key]
  }
  return {
    setStorageValue,
    getStorageValue,
    storageValue
  }
}
