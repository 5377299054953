import repositories from '@/repositories'
import { ref } from 'vue'
import { BackgroundTask, NewBackgroundTask } from '@/types/interfaces'

const jobsBeingProcessed = ref<any[]>([])

const watchInterval = ref<number>(1)
interface CustomReportHeadersPayload {
  jobType: string,
  type: string,
  reportType: string,
  customReportType: string
}

const currentErrorReport = ref('')
const bgToastRef = ref<any>(null)

const enqueued = ref<BackgroundTask[]>([])
const inProgress = ref<BackgroundTask[]>([])
const completed = ref<BackgroundTask[]>([])

const SUCCESS_REPORT_JOB_TYPES = ['work_orders_import']

export const useBackgroundTasks = () => {
  const INITIATED = 'initiated'
  const PENDING = 'pending'
  const RUNNING = 'running'
  const COMPLETED = 'completed'
  const CLOSED = 'closed'
  const NOT_RUNNING_STATUSES = [CLOSED, COMPLETED]
  const BULK_ACTIONS_JOB = ['bulk_close_wos', 'bulk_add_note', 'bulk_delete_wos', 'bulk_generate_wo_ins_report', 'bulk_mark_wo_ready_for_billing', 'bulk_update_wo_custom_status', 'bulk_dispatch_trips', 'bulk_work_order_download_trip_report']

  const getBackgroundTasks = async (params: any) => {
    try {
      const res = await repositories.backgroundTasks.get(params)
      return {
        data: res.backgroundTasks,
        totalPages: res.meta.totalPages
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getBackgroundTask = async (id: string | number) => {
    try {
      const res = await repositories.backgroundTasks.getById(id)
      return {
        data: res
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getErrorReport = async (id: string | number) => {
    try {
      const res = await repositories.backgroundTasks.getErrorReportById(id)
      console.error('error report - ', res)
      return {
        data: res
      }
    } catch (err) {
      console.error(err)
      return { data: false }
    }
  }

  const patchBackgroundTask = async (requestBody: any, params: any) => {
    try {
      const res = await repositories.backgroundTasks.patch(requestBody, params)
      return {
        data: res.backgroundTask
      }
    } catch (err: any) {
      console.error(err)
      return { data: false }
    }
  }

  const getCustomReportHeaders = async (body: CustomReportHeadersPayload) => {
    try {
      const res = await repositories.backgroundTasks.getCustomReportHeaders(body)
      return res
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const createBackgroundTask = async (body:FormData | NewBackgroundTask) => {
    try {
      const res = await repositories.backgroundTasks.post(body)
      res.backgroundTask.download = true
      addBackgroundTask(res.backgroundTask)
      return {
        data: res.backgroundTask
      }
    } catch (err: any) {
      console.log(err)
      const errorMsg = err.data?.errors || err.data?.errors?.base[0]?.error
      return { errorMsg }
    }
  }

  /*
  Watch the list of jobs being processsed (just ids)
  This variable changes when the below code runs OR a new job is created
  If it changes (i.e. a job is added or removed), wipe out the set interval
  If there are still jobs being processed, recreate the set interval
  Every 5 seconds we will fetch all jobs being processed (jobsBeingProcessed ref)
  After fetching the latest job data, check if any are done (logic in-progress)
  If any are done remove id from jbos being processed
  */

  const cleanSetInterval = (override = false) => {
    if (!jobsBeingProcessed.value.length || override) {
      inProgress.value = []
      enqueued.value = []
      completed.value = []
    }
  }

  const addBackgroundTask = async (job: BackgroundTask) => {
    if (typeof job.status === 'string') {
      if (job.status === RUNNING) {
        inProgress.value.push(job)
      } else if ([INITIATED, PENDING].includes(job.status)) {
        if (!(enqueued.value.some((bgjob: BackgroundTask) => bgjob.id === job.id))) {
          enqueued.value.push(job)
        }
      } else {
        completed.value.push(job)
      }
    }
  }

  const getSuccessReport = async (id: string | number) => {
    try {
      const res = await repositories.backgroundTasks.getSuccessReportById(id)
      return {
        data: res
      }
    } catch (err) {
      console.error(err)
      return { data: false }
    }
  }

  return {
    getBackgroundTasks,
    createBackgroundTask,
    jobsBeingProcessed,
    getBackgroundTask,
    getErrorReport,
    addBackgroundTask,
    watchInterval,
    getCustomReportHeaders,
    currentErrorReport,
    bgToastRef,
    cleanSetInterval,
    enqueued,
    inProgress,
    completed,
    INITIATED,
    PENDING,
    RUNNING,
    COMPLETED,
    CLOSED,
    NOT_RUNNING_STATUSES,
    patchBackgroundTask,
    getSuccessReport,
    SUCCESS_REPORT_JOB_TYPES,
    BULK_ACTIONS_JOB
  }
}
