import { useCompanyUsers } from '@/use/company_users'
import { AutocompletePayload, AutocompleteResponse } from '@/types/interfaces/api-v2/autocomplete'
import { FilterResources } from '@/types/enums/filter-types'

const { teamMembersAutoComplete } = useCompanyUsers()

const teamMemberAutoCompleteFetchFn = async (params: AutocompletePayload) => {
  const { data, nextPage } = await teamMembersAutoComplete({ ...params }) as { data: AutocompleteResponse[], nextPage: number }
  return {
    data: data.map((item: AutocompleteResponse, index: number) => {
      return {
        id: `${index}-${item.text}`,
        text: item.text,
        value: item.text
      }
    }),
    nextPage
  }
}

const TEAM_MEMBER_NAME = (config = {}, params = { field: 'name' }, fetchFn: any = teamMemberAutoCompleteFetchFn) => {
  return {
    text: 'Name',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'employee-name',
      headerText: 'Employee Name',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['full_name']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.EMPLOYEE,
    ...config
  }
}

const TEAM_MEMBER_PHONE_NUMBER = (config = {}, params = { field: 'phone' }, fetchFn: any = teamMemberAutoCompleteFetchFn) => {
  return {
    text: 'Phone Number',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'employee-phone-number-filter',
      headerText: 'Employee Phone Number',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['phone_number'],
      tokenSeparators: ['\t', '\n', ',']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.EMPLOYEE,
    ...config
  }
}

const TEAM_MEMBER_STATUS = (config = {}) => {
  return {
    text: 'Status',
    canSelect: false,
    popoverProps: {
      height: 'unset',
      slotKey: 'employee-status-filter',
      headerText: 'Employee Status',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      params: {},
      searchKeys: ['text'],
      items: [{ id: 0, value: 'Active', text: 'Active' }, { id: 1, value: 'Requesting', text: 'Requesting' }, { id: 2, value: 'Pending Signup', text: 'Pending Signup' }]
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.EMPLOYEE,
    ...config
  }
}

const TEAM_MEMBER_ROLE = (config = {}, params = { field: 'roles' }, fetchFn: any = teamMemberAutoCompleteFetchFn) => {
  return {
    text: 'Roles',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'employee-roles-filter',
      headerText: 'Employee Roles',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['role_name']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.EMPLOYEE,
    ...config
  }
}

const TEAM_MEMBER_EMAIL = (config = {}, params = { field: 'email' }, fetchFn: any = teamMemberAutoCompleteFetchFn) => {
  return {
    text: 'Email',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'employee-email-filter',
      headerText: 'Employee Email',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['email'],
      tokenSeparators: ['\t', '\n', ',']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.EMPLOYEE,
    ...config
  }
}

const TEAM_MEMBER_REGION = (config = {}, params = { field: 'regions' }, fetchFn: any = teamMemberAutoCompleteFetchFn) => {
  return {
    text: 'Region',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'employee-region-filter',
      headerText: 'Employee Region',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['regions_name']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.EMPLOYEE,
    ...config
  }
}

const TEAM_MEMBER_LOCATION_EMPLOYEE = (config = {}, params = { field: 'location_tm' }, fetchFn: any = teamMemberAutoCompleteFetchFn) => {
  return {
    text: 'Location Employee',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'location-employee',
      headerText: 'Location Employee',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['location_tm']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.EMPLOYEE,
    ...config
  }
}

const TEAM_MEMBER_LOCATION_EMPLOYEE_TRADE = (config = {}, params = { field: 'location_tm_trade' }, fetchFn: any = teamMemberAutoCompleteFetchFn) => {
  return {
    text: 'Location Employee Trade',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'location-employee-trade',
      headerText: 'Location Employee Trade',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['location_tm_trade']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.EMPLOYEE,
    ...config
  }
}

const TEAM_MEMBER_WORK_ORDER_MANAGER = (config = {}, params = { field: 'location_manager' }, fetchFn: any = teamMemberAutoCompleteFetchFn) => {
  return {
    text: 'Work Order Manager',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'work-order-manager',
      headerText: 'Work Order Manager',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['location_manager']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.EMPLOYEE,
    ...config
  }
}

export {
  TEAM_MEMBER_NAME,
  TEAM_MEMBER_PHONE_NUMBER,
  TEAM_MEMBER_STATUS,
  TEAM_MEMBER_ROLE,
  TEAM_MEMBER_EMAIL,
  TEAM_MEMBER_REGION,
  TEAM_MEMBER_LOCATION_EMPLOYEE,
  TEAM_MEMBER_LOCATION_EMPLOYEE_TRADE,
  TEAM_MEMBER_WORK_ORDER_MANAGER
}
