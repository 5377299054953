import { apis, api } from '@/api'
import qs from 'qs'
import { baseGetParams } from '@/utils/api'

interface BillPaymentCheckParams {
  id: number,
  checkId: number
}

export default {
  delete: ({ id, q }: any) => apis.$delete(`/bill_payments/${id}`, {
    params: {
      q
    },
    paramsSerializer: params => {
      return qs.stringify(params)
    }
  }),
  get: ({ perPage, page, q, include, scope }: baseGetParams) => apis.$get('/bill_payments', {
    params: {
      include,
      per_page: perPage,
      page,
      q,
      scope
    },
    paramsSerializer: (params: any) => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  post: (params: any, requestBody: any) => apis.$post('/bill_payments', {
    ...requestBody
  }, {
    params,
    headers: {
      'Content-Type': 'application/json'
    },
    paramsSerializer: (params: any) => {
      return qs.stringify(params)
    }
  }),
  postCheck: ({ id }:BillPaymentCheckParams, requestBody:any) => apis.$post(`/bill_payments/${id}/cheques`, {
    ...requestBody
  }, {
    params: {
      bill_payment_id: id
    },
    headers: {
      'Content-Type': 'application/json'
    },
    paramsSerializer: (params: any) => {
      return qs.stringify(params)
    }
  }),
  getCheckPdfDownloadById: ({ id, checkId }: BillPaymentCheckParams) => api.$getBuffer(`/bill_payments/${id}/cheques/${checkId}/download_pdf`, {
    params: {
      download: true,
      bill_payment_id: id,
      cheque_id: checkId
    },
    responseType: 'arraybuffer',
    paramsSerializer: (params: any) => {
      return qs.stringify(params)
    }
  }),
  billPaymentsSearch: ({ perPage, page, q, include, scope }: baseGetParams) => {
    return apis.$post('/bill_payments/search', {
      include,
      per_page: perPage,
      page,
      q,
      scope
    },
    {
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  }
}
