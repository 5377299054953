var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    class: [`uc-quantity d-flex align-center justify-center ${_vm.size}`, {
      'uc-quantity--no-background': !_vm.hasBackground
    }, `${_vm.backgroundColor}`]
  }, [_c('uc-text', {
    attrs: {
      "size": _vm.getFontSize(_vm.size),
      "type": 'body',
      "text": _vm.quantity,
      "palette-color": _vm.textPaletteColor,
      "bold": true
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };