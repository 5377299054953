import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "uc-toast__wrapper"
  }, [_c(VSnackbar, _vm._b({
    class: ['uc-toast', _vm.text === '' ? 'px-0' : 'px-2'],
    attrs: {
      "width": 640,
      "top": true,
      "min-height": 40,
      "rounded": 'lg',
      "transition": 'scroll-y-reverse-transition'
    },
    model: {
      value: _vm.isOpen,
      callback: function ($$v) {
        _vm.isOpen = $$v;
      },
      expression: "isOpen"
    }
  }, 'v-snackbar', {
    ..._vm.$attrs,
    color: _vm.state,
    timeout: -1
  }, false), [_vm._v(" " + _vm._s( /* timeout: -1 => vuetify prop to disable default timeout (no auto timeout) */) + " "), _c('div', {
    staticClass: "d-flex align-center"
  }, [_vm.prepend !== '' ? _c(VIcon, {
    staticClass: "uc-toast__prepend-icon mr-1"
  }, [_vm._v(" " + _vm._s(_vm.prepend) + " ")]) : _vm._e(), _c('div', {
    staticClass: "px-2"
  }, [_vm.text !== '' ? _c('uc-text', {
    attrs: {
      "size": 1,
      "type": 'body',
      "text": _vm.text,
      "palette-color": 'white',
      "line-height": '1.3',
      "bold": true
    }
  }) : _vm._e()], 1), _vm.append !== '' ? _c(VIcon, {
    staticClass: "uc-toast__append-icon"
  }, [_vm._v(" " + _vm._s(_vm.append) + " ")]) : _vm._e(), _c(VSpacer), _c('div', {
    staticClass: "d-flex align-center"
  }, [!!_vm.$slots.actions ? [_c('div', [_vm._t("actions")], 2)] : _vm._e(), _c(VIcon, {
    attrs: {
      "dense": true
    },
    on: {
      "click": function ($event) {
        return _vm.close();
      }
    }
  }, [_vm._v(" mdi-close ")])], 2)], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };