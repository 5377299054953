import { defineComponent, ref } from 'vue';
import StoreImageDisplay from './store-image-display.vue';
export default defineComponent({
  components: {
    StoreImageDisplay
  },
  props: {
    image: {
      type: String,
      required: true
    },
    state: {
      type: String,
      default: 'inactive'
    },
    appName: {
      type: String,
      required: true
    },
    category: {
      type: String,
      required: true
    }
  },
  setup: () => {
    const isHovering = ref(false);
    return {
      isHovering
    };
  }
});