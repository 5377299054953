var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.vertical ? _c('div', {
    staticClass: "uc-divider",
    style: `min-width: ${_vm.size}px; max-width: ${_vm.size}; height: 100%; max-height: 100%`
  }, [_c('div')]) : _c('div', {
    staticClass: "uc-divider",
    style: `min-height: ${_vm.size}px; max-height: ${_vm.size}; width: 100%: max-width: 100%;`
  }, [_c('div')]);
};
var staticRenderFns = [];
export { render, staticRenderFns };