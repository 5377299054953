import { api } from '@/api'
import qs from 'qs'

export default {
  get: (params:any) => api.$get('/company_classifications', {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params)
    }
  })
}
