import { reactive, ref } from 'vue'
import repositories from '@/repositories'
import {
  HUBSPOT_URL,
  DEFAULT_SUBDOMAIN,
  CURRENT_SUBDOMAIN
} from '@/constants/env'

import { useSession } from '@/use/session'

import { onboardingPrivateFlow } from '@/utils/flow-lists'
import { useCompanies } from '@/use/companies'
import { useUsersCompanies } from '@/use/users-companies'
import { useCompanyClassifications } from '@/use/company-classifications'
import { setCookies, getCookieValue } from '@/utils/cookies'

import router from '@/router'
import _ from 'lodash'
import { isMobile } from '@/utils/user-agent'
import { useClients } from './clients'
import { useSurveys } from './surveys'
import { useVuetify } from '@logue/vue2-helpers/vuetify'

const context = ref<any>(null)

// 0 = backward
// 1 = forward
// 2 = fade in
const animationDirection = ref(2)

const onboardingTransition = ref('medium-slide-fade-forward')

// when parent with a specific page is mounted call this so there is always access to routing in this composable
const setContext = (ctx: any) => {
  context.value = ctx
}

const { getSurveys, patchSurvey } = useSurveys()

const getCurrentOnboardStep = async (token = '') => {
  const { session } = useSession()
  try {
    let res = null as any
    // if I'm on default subdomain (coming from login), I need to fetch the steps using a company id
    // have a company id (access to company), and no token use the company id associated with me
    // if i have a token skip this since it means the company I have access to has no onboarding
    // then get onboarding steps of my company
    // else if i have access to a company (session.company_id) and not on the subdomain
    // get my steps based upon my subdomain
    if (
      CURRENT_SUBDOMAIN === DEFAULT_SUBDOMAIN &&
      session.loggedUser.company_id &&
      !token
    ) {
      res = await repositories.companies.getCurrentOnboardStep(
        session.loggedUser.company_id
      )
    } else if (
      session.loggedUser.company_id &&
      CURRENT_SUBDOMAIN !== DEFAULT_SUBDOMAIN
    ) {
      res = await repositories.companies.getCurrentOnboardStepWithSubdomain()
    }
    return res
  } catch (err) {
    console.error('Failed to get onboarding step', err)
    return null
  }
}
const onboardingState = reactive({
  currentStep: getCookieValue('onboardingState').currentStep || 0,
  steps: getCookieValue('onboardingState').steps || ([] as any),
  classification: getCookieValue('onboardingState').classification || '',
  currentStepName: getCookieValue('onboardingState').currentStepName || '',
  currentStepGroup: getCookieValue('onboardingState').currentStepGroup || '',
  latestStep: getCookieValue('onboardingState').latestStep || 0,
  token: '' as any,
  companyInfo: getCookieValue('onboardingState').companyInfo || ({} as any),
  subscription: getCookieValue('onboardingState').subscription || ({} as any),
  addons: getCookieValue('onboardingState').addons || [],
  addonTotalCost: getCookieValue('onboardingState').addonTotalCost || 0,
  integrations: getCookieValue('onboardingState').integrations || ({} as any),
  teamMembers: getCookieValue('onboardingState').teamMembers || ([] as any),
  invited: getCookieValue('onboardingState').invited || false,
  isOnboarding: getCookieValue('onboardingState').isOnboarding || false,
  applicationId: '' as any
})

const clearOnboardingCookies = async (step: number) => {
  onboardingState.companyInfo = { companyType: '' }
  onboardingState.subscription.selectedPlan = undefined
  onboardingState.addons = undefined
  onboardingState.addonTotalCost = 0
  onboardingState.integrations = undefined
  onboardingState.classification = undefined
  onboardingState.token = undefined
  onboardingState.invited = false
  onboardingState.currentStep = step
  onboardingState.latestStep = -1
  onboardingState.isOnboarding = step !== -1
  onboardingState.currentStepGroup = 'default'
  onboardingState.currentStepName = ''
  onboardingState.applicationId = ''
  setCookies({ onboardingState })
}

const { getClassifications } = useCompanyClassifications()
const { getClientRecruitTemplates } = useClients()

const getOnboardingSteps = async () => {
  let stepsPerClassification = [] as any
  const classificationSteps = await getClassifications({
    q: { name_eq: onboardingState.classification }
  })
  classificationSteps?.companyClassifications?.[0]?.steps.forEach(
    (classificationStep: string) => {
      stepsPerClassification = stepsPerClassification.concat(
        onboardingPrivateFlow.filter(
          (step) => step.value === classificationStep
        )
      )
    }
  )
  if (CURRENT_SUBDOMAIN !== DEFAULT_SUBDOMAIN) {
    const res = await getClientRecruitTemplates()
    if (!res.data) onboardingState.applicationId = ''
    else onboardingState.applicationId = res.data?.[0].id
  }
  if (onboardingState.applicationId) {
    const index = stepsPerClassification.findIndex(
      (step: any) => step.value === 'invite'
    )
    stepsPerClassification.splice(
      index,
      0,
      onboardingPrivateFlow.find((value: any) => value.value === 'custom-form')
    )
  }
  return stepsPerClassification
}

const calculateOnboardingSteps = async () => {
  try {
    let defaultSteps = onboardingPrivateFlow.filter(
      (step) => step.value === 'default'
    )
    let stepsPerClassification = [] as any
    if (onboardingState.classification) {
      stepsPerClassification = await getOnboardingSteps()
      if (onboardingState.subscription?.selectedPlan) {
        defaultSteps = defaultSteps.filter(
          (step) => step.componentName !== 'select-company-type'
        )
      }
      if (onboardingState.token || onboardingState.invited) {
        defaultSteps = defaultSteps.filter(
          (step) =>
            !['join-company', 'select-company-type'].includes(
              step.componentName
            )
        )
      }
    }
    if (
      !onboardingState.subscription?.selectedPlan ||
      onboardingState.subscription?.selectedPlan?.plan?.free
    ) {
      stepsPerClassification = stepsPerClassification.filter(
        (step: any) => step.value !== 'subscribe'
      )
    }
    onboardingState.steps = defaultSteps.concat(stepsPerClassification)
  } catch (err) {
    console.error('Failed to calculate onboarding steps', err)
  }
}

const changeSteps = async (value: number, transitionToNextStep = true) => {
  const { session, createSubdomainURL } = useSession()
  const { getUserCompanies } = useUsersCompanies()
  onboardingState.currentStep += value
  onboardingTransition.value =
    value > 0 ? 'medium-slide-fade-forward' : 'medium-slide-fade-backward'
  /* save the completed step if we are:
  1. we are not on the current subdomain
  2. the latest step is less than or equal to current step
  3. the current step or previous step is not a default step (see flow-lists.ts)
  4. if our current step and previous step are not part of the same grouping (company profile has 2 steps for example)
  */
  if (
    value >= 0 &&
    CURRENT_SUBDOMAIN !== DEFAULT_SUBDOMAIN &&
    onboardingState.latestStep <= onboardingState.currentStep &&
    (onboardingState.currentStep === onboardingState.steps.length ||
      (onboardingState.steps[onboardingState.currentStep].value !== 'default' &&
        onboardingState.steps[onboardingState.currentStep - 1].value !==
          'default' &&
        onboardingState.steps[onboardingState.currentStep - 1].value &&
        onboardingState.steps[onboardingState.currentStep].value !==
          onboardingState.steps[onboardingState.currentStep - 1].value))
  ) {
    const formData = new FormData()
    formData.append(
      'onboarding_step_attributes[completed_step]',
      onboardingState.steps[onboardingState.currentStep - 1].value
    )
    if (onboardingState.subscription?.selectedPlan) {
      formData.append(
        'onboarding_step_attributes[plan_term]',
        onboardingState.subscription.selectedPlan.term
      )
      formData.append(
        'onboarding_step_attributes[package_id]',
        onboardingState.subscription.selectedPlan.plan.id
      )
    }
    try {
      await repositories.companies.storeCurrentOnboardStep(formData)
    } catch (err) {
      console.error('Failed to save step', err)
    }
    onboardingState.latestStep =
      onboardingState.currentStep > onboardingState.latestStep
        ? onboardingState.currentStep
        : onboardingState.latestStep
    if (
      onboardingState.currentStep >= onboardingState.steps.length &&
      onboardingState.classification
    ) {
      // if there is an applicationId for recruit, get my company Id (some times isnt part of profile during signup)
      // and then make sure to "submit" the application when we finish the onboarding
      if (onboardingState.applicationId) {
        let companyId = session.loggedUser.company_id
        if (!companyId) {
          companyId = (
            await getUserCompanies({
              page: 1,
              perPage: 1
            })
          ).companies[0].id
        }
        const res = await getSurveys({
          q: {
            company_id_eq: companyId,
            survey_template_survey_type_eq: 'recruit',
            survey_template_id_eq: onboardingState.applicationId
          }
        })
        await patchSurvey(res.data[0].id, { label: 'submitted' }, {})
      }
      onboardingState.currentStepName = 'completed'
      onboardingState.currentStepGroup = 'completed'
      onboardingState.isOnboarding = false
      const validate = await validateFreeBroker()
      if (validate) {
        router.push(
          `${session.loggedUser.landing_page}${
            isMobile() ? '?login_modal=true' : ''
          }`
        )
      }
    }
  }
  if (transitionToNextStep && onboardingState.currentStepName !== 'completed') {
    onboardingState.currentStepName =
      onboardingState.steps[onboardingState.currentStep].componentName
    onboardingState.currentStepGroup =
      onboardingState.steps[onboardingState.currentStep].value
  }
  // onboardingState.latestStep = onboardingState.currentStep > onboardingState.latestStep ? onboardingState.currentStep : onboardingState.latestStep
  setCookies({ onboardingState })
}

const validateFreeBroker = async (path = '/pending') => {
  const { loadCurrentCompany, companies, loadCompanies } = useCompanies()
  const { session } = useSession()
  const companyResponse = await loadCompanies({
    q: { subdomain_eq: CURRENT_SUBDOMAIN }
  })
  await loadCurrentCompany(
    companyResponse[0]?.id || session.loggedUser.company_id
  )
  if (companies?.currentCompany?.approvalState?.toLowerCase() === 'pending') {
    router.push(path)
    return false
  } else {
    return true
  }
}

const getCurrentOnboardingState = async (token = '') => {
  const { session, createSubdomainURL } = useSession()
  const { getUserCompanies } = useUsersCompanies()
  const { currentRoute: route } = router
  if (token) {
    onboardingState.currentStepName = 'create-company'
    onboardingState.currentStepGroup = 'company_profile'
    onboardingState.token = route.query?.token || ''
    onboardingState.isOnboarding = true
    await calculateOnboardingSteps()
    onboardingState.currentStep = onboardingState.steps.findIndex(
      (step: any) => step.componentName === onboardingState.currentStepName
    )

    router.push({
      name: 'onboarding',
      query: { token: route.query.token }
    })
    return ''
  }
  const hasCompany =
    CURRENT_SUBDOMAIN !== DEFAULT_SUBDOMAIN || session?.loggedUser?.company_id
  let companies
  if (!hasCompany) {
    companies = await getUserCompanies({
      page: 1,
      perPage: 1,
      include: COMPANY_INCLUDE
    })
    if (companies.companies.length === 0) {
      onboardingState.currentStepName = 'join-company'
      onboardingState.currentStepGroup = 'default'
      onboardingState.token = ''
      onboardingState.isOnboarding = true
      await calculateOnboardingSteps()
      onboardingState.currentStep = onboardingState.steps.findIndex(
        (step: any) => step.componentName === onboardingState.currentStepName
      )
      CURRENT_SUBDOMAIN === DEFAULT_SUBDOMAIN
        ? router.push({
          name: 'onboarding',
          query: { token: route.query.token }
        })
        : window.location.replace(
          createSubdomainURL(DEFAULT_SUBDOMAIN, '/onboarding')
        )
      return ''
    } else {
      // else update session with companyId
      session.loggedUser.company_id = companies.companies[0].id
    }
  }
  // if there is a companyId in the session, get the current step
  const currStep = await getCurrentOnboardStep(token)
  // if we havent loaded company data load it
  if (!companies) {
    companies = await getUserCompanies({
      page: 1,
      perPage: 1,
      q: { id_eq: session.loggedUser.company_id },
      include: COMPANY_INCLUDE
    })
  }

  if (!companies.companies[0].userApprovedAt || !currStep.onboardingStep) {
    router.push('/users/settings')
    return ''
  }

  // TODO: do we need to check if we have no companies returned?
  // if my company has completed onboarding
  // or is on a step that doesnt exist but has a company
  // return the company subdomain (will redirect in another function)
  if (currStep?.onboardingStep === 'completed') {
    return companies.companies[0].subdomain
  }

  onboardingState.companyInfo = {
    ...onboardingState.companyInfo,
    id: session.loggedUser.company_id,
    email: companies.companies[0]?.email || '',
    DBA: companies.companies[0]?.dba || '',
    legalName: companies.companies[0]?.name || '',
    // legalTax?: string
    // ein?: string
    // stateId?: number
    // stateName?: string
    legalAddressData: {
      zip_code: companies.companies[0].headquarterAddress?.zipCode,
      formatted_address: companies.companies[0].headquarterAddress?.fullAddress,
      city: companies.companies[0].headquarterAddress?.city,
      state: companies.companies[0].headquarterAddress?.state,
      country: companies.companies[0].headquarterAddress?.country,
      lat: companies.companies[0].headquarterAddress?.lat,
      lng: companies.companies[0].headquarterAddress?.lng,
      address1: companies.companies[0].headquarterAddress?.address1,
      district: companies.companies[0].headquarterAddress?.district
    }
  }
  await calculateClassificationById(
    companies.companies[0]?.companyClassificationId
  )
  await calculateOnboardingSteps()
  if (route.query.token && currStep?.onboardingStep === null) {
    onboardingState.currentStepName = 'create-company'
    onboardingState.currentStepGroup = 'company_profile'
  } else {
    const currentStep = onboardingState.steps.find((step: any) => {
      return step.value === currStep.nextStep
    })

    onboardingState.currentStepName = currentStep?.componentName
    onboardingState.currentStepGroup = currentStep?.value
  }
  onboardingState.currentStep = onboardingState.steps.findIndex((step: any) => {
    return step.componentName === onboardingState.currentStepName
  })
  onboardingState.isOnboarding = true
  setCookies({ onboardingState })

  if (
    CURRENT_SUBDOMAIN === DEFAULT_SUBDOMAIN &&
    onboardingState.steps[onboardingState.currentStep].isProtected
  ) {
    window.location.replace(
      createSubdomainURL(companies.companies[0].subdomain, '/onboarding')
    )
  } else {
    router.push({
      name: 'onboarding',
      query: { token: route.query.token }
    })
  }
  // if we are on the wrong subdomain (default or not matching my current company)
  // redirect to correctly domain and path
  // else subdomain is correct and continue to correct step
  return ''
}

const COMPANY_INCLUDE =
  'package,package/feature_descriptions,package/package_payment_details,package/package_usage_notifications,logo,headquarter_address,state_of_registration,dba,classification'

const selectOnboardingPlan = (
  selectedPlan: any,
  termSelected: string,
  emit: any,
  paidCompanyCategoryId: number,
  classificationName?: string
) => {
  const deepPlanCopy = _.cloneDeep(selectedPlan)
  onboardingState.subscription = {
    selectedPlan: {
      plan: deepPlanCopy,
      term: termSelected
    }
  }
  onboardingState.companyInfo = {
    companyType: 'broker',
    shouldShowSelectCompany: false,
    companyCategoryId: paidCompanyCategoryId
  }
  onboardingState.classification = classificationName
  if (selectedPlan?.allowAddons) {
    emit('enterprise-selected', {
      plan: deepPlanCopy,
      term: termSelected,
      states: onboardingState
    })
  } else {
    delete deepPlanCopy.featureDescriptions
    delete deepPlanCopy.packageIntegrations
    delete deepPlanCopy.professionalServices
    delete deepPlanCopy.productServices
    setCookies({ onboardingState })
    router.push({ path: 'signup' })
  }
}

const calculateClassificationById = async (classificationId: number) => {
  if (classificationId) {
    const classificationSteps = await getClassifications({
      q: { id_eq: classificationId }
    })
    onboardingState.classification =
      classificationSteps?.companyClassifications?.[0]?.name
  }
}

const loadInvitedCompanyDetails = async (token: string | null) => {
  const { getInvitedCompanyDetails } = useCompanies()
  const companyDetails = await getInvitedCompanyDetails({
    token,
    include: 'contacts,master_user'
  })
  if (companyDetails?.id) {
    let emailOrPhone = ''
    if (companyDetails.masterUser) {
      emailOrPhone =
        companyDetails.masterUser?.email ||
        companyDetails.masterUser?.primary_phone_number
    } else {
      emailOrPhone = companyDetails.email
    }

    onboardingState.subscription.selectedPlan = ''
    onboardingState.token = token
    onboardingState.companyInfo.companyType =
      companyDetails?.roles?.length > 0
        ? companyDetails.roles.includes('contractor') ||
          companyDetails.roles.includes('vendor')
          ? 'provider'
          : 'requester'
        : 'requester'

    onboardingState.companyInfo.id = companyDetails?.id

    onboardingState.classification = companyDetails.roles?.includes(
      'vendor'
    )
      ? 'Provide Service'
      : 'Request Service'
    setCookies({ onboardingState })
    return emailOrPhone
  } else {
    onboardingState.token = undefined
    onboardingState.companyInfo.companyType = ''
    setCookies({ onboardingState })
    return ''
  }
}

export const useOnboarding = () => {
  const vuetify = useVuetify()
  const zoomOutMobile = () => {
    const viewport = document.querySelector('meta[name="viewport"]')
    if (viewport && vuetify.breakpoint.width < 599) {
      viewport.setAttribute(
        'content',
        'width=device-width, initial-scale=1.0, maximum-scale=1.0'
      )
      setTimeout(() => {
        viewport.setAttribute(
          'content',
          'width=device-width, user-scalable=yes'
        )
      }, 100)
    }
  }

  return {
    getCurrentOnboardStep,
    onboardingTransition,
    setContext,
    selectOnboardingPlan,
    zoomOutMobile,
    animationDirection,
    onboardingState,
    changeSteps,
    getCurrentOnboardingState,
    calculateOnboardingSteps,
    validateFreeBroker,
    calculateClassificationById,
    clearOnboardingCookies,
    loadInvitedCompanyDetails
  }
}
