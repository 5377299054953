import { api } from '@/api'
import qs from 'qs'

export default {
  getUserCompany: ({ perPage, page, q, id }: any) => api.$get(`/users/companies/${id}`, {
    params: {
      include: 'package,package/package_usage_notifications,package/package_payment_details,package/feature_descriptions,logo,headquarter_address,package/package_integrations',
      page,
      perPage,
      q
    },
    paramsSerializer: params => {
      return qs.stringify(params)
    }
  }),
  sendInvitationToUser: (payload: any) => api.$patch('/users/invitation/resend_invitation', payload),
  get: ({ perPage, page, q, include }: any) => api.$get('/users/companies', {
    params: {
      per_page: perPage,
      page,
      q,
      include
    },
    paramsSerializer: params => {
      return qs.stringify(params)
    }
  }),
  switchCompanies: (companyId: number | string) => api.$patch(`/users/companies/${companyId}/switch`)
}
