import { api } from '@/api'
import qs from 'qs'

interface GetTradeServicesParams {
    perPage?: number
    page: number
    q?: any
    s?: any
    include: string
}
export default {
  getTradeServicesList: (params: any) => {
    const { page, perPage, q }: GetTradeServicesParams = params
    return api.$get('/trade_services', {
      params: {
        include: 'trade, tasks',
        page,
        per_page: perPage,
        q
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },
  deleteTradeServices: (params: any) => {
    return api.$delete('/trade_services/bulk_destroy', {
      params: {
        ids: params
      }
    })
  },
  get: (params: any) => {
    const { page, perPage, q, include }: GetTradeServicesParams = params
    return api.$get('/trade_services', {
      params: {
        include,
        page,
        per_page: perPage,
        q
      },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    })
  },
  getServicesProvided: (params: any) => {
    const { page, perPage, q, include }: GetTradeServicesParams = params
    return api.$get('/services_provided', {
      params: {
        include,
        page,
        per_page: perPage,
        q
      }
    })
  }
}
