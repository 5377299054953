import {
  CLIENT_NAME,
  CLIENT_NO_OF_SITES,
  CLIENT_COUNTRY,
  CLIENT_STATE,
  CLIENT_CITY,
  CLIENT_ZIP,
  CLIENT_ADDRESS,
  CLIENT_LOCATION_NAME,
  CLIENT_LOCATION_IN_CARE_OF,
  CLIENT_LOCATION_COUNTRY,
  CLIENT_LOCATION_STATE,
  CLIENT_LOCATION_CITY,
  CLIENT_LOCATION_ZIP,
  CLIENT_LOCATION_ADDRESS
} from '@/constants/filters/resources/client'

export const pendingClientsFilters = () => {
  return {
    filters: [
      CLIENT_NAME({ pinned: true, isPrimary: true }, { field: 'name', tab: 'pending' }),
      CLIENT_NO_OF_SITES({ pinned: true, isPrimary: true }, 'pending'),
      CLIENT_COUNTRY({ pinned: true, isPrimary: true }, { field: 'country', tab: 'pending' }),
      CLIENT_STATE({ pinned: true, isPrimary: true }, { field: 'state', tab: 'pending' }),
      CLIENT_CITY({ pinned: true, isPrimary: true }, { field: 'city', tab: 'pending' }),
      CLIENT_ZIP({ pinned: true, isPrimary: true }, { field: 'zip_code', tab: 'pending' }),
      CLIENT_ADDRESS({ pinned: true, isPrimary: true }, { field: 'address1', tab: 'pending' }),
      CLIENT_LOCATION_NAME({ pinned: true, isPrimary: true }, 'pending'),
      CLIENT_LOCATION_IN_CARE_OF({ pinned: true, isPrimary: true }, 'pending'),
      CLIENT_LOCATION_COUNTRY({ pinned: true, isPrimary: true }, 'pending'),
      CLIENT_LOCATION_STATE({ pinned: true, isPrimary: true }, 'pending'),
      CLIENT_LOCATION_CITY({ pinned: true, isPrimary: true }, 'pending'),
      CLIENT_LOCATION_ZIP({ pinned: true, isPrimary: true }, 'pending'),
      CLIENT_LOCATION_ADDRESS({ pinned: true, isPrimary: true }, 'pending')
    ],
    sortBy: {
      value: 'name',
      direction: {
        text: 'Ascending',
        value: 'asc'
      },
      items: [
        {
          label: 'Name',
          value: 'name'
        }
      ]
    },
    fieldMapping: {
      'client-name': {
        name: 'client_name',
        field: 'text'
      },
      'number-of-sites': {
        name: 'client_locations_count',
        field: 'value'
      },
      'client-country': {
        name: 'client_headquarter_address_country',
        field: 'value'
      },
      'client-state': {
        name: 'client_headquarter_address_state',
        field: 'value'
      },
      'client-city': {
        name: 'client_headquarter_address_city',
        field: 'value'
      },
      'client-zip': {
        name: 'client_headquarter_address_zip_code',
        field: 'value'
      },
      'client-address': {
        name: 'client_headquarter_address_address1',
        field: 'value'
      },
      'client-location': {
        name: 'client_locations_name',
        field: 'value'
      },
      'client-location-in-care-of': {
        name: 'client_locations_billing_address_in_care_of',
        field: 'value'
      },
      'client-location-country': {
        name: 'client_locations_address_country',
        field: 'value'
      },
      'client-location-state': {
        name: 'client_locations_address_state',
        field: 'value'
      },
      'client-location-city': {
        name: 'client_locations_address_city',
        field: 'value'
      },
      'client-location-zip': {
        name: 'client_locations_address_zip_code',
        field: 'value'
      },
      'client-location-address': {
        name: 'client_locations_address_address1',
        field: 'value'
      }
    }
  }
}
