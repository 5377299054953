
export enum ResourceActions {
  CONFIRM = 'confirm',
  CANCEL = 'cancel',
  CLOSE = 'close',
  SKIP = 'skip',
  DONT_SKIP = 'dont-skip',
  MODAL_CLOSE = 'modal-close',
  SAVE = 'save',
  ERROR = 'error'
}
