import router from '@/router'
import { DOMAIN, RAILS_SESSION_COOKIE } from '@/constants/env'

export const expireIn = (days:number) => {
  days = days || 1
  const date = new Date()
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
  return date.toUTCString()
}

export const userDetailsCookie = `user-details${RAILS_SESSION_COOKIE}`
export const setCookies = (tokens:any) => {
// tokens looks like: {company-id: ffasdfjlkadsf, uc-web-refresh-token: alskdjfaklsdfj}
  Object.keys(tokens).forEach((token:any) => {
    document.cookie = `${token}=${window.btoa(JSON.stringify(
      tokens[token]
    ))};expires=${expireIn(1)}; domain=${DOMAIN ? DOMAIN.split(':')[0] : ''}; path=/; Secure`
  })
}

export const getCookieValue = (name: string): any => {
  try {
    const shouldJSONParse = ['company-id',
      'onboarding-state',
      'uc-web-access-token',
      'uc-web-refresh-token',
      `user-details${RAILS_SESSION_COOKIE}`,
      `${RAILS_SESSION_COOKIE}`,
      'user-details',
      'onboardingState',
      'utilizecore-theme',
      'uc-version'
    ]
    const shouldB64Decode = ['company-id',
      'onboarding-state',
      'uc-web-access-token',
      'uc-web-refresh-token',
      `user-details${RAILS_SESSION_COOKIE}`,
      'user-details',
      'onboardingState',
      'utilizecore-theme',
      'uc-version'
    ]

    let matchedCookie: string | any = document.cookie
      .match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')
      ?.pop()

    if (!matchedCookie) return {}
    if (shouldB64Decode.includes(name)) {
      matchedCookie = window.atob(matchedCookie)
    }
    if (shouldJSONParse.includes(name)) {
      matchedCookie = JSON.parse(matchedCookie)
    }
    return matchedCookie
  } catch (error:any) {
    if (error instanceof SyntaxError || error instanceof DOMException) {
      console.error('failed on cookie', error)
      document.cookie = `uc-web-access-token=; domain=${DOMAIN}; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`
      document.cookie = `uc-web-refresh-token=; domain=${DOMAIN}; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`
      document.cookie = `user-details${RAILS_SESSION_COOKIE}=; path=/; domain=${DOMAIN}; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`
      router.push('/login')
    }
  }
}
