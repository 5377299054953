import { api } from '@/api'
import qs from 'qs'

interface Params {
  include: string
  q: any
  page: number
  perPage: number,
  type: string,
  tab: string
}

export default {
  getTemplates: (params: Params) => {
    const { include, q, page, perPage, type, tab } = params
    return api.$get(`/report_templates/${type}_report_templates`, {
      params: {
        include,
        q,
        page,
        per_page: perPage,
        template_type: tab
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },
  createTemplate: (type: string, body:any) => api.$post(`/report_templates/${type}_report_templates`, body)

}
