/**
 * This type represents a enum (backwards compatible) of possible resource types we may need to describe based on the
 * constants in this file.
 */
export type ResourceType = 'work_orders' | 'weather_events' | 'proposals' | 'proposal' | 'invoice' | 'purchase-order' | 'AgreementContract' | 'trip' | 'location' | 'vendor' | 'client' | 'team-members' | 'payment'

// For API Usage
export const WORK_ORDER = 'work_orders'
export const WEATHER_EVENT = 'weather_events'
export const PROPOSAL = 'proposals'
export const OUTBOUND_INVOICE = 'outbound_invoice'
export const INBOUND_INVOICE = 'inbound_invoice'
export const OUTBOUND_PROPOSAL = 'outbound_proposal'
export const INBOUND_PROPOSAL = 'inbound_proposal'
export const OUTBOUND_PURCHASE_ORDER = 'outbound_purchase_order'
export const INBOUND_PURCHASE_ORDER = 'inbound_purchase_order'
export const WO_FETCH_PARAM_TYPE = 'WorkOrder'
export const EVENT_FETCH_PARAM_TYPE = 'WeatherWorks::StormDetail'
export const VENDOR = 'vendor'
export const CLIENT = 'client'
export const TEAM_MEMBER = 'team-members'
export const PAYMENT = 'payment'

// For vue naming convention
export const PROPOSAL_RESOURCE_TYPE = 'proposal'
export const INVOICE_RESOURCE_TYPE = 'invoice'
export const PO_RESOURCE_TYPE = 'purchase-order'
export const PURCHASE_ORDER_RESOURCE_TYPE = 'purchase_order' // used for security and dont use anywhere else
export const TRIP_RESOURCE_TYPE = 'trip'
export const WO_RESOURCE_TYPE = 'work-orders'

export const TRIP = 'trip'
export const LOCATION = 'location'
export const AGREEMENT_CONTRACT = 'AgreementContract'
