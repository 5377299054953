import { reactive } from 'vue'
import repositories from '@/repositories'
type Regions = {
  loading: boolean,
  list: Array<any>,
  selectedList: Array<any>,
  meta: {
    currentPage: 1,
    nextPage: 2,
    prevPage: null,
    totalCount: 0,
    totalPages: 0
  }
}
const regions = reactive<Regions>({
  loading: false,
  list: [],
  selectedList: [],
  meta: {
    currentPage: 1,
    nextPage: 2,
    prevPage: null,
    totalCount: 0,
    totalPages: 0
  }
})

interface LoadRegionsParams {
  page?: number
  perPage?: any
  q?: any,
}

export const useRegions = () => {
  const getRegions = async (params: LoadRegionsParams) => {
    try {
      const res = await repositories.regions.getRegionList(params)
      return {
        data: res.territories,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as any
    } catch (err) {
      console.log(err)
    }
  }
  const loadRegions = async ({ page, perPage, q }: LoadRegionsParams) => {
    try {
      regions.loading = true
      const res = await repositories.regions.getRegionList({ page, perPage, q })
      if (page === 1) {
        regions.list = res.territories.length === 0 ? [] : [...res.territories]
      } else {
        const newItems: any[] = []
        res.territories.forEach((eachItem: any) => {
          const existingItemIndex = regions.list.findIndex((region) => region.id === eachItem.id)
          if (existingItemIndex < 0) {
            newItems.push(eachItem)
          }
        })
        regions.list = [...regions.list, ...newItems]
      }
      regions.meta = { ...res.meta }
      regions.selectedList = regions.list.filter((region: any) => region.selected)
    } catch (err) {
      console.log(err)
    } finally {
      regions.loading = false
    }
  }

  const loadRegionsWithoutSaving = async ({ page, perPage, q }: LoadRegionsParams) => {
    try {
      const response = await repositories.regions.getRegionList({ page, perPage, q })
      return response
    } catch (err) {
      console.log(err)
      return {
        regions: [],
        meta: {}
      }
    }
  }

  const appendRegionItem = (item: any) => {
    if (!regions.list.find((eachItem) => eachItem.id === item.id)) {
      regions.list = [{ ...item }, ...regions.list]
    }
    regions.selectedList = regions.list.filter((region: any) => region.selected)
  }

  const selectRegionItems = (items: any[]) => {
    regions.list.forEach((eachRegion: any) => {
      const { id } = eachRegion
      eachRegion.selected = !!items.find((item: any) => item.id === id)
    })
    regions.selectedList = regions.list.filter((region: any) => region.selected)
  }

  const deleteRegions = async (items: any[]) => {
    regions.loading = true
    await Promise.all(items.map((item: any) => repositories.regions.deleteRegionWithId(item.id))).then(() => {
      regions.loading = false
    }).catch(err => {
      console.log(err)
      regions.loading = false
    })
  }

  return {
    regions,
    loadRegions,
    deleteRegions,
    appendRegionItem,
    selectRegionItems,
    loadRegionsWithoutSaving,
    getRegions
  }
}
