const {
  // VUE_APP_APU_URL,
  VUE_APP_OAUTH_BASE_URL,
  VUE_APP_OAUTH_CLIENT_SECRET,
  VUE_APP_OAUTH_CLIENT_ID,
  VUE_APP_RESET_PASSWORD_REDIRECT_URL,
  VUE_APP_PROTOCOL,
  VUE_APP_DOMAIN,
  VUE_APP_GOOGLE_API_KEY,
  VUE_APP_DEFAULT_SUBDOMAIN,
  VUE_APP_GOOGLE_STATIC_MAP_URL,
  VUE_APP_T_C_PATH,
  VUE_APP_PRIVACY_POLICY_PATH,
  VUE_APP_HIGHER_SUBDOMAIN,
  VUE_APP_HUBSPOT_APPOINTMENT_CREATION_URL,
  VUE_APP_GOOGLE_PLAY_STORE_URL,
  VUE_APP_STORE_URL,
  VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY,
  VUE_APP_RAILS_SESSION_COOKIE,
  VUE_APP_SEGMENT_WRITE_API_KEY,
  VUE_APP_DEPLOYMENT_TYPE,
  VUE_APP_CHARGEBEE_SITE,
  VUE_APP_APP_STORE_URL,
  VUE_APP_IS_RAEL_APP,
  NODE_ENV,
  VUE_APP_INTERCOM_APP_ID,
  VUE_APP_AZURE_ID,
  VUE_APP_PUSHER_APP_KEY,
  VUE_APP_PUSHER_CLUSTER,
  VUE_APP_PUSHER_PORT,
  VUE_APP_PUSHER_HOST,
  VUE_APP_PUSHER_PORT_SSL,
  VUE_APP_RAEL_PUSHER_APP_KEY,
  VUE_APP_RAEL_PUSHER_CLUSTER,
  VUE_APP_RAEL_PUSHER_PORT,
  VUE_APP_RAEL_PUSHER_HOST,
  VUE_APP_RAEL_PUSHER_PORT_SSL,
  VUE_APP_JITSI_DOMAIN,
  VUE_APP_AR_AP_BETA_DISABLED_SUBDOMAINS,
  VUE_APP_TERMS_AND_CONDITIONS_URL,
  VUE_APP_RAEL_TERMS_AND_CONDITIONS_URL,
  VUE_APP_WO_ENABLED_SUBDOMAINS
} = process.env

let apiURL:any = document.querySelector<HTMLElement>('[name=vue-app-apu-url]')
apiURL = apiURL?.content || ''

export const SUBDOMAIN = window.location.hostname.split('.')[0] === VUE_APP_HIGHER_SUBDOMAIN ? VUE_APP_DEFAULT_SUBDOMAIN : window.location.hostname.split('.')[0]
export const PROTOCOL = VUE_APP_PROTOCOL
export const DOMAIN = VUE_APP_DOMAIN
export const PORT = window.location.port
export const API_URL_INJECTED_RAILS = apiURL
const SUBDOMAIN_API_URL = window.location.hostname.split('.')[0] === VUE_APP_HIGHER_SUBDOMAIN ? '' : window.location.hostname.split('.')[0]
export const API_URL = `${PROTOCOL}://${SUBDOMAIN_API_URL}${SUBDOMAIN_API_URL === '' ? apiURL.substr(1, apiURL.length - 1) : apiURL}`
export const OAUTH_BASE_URL = `${PROTOCOL}://${SUBDOMAIN}${VUE_APP_OAUTH_BASE_URL}`
export const OAUTH_CLIENT_SECRET = VUE_APP_OAUTH_CLIENT_SECRET
export const OAUTH_CLIENT_ID = VUE_APP_OAUTH_CLIENT_ID
export const RESET_PASSWORD_REDIRECT_URL = VUE_APP_RESET_PASSWORD_REDIRECT_URL
export const GOOGLE_API_KEY = VUE_APP_GOOGLE_API_KEY
export const DEFAULT_SUBDOMAIN = VUE_APP_DEFAULT_SUBDOMAIN
export const GOOGLE_STATIC_MAP_URL = VUE_APP_GOOGLE_STATIC_MAP_URL
export const GOOGLE_ANALYTICS_ID = 'G-M7MQ3E3SY9'
export const HUBSPOT_URL = VUE_APP_HUBSPOT_APPOINTMENT_CREATION_URL
export const GOOGLE_PLAY_STORE_URL = VUE_APP_GOOGLE_PLAY_STORE_URL
export const APP_STORE_URL = VUE_APP_STORE_URL
export const T_C_URL = `${OAUTH_BASE_URL}${VUE_APP_T_C_PATH}`
export const PRIVACY_POLICY_URL = `${OAUTH_BASE_URL}${VUE_APP_PRIVACY_POLICY_PATH}`
export const GOOGLE_RECAPTCHA_SITE_KEY = VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY
export const RAILS_SESSION_COOKIE = VUE_APP_RAILS_SESSION_COOKIE || '_utilizecore_development_session'
export const SEGMENT_WRITE_API_KEY = VUE_APP_SEGMENT_WRITE_API_KEY
export const DEPLOYMENT_TYPE = VUE_APP_DEPLOYMENT_TYPE
export const HIGHER_SUBDOMAIN = VUE_APP_HIGHER_SUBDOMAIN
export const CURRENT_SUBDOMAIN = window.location.hostname.split('.')[0]
export const CHARGEBEE_SITE = VUE_APP_CHARGEBEE_SITE
export const LOGO_NAME = process.env.VUE_APP_IS_RAEL_APP === 'true' ? 'rael_box_logo.png' : 'utilizecore-logo.png'
export const APP_STORE_API_URL = `${NODE_ENV === 'development' ? 'http' : PROTOCOL}://${SUBDOMAIN}${VUE_APP_APP_STORE_URL}`
export const IS_RAEL_APP = VUE_APP_IS_RAEL_APP
export const INTERCOM_APP_ID = VUE_APP_INTERCOM_APP_ID || ''
export const AZURE_CLIENT_ID = VUE_APP_AZURE_ID
export const PUSHER_APP_KEY = process.env.VUE_APP_IS_RAEL_APP === 'true' ? (VUE_APP_RAEL_PUSHER_APP_KEY || '') : (VUE_APP_PUSHER_APP_KEY || '')
export const PUSHER_CLUSTER = process.env.VUE_APP_IS_RAEL_APP === 'true' ? (VUE_APP_RAEL_PUSHER_CLUSTER || '') : (VUE_APP_PUSHER_CLUSTER || '')
export const PUSHER_PORT = process.env.VUE_APP_IS_RAEL_APP === 'true' ? (Number(VUE_APP_RAEL_PUSHER_PORT) || 0) : (Number(VUE_APP_PUSHER_PORT) || 0)
export const PUSHER_HOST = process.env.VUE_APP_IS_RAEL_APP === 'true' ? (VUE_APP_RAEL_PUSHER_HOST || '') : (VUE_APP_PUSHER_HOST || '')
export const PUSHER_PORT_SSL = process.env.VUE_APP_IS_RAEL_APP === 'true' ? (Number(VUE_APP_RAEL_PUSHER_PORT_SSL) || 0) : (Number(VUE_APP_PUSHER_PORT_SSL) || 0)
export const JISTI_DOMAIN = VUE_APP_JITSI_DOMAIN
export const AR_AP_BETA_DISABLED_SUBDOMAINS = VUE_APP_AR_AP_BETA_DISABLED_SUBDOMAINS?.split(',')
export const TERMS_AND_CONDITIONS_URL = (process.env.VUE_APP_IS_RAEL_APP === 'true'
  ? VUE_APP_RAEL_TERMS_AND_CONDITIONS_URL
  : VUE_APP_TERMS_AND_CONDITIONS_URL) as string
export const WO_ENABLED_SUBDOMAINS = VUE_APP_WO_ENABLED_SUBDOMAINS?.split(',')
