import { apis } from '@/api'
import qs from 'qs'
import { baseGetParams } from '@/utils/api'
export default {
  get: (params: baseGetParams) => apis.$get('/payment_accounts', {
    params,
    paramsSerializer: (params: any) => {
      return qs.stringify(params)
    }
  })
}
