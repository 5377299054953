export enum TopLevelTabs {
  PAYABLE_TAB = 'Payable',
  RECEIVABLE_TAB = 'Receivable',
  RECRUITMENT_TAB = 'Recruitment',
  SALES_TAB = 'Sales',
  EXPLORE_TAB = 'Explore',
  NETWORK_TAB = 'Network',
  ORDERS_TAB = 'Work Orders',
  TRIPS_TAB = 'Trips',
  PURCHASING_TAB = 'Purchasing',
  MATERIALS_TAB = 'Materials',
  PAYMENT_TAB = 'Payments',
  CLIENTS = 'Clients',
  VENDORS = 'Vendors',
  PEOPLE = 'People',
  PROFILE = 'Profile'
}
