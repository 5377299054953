import { Trip } from '@/types/interfaces'
import { useSession } from '@/use/session'
import moment from 'moment'
import { PriorityObj } from '@/types/interfaces/api-v2/work-order/priority'
import { cloneDeep } from 'lodash'

export const DEFAULT_PRIORITY_OBJ_UNIT = 'minutes'
export type AttrBoundaries = {
  maxDate: string,
  minDate: string
}
export enum AllowedAttrTypes {
  OPEN_DATE = 'openDate',
  ETA = 'eta',
  ETC = 'etc',
  CLOSE_DATE = 'closeDate'
}

export function generateDefaultDateTime (
  attrType: AllowedAttrTypes, priorityObj?: PriorityObj,
  locationTz?: string, expectedDate?: string,
  perCurrentTime = true
): string {
  if (!attrType) return ''

  const { session } = useSession()
  const timezone = locationTz || session.loggedUser.timezone
  let dateVal = perCurrentTime ? moment().tz(timezone) : moment(expectedDate).tz(timezone)
  if (priorityObj) {
    if (priorityObj.scheduledAt) {
      // In db scheduledAt is saved in UTC format
      const scheduledAt = moment(priorityObj.scheduledAt).utc()
      const newDate = cloneDeep(dateVal)
      newDate.set('hour', scheduledAt.hours())
      newDate.set('minute', scheduledAt.minutes())
      if (newDate.isSameOrBefore(dateVal)) {
        dateVal = newDate.add(1, 'day')
      } else {
        dateVal = newDate
      }
    }
    dateVal = changeWithPriority(attrType, dateVal, priorityObj)
  }

  return dateVal.toISOString()
}

const changeWithPriority = (
  attrType: AllowedAttrTypes, date: moment.Moment,
  priorityObj: PriorityObj
): moment.Moment => {
  let valueToAdd = 0
  switch (attrType) {
    case AllowedAttrTypes.ETA: {
      valueToAdd = Number(priorityObj.defaultStartDuration || 0)
      break
    }
    case AllowedAttrTypes.ETC: {
      valueToAdd = Number(priorityObj.defaultEndDuration || 0)
      break
    }
    case AllowedAttrTypes.CLOSE_DATE: {
      valueToAdd = Number(priorityObj.defaultExpirationDuration || 0)
      break
    }
    default: break
  }
  return date.add(valueToAdd, DEFAULT_PRIORITY_OBJ_UNIT)
}

/**
 * This functions return the minDate and maxDate for the all date
 * attributes present at trip level, which might have effect from
 * WorkOrder attributes like startDate, expirationDate, endDate, ...
 */
export function tripDateBoundries (dateType: AllowedAttrTypes, trip: Trip): AttrBoundaries {
  const boundaries = { minDate: '', maxDate: '' }
  if (!trip) return boundaries

  const woCreationDate = trip.workOrder?.creationDate || trip.workOrder?.createdAt || ''
  if (dateType === AllowedAttrTypes.OPEN_DATE) {
    boundaries.minDate = trip.startDate?.toString() || woCreationDate
    boundaries.maxDate = trip.endDate?.toString() || ''
  } else {
    boundaries.minDate = woCreationDate
    boundaries.maxDate = trip.workOrder?.expirationDate || moment(
      trip.expirationDate || trip.endDate
    ).add(1, 'years').toISOString()
  }

  return boundaries
}
