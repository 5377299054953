import { defineComponent, ref } from 'vue';
import { ERROR, SUCCESS } from '@/constants/icons';
export default defineComponent({
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup: props => {
    const toastRef = ref(null);
    const copyToClipboard = () => {
      navigator.clipboard.writeText(props.value).then(function () {
        toastRef.value.show({
          message: 'Successfully copied \'' + props.value + '\' to clipboard',
          prependIcon: SUCCESS,
          color: 'success'
        });
      }, function () {
        toastRef.value.show({
          message: 'Could not copy \'' + props.value + '\'',
          prependIcon: ERROR,
          color: 'error'
        });
      });
    };
    return {
      copyToClipboard,
      toastRef
    };
  }
});