import { reactive } from 'vue'
import repositories from '@/repositories'
import { Response } from '@/utils/table'
import _ from 'lodash'
import { AutocompletePayload, AutocompleteResponse } from '@/types/interfaces/api-v2/autocomplete'

type Locations = {
  loading: boolean,
  list: Array<any>,
  latest: any[],
  meta: {
    currentPage: 1,
    nextPage: 2,
    prevPage: null,
    totalCount: 0,
    totalPages: 0
  }
}

const locations = reactive<Locations>({
  loading: false,
  list: [],
  latest: [],
  meta: {
    currentPage: 1,
    nextPage: 2,
    prevPage: null,
    totalCount: 0,
    totalPages: 0
  }
})

type InventoryLocation = {
  loading: boolean,
  list: Array<any>,
  latest:any,
  meta: {
    currentPage: 1,
    nextPage: 2,
    prevPage: null,
    totalCount: 0,
    totalPages: 0
  }
}
const inventoryLocation = reactive<InventoryLocation>({
  loading: false,
  list: [],
  latest: [],
  meta: {
    currentPage: 1,
    nextPage: 2,
    prevPage: null,
    totalCount: 0,
    totalPages: 0
  }
})

type Location = {
  loading: boolean,
  details: any,
  tax: number,
  equipIdURl: string
}
const location = reactive<Location>({
  loading: false,
  details: null,
  tax: 0,
  equipIdURl: ''
})

interface LoadInventoryLocation {
  page: number
  perPage?: any
  q?: any,
}

interface LoadLocationEquipmentsURL {
  locationId: number
}

export const useLocations = () => {
  const loadLocations:any = async (params: any) => {
    try {
      locations.loading = true
      const res = await repositories.locations.getLocations(params)
      locations.list = [...locations.list, ...res.locations]
      locations.latest = [...res.locations]
      locations.meta = { ...res.meta }
    } catch (err) {
      console.log(err)
    } finally {
      locations.loading = false
    }
  }

  const getLocations = async (params: any) => {
    try {
      const res: any = await repositories.locations.getLocations(params)
      return {
        data: res.locations,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as Response
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }
  const getLocationsNearBy = async (params: any) => {
    try {
      const res: any = await repositories.locations.getLocationsNearBy(params)
      return {
        data: res.locations,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as Response
    } catch (err) {
      console.log(err)
      return {} as Response
    }
  }

  const getInvoiceableLocations = async (params: any) => {
    try {
      const res: any = await repositories.locations.getInvoiceableLocations(params)
      return {
        data: res.locations,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as Response
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getInvoiceableLocsByClient = async (params: any) => {
    try {
      const res: any = await repositories.locations.getInvoiceableLocsByClient(params)
      return {
        data: res.locations,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as Response
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getBillableLocations = async (params: any) => {
    try {
      const res: any = await repositories.locations.getBillableLocations(params)
      return {
        data: res.locations,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as Response
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getInvoiceableResourcesByLocation = async (params: any) => {
    try {
      const res: any = await repositories.locations.getInvoiceableResourcesByLocation(params)
      return {
        data: res[_.camelCase(params.resourceType)],
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as Response
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getBillableResourcesByLocation = async (params: any) => {
    try {
      const res: any = await repositories.locations.getBillableResourcesByLocation(params)
      return {
        data: res[_.camelCase(params.resourceType)],
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as Response
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getInvoiceableTripsByLocAndResource = async (params: any) => {
    try {
      const res: any = await repositories.locations.getInvoiceableTripsByLocAndResource(params)
      return {
        data: res.trips,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as Response
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getBillableTripsByLocAndResource = async (params: any) => {
    try {
      const res: any = await repositories.locations.getBillableTripsByLocAndResource(params)
      return {
        data: res.trips,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as Response
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const loadLocationDetails = async (params:any) => {
    try {
      location.loading = true
      const res = await repositories.locations.getById(params)
      location.details = res.location
    } catch (err) {
      console.log(err)
    } finally {
      location.loading = false
    }
  }

  const getLocationById = async (params:any) => {
    try {
      const res = await repositories.locations.getById(params)
      return {
        data: res.location
      }
    } catch (err) {
      console.log(err)
      return {
        data: false
      }
    }
  }

  const getStates = async (params: any) => {
    try {
      const res = await repositories.locations.getStates(params)
      return {
        data: res.states,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      }
    } catch (err) {
      console.log(err)
      return {
        data: false
      }
    }
  }

  const loadLocationTax = async (id:number) => {
    try {
      const res = await repositories.locations.getLocationTax(id)
      location.tax = res.tax
    } catch (err) {
      console.log(err)
    }
  }

  const loadInventoryLocation = async ({ page, perPage, q }: LoadInventoryLocation) => {
    try {
      inventoryLocation.loading = true
      const res = await repositories.locations.getInventoryLocation({ page, perPage, q })
      if (page === 1) {
        inventoryLocation.list = res.locations.length === 0 ? [] : [...res.locations]
        inventoryLocation.latest = res.locations
      } else {
        inventoryLocation.list = [...inventoryLocation.list, ...res.locations]
        inventoryLocation.latest = res.locations
      }
      inventoryLocation.meta = { ...res.meta }
    } catch (err) {
      console.log(err)
    } finally {
      inventoryLocation.loading = false
    }
  }

  const loadLocationsWithoutSaving = async ({ page, perPage, q }: LoadInventoryLocation) => {
    try {
      const response = await repositories.locations.getInventoryLocation({ page, perPage, q })
      return response
    } catch (err) {
      console.log(err)
      return {
        inventoryLocation: [],
        meta: {}
      }
    }
  }

  const appendLocation = (item: any) => {
    if (!inventoryLocation.list.find((eachItem) => eachItem.id === item?.id)) {
      inventoryLocation.list = [{ ...item }, ...inventoryLocation.list]
    } else {
      const inventoryLocationIndex = inventoryLocation.list.findIndex((eachItem) => eachItem.id === item?.id)
      inventoryLocation.list.splice(inventoryLocationIndex, 1, { ...item })
    }
  }

  const loadLocationEquipmentsURL = async ({ locationId }: LoadLocationEquipmentsURL) => {
    try {
      location.loading = true
      const response = await repositories.locations.getLocationEquipmentsURL({ locationId })
      location.equipIdURl = response.equipIdSsoJwtUrl
    } catch (err) {
      console.log(err)
    } finally {
      location.loading = false
    }
  }

  const getMaterialMarkupsByLoc = async (params: any) => {
    try {
      const res = await repositories.locations.getMaterialMarkupsByLoc(params)
      return {
        data: res.materialMarkups,
        totalPages: 1

      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getClientAndLocContactsById = async (params: any) => {
    try {
      const res = await repositories.locations.getClientAndLocContactsById(params)
      return {
        data: res.result,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as Response
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }
  const activateSiteByID = async (siteId: number, action: any) => {
    try {
      const res = await repositories.locations.activateSiteByID(siteId, action)
      return { data: res.location }
    } catch (err) {
      console.log(err)
      return { data: false, error: err }
    }
  }
  const deactivateSiteByID = async (param: any, action: any) => {
    try {
      const res = await repositories.locations.deactivateSiteByID(param, action)
      return { data: res.location }
    } catch (err) {
      console.log(err)
      return { data: false, error: err }
    }
  }

  const getClientsLocations = async (params:any) => {
    try {
      const res = await repositories.locations.getClientsLocations(params)
      return {
        data: res.locations,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as Response
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const locationsAutoComplete = async (params: AutocompletePayload) => {
    try {
      const res = await repositories.locations.locationsAutoComplete(params)
      return {
        data: res.results,
        nextPage: res.nextPage
      } as { data: AutocompleteResponse[], nextPage: number }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getLocationContacts = async (params:any) => {
    try {
      const res = await repositories.locations.getLocationContacts(params)
      return {
        data: res.contacts,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as Response
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getLocationUsers = async (params: any) => {
    try {
      const res = await repositories.locations.getLocationUsers(params)
      return {
        data: res.locationUsers,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  return {
    location,
    locations,
    inventoryLocation,
    loadLocations,
    loadLocationDetails,
    loadLocationTax,
    loadInventoryLocation,
    loadLocationsWithoutSaving,
    appendLocation,
    loadLocationEquipmentsURL,
    getLocations,
    getLocationsNearBy,
    getInvoiceableLocations,
    getBillableLocations,
    getBillableResourcesByLocation,
    getBillableTripsByLocAndResource,
    getInvoiceableResourcesByLocation,
    getInvoiceableTripsByLocAndResource,
    getInvoiceableLocsByClient,
    getMaterialMarkupsByLoc,
    getClientAndLocContactsById,
    activateSiteByID,
    deactivateSiteByID,
    getLocationById,
    getStates,
    getClientsLocations,
    locationsAutoComplete,
    getLocationContacts,
    getLocationUsers
  }
}
