import {
  INVOICE_NUMBER,
  INVOICE_CUSTOM_NUMBER,
  INVOICE_STATUS,
  INVOICE_DATE_OF_ISSUE,
  INVOICE_DUE_DATE
} from '@/constants/filters/resources/invoice'

import {
  WEATHER_EVENT,
  WEATHER_EVENT_START_DATE,
  WEATHER_EVENT_END_DATE
} from '@/constants/filters/resources/weatherEvent'

import {
  CLIENT_NAME,
  CLIENT_COUNTRY,
  CLIENT_STATE,
  CLIENT_CITY,
  CLIENT_ZIP,
  CLIENT_ADDRESS
} from '@/constants/filters/resources/client'

import {
  SITE_NAME,
  SITE_COUNTRY,
  SITE_CITY,
  SITE_ZIP,
  SITE_ADDRESS,
  SITE_REGION
} from '@/constants/filters/resources/site'
import { WORK_ORDER_NUMBER, WORK_ORDER_SERVICE, WORK_ORDER_TRADE } from '../resources/workOrder'
import { computed } from 'vue'
import { useSession } from '@/use/session'
import { BROKER, CLIENT } from '@/constants/permissions'

export const allReceivableInvoiceFilters = () => {
  const { session } = useSession()

  const availableSiteFilters = () => {
    const baseFilters: any = [
    // SITE_COUNTRY({ pinned: false, isPrimary: false }),
    // SITE_CITY({ pinned: false, isPrimary: false }),
    // SITE_ZIP({ pinned: false, isPrimary: false }),
    // SITE_ADDRESS({ pinned: false, isPrimary: false })
    // SITE_REGION({ pinned: false, isPrimary: false })
    ]
    if (session.currentCompanyType === BROKER || session.currentCompanyType === CLIENT) {
      baseFilters.push(SITE_NAME({ pinned: true, isPrimary: true }))
    }
    return baseFilters
  }

  const availableWeatherEventFilters = () => {
    const baseFilters: any = []
    if (session.currentCompanyType === BROKER) {
      baseFilters.push(
        WEATHER_EVENT({ pinned: false, isPrimary: false }),
        WEATHER_EVENT_START_DATE({ pinned: false, isPrimary: false }),
        WEATHER_EVENT_END_DATE({ pinned: false, isPrimary: false })
      )
    }
    return baseFilters
  }

  const availableWorkOrderFilters = () => {
    const baseFilters: any = [
      WORK_ORDER_NUMBER({ pinned: true, isPrimary: true })
    ]
    if (session.currentCompanyType === BROKER) {
      baseFilters.push(
        WORK_ORDER_SERVICE({ pinned: false, isPrimary: false }),
        WORK_ORDER_TRADE({ pinned: false, isPrimary: false })
      )
    }
    return baseFilters
  }

  return {
    filters: [
      INVOICE_NUMBER(true, { pinned: true, isPrimary: true }),
      INVOICE_CUSTOM_NUMBER(true, { pinned: false, isPrimary: false }),
      INVOICE_STATUS(false, { pinned: true, isPrimary: true }),
      INVOICE_DATE_OF_ISSUE(false, { pinned: false, isPrimary: false }),
      ...availableWeatherEventFilters(),
      INVOICE_DUE_DATE(false, { pinned: true, isPrimary: true }),
      CLIENT_NAME({ pinned: true, isPrimary: true }),
      ...availableSiteFilters(),
      ...availableWorkOrderFilters()
    ],
    sortBy: {
      value: 'date_of_issue',
      direction: {
        text: 'Descending',
        value: 'desc'
      },
      items: [
        {
          label: 'Status',
          value: 'status'
        },
        {
          label: 'Due Date',
          value: 'due_date'
        },
        {
          label: 'Date of Issue',
          value: 'date_of_issue'
        },
        {
          label: 'Invoice #',
          value: 'short_invoice_number'
        },
        {
          label: 'Amount Due',
          value: 'payable_balance_cents'
        }
      ]
    },
    fieldMapping: {
      'invoice-number': {
        name: 'short_invoice_number',
        field: 'value'
      },
      'invoice-custom-number': {
        name: 'custom_invoice_number',
        field: 'value'
      },
      'invoice-work-order-number': {
        name: 'work_orders_id',
        field: 'value'
      },
      'invoice-status': {
        name: 'status',
        field: 'value'
      },
      'invoice-date-of-issue': {
        name: 'date_of_issue',
        field: 'value'
      },
      'invoice-due-date': {
        name: 'due_date',
        field: 'value'
      },
      'weather-event': {
        name: 'event_id',
        field: 'value'
      },
      'weather-event-start-date': {
        name: 'event_start_date',
        field: 'value'
      },
      'weather-event-end-date': {
        name: 'invoice_resources_resource_of_WeatherWorks::StormDetail_type_storm_end',
        field: 'value'
      },
      'client-name': {
        name: 'company_name',
        field: 'value'
      },
      'client-country': {
        name: 'company_address_country',
        field: 'value'
      },
      'client-state': {
        name: 'company_address_state',
        field: 'value'
      },
      'client-city': {
        name: 'company_address_city',
        field: 'value'
      },
      'client-zip': {
        name: 'company_address_zip_code',
        field: 'value'
      },
      'client-address': {
        name: 'company_address_full_address',
        field: 'value'
      },
      'site-name': {
        name: 'locations_id',
        field: 'value'
      },
      'site-country': {
        name: 'locations_address_country',
        field: 'value'
      },
      'site-city': {
        name: 'locations_address_city',
        field: 'value'
      },
      'site-zip': {
        name: 'locations_address_zip_code',
        field: 'value'
      },
      'site-address': {
        name: 'locations_address_full_address',
        field: 'value'
      },
      'site-region': {
        name: 'locations_cc_territory_name',
        field: 'text'
      },
      'work-order-number': {
        linkingTables: {
          clients: 'work_orders',
          sites: 'work_orders',
          proposals: 'work_order',
          'work-orders': '',
          events: ''
        },
        name: 'work_order_id',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'id',
          weatherEventsName: 'id'
        },
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'work-order-service': {
        name: 'work_order_work_order_trade_services_trade_service_id',
        field: 'value'
      },
      'work-order-trade': {
        name: 'work_order_work_order_trades_trade_id',
        field: 'value'
      },
      'work-order-event-end-date': {
        name: 'invoice_resources_resource_of_WeatherWorks::StormDetail_type_storm_end_date',
        field: 'value'
      }
    }
  }
}
