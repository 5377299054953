import { SITE_NAME } from '@/constants/filters/resources/site'
import { CLIENT_NAME } from '@/constants/filters/resources/client'
import {
  PROPOSAL_NUMBER,
  PROPOSAL_CUSTOM_NAME,
  PROPOSAL_STATUS,
  PROPOSAL_DATE_OF_ISSUE,
  PROPOSAL_EXPIRATION_DATE,
  PROPOSAL_DEPARTMENT
} from '@/constants/filters/resources/proposal'
import {
  WORK_ORDER_NUMBER, WORK_ORDER_SERVICE, WORK_ORDER_TRADE
} from '@/constants/filters/resources/workOrder'
import { computed } from 'vue'
import { useSession } from '@/use/session'
import { BROKER, CLIENT } from '@/constants/permissions'
import { AutocompletePayload, AutocompleteResponse } from '@/types/interfaces/api-v2/autocomplete'
import { useOutboundProposals } from '@/use/proposals/outbound'

export const outboundProposalFilters = () => {
  const { session } = useSession()
  const { proposalsAutoComplete } = useOutboundProposals()

  const proposalsAutoCompleteFetchFn = async (params: AutocompletePayload) => {
    const { data, nextPage } = await proposalsAutoComplete(params) as { data: AutocompleteResponse[], nextPage: number }
    return {
      data: data.map((item: AutocompleteResponse, index: number) => {
        return {
          id: `${index}-${item.text}`,
          text: item.text,
          value: item.text
        }
      }),
      nextPage
    }
  }

  const availableProposalFilters = () => {
    const baseFilters: any = [
      PROPOSAL_NUMBER(true, { pinned: true, isPrimary: true }, { field: 'proposal_number' }, proposalsAutoCompleteFetchFn),
      PROPOSAL_CUSTOM_NAME(true, { pinned: false, isPrimary: false }, { field: 'custom_proposal_name' }, proposalsAutoCompleteFetchFn),
      PROPOSAL_STATUS(true, { pinned: true, isPrimary: true }),
      PROPOSAL_DATE_OF_ISSUE({ pinned: false, isPrimary: false }),
      PROPOSAL_EXPIRATION_DATE({ pinned: false, isPrimary: false })
    ]
    if (session.currentCompanyType === BROKER) {
      baseFilters.push(PROPOSAL_DEPARTMENT(true, { pinned: false, isPrimary: false }, { field: 'department' }, proposalsAutoCompleteFetchFn))
    }
    return baseFilters
  }

  const availableSiteFilters = () => {
    const baseFilters: any = []
    if (session.currentCompanyType === BROKER || session.currentCompanyType === CLIENT) {
      baseFilters.push(
        SITE_NAME({ pinned: true, isPrimary: true }, { field: 'site', invoiceables: false }, proposalsAutoCompleteFetchFn)
      )
    }
    return baseFilters
  }

  const availableWorkOrderFilters = () => {
    const baseFilters: any = [
      WORK_ORDER_NUMBER({ pinned: true, isPrimary: true }, { field: 'wo_id', invoiceables: false }, proposalsAutoCompleteFetchFn)
    ]
    if (session.currentCompanyType === BROKER) {
      baseFilters.push(
        WORK_ORDER_SERVICE({ pinned: false, isPrimary: false }, { field: 'trade_service' }, proposalsAutoCompleteFetchFn),
        WORK_ORDER_TRADE({ pinned: false, isPrimary: false }, { field: 'trade' }, proposalsAutoCompleteFetchFn)
      )
    }
    return baseFilters
  }

  return {
    filters: [
      CLIENT_NAME({ pinned: true, isPrimary: true }, { field: 'client', tab: 'active' }, proposalsAutoCompleteFetchFn),
      ...availableSiteFilters(),
      ...availableProposalFilters(),
      ...availableWorkOrderFilters()
    ],

    sortBy: {
      value: 'date_of_issue',
      direction: {
        text: 'Descending',
        value: 'desc'
      },
      items: [
        {
          label: 'Status',
          value: 'status'
        },
        {
          label: 'Proposal Date',
          value: 'proposal_date'
        },
        {
          label: 'Date of Issue',
          value: 'date_of_issue'
        },
        {
          label: 'Proposal #',
          value: 'estimate_number'
        },
        {
          label: 'Amount',
          value: 'total_cents'
        }
      ]
    },
    fieldMapping: {
      'client-name': {
        name: 'company_id',
        field: 'value'
      },
      'site-name': {
        name: 'location_id',
        field: 'value'
      },
      'site-region': {
        name: 'location_cc_territory_name',
        field: 'text'
      },
      'proposal-number': {
        name: 'estimate_number',
        field: 'value'
      },
      'proposal-custom-name': {
        name: 'custom_proposal_name',
        field: 'value'
      },
      'proposal-status': {
        name: 'status',
        field: 'value'
      },
      'proposal-date-of-issue': {
        name: 'date_of_issue',
        field: 'value'
      },
      'proposal-expiration-date': {
        name: 'proposal_date',
        field: 'value'
      },
      'proposal-client-name': {
        name: 'company_id',
        field: 'value'
      },
      'proposal-site-name': {
        name: 'location_id',
        field: 'value'
      },
      'po-number': {
        name: 'annexed_po_number',
        field: 'value'
      },
      department: {
        name: 'department_id',
        field: 'id'
      },
      'work-order-number': {
        name: 'work_order_id',
        field: 'value'
      },
      'work-order-service': {
        name: 'work_order_work_order_trade_services_trade_service_id',
        field: 'value'
      },
      'work-order-trade': {
        name: 'work_order_work_order_trades_trade_id',
        field: 'value'
      }
    }
  }
}
