import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    ref: "activator",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _setup.modalOpen = true;
      }
    }
  }), _c(VDialog, {
    attrs: {
      "fullscreen": _vm.isFullScreen,
      "persistent": _vm.persistent,
      "content-class": _vm.isFullScreen ? 'fullscreen' : 'uc-modal',
      "scrollable": _vm.scrollableContent.scrollable,
      "value": _setup.modalOpen,
      "overlay-opacity": "0.9",
      "close-delay": _vm.closeDelay,
      "hide-overlay": _vm.hideOverlay
    },
    on: {
      "click:outside": function ($event) {
        !_vm.persistent && _setup.close();
      }
    }
  }, [_c('uc-modal-card', _vm._b({
    staticStyle: {
      "max-height": "calc(90vh - 104px)"
    },
    on: {
      "close-card": function ($event) {
        _setup.close();
        _vm.$emit('close:cancel');
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, scopedSlotName) {
      return {
        key: scopedSlotName,
        fn: function (slotData) {
          return [_vm._t(scopedSlotName, null, null, slotData)];
        }
      };
    })], null, true)
  }, 'uc-modal-card', {
    ..._vm.modalCardProps,
    closable: _vm.closable,
    scrollableContent: _vm.scrollableContent
  }, false))], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };