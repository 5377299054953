import { api } from '@/api'
import qs from 'qs'

interface GetWorkOrdersParams {
  scope?: string
  include?: string
  q?: any
  page?: number
  perPage?: number
}

export default {
  getToDoWorkOrders: ({ scope, include, q, page, perPage }: GetWorkOrdersParams) => {
    return api.$get('/users/work_orders', {
      params: {
        scope,
        include,
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  }
}
