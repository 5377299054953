import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VListItem, _vm._g(_vm._b({
    staticClass: "uc-list-row",
    class: [{
      'back-row': _vm.isBackRow
    }, {
      'nested-row': _vm.nested
    }],
    style: `max-height: ${_vm.subText === '' ? '48px' : '64px'}`,
    attrs: {
      "two-line": _vm.subText !== ''
    }
  }, 'v-list-item', _vm.$attrs, false), _vm.$listeners), [_vm.icon !== '' ? _c(VListItemAvatar, [_vm.iconType === 'mdi' ? _c(VIcon, [_vm._v(" mdi-" + _vm._s(_vm.icon) + " ")]) : _vm._e(), _vm.iconType !== 'mdi' ? _c(VIcon, {
    staticClass: "fa",
    attrs: {
      "size": 16
    }
  }, [_vm._v(" " + _vm._s(_vm.icon) + " ")]) : _vm._e()], 1) : _vm._e(), _vm.subText !== '' ? _c(VListItemContent, [_c(VListItemTitle, {
    staticClass: "uc-list-row__two-line-title"
  }, [_vm._v(" " + _vm._s(_vm.text) + " ")]), _c(VListItemSubtitle, {
    staticClass: "uc-list-row__two-line-subtitle"
  }, [_vm._v(" " + _vm._s(_vm.subText) + " ")])], 1) : _c(VListItemTitle, {
    class: `${_vm.localTextColor}--text text-wrap`
  }, [_vm._v(" " + _vm._s(_vm.text) + " ")]), _vm.nested ? _c(VListItemIcon, {
    staticClass: "uc-list-row__nested"
  }, [_c(VIcon, {
    staticClass: "transparent--lg"
  }, [_vm._v(" mdi-chevron-right ")])], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };