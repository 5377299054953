import Vue, { defineAsyncComponent } from 'vue'
import ucListRowLoading from '@/components/uc/list-row/loading.vue'
export * from './store'
export * from './uc'

type ComponentPathMappings = { [key: string]: string }

/*
list of components that will be bound to the application on create
this should be things we do not want to async load pattern is
<file path from components folder hyphenated>: <file path from components folder with forward slashes>
*/
const components: ComponentPathMappings = {
  'uc-nested-drawer': 'uc/nested-drawer',
  'work-orders-modals-custom-status': 'work-orders/modals/custom-status',
  'trips-draft-trips-modals-schedule': 'trips/draft-trips/modals/schedule',
  'trips-draft-trips-modals-delete': 'trips/draft-trips/modals/delete',
  'trips-draft-trips-modals-email': 'trips/draft-trips/modals/email',
  'uc-table': 'uc/table'
}

for (const key in components) {
  Vue.component(key, () => import(`@/components/${components[key]}`))
}

export const getDynamicComponent = (componentPath: string) => defineAsyncComponent({
  loader: () => import(`@/components/${componentPath}`),
  loadingComponent: ucListRowLoading
})
