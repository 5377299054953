import { api } from '@/api'
import { baseGetParams } from '@/utils/api'
import qs from 'qs'
import { AutocompletePayload } from '@/types/interfaces/api-v2/autocomplete'
interface GetProposalsListParams {
  perPage?: number
  page: number
  q?: any
  s?: any,
  include?: any,
  proposalId?: number | string,
  updateContent?: any
}

interface updateProposalStatusParams {
  action:string,
  proposalId: number | string,
  reason: string,
  annexedPoNumber?: string,
  include?: any
}
export default {
  get: (params : any) => {
    const { page, perPage, q, include }: GetProposalsListParams = params
    return api.$get('/inbound_proposals', {
      params: {
        include,
        page,
        per_page: perPage,
        q
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  addNewInboundProposal: (proposal:FormData) => {
    return api.$post('/inbound_proposals', proposal, {
      headers: {
        content_type: 'multipart/form-data'
      }
    })
  },
  post: (params: any, requestBody: any) => {
    return api.$post('/inbound_proposals', {
      ...requestBody
    }, {
      params,
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  newV2: (params: any, requestBody: any) => {
    return api.$post('/inbound_proposals/new_v2', {
      ...requestBody
    }, {
      params,
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  sendInboundEmail: (body:any) => {
    return api.$post(`/inbound_proposals/${body.proposal_email.ids[0]}/send_email`, body)
  },
  patchInbound: (params : any) => {
    const { proposalId, include, perPage, q, page, updateContent }: GetProposalsListParams = params
    return api.$patch(`/inbound_proposals/${proposalId}`, { proposal: updateContent }, {
      params: {
        id: proposalId,
        include,
        page,
        per_page: perPage,
        q
      },
      paramsSerializer: (params:any) => {
        return qs.stringify(params)
      }
    })
  },
  updateInboundProposalStatus: ({ action, proposalId, reason, include, annexedPoNumber }:updateProposalStatusParams) => {
    return api.$patch(`inbound_proposals/${proposalId}/${action}`, {
      id: proposalId,
      include,
      reason,
      annexedPoNumber
    })
  },
  getById: (id: number, { include }: baseGetParams) => api.$get(`/inbound_proposals/${id}`, {
    params: {
      include
    },
    paramsSerializer: (params: any) => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  inboundBulkDestroy: ({ query }: { query: any }) => {
    return api.$delete('inbound_proposals/bulk_destroy', {
      params: {
        query
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  deleteInboundProposal: (proposalId: string, objectScope = '') => {
    return api.$delete(`/inbound_proposals/${proposalId}`, {
      params: {
        objectScope,
        include_grouped: true
      }
    })
  },
  inboundProposalsAutoComplete: (params: AutocompletePayload) => {
    const { field, terms, predicate, tab, roles, finderParams, page, perPage } = params
    return api.$get('/inbound_proposals/autocomplete', {
      params: {
        field,
        terms,
        predicate,
        tab,
        roles,
        finderParams,
        page,
        perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  inboundProposalsSearch: (params : any) => {
    const { page, perPage, q, include }: GetProposalsListParams = params
    return api.$post('/inbound_proposals/search', {
      include,
      page,
      per_page: perPage,
      q
    },
    {
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  }
}
