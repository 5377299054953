import { InternalAxiosRequestConfig, AxiosResponse } from 'axios'
import snakecaseKeys from 'snakecase-keys'
import camelcaseKeys from 'camelcase-keys'
import { API_URL, API_URL_INJECTED_RAILS, PROTOCOL } from '@/constants/env'
import { ref } from 'vue'

const subDomainForAPI = ref<string>('')
const companyIDForAPI = ref<number>(0)

export const setSubdomainForAPI = (subdomain:string) => {
  subDomainForAPI.value = subdomain
}

export const setCompanyIDForAPI = (companyId:number) => {
  companyIDForAPI.value = companyId
}
const createURL = (subdomain = '') => {
  return `${PROTOCOL}://${subdomain}${subdomain === ''
? API_URL_INJECTED_RAILS.substr(1, API_URL_INJECTED_RAILS.length - 1)
: API_URL_INJECTED_RAILS}`
}
const RAILS_URLS = ['/sign_out', '/login', '/landing']
const URL_TO_CHECK_SUBDOMAINFORAPI = ['/users/companies/accessible_features']
export const addBaseURL = (req: InternalAxiosRequestConfig) => ({
  ...req,
  baseURL: RAILS_URLS.includes(req.url || '')
    ? window.location.origin
    : (subDomainForAPI.value === '' || !URL_TO_CHECK_SUBDOMAINFORAPI.includes(req.url || '')
        ? API_URL
        : createURL(subDomainForAPI.value))
})

export const addCaseConverterForRequest = (req: InternalAxiosRequestConfig) => {
  if (req.data && !(req.data instanceof FormData)) {
    req.data = snakecaseKeys(req.data, { deep: true, exclude: ['g-recaptcha-response', /.*_[A-Z].*/, /^_.*/] })
  }
  if (req.params) {
    req.params = snakecaseKeys(req.params, { deep: true, exclude: ['g-recaptcha-response', /.*_[A-Z].*/, /^_.*/] })
  }
  return req
}

export const addCaseConverterForResponse = (res: AxiosResponse) => {
  if (res.data) {
    res.data = camelcaseKeys(res.data, { deep: true })
  }
  return res
}

export const addErrorNotifier = (error: any) => {
  // Show toastr messages from error
  throw error
}

export const applyPlugins = <T>(
  functions: ((arg0: T) => T)[],
  payload: T
): T => functions.reduce((res, cur) => cur(res), payload)

export const addAuthorization = (req: InternalAxiosRequestConfig) => {
  let headers:any = {
    'Content-Type': req?.headers?.content_type
  }
  if (companyIDForAPI.value !== 0) {
    headers = {
      ...headers,
      'COMPANY-ID': companyIDForAPI.value
    }
  }
  if (RAILS_URLS.includes(req.url || '')) {
    headers = {
      ...headers,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  }
  req.headers = { ...headers, ...req.headers }

  return req
}
