import { api } from '@/api'
import qs from 'qs'

interface GetAttachmentsParams {
  resourceId: string,
  resourceType: string,
  perPage?: number,
  page?: number,
  q?: any,
  s?: any,
  include?: any
  tripId?: number | string
  sendScSyncDetails?: boolean
  onlyWorkOrderAttachments?: boolean
}

interface PostAttachmentParams {
  resourceId: string,
  resourceType: string,
  payload: any
}

interface PatchAttachmentParams {
  resourceId: string,
  resourceType: string,
  payload: any,
  attachmentId: string
}

interface DeleteAttachmentParams {
  resourceId: string,
  resourceType: string,
  id: string | number
}
// resourceType: work_orders, invoices, inbound_invoices, etc
// resourceId: workOrder.id, invoice.id (selectedInvoice.value.id)

export default {
  get: (params: GetAttachmentsParams) => {
    return api.$get(`/${params.resourceType}/${params.resourceId}/attachments`, {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    })
  },
  post: async ({ resourceId, resourceType, payload }:PostAttachmentParams) => {
    return await api.$post(`/${resourceType}/${resourceId}/attachments`, payload)
  },
  patch: async ({ resourceId, resourceType, payload, attachmentId }:PatchAttachmentParams) => {
    return await api.$patch(`/${resourceType}/${resourceId}/attachments/${attachmentId}`, payload)
  },
  delete: async ({ resourceId, resourceType, id }:DeleteAttachmentParams) => {
    const resourceKey = `${resourceType.substring(-1)}_id'`
    return await api.$delete(`/${resourceType}/${resourceId}/attachments/${id}`, {
      params: {
        [resourceKey]: resourceId,
        id
      },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    })
  },
  syncAttachment: async ({ resourceId, resourceType, attachmentId, payload }:PatchAttachmentParams) => {
    return await api.$patch(`/${resourceType}/${resourceId}/attachments/${attachmentId}`, {
      work_order_attachment: {
        ...payload
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  getWorkOrderTradeAttachment: ({ resourceId, page, perPage, include, sendScSyncDetails }: GetAttachmentsParams) => {
    return api.$get(`work_orders/${resourceId}/trade_documents`, {
      params: {
        page,
        perPage,
        include,
        sendScSyncDetails
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  }

}
