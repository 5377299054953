import { reactive } from 'vue'
import repositories from '@/repositories'

const taxes = reactive({
  loading: false,
  list: []
})

export interface GetTaxesParams {
  locationId?: string | number
  clientId?: string | number
  invoiceId?: string | number
  addressId?: string | number
  tradeService?: string | number
  include?: string
  q?: any
  page?: number
  perPage?: number
  vendorId?: string | number
  objectScope?: 'both' | 'single' | 'multiple'
  tab?: string
}

export const useTaxes = () => {
  const loadTaxes = async (id:any) => {
    try {
      taxes.loading = true
      const res = await repositories.taxes.getTaxesByLoc(id)
      taxes.list = res.tax
      return res.tax
    } catch (err) {
      console.log(err)
    } finally {
      taxes.loading = false
    }
  }
  const getTax = async (params: GetTaxesParams) => {
    try {
      const res = await repositories.taxes.getTax(params)
      return {
        data: res.tax,
        totalPages: 1
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }
  const getTaxes = async (params: GetTaxesParams) => {
    try {
      const res = await repositories.taxes.getTaxes(params)
      return {
        data: res.taxes,
        totalPages: 1
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  return {
    taxes,
    loadTaxes,
    getTax,
    getTaxes
  }
}
