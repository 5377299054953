import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "uc-list",
    style: {
      ..._vm.styleObj,
      backgroundColor: `var(${_vm.backgroundColor})`
    },
    attrs: {
      "data-cy": _vm.dataCy
    }
  }, [_c('transition', {
    attrs: {
      "appear": "",
      "mode": 'out-in',
      "name": _vm.transitionType
    },
    on: {
      "enter": function ($event) {
        !_vm.firstTransition && _vm.calcHeight($event);
      },
      "after-enter": function ($event) {
        _vm.firstTransition && _vm.calcHeight($event);
      }
    }
  }, [_vm._l([0, 1], function (i) {
    return [_vm.alternator === !!i ? _c('uc-list-card', {
      key: i,
      ref: "menuCard",
      refInFor: true,
      attrs: {
        "background-color": _vm.backgroundColor,
        "fill-type": _vm.fillType,
        "width": !_vm.$vuetify.breakpoint.xsOnly ? _vm.contentWidth : '100vw',
        "height": _vm.height,
        "infinite-scroll": _vm.hasFetch,
        "has-next-page": _vm.hasNextPage
      },
      on: {
        "load": function ($event) {
          return _vm.getRowData();
        },
        "click-outside": function ($event) {
          return _vm.resetList();
        }
      },
      scopedSlots: _vm._u([(_vm.alternator === !!i ? _vm.currentHeader : _vm.previousHeader) && !_vm.currentRows.length ? {
        key: "no-data",
        fn: function () {
          return [_c('div', {
            staticClass: "animation-container overflow-hidden d-flex fill-height flex-column align-center justify-center"
          }, [_c('dotlottie-player', {
            staticClass: "lottie-animation",
            attrs: {
              "autoplay": "",
              "loop": "",
              "src": require('@/assets/animations/ConfusedOtto.lottie')
            }
          }), _c('uc-text', {
            staticClass: "my-3",
            attrs: {
              "size": 3,
              "text": 'No company found',
              "bold": true,
              "type": 'title'
            }
          })], 1)];
        },
        proxy: true
      } : null, (_vm.alternator === !!i ? _vm.currentHeader : _vm.previousHeader) ? {
        key: "header",
        fn: function () {
          return [_c('uc-list-row', {
            attrs: {
              "text": _vm.alternator === !!i ? _vm.currentHeader : _vm.previousHeader,
              "icon": 'chevron-left',
              "is-back-row": true
            },
            on: {
              "click": _vm.goBack
            }
          }), _c(VDivider, {
            staticClass: "divider"
          }), _vm.currentData.hasSearch ? _c('uc-search', {
            ref: "searchRef",
            refInFor: true,
            staticClass: "pa-2",
            attrs: {
              "value": _vm.searchValue,
              "placeholder": 'Search Company'
            },
            on: {
              "search-input": $event => {
                _vm.searchValue = $event;
                _vm.getRowData($event, true);
              }
            }
          }) : _vm._e()];
        },
        proxy: true
      } : null, {
        key: "rows",
        fn: function () {
          return [_vm._l(_vm.alternator === !!i ? _vm.currentRows : _vm.previousRows, function (row, j) {
            return [(row.render ? row.render() : true) ? _c('uc-list-row', {
              key: row.key || j,
              attrs: {
                "icon": row.icon,
                "text": row.text || '',
                "data-cy": "uc-list-row",
                "href": row.href && row.href || '',
                "icon-type": row.iconType,
                "sub-text": row.subText || '',
                "nested": row.type === 'nested',
                "text-color": row.textColor ? row.textColor : 'text'
              },
              on: {
                "contextmenu": function ($event) {
                  row.rightClickFn && row.rightClickFn(row, j);
                },
                "click": function ($event) {
                  _vm.rowClick(row, j);
                  _vm.$emit('click:row', row);
                  row.eventName && _vm.$emit(row.eventName);
                }
              }
            }) : _vm._e()];
          })];
        },
        proxy: true
      }], null, true)
    }) : _vm._e()];
  })], 2)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };