import { ColumnDefs } from '@/types/interfaces'

export const INTEGRATIONS_HEADER = () => {
  return {
    fontSize: 'lg',
    border: false,
    canSelect: true,
    showActions: false,
    canExpand: false,
    headers: [
      {
        label: 'Integration Partner',
        hideTextOnMobile: false,
        templateName: 'app_name',
        resourceField: 'name',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: {
          xs: 'flex',
          sm: '200',
          md: '250',
          lg: '300'
        }
      },
      {
        label: 'Description',
        hideTextOnMobile: false,
        templateName: 'app_description',
        resourceField: 'description',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: {
          xs: 'hide',
          sm: 'hide',
          md: 'flex',
          lg: 'flex'
        }
      }
    ]
  } as ColumnDefs
}

export const ADDONS_HEADER = () => {
  return {
    fontSize: 'lg',
    border: false,
    canSelect: true,
    showActions: false,
    canExpand: false,
    headers: [
      {
        label: 'Module Addons',
        hideTextOnMobile: false,
        templateName: 'app_name',
        resourceField: 'name',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: {
          xs: 'flex',
          sm: '200',
          md: '250',
          lg: '300'
        }
      },
      {
        label: 'Description',
        hideTextOnMobile: false,
        templateName: 'app_description',
        resourceField: 'description',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: {
          xs: 'hide',
          sm: 'flex',
          md: 'flex',
          lg: 'flex'
        }
      }
    ]
  } as ColumnDefs
}

export const SERVICES_HEADER = () => {
  return {
    fontSize: 'lg',
    border: false,
    canSelect: true,
    showActions: false,
    canExpand: false,
    headers: [
      {
        label: 'Services',
        hideTextOnMobile: false,
        templateName: 'app_name',
        resourceField: 'name',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: {
          xs: 'flex',
          sm: '200',
          md: '250',
          lg: '300'
        }
      },
      {
        label: 'Description',
        hideTextOnMobile: false,
        templateName: 'app_description',
        resourceField: 'description',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: {
          xs: 'hide',
          sm: 'flex',
          md: 'flex',
          lg: 'flex'
        }
      }
    ]
  } as ColumnDefs
}
