import repositories from '@/repositories'

export const useBillPayments = () => {
  const deleteBillPayments = async (params: any) => {
    try {
      const res: any = await repositories.billPayments.delete(params)
      return res
    } catch (err: any) {
      console.log(err)
      return err.data
    }
  }
  const getBillPayments = async (params: any) => {
    try {
      const res: any = await repositories.billPayments.get(params)
      return {
        data: res.billPayments,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      }
    } catch (err) {
      console.log(err)
    }
  }

  const postBillPayments = async (params: any, requestBody: any) => {
    try {
      const res = await repositories.billPayments.post(params, requestBody)
      return { data: res.billPayment, success: true }
    } catch (err: any) {
      console.error(err)
      return { data: false, success: false }
    }
  }
  const createBillPaymentCheck = async (params: any, requestBody:any) => {
    try {
      const res: any = await repositories.billPayments.postCheck(params, requestBody)
      return { data: res.cheque }
    } catch (err: any) {
      console.error(err)
      return { data: false }
    }
  }

  const getBillPaymentCheckDownloadPdf = async (params: any) => {
    try {
      const res: any = await repositories.billPayments.getCheckPdfDownloadById(params)
      return res
    } catch (err: any) {
      console.error(err)
      return false
    }
  }

  const billPaymentsSearch = async (params: any) => {
    try {
      const res: any = await repositories.billPayments.billPaymentsSearch(params)
      return {
        data: res.billPayments,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      }
    } catch (err) {
      console.log(err)
    }
  }

  return {
    deleteBillPayments,
    getBillPayments,
    postBillPayments,
    createBillPaymentCheck,
    getBillPaymentCheckDownloadPdf,
    billPaymentsSearch
  }
}
