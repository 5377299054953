import { mimeTypes } from '@/constants/attachments'

const emailRules = [
  (v:string) => !!v || 'E-mail or Phone is required',
  // copied from https://emailregex.com/
  (v:string) => {
    const singleEmail = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    // copied from : https://www.regextester.com/109204
    const multiEmail = /^([a-zA-Z0-9,!#\\$%&'\\*\\+/=\\?\\^_`\\{\\|}~-]+(\.[a-zA-Z0-9,!#\\$%&'\\*\\+/=\\?\\^_`\\{\\|}~-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.([a-zA-Z]{2,}))(((,)\s*)[a-zA-Z0-9,!#\\$%&'\\*\\+/=\\?\\^_`\\{\\|}~-]+(\.[a-zA-Z0-9,!#\\$%&'\\*\\+/=\\?\\^_`\\{\\|}~-]+)*@[a-zA-Z\-0-9]+(\.[a-zA-Z\-0-9]+)*\.([a-zA-Z]{2,}))*$/
    const regexArr = [singleEmail, multiEmail]
    const valid = regexArr.filter((regex:RegExp) => regex.test(v))
    return !!valid.length || 'Email must be valid'
  }

]
const emailRule = (v: any, required:boolean) => {
  let errorMessage = ''
  const requiredRules = required ? emailRules : [emailRules[1]]
  requiredRules.every((rule: any) => {
    const valid = (typeof rule(v) === 'boolean' && !!rule(v)) || (!required && !v)
    errorMessage = (!required && !v) ? '' : rule(v)
    return valid
  })
  // v-input via vuetify api docs will always enter error state when passed either 'false' or a string that represents an error message :   https://vuetifyjs.com/en/api/v-input/
  return errorMessage.length ? errorMessage : true
}
const nonRequiredEmailRule = (v:string) => {
  return emailRule(v, false)
}

const requiredEmailRule = (v:string) => {
  return emailRule(v, true)
}

const taxClassification = [
  (v:string) => !!v || 'Legal Tax Classification is required'
]
const stateOfRegistration = [
  (v:string) => !!v || 'State of Company Registration is required'
]
const einRules = [
  (v:string) => !(/[A-Za-z<>%!@&*?#();'".:$]/.test(v)) || 'Provide only numbers'
]

const websiteRules = [
  (v:string) => (/^(https?|ftp):\/\/(?:www\.)?([a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+)(?:\/[^\s]*)?$/i.test(v)) || 'Provide valid url'
]

// why is this reactive?
const companyNameRules = [
  (v: string) => { return !!v || 'Legal Company Name is required' },
  (v: string) => { return v.length >= 3 || 'Name is too short (minimum is 3 characters)' }
]

const DBARules = [
  (v: string) => !!v || 'DBA is required',
  (v:string) => !(/<>%!@&*?#();'".:$]/.test(v)) || 'Provide String or Numbers',
  (v: string) => v.length >= 3 || 'DBA is too short (minimum is 3 characters)'

]
const passwordRules = [
  (v:string) => !!v || 'Password is required',
  (v:string) => v.length >= 6 || 'Password must be 6 or more characters'
]

const fullNameRules = [
  (v:any) => !!v.trim() || 'Full Name is required',
  (v:any) => /(^[a-zA-Z]+( )[a-zA-Z]+(( )[a-zA-Z]+)?)+$/.test(v.trim()) || 'Provide valid Full Name'
]

const presenceRule = [
  (v:string) => !!v?.trim() || 'This field is required'
]

const filePresenceRule = [
  (v: File[]) => !!v.length || 'This field is required'
]

const fileTypeRule = [
  (v: File[]) => checkFileType(v)
]

const fileRules = (v: File[], required: boolean, fileMaxSize: number) => {
  let errorMessage = ''
  const fileSizeRule = [(v: File[]) => checkFileSize(v, fileMaxSize)]
  const requiredRules = required ? [...filePresenceRule, ...fileTypeRule, ...fileSizeRule] : [...fileTypeRule, ...fileSizeRule]
  requiredRules.every((rule: any) => {
    errorMessage = errorMessage.length ? errorMessage : ((!required && !v) ? '' : rule(v))
    return true
  })
  return errorMessage.length ? errorMessage : true
}

const checkFileSize = (v: File[], fileMaxSize: number) => {
  const sizeExceededFiles = v.filter((v: File) => { return v.size > fileMaxSize })
  return sizeExceededFiles.length && 'File max size exceeded!'
}

const checkFileType = (v: File[]) => {
  const incompatibleFiles = v.filter((v: File) => { return v.type && !(mimeTypes.includes(v.type) || mimeTypes.includes(`${v.type?.split('/')[0]}/*`)) })
  return incompatibleFiles.length && 'Unsupported files!'
}

export const useRules = () => {
  return {
    emailRules,
    DBARules,
    passwordRules,
    companyNameRules,
    presenceRule,
    fullNameRules,
    websiteRules,
    emailRule,
    nonRequiredEmailRule,
    requiredEmailRule,
    filePresenceRule,
    fileRules
  }
}

export default {
  stateOfRegistration,
  emailRules,
  DBARules,
  websiteRules,
  einRules,
  passwordRules,
  presenceRule,
  companyNameRules,
  fullNameRules,
  taxClassification,
  emailRule,
  nonRequiredEmailRule,
  requiredEmailRule
}
