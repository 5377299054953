import { api } from '@/api'
import qs from 'qs'

interface getAllDraftTripsParams {
  include?: string
  q?: any
  page?: number
  perPage?: number
}

interface updateDraftTripParams {
  id: number
  startDate: string | Date,
  endDate: string | Date
  include?: string
  q?: any
}

interface fetchDraftTripEmailsParams {
  id: number
  include?: string
  q?: any
  page?: number
  perPage?: number
}

interface sendEmailParams {
  id: number
  to: string[],
  cc?: string[]
  bcc?: string[]
  contractor?: boolean
}

export default {
  index: ({ include, q, page, perPage }: getAllDraftTripsParams) => {
    return api.$get('/draft_trips', {
      params: {
        include,
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  update: ({ id, startDate, endDate, include, q }: updateDraftTripParams) => {
    return api.$patch(`/draft_trips/${id}`, {
      draftTrip: {
        id,
        startDate,
        endDate,
        include,
        q
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  destroy: (id: number | string) =>
    api.$delete(`/draft_trips/${id}`),
  createTrip: (id: number) => {
    return api.$get(`/draft_trips/${id}/create_trip`, {
      params: { id },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  resourcesList: ({ id, include, q, page, perPage }: fetchDraftTripEmailsParams) => {
    return api.$get(`/draft_trips/${id}/resources_list`, {
      params: {
        include,
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  sendReminderEmail: ({ id, to, cc, bcc, contractor }: sendEmailParams) => {
    return api.$get(`/draft_trips/${id}/send_reminder_email`, {
      params: {
        to,
        cc,
        bcc,
        contractor
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  }
}
