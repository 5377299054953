var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('uc-input', {
    staticClass: "uc-search",
    attrs: {
      "append-icon": _setup.localValue === '' ? 'mdi-magnify' : null,
      "value": _setup.localValue,
      "label": _vm.label,
      "loading": _setup.loading,
      "clearable": true,
      "disabled": _vm.disabled,
      "placeholder": _vm.placeholder,
      "hide-details": true,
      "autofocus": _vm.autofocus,
      "label-variant": _vm.labelVariant,
      "dense": _vm.dense
    },
    on: {
      "input": function ($event) {
        return _setup.debounceWrapper($event);
      },
      "clear": function ($event) {
        return _vm.$emit('search-clear');
      },
      "paste": function ($event) {
        $event.preventDefault();
        return _setup.onPaste.apply(null, arguments);
      },
      "blur": function ($event) {
        return _vm.$emit('blur');
      }
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };