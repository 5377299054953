export const SUCCESS = 'mdi-check-circle-outline'
export const EXPORT = 'mdi-download'
export const ERROR = 'mdi-close-circle-outline'
// export const REJECTED = 'mdi-sticker-alert'
export const DRAFT = 'mdi-text-box'
export const DELETE = 'mdi-delete'
export const INFO = 'mdi-information'
export const INFO_OUTLINE = 'far fa-info-circle fa-fw'
export const PDF_DOWNLOAD = 'mdi-file-pdf-box'
export const CURRENCY = 'mdi-currency-usd'
export const CLIPBOARD_ALERT = 'mdi-clipboard-alert'
export const CREATE = 'mdi-creation'
export const ANALYTICS = 'fa-analytics'
export const INBOX = 'mdi-inbox-arrow-down'
export const TEXT_CHECK = 'mdi-text-box-check'
export const SEND = 'mdi-send'
export const SHARE = 'mdi-share-variant'
export const HELP = 'mdi-help-circle-outline'
export const REJECTED_ALERT = 'mdi-alert-circle-outline'
export const DATE_EXPIRED = 'mdi-calendar-remove-outline'
export const PRINT = 'mdi-printer'
export const RECEIVED = 'mdi-email'
export const CLOSE = 'mdi-close-box'
export const DRAWER_OPENED = 'fas fa-caret-up fa-fw'
export const DRAWER_CLOSED = 'fas fa-caret-down fa-fw'
export const ALERT = 'mdi-alert'
export const WEATHER_CLOUD = 'mdi-weather-cloudy'
export const CREDIT_CARD = 'mdi-credit-card'
export const BANK = 'mdi-bank'
export const TRANSFER = 'fa-sort fa-fw'
export const NESTED_LIST = 'fa-list-tree fa-fw'
export const PAYMENT = 'mdi-cash-sync'
export const EXIT = 'fal fa-times fa-fw'
export const SYNC = 'mdi-sync'
export const BLOCK_HELPER = 'mdi-block-helper'
export const ICON_ICE = 'cube-outline'
export const ICON_SNOW = 'snowflake'
export const GENERATE_INVOICE = 'mdi-receipt-text-plus'
export const ICON_TEAM_MEMBER = 'mdi-account'
export const ICON_ROLE = 'mdi-badge-account-horizontal'
export const ICON_SITE = 'mdi-office-building'
export const ICON_VENDOR = 'mdi-truck'
export const ICON_PEOPLE = 'mdi-account-circle'
export const ICON_CLIENT = 'mdi-account-group'
export const ICON_CREW = 'mdi-account-multiple'
export const ICON_MY_SITES = 'mdi-home'
export const ICON_MANAGED_SITES = 'mdi-map-marker'
export const ICON_ACTIVE_VENDOR = 'mdi-check-circle'
export const OPEN_IN_NEW_TAB = 'mdi-open-in-new'
export const ACCOUNT_CASH = 'mdi-account-cash'
export const LIGHTNING_BOLT = 'mdi-lightning-bolt'
export const IMAGE_MULTIPLE_OUTLINE = 'mdi-image-multiple-outline'
export const SHOW_TEXT_EYE = 'mdi-eye-outline'
export const HIDE_TEXT_EYE = 'mdi-eye-off-outline'
export const CHECK_DECAGRAM = 'mdi-check-decagram'
export const TRASH_ICON = 'mdi-trash-can'
export const SQUARE_EDIT_ICON = 'mdi-square-edit-outline'
export const ADD_ICON = 'mdi-plus-circle'
export const CART_OUTLINE = 'mdi-cart-outline'
export const ICON_WARNING = 'mdi-alert-outline'
export const REVIEW = 'mdi-file-find'
export const ICON_TEMPLATES = 'mdi-pencil-plus'
export const FILE_DOWNLOAD = 'mdi-file-download'
export const FILE_UPLOAD = 'mdi-file-upload'
export const PLUS = 'fas fa-plus fa-fw'
export const BULLSEYE = 'mdi-bullseye'
export const ATOM = 'mdi-atom'
export const OFFICE_BUILDING = 'mdi-office-building'
export const MULTIPLE_PLUS = 'mdi-account-multiple-plus'
export const WEATHER_NIGHT = 'mdi-weather-night'
export const WEATHER_SUNNY = 'mdi-white-balance-sunny'
export const COG_BOX = 'cog-box'
export const ICON_ROCKET = 'rocket'
export const ICON_FORMAT_ALIGN_LEFT = 'mdi-format-align-left'
export const ICON_LIST_BULLETED_SQUARE = 'mdi-format-list-bulleted-square'
export const ICON_MULTIPLE_OUTLINE = 'mdi-image-multiple-outline'
export const ICON_LIGHTNING_BOLT = 'mdi-lightning-bolt'
export const ICON_COMMENT_OUTLINE = 'mdi-comment-outline'
export const ICON_DOT_VERTICAL = 'mdi-dots-vertical'
export const ICON_BACK_BURGER = 'mdi-backburger'
export const ICON_MENU = 'mdi-menu'
export const CLOUD_UPLOAD = 'mdi-cloud-upload'
export const PHONE = 'mdi-phone'
export const MONITOR = 'mdi-desktop-mac'
export const CLOCK_OUTLINE = 'mdi-clock-outline'
export const NO_DOCUMENT = 'mdi-file-document-remove-outline'
export const CONTENT_SAVE = 'mdi-content-save'
export const ICON_FILTER = 'mdi-filter'
export const PIN = 'mdi-pin'
export const ICON_MULTIPLE_CASH = 'mdi-cash-multiple'
export const SEARCH = 'mdi-magnify'
export const ACCOUNT_LOCK = 'mdi-account-lock'
export const ICON_CANCELLED = 'mdi-cancel'
export const ICON_ARROW_UP_BOLD_CIRCLE_OUTLINE = 'mdi-arrow-up-bold-circle-outline'
export const ICON_ARROW_DOWN_BOLD_CIRCLE_OUTLINE = 'mdi-arrow-down-bold-circle-outline'
export const ICON_PACKAGE_VARIANT_CLOSED = 'mdi-package-variant-closed'
export const ICON_INVOICE_DOLLAR = 'fa-file-invoice-dollar'
export const WEATHER_LIGHTNING = 'mdi-weather-lightning'
export const CHECKED_IN = 'mdi-clock-time-four'
export const FILE_LINES = 'mdi-file-document-outline'
export const TRANSIT_CONNECTION_HORIZONTAL = 'mdi-transit-connection-horizontal'
export const ICON_ACTIVE = 'mdi-check'
export const CHECK_BOLD = 'mdi-check-bold'
export const ICON_HELP_CIRCLE = 'mdi-help-circle'
export const CLOSE_OCTAGON = 'mdi-close-octagon'
export const TOOLS = 'fa-tools fa-fw'
export const ACCOUNT_CHECK = 'mdi-account-check'
export const ACCOUNT_HARD_HAT = 'mdi-account-hard-hat'
export const EYE = 'mdi-eye'
export const CLIPBOARD_ACCOUNT = 'mdi-clipboard-account'
export const TIMER_OUTLINE = 'mdi-timer-outline'
export const LOGOUT_VARIANT = 'mdi-logout-variant'
export const TEXT_BOX_CHECK_OUTLINE = 'mdi-text-box-check-outline'
export const NOTE_MULTIPLE = 'mdi-note-multiple'
export const RECEIPT = 'mdi-receipt'
export const HANDSHAKE = 'mdi-handshake'
export const BRIEFCASE_PLUS = 'mdi-briefcase-plus'
export const DATABASE = 'mdi-database'
export const DOTS_HORIZONTAL = 'mdi-dots-horizontal'
export const CART = 'mdi-cart'
export const CARD_ACCOUNT_DETAILS = 'mdi-card-account-details'
export const ACCOUNT_WRENCH = 'mdi-account-wrench'
export const ARROW_LEFT_CIRCLE = 'fa-arrow-circle-left fa-fw'
export const ARROW_RIGHT_CIRCLE = 'fa-arrow-circle-right fa-fw'
export const ICON_SETTING = 'mdi-cog'
export const CHECK = 'fa-check fa-fw'
export const ICON_DOT_GRID = 'mdi-dots-grid'
export const MOVING_TRUCK = 'mdi-truck-fast'
export const FILE_DOCUMENT_MULTIPLE_OUTLINE = 'mdi-file-document-multiple-outline'
export const FILE_ALERT_OUTLINE = 'mdi-file-alert-outline'
export const FILE_SIGN = 'mdi-file-sign'
export const FILE_ACCOUNT_OUTLINE = 'mdi-file-account-outline'
export const SUB_ARROW_RIGHT = 'mdi-subdirectory-arrow-right'
export const INVOICE_DOLLAR = 'fa-file-invoice-dollar fa-fw'
export const SNOWFLAKE = 'fa-snowflake fa-fw'
export const IMAGE = 'fa-image fa-fw'
export const INFO_CIRCLE = 'fa-info-circle fa-fw'
export const DOLLY_FLATBED = 'fa-dolly-flatbed-alt fa-fw'
export const MAXIMIZE_BOTTOM_PANEL = 'fal fa-arrow-to-top fa-fw'
export const MAXIMIZE_TOP_PANEL = 'fal fa-arrow-to-bottom fa-fw'
export const RESTORE_SIZE_FROM_BOTTOM_PANEL = 'fal fa-arrow-from-top fa-fw'
export const RESTORE_SIZE_FROM_TOP_PANEL = 'fal fa-arrow-from-bottom fa-fw'
export const MAXIMIZE_LEFT_PANEL = 'fa-arrow-to-right fa-fw'
export const RESTORE_FROM_LEFT_PANEL = 'fa-arrow-from-right fa-fw'
export const ACTIONS_REQUIRED = 'fal fa-exclamation-triangle fa-fw'
export const WORK_ORDER_STATE_CANCELED = 'fa-times-octagon fa-fw'
export const WORK_ORDER_STATE_CLOSED_ARCHIVED = 'fa-box-check fa-fw'
export const WORK_ORDER_STATE_COMPLETED = 'fa-check-circle fa-fw'
export const WORK_ORDER_STATE_ACTION_REQUIRED = 'fa-file-exclamation fa-fw'
export const WORK_ORDER_STATE_IN_PROGRESS = 'fa-circle-notch fa-fw'
export const TV_ALT = 'fas fa-tv-alt fa-fw'
export const USERS_CLASS = 'fad fa-users-class fa-fw'
export const MOBILE = 'far fa-mobile fa-fw'
export const SNOWFLAKES = 'fas fa-snowflakes fa-fw'
export const HARDHAT = 'fas fa-hard-hat fa-fw'
export const MAIL_BOX = 'fas fa-mailbox'
export const PAPER_PLAN = 'fas fa-paper-plane'
export const CHECK_CIRCLE = 'far fa-check-circle'
export const PRINT_MAGNIFYING_GLASS = 'fas fa-print-search'
export const PRINT_SLASH = 'fas fa-print-slash'
export const CIRCLE_EXCLAMATION = 'far fa-exclamation-circle'
export const MESSAGE_DOTS = 'fas fa-comment-alt-dots'
export const FILE_CROSS = 'fas fa-file-times'
export const FILE_EDIT = 'fas fa-file-edit'
export const FOLDER_UPLOAD = 'far fa-folder-upload'
export const PAPER_CLIP = 'far fa-paperclip'
export const FILE_INVOICE_ICON = 'fas fa-file-invoice-dollar'
export const FILE_LINE_ICON = 'fas fa-file-alt'
export const MAP_MARKER_ALT = 'far fa-map-marker-alt fa-fw'
export const USER_HEADSET = 'fas fa-user-headset fa-fw'
export const PAYMENT_BILL = 'fas fa-money-bill'
