import { ref } from 'vue'
import { RequiredActionResourceTypes } from '@/types/enums/required-action/resource-types'
import { useInboundProposals } from '../proposals/inbound'
import { useOutboundProposals } from '@/use/proposals/outbound'
import { useOutboundPurchaseOrders } from '../purchase-orders/outbound'
import { useInboundInvoices } from '../invoices/inbound'
import { useOutboundInvoices } from '../invoices/outbound'
import { INVOICE_RESOURCE_TYPE, PO_RESOURCE_TYPE, PROPOSAL_RESOURCE_TYPE, TRIP, WO_RESOURCE_TYPE } from '@/constants/resource-types'
import { useTrips } from '../trips'
import { useWorkOrders } from '../work-orders'
import { WorkOrder } from '@/types/interfaces'
import { RequiredAction } from '@/types/interfaces/api-v2/required-action'
import { useDetailsPanel } from '../details-panel'
import { useProposals } from '../proposals'
import { useInvoices } from '../invoices'
import { usePurchaseOrders } from '../purchase-orders'
import { useSession } from '../session'

const { getTripById, getTripsActions } = useTrips()

const DEFAULT_START_INDEX = 0
const currentResourceIndex = ref(DEFAULT_START_INDEX)
const currentBulkActions = ref<RequiredAction[]>([])
const currentWorkOrder = ref<WorkOrder>()

export const useWorkOrderResolve = () => {
  const openResourceForResolve = async (
    workOrder: WorkOrder,
    requiredAction: RequiredAction[],
    currentIndex = DEFAULT_START_INDEX
  ) => {
    const { userHaveAccess } = useSession()
    let filteredActions = requiredAction
    if (!userHaveAccess('invoice', '')) {
      filteredActions = filteredActions.filter(action => action.resourceType !== RequiredActionResourceTypes.INVOICE)
    }
    if (!userHaveAccess('proposal', '')) {
      filteredActions = filteredActions.filter(action => action.resourceType !== RequiredActionResourceTypes.PROPOSAL)
    }
    if (!userHaveAccess('purchase_order', '')) {
      filteredActions = filteredActions.filter(action => action.resourceType !== RequiredActionResourceTypes.PURCHASE_ORDER)
    }
    if (!userHaveAccess('work_orders', '')) {
      filteredActions = filteredActions.filter(action => action.resourceType !== RequiredActionResourceTypes.TRIP)
    }
    currentResourceIndex.value = currentIndex
    currentWorkOrder.value = workOrder
    currentBulkActions.value = filteredActions
    const { getWorkOrderById, getWorkOrderActions } = useWorkOrders()
    const { getProposalById } = useOutboundProposals()
    const { getInboundProposalById } = useInboundProposals()
    const { getInvoiceById } = useOutboundInvoices()
    const { getInboundInvoiceById } = useInboundInvoices()
    const { getPurchaseOrderById } = useOutboundPurchaseOrders()
    const { allProposalActions } = useProposals()
    const { allInvoiceActions } = useInvoices()
    const { allOutboundPurchaseOrderActions } = usePurchaseOrders()
    const currentAction = filteredActions[currentResourceIndex.value]
    const isOutbound = !currentAction.resourceIsReceived

    const detailsPanelData: Record<string, any> = {
      WorkOrder: {
        fetchFn: getWorkOrderById,
        actions: getWorkOrderActions(),
        detailsPanelPath: 'work-orders/details',
        localDetailsPanelComponentProps: { resourceType: WO_RESOURCE_TYPE }
      },
      Trip: {
        fetchFn: getTripById,
        actions: getTripsActions(),
        detailsPanelPath: 'trips/details',
        localDetailsPanelComponentProps: { resourceType: TRIP }
      },
      Proposal: {
        fetchFn: isOutbound ? getProposalById : getInboundProposalById,
        actions: allProposalActions(isOutbound),
        detailsPanelPath: 'uc/resource-details',
        localDetailsPanelComponentProps: { resourceType: PROPOSAL_RESOURCE_TYPE }
      },
      PurchaseOrder: {
        fetchFn: getPurchaseOrderById,
        actions: allOutboundPurchaseOrderActions(),
        detailsPanelPath: 'uc/resource-details',
        localDetailsPanelComponentProps: { resourceType: PO_RESOURCE_TYPE }
      },
      Invoice: {
        fetchFn: isOutbound ? getInvoiceById : getInboundInvoiceById,
        actions: allInvoiceActions(isOutbound),
        detailsPanelPath: 'uc/resource-details',
        localDetailsPanelComponentProps: { resourceType: INVOICE_RESOURCE_TYPE }
      }
    }
    goToNextResource(currentAction, detailsPanelData, filteredActions, workOrder)
  }

  const goToNextResource = async (currentAction: RequiredAction, detailsPanelData: Record<string, any>, actions: RequiredAction[], workOrder: WorkOrder) => {
    const isOutbound = !currentAction.resourceIsReceived
    const resourceDetailsPanelData = detailsPanelData[currentAction.resourceType]
    let response = { data: false }
    // Below API we are calling without include parameters as in Resource details panel It will get called with include parameters
    switch (currentAction?.resourceType) {
      case RequiredActionResourceTypes.WORK_ORDER:
        response = await resourceDetailsPanelData.fetchFn({
          workOrderId: currentAction.resourceId,
          include: ''
        })
        break
      case RequiredActionResourceTypes.TRIP:
        response = await resourceDetailsPanelData?.fetchFn(workOrder.id, currentAction.resourceId, { include: '' })
        break
      case RequiredActionResourceTypes.INVOICE:
        response = await resourceDetailsPanelData?.fetchFn(currentAction.resourceId, { objectScope: 'both', include: '' })
        break
      case RequiredActionResourceTypes.PROPOSAL:
        response = await resourceDetailsPanelData?.fetchFn(currentAction.resourceId, '')
        break
      case RequiredActionResourceTypes.PURCHASE_ORDER:
        response = await resourceDetailsPanelData?.fetchFn(currentAction.resourceId, '')
        break
    }
    if (!response.data) {
      return
    }
    const detailsProps = {
      totalResources: actions.length,
      inCreateResourceFlow: true,
      currentResourceIndex: currentResourceIndex.value,
      outbound: isOutbound,
      inBulkActionFlow: true,
      canDoubleClickToEdit: true,
      message: currentAction.message,
      resourceType: resourceDetailsPanelData.localDetailsPanelComponentProps.resourceType,
      actions: resourceDetailsPanelData.actions
    }
    if (currentResourceIndex.value === DEFAULT_START_INDEX) {
      useDetailsPanel().openGlobalDetailsPanel(resourceDetailsPanelData.detailsPanelPath, detailsProps, 'setSelectedResources', [response.data], { 'change-resource': goToNextTripResource })
    } else {
      useDetailsPanel().updateGlobalDetailsPanel(detailsProps, 'setSelectedResources', [response.data], resourceDetailsPanelData.detailsPanelPath)
    }
  }

  const goToNextTripResource = (index = 0) => {
    currentResourceIndex.value = index
    openResourceForResolve(currentWorkOrder.value as WorkOrder, currentBulkActions.value, index)
  }
  return {
    openResourceForResolve
  }
}
