import { DetailsPanelButtonState } from '@/types/enums'
export const inboundActionData: any[] = [
  {
    name: 'Approve',
    validStatuses: [
      'sent'
    ],
    primaryAction: [
      'sent'
    ],
    textColor: 'success'
  },
  {
    name: 'Reject',
    validStatuses: [
      'sent'
    ],
    primaryAction: [
    ],
    textColor: 'error'
  },
  {
    name: 'Close',
    validStatuses: [
      'accepted'
    ],
    primaryAction: [
      'accepted'
    ]
  },
  {
    name: 'Skip',
    primaryAction: [
      'skip'
    ],
    validStatuses: true,
    textColor: 'error',
    detailsPanel: DetailsPanelButtonState.Only
  }
]
