import { AutocompletePayload, AutocompleteResponse } from '@/types/interfaces/api-v2/autocomplete'
import { useCompanies } from '@/use/companies'
import { useTrips } from '@/use/trips'

const { tripsAutoComplete } = useTrips()
const { companiesAutoComplete } = useCompanies()

const tripsAutoCompleteFetchFn = async (params: AutocompletePayload) => {
  const { data, nextPage } = await tripsAutoComplete({ ...params }) as { data: AutocompleteResponse[], nextPage: number }
  return {
    data: data.map((item: AutocompleteResponse, index: number) => {
      return {
        id: `${index}-${item.text}`,
        text: item.text,
        value: item.text
      }
    }),
    nextPage
  }
}

const companiesAutoCompleteFetchFn = async (params: AutocompletePayload) => {
  const { data, nextPage } = await companiesAutoComplete({ ...params }) as { data: AutocompleteResponse[], nextPage: number }
  return {
    data: data.map((item: AutocompleteResponse, index: number) => {
      return {
        id: `${index}-${item.text}`,
        text: item.text,
        value: item.text
      }
    }),
    nextPage
  }
}

const EXTERNAL_REFERENCES_INTEGRATION_PLATFORM = (config = {}, params = { field: 'integration_platforms' }, fetchFn: any = companiesAutoCompleteFetchFn) => {
  return {
    text: 'Integration Platform',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'external-references-integration-platform-filter',
      headerText: 'Integration Platform',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['integration_platforms']
    },
    pinned: false,
    isPrimary: false,
    ...config
  }
}

const EXTERNAL_REFERENCES_EXTERNAL_ID = (config = {}, params = { field: 'external_ref_id' }, fetchFn: any = tripsAutoCompleteFetchFn) => {
  return {
    text: 'External ID',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'external-references-external-id-filter',
      headerText: 'External ID',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: []
    },
    pinned: false,
    isPrimary: false,
    ...config
  }
}

export {
  EXTERNAL_REFERENCES_INTEGRATION_PLATFORM,
  EXTERNAL_REFERENCES_EXTERNAL_ID
}
