import repositories from '@/repositories/index'
import { baseGetParams } from '@/utils/api'
interface postAttendanceCheckInParams extends baseGetParams {
  assigneeId: number
  assigneeType: 'User' | 'Tradesmen'
}

interface patchAttendanceCheckOutParams {
  id: string | number,
  note?: string
}

export const useAttendances = () => {
  // will return 403 if called on user who has already checked in
  const postAttendanceCheckIn = async (params: postAttendanceCheckInParams) => {
    try {
      const res = await repositories.attendances.postCheckIn(params)
      return res
    } catch (err: any) {
      console.error(err)
      return {}
    }
  }
  const patchAttendanceCheckOut = async (params: patchAttendanceCheckOutParams) => {
    try {
      const res = await repositories.attendances.patchCheckOut(params.id, params.note || '')
      return res
    } catch (err: any) {
      console.error(err)
      return {}
    }
  }
  return {
    postAttendanceCheckIn,
    patchAttendanceCheckOut
  }
}
