import { defineComponent, computed, watch } from 'vue';
import _ from 'lodash';
export default defineComponent({
  props: {
    showSelectAll: {
      type: Boolean,
      default: true
    },
    isBulkSelected: {
      type: Boolean,
      default: false
    },
    actions: {
      type: Array,
      default: () => []
    },
    buttonMenuProps: {
      type: Object,
      default: null
    },
    noneSelected: {
      type: Boolean,
      default: false
    },
    isAnySelected: {
      type: Boolean,
      default: false
    }
  },
  setup: (props, {
    emit
  }) => {
    watch([() => props.isBulkSelected], () => {
      emit('update:bulk-selection-data', {
        isSelectAll: props.isBulkSelected
      });
    });
    const calculatedActions = computed(() => {
      return props.actions.map(action => {
        return {
          ...action
        };
      });
    });
    const hasButtonMenu = computed(() => {
      return !!props.buttonMenuProps && Object.keys(props.buttonMenuProps).length;
    });
    const menuListenerObj = computed(() => {
      var _props$buttonMenuProp, _props$buttonMenuProp2, _props$buttonMenuProp3, _props$buttonMenuProp4;
      const temp = {};
      if (Array.isArray((_props$buttonMenuProp = props.buttonMenuProps) === null || _props$buttonMenuProp === void 0 ? void 0 : (_props$buttonMenuProp2 = _props$buttonMenuProp.menuData) === null || _props$buttonMenuProp2 === void 0 ? void 0 : _props$buttonMenuProp2.rows)) {
        props.buttonMenuProps.menuData.rows.forEach(row => {
          const tempFn = () => {
            emit(`${_.kebabCase(row.eventName || row.text)}`);
          };
          temp[`${_.kebabCase(row.eventName || row.text)}`] = tempFn;
        });
      }
      if (typeof (props === null || props === void 0 ? void 0 : (_props$buttonMenuProp3 = props.buttonMenuProps) === null || _props$buttonMenuProp3 === void 0 ? void 0 : (_props$buttonMenuProp4 = _props$buttonMenuProp3.buttonProps) === null || _props$buttonMenuProp4 === void 0 ? void 0 : _props$buttonMenuProp4.text) === 'string') {
        temp[`${_.kebabCase(props.buttonMenuProps.buttonProps.eventName || props.buttonMenuProps.buttonProps.text)}`] = () => emit(`${_.kebabCase(props.buttonMenuProps.buttonProps.eventName || props.buttonMenuProps.buttonProps.text)}`);
      }
      return temp;
    });
    const isIndeterminate = computed(() => props.isBulkSelected ? false : props.isAnySelected);
    return {
      calculatedActions,
      hasButtonMenu,
      menuListenerObj,
      isIndeterminate
    };
  }
});