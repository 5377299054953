import { reactive, ref, h } from 'vue'
import repositories from '@/repositories'

import { INFO, SUCCESS, SHARE, BLOCK_HELPER, ERROR } from '@/constants/icons'
import { startCase } from 'lodash'
import { useUsersCompanies } from '@/use/users-companies'
import { useTransactionResources } from '@/use/transactional-resources'
import { useToasts } from './toasts'
import { useListRow } from './list-rows'
import { DetailsPanelButtonState, DetailsPanelVisibility } from '@/types/enums'
import { AutocompletePayload, AutocompleteResponse } from '@/types/interfaces/api-v2/autocomplete'
import { useDetailsPanel } from './details-panel'
import { useWorkOrders } from './work-orders'
import { INVOICE_RESOURCE_TYPE, PROPOSAL_RESOURCE_TYPE, WO_RESOURCE_TYPE } from '@/constants/resource-types'
import { useOutboundInvoices } from './invoices/outbound'
import { useOutboundProposals } from './proposals/outbound'
import { useApp } from './app'
import { PROTOCOL } from '@/constants/env'
import { useFeatureFlags } from './feature-flags'
import { useSession } from '@/use/session'

const { currentUsersCompany } = useUsersCompanies()
const { generateMenuActions } = useListRow()
const activeClientsProfileEditModalRef = ref<any>(null)
const activeClientTable = ref<any>(null)
const {
  configureDetailsPanel
} = useTransactionResources()
export const universalActions = [
  {
    value: 'view-public-profile',
    updateSelectedResource: true,
    actionFn: (client: any) => {
      const { openGlobalDetailsPanel } = useDetailsPanel()
      openGlobalDetailsPanel('clients/profile', {
        canDoubleClickToEdit: true,
        resourceType: 'client',
        visibility: DetailsPanelVisibility.Fullscreen,
        isPublicTab: true,
        actions: getClientMenuActions()
      }, 'setSelectedResources', client)
    },
    noSuccessEmit: true
  },
  {
    value: 'edit',
    updateSelectedResource: true,
    actionFn: (client: any, { emit }: any) => {
      if (emit) {
        emit('edit')
      } else {
        const { openGlobalDetailsPanel } = useDetailsPanel()
        openGlobalDetailsPanel('clients/profile', {
          canDoubleClickToEdit: false,
          resourceType: 'client',
          visibility: DetailsPanelVisibility.Fullscreen,
          isPublicTab: false,
          actions: getClientMenuActions()
        }, 'setSelectedResources', client)
      }
    },
    noSuccessEmit: true
  },
  {
    value: 'save',
    noSuccessEmit: true
  }
]

const clients:{
  loading: boolean,
  list: any,
  netTerms: any,
  meta: any
} = reactive({
  loading: false,
  list: [],
  netTerms: [],
  meta: {
    currentPage: null,
    nextPage: null,
    prevPage: null,
    totalPages: null,
    totalCount: null
  }
})
interface loadClientsParams {
  page: number
  perPage?: any
  q?: any,
  isVendor?: boolean,
}

interface Params {
  include?: string
  q?: any
  page?: number
  perPage?: number,
  id?: number,
  clientId? : number | string
}
const clientsProfileModalRef = ref<any>(null)
const detailsRef = ref<any>(null)
const ACTIVE = 'active'
const PENDING = 'pending'
const BLOCKED = 'blocked'
const SIGNEDUP = 'SIGNED-UP'
const NOTSIGNEDUP = 'NOT SIGNED-UP'

export const configureActionModal = (key: string) => {
  const config = clientActionModalProps[key]
  return {
    ...config
  }
}
const clientStatuses = [ACTIVE, PENDING, BLOCKED]
export const universalClientActionData: any[] = [
  {
    name: 'Block',
    textColor: 'error',
    validStatuses: [ACTIVE],
    primaryAction: []
  }
]
export const clientActionData: any[] = [
  {
    name: '+ Work Order',
    icon: '',
    textColor: '',
    validStatuses: [ACTIVE],
    primaryAction: []
  },
  {
    name: 'Unblock',
    textColor: 'success',
    validStatuses: [BLOCKED],
    primaryAction: [BLOCKED]
  },
  {
    name: 'Edit',
    icon: '',
    textColor: '',
    validStatuses: [ACTIVE, PENDING],
    primaryAction: [],
    detailsPanel: DetailsPanelButtonState.Only
  },
  {
    name: 'View Public Profile',
    icon: '',
    textColor: '',
    validStatuses: [ACTIVE, PENDING, BLOCKED],
    primaryAction: []
  },
  {
    name: '+ Invoice',
    icon: '',
    textColor: '',
    validStatuses: [ACTIVE],
    primaryAction: []
  },
  {
    name: '+ Proposal',
    icon: '',
    textColor: '',
    validStatuses: [ACTIVE],
    primaryAction: []
  },
  {
    name: '+ Contacts',
    icon: '',
    textColor: '',
    validStatuses: [ACTIVE],
    primaryAction: []
  },
  {
    name: '+ Service Rates',
    icon: '',
    textColor: '',
    validStatuses: [ACTIVE],
    primaryAction: []
  },
  {
    name: 'IVR/WEB',
    icon: '',
    textColor: '',
    validStatuses: [ACTIVE],
    primaryAction: []
  },
  {
    name: 'Sites',
    icon: '',
    textColor: '',
    validStatuses: [ACTIVE],
    primaryAction: []
  },
  {
    name: 'Asset',
    icon: '',
    textColor: '',
    validStatuses: [ACTIVE],
    primaryAction: []
  },
  {
    name: '+ Materials',
    icon: '',
    textColor: '',
    validStatuses: [ACTIVE],
    primaryAction: []
  },
  {
    name: '+ Notes',
    icon: '',
    textColor: '',
    validStatuses: [ACTIVE],
    primaryAction: []
  },
  {
    name: '+ Client employee',
    icon: '',
    validStatuses: [ACTIVE],
    primaryAction: []
  },
  {
    name: 'Client Portal',
    icon: '',
    validStatuses: [ACTIVE],
    primaryAction: []
  },
  {
    name: 'Share',
    validStatuses: [ACTIVE, PENDING, BLOCKED],
    primaryAction: [],
    detailsPanel: DetailsPanelButtonState.Icon,
    buttonIcon: SHARE,
    eventName: 'share'
  },
  {
    name: 'Save',
    primaryAction: [],
    validStatuses: [ACTIVE, PENDING, BLOCKED],
    detailsPanel: DetailsPanelButtonState.Only,
    eventName: 'save'
  }
]

const clientMap = {} as any

export const clientMenuActions: any = generateMenuActions(clientStatuses, clientMap, universalClientActionData, clientActionData, '', true)

export const clientActionModalProps: any = {
  block: {
    dynamicContentProps: {
      allowedStatuses: universalClientActionData.find((action: any) => action.name === 'Block').validStatuses,
      resultingStatus: ''
    },
    modalCardProps: {
      headerText: 'Block Client',
      headerIcon: BLOCK_HELPER,
      size: 'md'
    },
    confirmButtonProps: {
      text: 'BLOCK',
      state: 'error'
    }
  },
  unblock: {
    dynamicContentProps: {
      allowedStatuses: clientActionData.find((action: any) => action.name === 'Unblock').validStatuses,
      resultingStatus: ''
    },
    modalCardProps: {
      headerText: 'Unblock Client',
      headerIcon: BLOCK_HELPER,
      size: 'md'
    },
    confirmButtonProps: {
      text: 'UNBLOCK',
      state: 'success'
    }
  }
}

export const genActionableClients = (actionText: string, clients: any[]) => {
  let allowedStatuses = clientActionData.find((action: any) => action.name === actionText).validStatuses
  if ((typeof allowedStatuses === 'boolean') && allowedStatuses) {
    allowedStatuses = clientStatuses
  }
  return clients.filter((client: any) => {
    return allowedStatuses.includes(client.user.status)
  })
}

export const DEFAULT_CONTACT = {
  email: '',
  role: '',
  invitePlatform: true,
  firstName: '',
  lastName: '',
  isUserEmailValid: false,
  otherDetails: {
    mobilePhone: '',
    isMobilePhoneValid: false,
    profilePicture: null,
    title: '',
    landLinePhoneNumber: '',
    landLineExtension: '',
    useContactAsBilling: false,
    applyContactForAllSites: false
  }
}

export const DEFAULT_NEW_CLIENT = {
  name: '',
  externalId: null,
  otherDetails: {
    logo: null,
    customId: '',
    legalAddress: null,
    county: '',
    businessEmail: '',
    businessPhone: '',
    businessFax: '',
    taxExempt: false,
    onCreditHold: false,
    permanentClientInstruction: ''
  },
  inviteContacts: [DEFAULT_CONTACT],
  inviteDetails: {
    email: '',
    phone: ''
  }
}

export const DEFAULT_NOTE = {
  id: '',
  comment: '',
  isNote: true,
  isAlert: false,
  startDate: '',
  expirationDate: '',
  noteId: ''
}

export const DEFAULT_MATERIAL = {
  materialId: 0,
  unitCost: '',
  unitSell: '',
  materialCode: '',
  unitOfMeasure: '',
  amountInStock: '',
  units: '',
  description: '',
  barCode: '',
  name: ''

}

export const DEFAULT_CLIENT_DETAILS = {
  basicInformation: {
    name: '',
    email: '',
    phoneNumber: '',
    fax: '',
    customId: '',
    mobilePhoneNumber: '',
    webAddress: '',
    location: {}
  },
  ivr: {
    phoneNo: '',
    pin: '',
    procedure: '',
    required: false,
    userId: '',
    userPassword: '',
    webUrl: ''
  },
  additional: [],
  othersDetail: {
    taxExempt: false,
    isCreditHold: false,
    instructionNote: '',
    includePermanentInstructionToDispatchEmail: false,
    serviceChannelId: ''
  },
  addresses: [],
  access: {
    displayWoClient: false,
    displayServiceRequest: false,
    weatherWorksSiteEventBilling: false
  },
  isDisabled: false
}

const getClientMenuActions = () => {
  return [
    ...universalActions,
    {
      value: 'work-order',
      requiresConfirmation: false,
      actionFn: (clients: any[], localDetailsPanelComponentProps: any) => {
        if (useFeatureFlags().hideVueWorkOrder) {
          localDetailsPanelComponentProps.router.push(`/company/wizard/work_orders/new?header=false&step=details&client_id=${clients[0].id}`)
        } else {
          useDetailsPanel().openGlobalDetailsPanel('work-orders/details', {
            outbound: false,
            canDoubleClickToEdit: false,
            resourceType: 'workOrder',
            actions: useWorkOrders().getWorkOrderActions()
          }, 'enterCreateResourceFlow', { WO_RESOURCE_TYPE })
        }
      }
    },
    {
      value: 'service-request',
      requiresConfirmation: false,
      actionFn: (_: any[], localDetailsPanelComponentProps: any) => {
        localDetailsPanelComponentProps.router.push('/company/service_requests/site_selections')
      }
    },
    {
      value: 'invoice',
      requiresConfirmation: false,
      actionFn: (clients: any[]) => {
        const allOutboundActions = [
          ...useOutboundInvoices().outboundInvoiceModalRequiredActions,
          ...useOutboundInvoices().basicInvoiceOutboundActions
        ]
        useDetailsPanel().openGlobalDetailsPanel('uc/resource-details', {
          outbound: true,
          canDoubleClickToEdit: true,
          resourceType: INVOICE_RESOURCE_TYPE,
          actions: allOutboundActions
        }, 'enterCreateResourceFlow', { prefillData: { company: clients[0] } })
      },
      noSuccessEmit: true
    },
    {
      value: 'proposal',
      requiresConfirmation: false,
      actionFn: (clients: any[]) => {
        const allOutboundActions = () => {
          return [
            ...useOutboundProposals().basicProposalOutboundActions(useApp().companyType.value).map((item: any) => {
              const temp = Object.assign({}, item)
              delete temp.bulkConfig
              return temp
            }),
            ...useOutboundProposals().proposalOutboundModalRequiredActions
          ]
        }
        useDetailsPanel().openGlobalDetailsPanel('uc/resource-details', {
          outbound: true,
          canDoubleClickToEdit: true,
          resourceType: PROPOSAL_RESOURCE_TYPE,
          actions: allOutboundActions()
        }, 'enterCreateResourceFlow', { prefillData: { company: clients[0] } })
      },
      noSuccessEmit: true
    },
    {
      value: 'contacts',
      updateSelectedResource: false,
      actionFn: (client: any[], additionalParams: any) => {
        activeClientsProfileEditModalRef.value.modelDetails.title = client[0] ? `Add contacts for ${client[0].name}` : 'Add contacts'
        // pass content table ref for reload as we are showing this in active client list
        activeClientsProfileEditModalRef.value.modelDetails.contentRef = activeClientTable.value
        activeClientsProfileEditModalRef.value.modelDetails.type = 'AddContacts'
        activeClientsProfileEditModalRef.value.modelDetails.selectedClient = client[0]
        activeClientsProfileEditModalRef.value.modelDetails.additionalParams = additionalParams
        activeClientsProfileEditModalRef.value?.show()
      }
    },
    {
      value: 'service-rates',
      updateSelectedResource: true,
      actionFn: (client: any) => {
        window.open(`company/wizard/clients/${client[0]?.id}/service_rates/edit`)
      }
    },
    {
      value: 'ivr-web',
      updateSelectedResource: true,
      actionFn: (client: any, { detailsRef, localDetailsPanelComponentProps, toggleFullScreenView, setSelectedResources, isFromTable }: any) => {
        localDetailsPanelComponentProps.isPublicTab = false
        if (isFromTable) {
          detailsRef.toggleFullScreenView()
        }
        localDetailsPanelComponentProps.nestedSelectedTab = 'IVR-Web'
        configureDetailsPanel(client, toggleFullScreenView, setSelectedResources)
      }
    },
    {
      value: 'sites',
      updateSelectedResource: true,
      actionFn: (client: any) => {
        window.open(`company/clients/${client[0]?.id}/locations`)
      }
    },
    {
      value: 'asset',
      requiresConfirmation: false,
      actionFn: (clients: any[]) => {
        window.open(`/company/clients/${clients[0].id}/equipment`)
      }
    },
    {
      value: 'notes',
      updateSelectedResource: false,
      actionFn: (client: any[], additionalParams: any) => {
        activeClientsProfileEditModalRef.value.modelDetails.title = client[0] ? `Add a note for ${client[0].name}` : 'Add a note'
        activeClientsProfileEditModalRef.value.modelDetails.type = 'Note'
        activeClientsProfileEditModalRef.value.modelDetails.selectedClient = client[0]
        activeClientsProfileEditModalRef.value.modelDetails.additionalParams = additionalParams
        activeClientsProfileEditModalRef.value?.show()
      }
    },
    {
      value: 'materials',
      updateSelectedResource: false,
      actionFn: (client: any[], additionalParams: any) => {
        activeClientsProfileEditModalRef.value.modelDetails.title = client[0] ? `Add material for ${client[0].name}` : 'Add material'
        activeClientsProfileEditModalRef.value.modelDetails.type = 'AddMaterial'
        activeClientsProfileEditModalRef.value.modelDetails.selectedClient = client[0]
        activeClientsProfileEditModalRef.value.modelDetails.additionalParams = additionalParams
        activeClientsProfileEditModalRef.value?.show()
      }
    },
    {
      value: 'client-employee',
      requiresConfirmation: false,
      actionFn: (client: any[], additionalParams: any) => {
        activeClientsProfileEditModalRef.value.modelDetails.title = client[0] ? `Invite client employees for ${client[0].name}` : 'Invite client employees'
        activeClientsProfileEditModalRef.value.modelDetails.type = 'AddEmployees'
        activeClientsProfileEditModalRef.value.modelDetails.selectedClient = client[0]
        activeClientsProfileEditModalRef.value.modelDetails.additionalParams = additionalParams
        activeClientsProfileEditModalRef.value?.show()
      },
      noSuccessEmit: true
    },
    {
      value: 'client-portal',
      requiresConfirmation: false,
      actionFn: (clients: any[]) => {
        const url = `${PROTOCOL}://${clients[0].subdomain}${process.env.VUE_APP_OAUTH_BASE_URL}`
        window.open(`${url}`, '_blank')
      },
      noSuccessEmit: true
    },
    {
      value: 'block',
      requiresConfirmation: true,
      modal: clientActionModalProps.block,
      actionFn: async (clients: any[], additionalParams: any) => {
        await useClients().actionListenersAndFunctions.block(
          clients,
          additionalParams
        )
      }
    },
    {
      value: 'share',
      actionFn: async (clients: any[], additionalParams: any) => {
        await useClients().actionListenersAndFunctions.share(
          clients,
          additionalParams
        )
      },
      noSuccessEmit: true
    }

  ]
}

export const useClients = () => {
  const { addToast } = useToasts()
  const loadClients = async (params:any) => {
    try {
      clients.loading = true
      const res = await repositories.clients.getClients(params)
      clients.list = res.clients
      clients.meta = res.meta
      return res.clients
    } catch (err) {
      console.log(err)
    } finally {
      clients.loading = false
    }
  }

  const getClients = async (params:any) => {
    try {
      clients.loading = true
      const res = await repositories.clients.getClients(params)
      return {
        data: res.clients,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    } finally {
      clients.loading = false
    }
  }

  const getClientBillingMethods = async (params:any) => {
    try {
      const res = await repositories.clients.getClientBillingMethods(params)
      return {
        data: res.billingMethods,
        totalCount: res.billingMethods.length,
        totalPages: 1
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    } finally {
      clients.loading = false
    }
  }

  const getInvoiceableClients = async (params:any) => {
    try {
      const res = await repositories.clients.getInvoiceableClients(params)
      return {
        data: res.clients,
        nextPage: res.meta.nextPage
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getClientRecruitTemplates = async () => {
    try {
      const res = await repositories.clients.getRecruitTemplates()
      return {
        data: res['survey/surveyTemplates'],
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getClientById = async (params:any) => {
    try {
      const res = await repositories.clients.getById(params)
      return {
        data: res.client
      }
    } catch (err) {
      console.log(err)
      return {
        data: false
      }
    }
  }

  const genClientsChipProps = ({
    clients
  }: {
        clients: any
    }) => {
    let state = ''
    let prependIcon = ''
    const { status, subdomain } = clients
    let text = startCase(status)

    if (subdomain) {
      text = SIGNEDUP
      state = 'success'
      prependIcon = SUCCESS
    } else {
      text = NOTSIGNEDUP
      state = 'warning'
      prependIcon = INFO
    }

    return {
      state,
      prependIcon,
      text
    }
  }

  const getTaxExemptByClient = async (params: Params) => {
    try {
      const res = await repositories.clients.getTaxExempt(params)
      return {
        data: res.client
      }
    } catch (err) {
      console.log(err)
      return {
        data: false
      }
    }
  }

  const getClientsNotes = async (params:any) => {
    try {
      clients.loading = true
      const res = await repositories.clients.getClientsNotes(params)
      return {
        data: res.notes,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    } finally {
      clients.loading = false
    }
  }

  const addClientNote = async (params: any, isEdit = false) => {
    try {
      const res = isEdit ? await repositories.clients.editClientNote(params) : await repositories.clients.addClientNote(params)
      return res
    } catch (err) {
      console.error(err)
      return err
    }
  }

  const deleteClientNote = async (params: any) => {
    try {
      const res = await repositories.clients.deleteClientNote(params)
      return res
    } catch (err) {
      console.error(err)
      return err
    }
  }

  const getClientContacts = async (params:any, isPublic = false) => {
    try {
      clients.loading = true
      const res = await repositories.clients.getClientContacts(params, isPublic)
      return {
        data: res?.contacts,
        totalCount: res?.meta?.totalCount,
        totalPages: res?.meta?.totalPages
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    } finally {
      clients.loading = false
    }
  }

  const addClientContacts = async (params: any) => {
    try {
      const res = await repositories.clients.addClientContacts(params)
      return {
        data: res
      } as any
    } catch (err: any) {
      console.log(err)
      return { error: err?.data?.errors }
    }
  }

  const updateClientContacts = async (params: any) => {
    try {
      const res = await repositories.clients.updateClientContacts(params)
      return {
        data: res
      } as any
    } catch (err: any) {
      console.error(err)
      return { error: err?.data?.errors }
    }
  }

  const deleteClientContacts = async (params: any) => {
    try {
      const res = await repositories.clients.deleteClientContacts(params)
      return res
    } catch (err) {
      console.error(err)
      return err
    }
  }

  const getClientsAddresses = async (params: any) => {
    try {
      const res = await repositories.clients.getAddresses(params)
      return {
        data: res.addresses,
        totalPages: 1,
        totalCount: res.addresses.length
      } as any
    } catch (err) {
      console.log(err)
    }
  }

  const getClientsPublicAddresses = async (params: any) => {
    try {
      const res = await repositories.clients.getPublicAddresses(params)
      return {
        data: res.addresses,
        totalPages: 1,
        totalCount: res.addresses.length
      } as any
    } catch (err) {
      console.log(err)
    }
  }

  // depricated below here
  const loadClientWithClientId = async (params: any) => {
    try {
      clients.loading = true
      const response = await repositories.clients.getById(params)
      clients.list = response.client
      clients.loading = false
      return response.client
    } catch (err) {
      console.log(err)
      clients.loading = false
      return null
    }
  }

  const blockOrUnblockClient = async (params: any) => {
    try {
      clients.loading = true
      const res = await repositories.clients.blockOrUnblockClient(params)
      if (res && res.client) { return true } else { return false }
    } catch (err) {
      return false
    } finally {
      clients.loading = false
    }
  }
  const rejectClient = async (clientId: any) => {
    try {
      clients.loading = true
      const res = await repositories.clients.rejectClient(clientId)
      if (res && res.success) { return true } else { return false }
    } catch (err) {
      return false
    } finally {
      clients.loading = false
    }
  }
  const acceptClient = async (clientId: any) => {
    try {
      clients.loading = true
      const res = await repositories.clients.acceptClient(clientId)
      if (res && res.success) { return true } else { return false }
    } catch (err) {
      return false
    } finally {
      clients.loading = false
    }
  }

  const getClientNetTerms = async (params: any) => {
    try {
      const res = await repositories.clients.getNetTerms(params)
      return {
        data: res,
        defaultNetTerm: res.defaultNetTerm,
        totalPages: 1,
        totalCount: res.netTerms.length
      } as any
    } catch (err) {
      console.log(err)
    }
  }
  const loadClientsWithoutSaving = async ({ page, perPage, q }: loadClientsParams) => {
    try {
      const response = await repositories.clients.getClients({ page, perPage, q, include: 'contacts,logo,headquarter_address' })
      return response
    } catch (err) {
      console.log(err)
      return {
        clients: [],
        meta: {}
      }
    }
  }

  const inviteClient = async (companyId: number, include: string) => {
    try {
      const res = await repositories.clients.inviteClient(companyId, include)
      return res
    } catch (err) {
      return {}
    }
  }

  const postClient = async (param: any) => {
    try {
      const res = await repositories.clients.postClient(param)
      return res
    } catch (err) {
      return {}
    }
  }

  const updateClient = async (id: string, param: any) => {
    try {
      const res = await repositories.clients.updateClient(id, param)
      return res
    } catch (err) {
      return {}
    }
  }

  const downloadSampleExcel = async () => {
    try {
      const res = await repositories.clients.downloadSampleExcel()
      return res
    } catch (err) {
      return {}
    }
  }

  const actionListenersAndFunctions: any = {
    block: async (
      client: any[],
      additionalParams: any
    ) => {
      const param = { clientId: client[0].id, path: 'block' }
      await blockOrUnblockClient(param)
      const localClientId = client[0].id
      const localClientName = client[0].name
      const isFromTable = additionalParams?.isFromTable
      addToast({
        color: 'success',
        message: !isFromTable ? `${localClientName} has been blocked.` : '',
        timeout: isFromTable ? 15000 : 5000,
        prependIcon: SUCCESS,
        component: () =>
          h(
            'div', { style: { fontWeight: 500, fontSize: '1rem' } }, [' ', '',
              h('router-link', { props: { to: `/clients/${localClientId}` } }, [`${localClientName}`]), ' has been blocked'
            ]
          )
      })
    },
    unblock: async (
      client: any[],
      additionalParams: any
    ) => {
      const param = { clientId: client[0].id, path: 'unblock' }
      const response = await blockOrUnblockClient(param)
      if (response) {
        const localClientId = client[0].id
        const localClientName = client[0].name
        const isFromTable = additionalParams?.isFromTable
        addToast({
          color: 'success',
          message: !isFromTable ? `${localClientName} has been unblocked.` : '',
          timeout: isFromTable ? 15000 : 5000,
          prependIcon: SUCCESS,
          component: () =>
            h(
              'div', { style: { fontWeight: 500, fontSize: '1rem' } }, [' ', '',
                h('router-link', { props: { to: `/clients/${localClientId}` } }, [`${localClientName}`]), ' has been unblocked'
              ]
            )
        })
      } else {
        addToast({
          color: 'error',
          message: 'Something went wrong please try again!',
          timeout: 5000,
          prependIcon: ERROR
        })
      }
    },
    share: (
      client: any[],
      additionalParams: any
    ) => {
      const localClientId = client[0].id
      const localClientName = client[0].name
      const isFromTable = additionalParams?.isFromTable
      const url = useSession().createSubdomainURL(currentUsersCompany.value.subdomain, `/clients/${client[0].id}`)
      navigator.clipboard.writeText(url).then(function () {
        addToast({
          color: 'success',
          message: !isFromTable ? 'Link copied to clipboard' : '',
          timeout: isFromTable ? 15000 : 5000,
          prependIcon: SUCCESS,
          component: () => isFromTable
            ? h(
              'div', { style: { fontWeight: 500, fontSize: '1rem' } }, [' ', 'Link copied to clipboard for ',
                h('router-link', { props: { to: `/clients/${localClientId}` } }, [`${localClientName}`])
              ]
            )
            : h()
        })
      })
    }
  }

  const getClientsCustomFields = async (params:any) => {
    try {
      clients.loading = true
      const res = await repositories.clients.getClientsCustomFields(params)
      return {
        data: res.customFields,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages
      }
    } catch (err: any) {
      console.log(err)
      return { error: err?.data?.errors }
    } finally {
      clients.loading = false
    }
  }

  const getClientsMaterials = async (params:any) => {
    try {
      clients.loading = true
      const res = await repositories.clients.getClientsMaterials(params)
      return {
        data: res.materialRates,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    } finally {
      clients.loading = false
    }
  }

  const addClientMaterials = async (params: any) => {
    try {
      const res = await repositories.clients.addClientMaterials(params)
      return {
        data: res
      } as any
    } catch (err: any) {
      console.log(err)
      return { error: err?.data?.errors }
    }
  }

  const updateClientMaterials = async (params: any) => {
    try {
      const res = await repositories.clients.updateClientMaterials(params)
      return {
        data: res
      } as any
    } catch (err: any) {
      console.error(err)
      return { error: err?.data?.errors }
    }
  }

  const bulkDeleteClientMaterials = async (params: any) => {
    try {
      const res = await repositories.clients.bulkDeleteMaterials(params.materialRateIds, params.id)
      return res
    } catch (err) {
      console.error(err)
      return err
    }
  }

  const inviteClientEmployee = async (params: any) => {
    try {
      const res = await repositories.clients.inviteClientEmployee(params)
      return {
        data: res
      } as any
    } catch (err: any) {
      console.log(err)
      return { error: err?.data?.errors }
    }
  }

  const updateClientDetails = async (id: string, params:any) => {
    try {
      const res = await repositories.clients.updateClientDetails(id, params)
      return res
    } catch (err) {
      return {}
    }
  }

  const updateProfileLogo = async (params: any, id: number) => {
    try {
      const res = await repositories.clients.updateProfileLogo(params, id)
      return {
        data: res.client
      }
    } catch (err) {
      console.error(err)
      return { data: false }
    }
  }

  const getClientsLicense = async (param: any) => {
    try {
      const res = await repositories.clients.getClientsLicense(param)
      return {
        data: res.licenses,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as any
    } catch (err) {
      console.log(err)
      return {
        data: [],
        totalPages: 0,
        totalCount: 0
      } as any
    }
  }

  const clientsAutoComplete = async (params: AutocompletePayload) => {
    try {
      const res = await repositories.clients.clientsAutoComplete(params)
      return {
        data: res.results,
        nextPage: res.nextPage
      } as { data: AutocompleteResponse[], nextPage: number }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getClientAddresses = async (params: any) => {
    try {
      const res = await repositories.clients.getClientAddresses(params)
      return {
        data: res.addresses,
        totalPages: res.totalPages,
        totalCount: res.totalCount
      }
    } catch (err) {
      console.error(err)
      return { data: false }
    }
  }

  const getClientLocations = async (params: any) => {
    try {
      const res = await repositories.clients.getClientLocations(params)
      return {
        data: res.locations,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      }
    } catch (err) {
      console.error(err)
      return { data: false }
    }
  }

  const getClientLocationsAddress = async (params: any) => {
    try {
      const res = await repositories.clients.getClientLocationsAddress(params)
      return {
        data: res.addresses,
        totalPages: res.totalPages,
        totalCount: res.totalCount
      }
    } catch (err) {
      console.error(err)
      return { data: false }
    }
  }

  const isCompanyHaveBlockAccess = () => {
    const { session, userHaveAccess } = useSession()
    if (userHaveAccess('customers', 'edit_delete_or_create_on_client') && session?.currentCompany?.approvedAt) {
      return true
    }
    return false
  }

  const clientsSearch = async (params:any) => {
    try {
      clients.loading = true
      const res = await repositories.clients.clientsSearch(params)
      return {
        data: res.clients,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    } finally {
      clients.loading = false
    }
  }

  const invoiceablesClientsSearch = async (params:any) => {
    try {
      const res = await repositories.clients.invoiceablesClientsSearch(params)
      return {
        data: res.clients,
        nextPage: res.meta.nextPage
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  return {
    clients,
    loadClients,
    getClients,
    getClientNetTerms,
    loadClientWithClientId,
    blockOrUnblockClient,
    rejectClient,
    acceptClient,
    loadClientsWithoutSaving,
    getInvoiceableClients,
    getClientRecruitTemplates,
    getClientBillingMethods,
    getClientById,
    getTaxExemptByClient,
    ACTIVE,
    PENDING,
    BLOCKED,
    genClientsChipProps,
    inviteClient,
    postClient,
    updateClient,
    getClientsNotes,
    addClientNote,
    clientsProfileModalRef,
    deleteClientNote,
    detailsRef,
    downloadSampleExcel,
    actionListenersAndFunctions,
    getClientsCustomFields,
    getClientContacts,
    addClientContacts,
    updateClientContacts,
    deleteClientContacts,
    getClientsMaterials,
    addClientMaterials,
    updateClientMaterials,
    bulkDeleteClientMaterials,
    getClientsAddresses,
    inviteClientEmployee,
    universalActions,
    updateClientDetails,
    updateProfileLogo,
    clientMenuActions,
    getClientsLicense,
    clientsAutoComplete,
    getClientsPublicAddresses,
    getClientAddresses,
    getClientLocations,
    getClientLocationsAddress,
    isCompanyHaveBlockAccess,
    clientsSearch,
    invoiceablesClientsSearch,
    getClientMenuActions,
    activeClientsProfileEditModalRef,
    activeClientTable
  }
}
