import { api } from '@/api'
import qs from 'qs'

interface Params {
  include: string
  q: any
  page: number
  perPage: number,
  id?: string
}

export default {
  getDepartments: (params:any) => {
    const parameters:Params = params
    const { include, q, page, perPage } = parameters
    return api.$get('/departments', {
      params: {
        include,
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },
  getDepartmentById: (params:any) => {
    const { q, page, perPage, id, include } = params
    return api.$get(`/departments/${id}`, {
      params: {
        include,
        q,
        page,
        per_page: perPage,
        id
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }

    })
  }
}
