import repositories from '@/repositories'
import { baseGetResponse } from '@/utils/api'

export const useWeatherEvents = () => {
  const getWeatherEvents = async (params: any) => {
    try {
      const res = await repositories.weatherEvents.get(params)
      return {
        data: res.stormDetails,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages
      } as baseGetResponse
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }
  const getInvoiceableWeatherEventsByClient = async (params: any) => {
    try {
      const res: any = await repositories.weatherEvents.getInvoiceableWeatherEventsByClient(params)
      return {
        data: res.weatherEventLocations,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as baseGetResponse
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getBillableWeatherEventsByVendor = async (params: any) => {
    try {
      const res: any = await repositories.weatherEvents.getBillableWeatherEventsByVendor(params)
      return {
        data: res.weatherEventLocations,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as baseGetResponse
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  return {
    getWeatherEvents,
    getInvoiceableWeatherEventsByClient,
    getBillableWeatherEventsByVendor
  }
}
