import { Tab } from '../interfaces'

// How buttons can behave in details panel
export enum DetailsPanelButtonState {
  Expose = 'expose',
  Only = 'only',
  Hide = 'hide',
  Icon = 'icon',
  Never = 'never'
}

// Where the tabs can go in details panel
export enum TabLocations {
  Left = 'left',
  Center = 'center',
  Right = 'right'
}

// Details panel visibility optios
export enum DetailsPanelVisibility {
  Hide = 'hide',
  Show = 'show',
  Fullscreen = 'fullscreen'
}
