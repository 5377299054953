import { api } from '@/api'
import qs from 'qs'

interface GetTaxCodeListParams {
  perPage: number
  page: number
  q?: any
}

export default {
  getTaxCodeList: ({ perPage, page, q }: GetTaxCodeListParams) => api.$get('/tax_codes', {
    params: {
      page,
      per_page: perPage,
      q
    },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  post: (params: any, requestBody: any) => {
    return api.$post('/tax_codes', {
      ...requestBody
    }, {
      params,
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  }
}
