import { defineComponent, ref, onMounted, nextTick, watch, computed } from 'vue';
import { formatNumber } from '@/utils/formatting';
import _ from 'lodash';
import { HIDE_TEXT_EYE, SHOW_TEXT_EYE } from '@/constants/icons';
export default defineComponent({
  props: {
    value: {
      type: [String, Object, Number, Array],
      default: ''
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    prependIcon: {
      type: String,
      default: null
    },
    appendIcon: {
      type: String,
      default: null
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: [Array, Function],
      default: () => []
    },
    validateOnBlur: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    hideDetails: {
      type: [Boolean, String],
      default: false
    },
    labelVariant: {
      type: Boolean,
      default: false
    },
    prefix: {
      type: String,
      default: null
    },
    increment: {
      type: Number,
      default: 1
    },
    tabIndex: {
      type: [String, Number],
      default: null
    },
    suffix: {
      type: [String, Number],
      default: null
    },
    dense: {
      type: Boolean,
      default: false
    },
    max: {
      type: String,
      default: ''
    },
    errorMessages: {
      type: [Array, String],
      default: ''
    },
    hint: {
      type: [Array, String],
      default: ''
    },
    persistentHint: {
      type: Boolean,
      default: false
    },
    canDoubleClickToEdit: {
      type: Boolean,
      default: false
    },
    nonEditModeComponent: {
      type: String,
      default: 'text',
      validator: val => {
        return ['text', 'chip'].includes(val.toLowerCase());
      }
    },
    showCopyIcon: {
      type: Boolean,
      default: false
    },
    decimals: {
      type: Number,
      default: 0
    },
    doubleClickProps: {
      type: Object,
      default: () => {
        return {
          text: {},
          chip: {}
        };
      }
    },
    required: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: String,
      default: ''
    },
    minWidth: {
      type: String,
      default: ''
    },
    classes: {
      type: Array,
      default: () => []
    },
    autofill: {
      type: Boolean,
      default: true
    },
    maxLength: {
      type: Number,
      default: null
    },
    readModeEmptyState: {
      type: String,
      default: null
    }
  },
  setup: (props, {
    emit
  }) => {
    const inputRef = ref(null);
    const localTabIndex = ref(props.tabIndex);
    const hovering = ref(false);
    const isEditMode = ref(!props.canDoubleClickToEdit);
    const showPwd = ref(false);
    let propChanged = false;
    const {
      startCase
    } = _;
    const toggleEditMode = () => {
      if (propChanged) {
        propChanged = false;
        return;
      }
      isEditMode.value = !isEditMode.value;
      emit('change:edit-mode', isEditMode.value);
      nextTick(() => {
        init();
      });
    };
    // show v-text-field after the compoment is mounted, to allow autofill property to take effect since <input> will not re-render if prop values are changed
    const showField = ref(false);
    const init = () => {
      if (isEditMode.value) {
        const res = [...inputRef.value.$el.querySelectorAll('input')];
        res[0].tabIndex = localTabIndex.value ? localTabIndex.value : 1;
        if (props.canDoubleClickToEdit) {
          res[0].focus();
        }
      }
    };
    onMounted(() => {
      init();
      showField.value = true;
    });
    const getEmitValue = val => {
      return val.target.value;
    };
    watch(() => props.canDoubleClickToEdit, () => {
      isEditMode.value = !props.canDoubleClickToEdit;
      emit('change:edit-mode', isEditMode.value);
      propChanged = true;
      nextTick(() => {
        init();
      });
    });
    watch(() => props.tabIndex, () => {
      localTabIndex.value = props.tabIndex ?? null;
      nextTick(() => {
        init();
      });
    });
    const localRules = ref(_.cloneDeep(Array.isArray(props.rules) ? props.rules : [props.rules]));
    const addRequiredRule = () => {
      if (props.required) {
        var _localRules$value;
        (_localRules$value = localRules.value) === null || _localRules$value === void 0 ? void 0 : _localRules$value.unshift(v => {
          var _Object$keys;
          return !!v && !!v.toString().length || v && ((_Object$keys = Object.keys(v)) === null || _Object$keys === void 0 ? void 0 : _Object$keys.length) && !!v[props.itemValue] || `${props.label ? startCase(props.label) : 'Field'} is required`;
        });
      }
    };
    watch(() => props.required, () => {
      addRequiredRule();
    });
    addRequiredRule();
    watch(() => props.rules, () => {
      localRules.value = _.cloneDeep(Array.isArray(props.rules) ? props.rules : [props.rules]);
      addRequiredRule();
    }, {
      deep: true
    });
    const formatNumberByPrefix = () => {
      const formattedText = props.value;
      if (Number.isNaN(formattedText)) return '';
      if (props.decimal === 0 || !props.prefix && !props.suffix) return formattedText;
      return `${props.prefix || ''}${formatNumber(props.value, {
        style: 'decimal',
        styleOptions: {
          minimumFractionDigits: props.decimal,
          maximumFractionDigits: props.decimal
        },
        commaSeparated: true
      })}${props.suffix || ''}`;
    };
    const togglePassword = () => {
      showPwd.value = !showPwd.value;
    };
    const clickAppendIcon = () => {
      if (props.type === 'password') {
        togglePassword();
      }
      emit('click:append');
    };
    const validate = () => {
      return inputRef.value.validate(true);
    };
    const appendInputIcon = computed(() => {
      if (props.type === 'password') {
        return showPwd.value ? SHOW_TEXT_EYE : HIDE_TEXT_EYE;
      }
      return props.appendIcon;
    });
    const inputType = computed(() => {
      if (props.type === 'password') {
        return showPwd.value ? 'text' : props.type;
      }
      return props.type;
    });
    const readOnlyText = computed(() => {
      const text = formatNumberByPrefix();
      if (text) return text;else {
        if (props.readModeEmptyState) return props.readModeEmptyState;else if (props.label) return `No ${startCase(props.label)}`;
        return '';
      }
    });
    return {
      inputRef,
      getEmitValue,
      isEditMode,
      toggleEditMode,
      formatNumber,
      formatNumberByPrefix,
      hovering,
      startCase,
      localRules,
      showPwd,
      validate,
      appendInputIcon,
      inputType,
      clickAppendIcon,
      showField,
      localTabIndex,
      readOnlyText
    };
  }
});