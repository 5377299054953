import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', _vm._g(_vm._b({
    staticClass: "app-store-tile rounded d-flex align-center",
    class: [`app-store-tile--${_vm.state}`, {
      'elevation-3': _vm.isHovering
    }],
    on: {
      "mouseover": function ($event) {
        _vm.isHovering = true;
      },
      "mouseout": function ($event) {
        _vm.isHovering = false;
      }
    }
  }, 'div', _vm.$attrs, false), _vm.$listeners), [_vm.state === 'active' ? _c(VIcon, {
    staticClass: "activated-icon",
    attrs: {
      "color": "primary",
      "size": "1.5rem"
    }
  }, [_vm._v(" mdi-check-circle-outline ")]) : _vm._e(), _c('store-image-display', {
    attrs: {
      "image": _vm.image,
      "backdrop-color": _vm.state === 'disabled' ? 'transparent' : null
    }
  }), _c('div', {
    staticClass: "app-store-tile__app-info d-flex flex-column ml-4"
  }, [_c('uc-text', {
    attrs: {
      "size": 1,
      "text": _vm.appName,
      "bold": true,
      "type": 'body'
    }
  }), _c('uc-text', {
    attrs: {
      "size": 2,
      "text": _vm.category,
      "type": 'body'
    }
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };