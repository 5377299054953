import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    fullScreen: {
      type: Boolean,
      default: true
    },
    show: {
      type: Boolean,
      required: true
    }
  }
});