import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "uc-tabs",
    style: {
      overflow: _vm.vertical ? 'auto' : ''
    }
  }, [_c('div', {
    staticClass: "tab-row d-flex align-center",
    style: {
      backgroundColor: `var(--v-${!_vm.isNested ? 'layer-secondary' : 'layer'}-base)`
    }
  }, [_c(VTabs, {
    ref: "tabsRef",
    class: ['uc-tabs__tabs', {
      'uc-tabs__tabs--nested': _vm.isNested
    }, {
      'uc-tabs__tabs--has-children-tabs': _vm.hasChildrenTabs
    }],
    attrs: {
      "color": _vm.$vuetify.theme.dark ? 'text' : 'primary',
      "background-color": !_vm.isNested ? 'layer-secondary' : 'layer',
      "height": _vm.calcHeight(),
      "vertical": _vm.vertical,
      "grow": _vm.grow,
      "centered": _vm.centered,
      "show-arrows": ""
    },
    on: {
      "change": function ($event) {
        _vm.$emit('change', _vm.tab);
        _vm.$emit('input', _vm.tab);
      }
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, _vm._l(_vm.tabValues, function ({
    name,
    quantity,
    prependIcon,
    appendIcon,
    tooltipValue,
    tooltipPosition
  }, index) {
    return _c(VTab, {
      key: index,
      class: `justify-${_vm.alignText}`,
      attrs: {
        "data-cy": "tabs-content"
      },
      on: {
        "click": function ($event) {
          return _vm.$emit('tab-clicked');
        }
      }
    }, [_c('uc-tooltip', {
      attrs: {
        "text": tooltipValue,
        "position": tooltipPosition
      }
    }, [_c('div', {
      staticClass: "d-flex align-center"
    }, [prependIcon ? _c('uc-icon', {
      staticClass: "d-flex mr-1",
      attrs: {
        "size": "sm",
        "value": prependIcon
      }
    }) : _vm._e(), _vm._v(" " + _vm._s(name) + " "), quantity ? _c('uc-quantity', {
      staticClass: "ml-2",
      attrs: {
        "quantity": `${quantity}`
      }
    }) : _vm._e(), appendIcon ? _c('uc-icon', {
      staticClass: "ml-1",
      attrs: {
        "size": "sm",
        "value": appendIcon
      }
    }) : _vm._e()], 1)])], 1);
  }), 1), _vm._t("append-tab-row")], 2), _c('div', {
    staticClass: "prepend-content",
    style: {
      backgroundColor: _vm.isNested ? 'var(--v-layer-base)' : 'var(--v-layer-secondary-base)'
    }
  }, [_vm._t("prepend-content")], 2), !_vm.vertical ? _c('div', {
    staticClass: "d-flex flex-column",
    class: {
      'pa-2': !_vm.noMargin
    },
    style: `height: 100%; background-color: var(${_vm.contentBackground}); overflow: hidden; flex: 1;`
  }, [!_vm.prerenderTabs ? _c('transition', {
    attrs: {
      "mode": 'out-in',
      "name": _vm.transitionType
    }
  }, [_vm._l(_vm.lowerCaseTabNames, function (tabName, index) {
    return [_vm.tab === index ? _c('div', {
      key: index,
      staticClass: "uc-tabs__content",
      style: {
        backgroundColor: `var(${'contentBackground'})`,
        overflowY: _vm.scroll ? 'auto' : 'hidden'
      }
    }, [!_vm.loading ? _vm._t(tabName) : _vm._e()], 2) : _vm._e()];
  })], 2) : _vm._e(), _vm.prerenderTabs ? [_vm._l(_vm.lowerCaseTabNames, function (tabName, index) {
    return [_c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.tab === index,
        expression: "tab === index"
      }],
      key: index,
      staticClass: "uc-tabs__content",
      style: {
        backgroundColor: `var(${'contentBackground'})`,
        overflowY: _vm.scroll ? 'auto' : 'hidden'
      }
    }, [_vm._t(tabName)], 2)];
  })] : _vm._e()], 2) : _vm._e(), _vm._t("append-content")], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };