var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('uc-tooltip', {
    attrs: {
      "text": !_vm.value || _vm.disabled ? 'No text' : 'Copy to clipboard'
    }
  }, [_c('uc-button', {
    attrs: {
      "disabled": !_vm.value || _vm.disabled,
      "type": "text",
      "state": "text",
      "icon": "",
      "has-hover": "",
      "icon-name": "mdi-content-copy",
      "size": "xs"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.copyToClipboard.apply(null, arguments);
      }
    }
  })], 1), _c('uc-toast', {
    ref: "toastRef"
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };