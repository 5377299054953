import vuetify from '@/plugins/vuetify'
import { computed, onUnmounted, ref, Ref, watch } from 'vue'

export type ResponsiveSizeNoCard = 'lg' | 'md' | 'sm' | 'xs';
export type ResponsiveSize = ResponsiveSizeNoCard | 'card';

export const useResponsiveness = () => {
  /**
   * Get the container size, if we allow cards, when we hit the card breakpoint width we will allow that to be emitted
   * however if we don't allow cards, xs is the smallest it can be.
   *
   * @param width - The container width
   * @param allowCard (false) - Is card sizing allowed?
   */
  const getContainerSize = (width:number, allowCard = false): ResponsiveSize => {
    if (width >= vuetify?.preset.breakpoint.thresholds.md) {
      return 'lg'
    } else if (width >= vuetify?.preset.breakpoint.thresholds.sm) {
      return 'md'
    } else if (width >= vuetify?.preset.breakpoint.thresholds.xs) {
      return allowCard ? 'card' : 'sm'
    } else if (width >= 375) {
      return allowCard ? 'card' : 'xs'
    } else {
      return allowCard ? 'card' : 'xs'
    }
  }

  /**
   * This gets the full layout of the slot based on the column visibility.
   *
   * @param column - The column object
   * @param size - The size used
   */
  const getSlotVisibility = (column:any, size: ResponsiveSize) => {
    if (!column.visibility) return { classes: ['flex-1', 'px-2'] }
    if (column.visibility[size] === 'hide') return { style: { display: 'none !important' }, classes: [] }
    return {
      classes: [
        size === 'card' ? '' : 'px-2',
          `justify-${column.styleObj.justify}`,
            `align-${column.styleObj.align}`,
            `${column.visibility[size] === 'flex' ? 'flex-1' : ''}`,
            'flex-row d-flex',
            column.styleObj.overflow || 'overflow-hidden'
      ],
      style: {
        width: `${column.visibility[size] === 'flex' ? '' : `${column.visibility[size]}px`}`

      }
    }
  }

  const getFlexRatioByScrenSize = (size:string, headers:any) => {
    headers = headers.filter((col:any) => col.visibility[size] !== 'hide' && !NON_COLUMN_SLOTS.includes(col.templateName))
    return headers.length
  }

  const smOrXsContainerSize = (size: ResponsiveSize) => {
    return size === 'sm' || size === 'xs' || size === 'card'
  }

  const xsContainerSize = (size: ResponsiveSize) => {
    return size === 'xs' || size === 'card'
  }

  const scrollToBottom = (id:string) => {
    const element:any = document.getElementById(id)
    element.scrollTop = element.scrollHeight
    element.scrollIntoView({ behavior: 'smooth' })
  }

  const isCardSize = (size: ResponsiveSize) => {
    return size === 'card'
  }

  const NON_COLUMN_SLOTS = ['actions', 'drawer-content-expander', 'checkbox']

  return {
    getContainerSize,
    getSlotVisibility,
    NON_COLUMN_SLOTS,
    getFlexRatioByScrenSize,
    smOrXsContainerSize,
    xsContainerSize,
    scrollToBottom,
    isCardSize
  }
}

export const useResponsiveSize = (size: Ref<ResponsiveSize>) => {
  const isCard = computed(() => size.value === 'card')

  const isXsOrSmaller = computed(() =>
    size.value === 'xs' || isCard.value
  )

  const isSmOrSmaller = computed(() =>
    size.value === 'sm' || isXsOrSmaller.value
  )

  const isMdOrSmaller = computed(() => size.value === 'md' || isSmOrSmaller.value)

  return {
    isCard,
    isXsOrSmaller,
    isSmOrSmaller,
    isMdOrSmaller
  }
}

export const useObserveAndComputeAtEndOfResizing = (elementRef: Ref<any>, callback: (observedWidth: number) => void) => {
  // we will have to accept one frame as a loss frame because we need it to know we stopped animating.
  // basically whenever we receive an update on ResizeObserver, we may or may not still be animating.
  // so what we are going to do is store the size that is measured. Then we will trigger a check on the next frame
  // to see if that size is still identical or not.
  //
  // If the sizing has changed, we ignore because it means we're still mid animation. If the sizing is identical we're
  // done with whatever animating was happening and we can now invoke our callback with the observed width.
  //
  // This means we can trigger things like responsive size changes only when animations end and not use up compute power
  // to resize during animations
  const observerRef = ref<ResizeObserver | null>(null)
  watch(() => elementRef.value, (value, oldValue) => {
    // we are effectively only looking at a mount event for observation, we're ignoring any internal re-rendering
    // potential because we shouldn't be observing an array element.
    if (value && !oldValue) {
      const elementObserved = elementRef.value.$el
      // an initial callback to set up the correct sizing in the very beginning
      callback(elementObserved.getBoundingClientRect().width)

      observerRef.value = new ResizeObserver((entries) => {
        const observedWidth = entries[0].contentRect.width
        setTimeout(() => {
          const rectangle = elementObserved.getBoundingClientRect()
          // For some reason getBoudingClientRect().width is returning fractional decimals and the two values are slightly off.  To resolvet this we are rounding down which will have no impact since pixels cannot have fractions
          if (Math.floor(observedWidth) === Math.floor(rectangle.width)) {
            callback(observedWidth)
          }
        }, 17) // let's check every frame at 60fps, nextTick doesn't do what we need here.
      })
      observerRef.value.observe(elementObserved)
    }
  }, { immediate: true })
  onUnmounted(() => {
    observerRef.value?.disconnect()
  })
}
