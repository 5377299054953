import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "d-flex flex-column overflow-hidden"
  }, [_vm.columns.headers && _vm.columns.headers.length && _vm.data.length && !_vm.hideHeader && _vm.size !== 'card' ? _c('uc-rows-headers', {
    staticClass: "mb-2",
    staticStyle: {
      "gap": "10px"
    },
    attrs: {
      "font-size": _vm.columns.fontSize,
      "border": _vm.columns.border,
      "headers": _vm.columns.headers,
      "size": _vm.size,
      "can-expand": _vm.columns.canExpand,
      "show-actions": _vm.columns.showActions,
      "can-select": _vm.columns.canSelect
    }
  }) : _vm._e(), _vm.columns.headers && _vm.columns.headers.length && _vm.data.length && !_vm.hideHeader && _vm.size === 'card' ? _c('uc-rows-card-headers', {
    staticClass: "mb-2",
    attrs: {
      "headers": _vm.columns.headers,
      "can-expand": _vm.columns.canExpand,
      "show-actions": _vm.columns.showActions,
      "can-select": _vm.columns.canSelect
    }
  }) : _vm._e(), _c('div', {
    staticClass: "uc-rows__wrapper"
  }, [_c(VDataIterator, {
    ref: "dataIteratorRef",
    class: `uc-rows fill-height ${!_vm.data.length ? 'overflow-hidden no-data' : 'overflow-auto'}`,
    attrs: {
      "items": _vm.data,
      "disable-filtering": true,
      "disable-pagination": true,
      "disable-sort": "",
      "hide-default-footer": ""
    },
    on: {
      "item-selected": function ($event) {
        return _vm.$emit('item-selected', $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "no-data",
      fn: function () {
        var _vm$currentRansack, _vm$currentRansack$g, _vm$currentRansack$g$, _vm$currentRansack$g$2;
        return [_vm.totalPages == -1 ? _c('div', [_c('uc-list-row-loading', {
          attrs: {
            "first-row": true,
            "last-row": true,
            "size": _vm.size,
            "column-defs": _vm.columns
          }
        })], 1) : _vm.$slots['empty-state'] ? _vm._t("empty-state") : _c('div', [(_vm$currentRansack = _vm.currentRansack) !== null && _vm$currentRansack !== void 0 && (_vm$currentRansack$g = _vm$currentRansack.g) !== null && _vm$currentRansack$g !== void 0 && (_vm$currentRansack$g$ = _vm$currentRansack$g[0]) !== null && _vm$currentRansack$g$ !== void 0 && (_vm$currentRansack$g$2 = _vm$currentRansack$g$.c) !== null && _vm$currentRansack$g$2 !== void 0 && _vm$currentRansack$g$2[0] && !_vm.noFilters ? _c('div', {
          staticClass: "animation-container overflow-hidden d-flex fill-height flex-column align-center justify-center"
        }, [_c('dotlottie-player', {
          staticClass: "lottie-animation",
          style: `height: ${_vm.getHeight}`,
          attrs: {
            "autoplay": "",
            "loop": "",
            "src": require('@/assets/animations/ConfusedOtto.lottie')
          }
        }), _c('uc-text', {
          staticClass: "my-4",
          attrs: {
            "size": 2,
            "text": 'Oops! No results matching your filters!',
            "bold": true,
            "type": 'title'
          }
        })], 1) : _c('div', {
          staticClass: "animation-container overflow-hidden d-flex fill-height flex-column align-center justify-center"
        }, [_vm.iconName ? _c(VIcon, {
          attrs: {
            "size": "60px"
          }
        }, [_vm._v(" " + _vm._s(_vm.iconName) + " ")]) : _vm.animationData ? _c('dotlottie-player', {
          staticClass: "lottie-animation",
          style: `height: ${_vm.getHeight}`,
          attrs: {
            "autoplay": "",
            "loop": "",
            "src": typeof _vm.animationData === 'function' ? _vm.animationData() : _vm.animationData
          }
        }) : _vm._e(), _c('uc-text', {
          staticClass: "my-4",
          staticStyle: {
            "text-align": "center"
          },
          attrs: {
            "size": 2,
            "text": _vm.title,
            "bold": true,
            "type": 'title'
          }
        }), _vm.message ? _c('uc-text', {
          staticClass: "my-2",
          staticStyle: {
            "text-align": "center"
          },
          attrs: {
            "size": 2,
            "text": _vm.message,
            "bold": false,
            "type": 'body'
          }
        }) : _vm._e()], 1)])];
      },
      proxy: true
    }, {
      key: "item",
      fn: function (resource) {
        return [_c('uc-list-row-resource', _vm._g(_vm._b({
          key: `${resource.item.id}`,
          ref: 'listRow' + resource.index,
          staticClass: "uc-rows__wrapper",
          attrs: {
            "id": resource.item.id,
            "type": resource.item.type,
            "column-defs": _vm.columns,
            "item": resource.item,
            "size": _vm.size
          },
          on: {
            "toggle-select": function ($event) {
              resource.select(!resource.isSelected);
              _vm.emitSelectedResources();
            },
            "row-click": function ($event) {
              return _vm.$emit('row-click', {
                index: resource.index,
                id: resource.item.id,
                type: resource.item.type
              });
            },
            "row-dblclick": function ($event) {
              return _vm.$emit('row-dblclick', {
                index: resource.index,
                type: resource.item.type,
                id: resource.item.id,
                details: resource.item
              });
            }
          }
        }, 'uc-list-row-resource', {
          ...resource.item.props,
          firstRow: resource.index === 0,
          lastRow: resource.index === _vm.data.length - 1 && (_vm.currentPage === _vm.totalPages || _vm.totalPages === -1),
          isSelected: resource.isSelected,
          backgroundColor: _vm.selectedId === resource.item.id ? 'layer-active' : 'layer'
        }, false), _vm.listenerObj))];
      }
    }, _vm.canLoadMore ? {
      key: "footer",
      fn: function () {
        return [_c('uc-list-row-loading', {
          attrs: {
            "first-row": false,
            "last-row": false,
            "size": _vm.size,
            "column-defs": _vm.columns
          }
        }), _c('uc-list-row-loading', {
          attrs: {
            "first-row": false,
            "last-row": true,
            "size": _vm.size,
            "column-defs": _vm.columns
          }
        })];
      },
      proxy: true
    } : null], null, true),
    model: {
      value: _vm.localSelectedResources,
      callback: function ($$v) {
        _vm.localSelectedResources = $$v;
      },
      expression: "localSelectedResources"
    }
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };