export default {
  bind: (el:any, bindings:any) => {
    const source = el.innerText
    const search = bindings.value

    // Convert to array every time to handel the both array and single search.
    const newSearch = Array.isArray(search) ? search : [search]
    let foundMatch = false
    if (newSearch && newSearch.length > 0) {
      newSearch.forEach(ele => {
        const results: { text: any; match: boolean }[] = []
        const regexp = new RegExp(ele, 'ig')
        let start = 0
        for (const match of source.matchAll(regexp)) {
          results.push({
            text: source.substring(start, match.index),
            match: false
          })
          start = match.index
          results.push({
            text: source.substr(start, ele.length),
            match: true
          })
          start += ele.length
        }
        if (start < source.length) {
          results.push({ text: source.substring(start), match: false })
        }

        if (results.length === 0) {
          results.push({
            text: source,
            match: false
          })
        }

        let output = ''
        // Break the loop if we have found a match
        // Modified this code to handle the multipile search (Copy Paste Functionlity).
        if (foundMatch) return
        results.forEach(result => {
          if (result.match) {
            foundMatch = true
            if (bindings?.modifiers?.bg) {
              output += `<span style="background-color: yellow;">${result.text}</span>`
            } else {
              output += `<strong>${result.text}</strong>`
            }
          } else {
            output += `${result.text}`
          }
        })
        el.innerHTML = `${output}`
      })
    }
  }
}
