import { useInboundInvoices } from '@/use/invoices/inbound'
import { useOutboundInvoices } from '@/use/invoices/outbound'
import { snakeCase } from 'lodash'
import { AutocompletePayload, AutocompleteResponse } from '@/types/interfaces/api-v2/autocomplete'
import { ref } from 'vue'
import { FilterResources } from '@/types/enums/filter-types'

const { inboundInvoicesAutoComplete } = useInboundInvoices()
const { invoicesAutoComplete } = useOutboundInvoices()

const isOutbound = ref(false)

const invoiceStatuses: Record <string, string> = {
  Draft: 'draft',
  Sent: 'sent',
  Received: 'sent',
  Paid: 'paid',
  'Partially Paid': 'partially_paid',
  Void: 'void',
  Approved: 'approved',
  Rejected: 'rejected',
  'Approved To Send': 'approved_to_send'
}

const invoiceAutoCompleteFetchFn = async (params: AutocompletePayload) => {
  const { data, nextPage } = (isOutbound.value)
    ? await invoicesAutoComplete(params) as { data: AutocompleteResponse[], nextPage: number }
    : await inboundInvoicesAutoComplete(params) as { data: AutocompleteResponse[], nextPage: number }
  return {
    data: data.map((item: AutocompleteResponse, index: number) => {
      return {
        id: `${index}-${item.text}`,
        text: item.text,
        value: item.text
      }
    }),
    nextPage
  }
}

const INVOICE_NUMBER = (outbound: boolean, config = {}, params = { field: 'invoice_number' }, fetchFn: any = invoiceAutoCompleteFetchFn) => {
  isOutbound.value = outbound
  return {
    text: 'Number',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'invoice-number-filter',
      headerText: 'Invoice Number',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['invoice_number'],
      tokenSeparators: ['\t', '\n', ',']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.INVOICE,
    ...config
  }
}

const INVOICE_CUSTOM_NUMBER = (outbound: boolean, config = {}, params = { field: 'custom_invoice_number' }, fetchFn: any = invoiceAutoCompleteFetchFn) => {
  isOutbound.value = outbound
  return {
    text: 'Custom Number',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'invoice-custom-number-filter',
      headerText: 'Invoice Custom Number',
      size: 'lg',
      contentType: 'number',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['custom_invoice_number'],
      tokenSeparators: ['\t', '\n', ',']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.INVOICE,
    ...config
  }
}

const INVOICE_STATUS = (outbound: boolean, config = {}) => {
  return {
    text: 'Status',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'invoice-status-filter',
      headerText: 'Invoice Status',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn: async (params: any) => {
        const data = []

        for (const key of Object.keys(invoiceStatuses)) {
          data.push({
            id: snakeCase(key),
            text: key,
            value: invoiceStatuses[key]
          })
        }

        return {
          data,
          totalCount: Object.keys(invoiceStatuses).length,
          totalPages: 1
        }
      },
      params: { q: { s: 'invoice_status asc' } },
      searchKeys: ['text']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.INVOICE,
    ...config
  }
}

const INVOICE_DATE_OF_ISSUE = (outbound: boolean, config = {}) => {
  return {
    text: 'Date of Issue',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'invoice-date-of-issue-filter',
      headerText: 'Invoice Date of Issue',
      size: 'md',
      contentType: 'date',
      rowType: 'text',
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.INVOICE,
    ...config
  }
}

const INVOICE_DUE_DATE = (outbound: boolean, config = {}) => {
  return {
    text: 'Due Date',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'invoice-due-date-filter',
      headerText: 'Invoice Due Date',
      size: 'md',
      contentType: 'date',
      rowType: 'text',
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.INVOICE,
    ...config
  }
}

const INVOICE_RECEIVED_DATE = (config = {}) => {
  return {
    text: 'Received Date',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'invoice-received-date-filter',
      headerText: 'Invoice Received Date',
      size: 'md',
      contentType: 'date',
      rowType: 'text',
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.INVOICE,
    ...config
  }
}

const INVOICE_AGREEMENT_CONTRACT_NUMBER = (outbound: boolean, config = {}, params = { field: 'agreement_number' }, fetchFn: any = invoiceAutoCompleteFetchFn) => {
  isOutbound.value = outbound
  return {
    text: 'Schedule Service ID',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'invoice-schedule-service-filter',
      headerText: 'Schedule Service ID',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['agreement_number']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.INVOICE,
    ...config
  }
}

const INVOICE_AGREEMENT_CUSTOM_NUMBER = (outbound: boolean, config = {}, params = { field: 'agreement_custom_no' }, fetchFn: any = invoiceAutoCompleteFetchFn) => {
  isOutbound.value = outbound
  return {
    text: 'Schedule Service Custom ID',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'invoice-schedule-service-custom-id-filter',
      headerText: 'Schedule Service Custom ID',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['agreement_custom_no']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.INVOICE,
    ...config
  }
}

const INVOICE_EXTERNAL_REGION = (outbound: boolean, config = {}, params = { field: 'inv_region' }, fetchFn: any = invoiceAutoCompleteFetchFn) => {
  isOutbound.value = outbound
  return {
    text: 'Invoice External Region',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'invoice-external-region-filter',
      headerText: 'Invoice External Region',
      size: 'lg',
      contentType: 'number',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['inv_region']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.INVOICE,
    ...config
  }
}

export {
  INVOICE_NUMBER,
  INVOICE_CUSTOM_NUMBER,
  INVOICE_STATUS,
  INVOICE_DATE_OF_ISSUE,
  INVOICE_DUE_DATE,
  INVOICE_RECEIVED_DATE,
  INVOICE_AGREEMENT_CONTRACT_NUMBER,
  INVOICE_AGREEMENT_CUSTOM_NUMBER,
  INVOICE_EXTERNAL_REGION
}
