import { INTEGRATIONS_HEADER, ADDONS_HEADER, SERVICES_HEADER } from '@/constants/headers/integrations';
import { useObserveAndComputeAtEndOfResizing, useResponsiveness } from '@/use/responsiveness';
import { defineComponent, ref, watchEffect, watch } from 'vue';
import { INFO_OUTLINE } from '@/constants/icons';
import { DEFAULT_INVOICE_VOLUME, INVOICE_INCREMENTAL_VOLUME } from '@/constants/packages';
export default defineComponent({
  props: {
    integrationApps: {
      type: Array,
      default: () => []
    },
    invoiceVolume: {
      type: Number,
      default: null
    },
    planDetails: {
      type: Object,
      default: null
    },
    isStoreModal: {
      type: Boolean,
      default: false
    },
    hideDivider: {
      type: Boolean,
      default: false
    },
    addOns: {
      type: Array,
      default: () => []
    },
    services: {
      type: Array,
      default: () => []
    }
  },
  setup: (props, {
    emit
  }) => {
    var _props$planDetails, _props$planDetails2, _props$planDetails3;
    const integrationsArray = ref(((_props$planDetails = props.planDetails) === null || _props$planDetails === void 0 ? void 0 : _props$planDetails.packageIntegrations) || []);
    const professionalServices = ref(((_props$planDetails2 = props.planDetails) === null || _props$planDetails2 === void 0 ? void 0 : _props$planDetails2.professionalServices) || []);
    const productServices = ref(((_props$planDetails3 = props.planDetails) === null || _props$planDetails3 === void 0 ? void 0 : _props$planDetails3.productServices) || []);
    const {
      getContainerSize
    } = useResponsiveness();
    const hideHeader = ref(false);
    const integrationsTableRef = ref(null);
    const servicesTableRef = ref(null);
    const addOnsTableRef = ref(null);
    const usage = ref(props.invoiceVolume);
    useObserveAndComputeAtEndOfResizing(integrationsTableRef, observedWidth => {
      hideHeader.value = ['xs'].includes(getContainerSize(observedWidth));
    });
    useObserveAndComputeAtEndOfResizing(servicesTableRef, observedWidth => {
      hideHeader.value = ['xs'].includes(getContainerSize(observedWidth));
    });
    useObserveAndComputeAtEndOfResizing(addOnsTableRef, observedWidth => {
      hideHeader.value = ['xs'].includes(getContainerSize(observedWidth));
    });
    watchEffect(() => {
      if (integrationsTableRef !== null && integrationsTableRef !== void 0 && integrationsTableRef.value) {
        var _integrationsTableRef, _integrationsTableRef2, _integrationsTableRef3;
        integrationsTableRef.value.selectedResources = props.integrationApps;
        (_integrationsTableRef = integrationsTableRef.value) === null || _integrationsTableRef === void 0 ? void 0 : (_integrationsTableRef2 = _integrationsTableRef.resourceData) === null || _integrationsTableRef2 === void 0 ? void 0 : (_integrationsTableRef3 = _integrationsTableRef2.rows) === null || _integrationsTableRef3 === void 0 ? void 0 : _integrationsTableRef3.forEach(element => {
          var _props$planDetails4;
          if (props.integrationApps.some(data => {
            return data.id === element.id;
          })) {
            element.selected = true;
          } else if (props.integrationApps.length >= ((_props$planDetails4 = props.planDetails) === null || _props$planDetails4 === void 0 ? void 0 : _props$planDetails4.numberOfIntegrations)) {
            element.props.selectionDisabled = true;
          } else {
            element.props.selectionDisabled = false;
          }
        });
      }
      if (addOnsTableRef !== null && addOnsTableRef !== void 0 && addOnsTableRef.value) {
        var _addOnsTableRef$value, _addOnsTableRef$value2, _addOnsTableRef$value3;
        addOnsTableRef.value.selectedResources = props.addOns;
        (_addOnsTableRef$value = addOnsTableRef.value) === null || _addOnsTableRef$value === void 0 ? void 0 : (_addOnsTableRef$value2 = _addOnsTableRef$value.resourceData) === null || _addOnsTableRef$value2 === void 0 ? void 0 : (_addOnsTableRef$value3 = _addOnsTableRef$value2.rows) === null || _addOnsTableRef$value3 === void 0 ? void 0 : _addOnsTableRef$value3.forEach(element => {
          if (props.addOns.some(data => {
            return data.id === element.id;
          })) {
            element.selected = true;
          }
        });
      }
      if (servicesTableRef !== null && servicesTableRef !== void 0 && servicesTableRef.value) {
        var _servicesTableRef$val, _servicesTableRef$val2, _servicesTableRef$val3;
        servicesTableRef.value.selectedResources = props.services;
        (_servicesTableRef$val = servicesTableRef.value) === null || _servicesTableRef$val === void 0 ? void 0 : (_servicesTableRef$val2 = _servicesTableRef$val.resourceData) === null || _servicesTableRef$val2 === void 0 ? void 0 : (_servicesTableRef$val3 = _servicesTableRef$val2.rows) === null || _servicesTableRef$val3 === void 0 ? void 0 : _servicesTableRef$val3.forEach(element => {
          if (props.services.some(data => {
            return data.id === element.id;
          })) {
            element.selected = true;
          }
        });
      }
    });
    const saveSelectedIntegrations = (appData, type) => {
      emit('select-integration', appData.item, type);
    };
    watch(() => props.invoiceVolume, volume => {
      if (volume) {
        usage.value = volume;
      }
    });
    return {
      INTEGRATIONS_HEADER,
      integrationsArray,
      professionalServices,
      productServices,
      hideHeader,
      integrationsTableRef,
      saveSelectedIntegrations,
      INFO_OUTLINE,
      INVOICE_INCREMENTAL_VOLUME,
      DEFAULT_INVOICE_VOLUME,
      usage,
      addOnsTableRef,
      servicesTableRef,
      ADDONS_HEADER,
      SERVICES_HEADER
    };
  }
});