import {
  VENDOR_NAME,
  VENDOR_EMAIL,
  VENDOR_PHONE,
  VENDOR_COINS_ID,
  VENDOR_PLATFORM_ID,
  VENDOR_MARKETS,
  VENDOR_REGIONS,
  VENDOR_ONBOARDED,
  VENDOR_COI_EXPIRATION,
  VENDOR_COVERAGE_AMOUNT,
  VENDOR_COI_DOCUMENTS,
  VENDOR_COMPLIANCE,
  VENDOR_TRADE_NAME,
  VENDOR_SIC_CODE,
  VENDOR_CITY,
  VENDOR_STATE,
  VENDOR_COUNTRY,
  VENDOR_ZIP_CODE,
  VENDOR_ADDRESS,
  VENDOR_CLAIM_STATUS_FILTER
} from '@/constants/filters/resources/vendor'

export const pendingVendorsFilters = () => {
  return {
    filters: [
      VENDOR_NAME({ pinned: true, isPrimary: true }, { field: 'name', tab: 'pending' }),
      VENDOR_EMAIL({ pinned: true, isPrimary: true }, 'pending'),
      VENDOR_PHONE({ pinned: true, isPrimary: true }, 'pending'),
      VENDOR_COINS_ID({ pinned: false, isPrimary: true }, { field: 'vendor_custom_id', tab: 'pending' }),
      VENDOR_PLATFORM_ID({ pinned: false, isPrimary: true }, 'pending'),
      VENDOR_MARKETS({ pinned: false, isPrimary: true }, 'pending'),
      VENDOR_REGIONS({ pinned: false, isPrimary: true }, 'pending'),
      VENDOR_ONBOARDED({ pinned: false, isPrimary: true }),
      VENDOR_COI_EXPIRATION({ pinned: false, isPrimary: true }),
      VENDOR_COMPLIANCE({ pinned: false, isPrimary: true }),
      VENDOR_COVERAGE_AMOUNT({ pinned: false, isPrimary: true }, 'pending'),
      VENDOR_COI_DOCUMENTS({ pinned: true, isPrimary: true }),
      VENDOR_TRADE_NAME({ pinned: true, isPrimary: true }, 'pending'),
      VENDOR_SIC_CODE({ pinned: false, isPrimary: true }, 'pending'),
      VENDOR_CITY({ pinned: false, isPrimary: true }, { field: 'city', tab: 'pending' }),
      VENDOR_STATE({ pinned: true, isPrimary: true }, { field: 'state', tab: 'pending' }),
      VENDOR_COUNTRY({ pinned: false, isPrimary: true }, { field: 'country', tab: 'pending' }),
      VENDOR_ZIP_CODE({ pinned: true, isPrimary: true }, { field: 'zip_code', tab: 'pending' }),
      VENDOR_ADDRESS({ pinned: false, isPrimary: true }, { field: 'address1', tab: 'pending' }),
      VENDOR_CLAIM_STATUS_FILTER({ pinned: true, isPrimary: true }, 'pending')
    ],
    sortBy: {
      value: 'name',
      direction: {
        text: 'Ascending',
        value: 'asc'
      },
      items: [
        {
          label: 'Name',
          value: 'name'
        }
      ]
    },
    fieldMapping: {
      'vendor-name': {
        name: 'vendor_name',
        field: 'text'
      },
      'vendor-email': {
        name: 'vendor_email',
        field: 'text'
      },
      'vendor-phone-number': {
        name: 'vendor_phone',
        field: 'text'
      },
      'vendor-platform-id': {
        name: 'vendor_id',
        field: 'text'
      },
      'vendor-markets': {
        name: 'vendor_markets_label',
        field: 'text'
      },
      'vendor-trade-name': {
        name: 'vendor_root_trades_name',
        field: 'value'
      },
      'vendor-coins-id': {
        name: 'vendor_vendor_coins_connection_external_id',
        field: 'value'
      },
      'vendor-regions': {
        name: 'vendor_territories_name',
        field: 'value'
      },
      'vendor-on-boarded': {
        name: 'vendor_subdomain',
        field: 'value',
        operator: 'not_null'
      },
      'vendor-coi-expiration': {
        name: 'vendor_local_coi_documents_expiration_date',
        field: 'value',
        operator: 'days_between'
      },
      'vendor-coverage-amount': {
        name: 'vendor_local_coi_documents_coverage',
        field: 'value'
      },
      'vendor-coi-documents': {
        name: 'vendor_local_coi_documents_coi_label',
        field: 'value'
      },
      'vendor-compliance': {
        name: 'vendor_compliance',
        field: 'value'
      },
      'vendor-sic-code': {
        name: 'vendor_root_trades_sic_code',
        field: 'value'
      },
      'vendor-city': {
        name: 'vendor_headquarter_address_city',
        field: 'value'
      },
      'vendor-state': {
        name: 'vendor_headquarter_address_state',
        field: 'value'
      },
      'vendor-country': {
        name: 'vendor_headquarter_address_country',
        field: 'value'
      },
      'vendor-zip-code': {
        name: 'vendor_headquarter_address_zip_code',
        field: 'value'
      },
      'vendor-address': {
        name: 'vendor_headquarter_address_address1',
        field: 'value'
      },
      'vendor-claim-status': {
        name: 'vendor_cc_vendor_claim_status_status',
        field: 'value'
      }
    }
  }
}
