import { usePayments } from '@/use/payments'
import { useBillPayments } from '@/use/bill-payments'
import { useMakePayment } from '@/use/makePayment'
import { snakeCase, startCase } from 'lodash'

import { paymentStatuses } from '@/constants'
import { AutocompletePayload, AutocompleteResponse } from '@/types/interfaces/api-v2/autocomplete'

const { getPayments, paymentsAutoComplete } = usePayments()
const { getBillPayments } = useBillPayments()
const { madePaymentsAutoComplete } = useMakePayment()

const PAYMENT_REFERENCE_NUMBER = (received: boolean, config = {}) => {
  return {
    text: 'Reference Number',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'payment-reference-number-filter',
      headerText: 'Reference Number',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      tokenSeparators: ['\t', '\n', ','],
      fetchFn: async (params: AutocompletePayload) => {
        const { data, nextPage } = (received)
          ? await paymentsAutoComplete({ ...params }) as { data: AutocompleteResponse[], nextPage: number }
          : await madePaymentsAutoComplete({ ...params }) as { data: AutocompleteResponse[], nextPage: number }

        return {
          data: data.map((item: AutocompleteResponse, index: number) => {
            return {
              id: `${index}-${item.text}`,
              text: item.text,
              value: item.text
            }
          }),
          nextPage
        }
      },
      params: { field: 'reference_number' },
      searchKeys: ['reference_number']
    },
    pinned: false,
    isPrimary: false,
    values: [],
    ...config
  }
}

const PAYMENT_METHOD = (received: boolean, config = {}) => {
  return {
    text: 'Payment Method',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'payment-payment-method-filter',
      headerText: 'Payment Method',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn: async (params: AutocompletePayload) => {
        const { data, nextPage } = (received)
          ? await paymentsAutoComplete({ ...params }) as { data: AutocompleteResponse[], nextPage: number }
          : await madePaymentsAutoComplete({ ...params }) as { data: AutocompleteResponse[], nextPage: number }

        return {
          data: data.map((item: AutocompleteResponse, index: number) => {
            return {
              id: `${index}-${item.text}`,
              text: typeof item.text === 'string' ? startCase(item.text.replaceAll('_', ' ')) : '',
              value: item.text
            }
          }),
          nextPage
        }
      },
      params: { field: 'payment_method' },
      searchKeys: ['payment_method']
    },
    pinned: false,
    isPrimary: false,
    values: [],
    ...config
  }
}

// Not in use (Missing API)
const PAYMENT_DEPOSIT_ACCOUNT = (received: boolean, config = {}) => {
  return {
    text: 'Deposit Account',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'payment-deposit-account-filter',
      headerText: 'Deposit Account',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn: async (params: any) => {
        const { data, totalPages, totalCount } = (received)
          ? await getPayments({ ...params })
          : await getBillPayments({ ...params }) as any

        return {
          data: data.map((item: any) => {
            return {
              id: item.id,
              text: item?.PaymentAccount?.accountName,
              value: item?.PaymentAccount?.accountName
            }
          }),
          totalCount,
          totalPages
        }
      },
      params: { include: 'payment_account, payment_account/ledger_account', q: { s: 'deposit_account asc' } },
      searchKeys: ['deposit_account']
    },
    pinned: false,
    isPrimary: false,
    values: [],
    ...config
  }
}

const PAYMENT_DEPOSIT_DATE = (received: boolean, config = {}) => {
  return {
    text: (received) ? 'Deposit Date' : 'Payment Date',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'payment-deposit-date-filter',
      headerText: 'Deposit Date',
      size: 'md',
      contentType: 'date',
      rowType: 'text',
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    values: [],
    ...config
  }
}

// Not in use (We need the ability to do greater than, equal to or less than)
const PAYMENT_AMOUNT_RECEIVED = (received: boolean, config = {}) => {
  return {
    text: 'Amount',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'payment-amount-received-filter',
      headerText: 'Amount',
      size: 'lg',
      contentType: 'number',
      rowType: 'text',
      showHeaders: true,
      fetchFn: async (params: any) => {
        const { data, totalPages, totalCount } = (received)
          ? await getPayments({ ...params })
          : await getBillPayments({ ...params }) as any

        return {
          data: data.map((item: any) => {
            return {
              id: item.id,
              text: item.amount,
              value: item.amount
            }
          }),
          totalCount,
          totalPages
        }
      },
      params: { q: { s: 'amount asc' } },
      searchKeys: ['amount'],
      filterUnique: true
    },
    pinned: false,
    isPrimary: false,
    values: [],
    ...config
  }
}

const PAYMENT_STATUS = (received: boolean, config = {}) => {
  return {
    text: 'Status',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'payment-status-filter',
      headerText: 'Status',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn: async (params: any) => {
        const statuses = Object.keys(paymentStatuses)

        return {
          data: statuses.map((key: any) => {
            return {
              id: snakeCase(key),
              text: startCase(key.replaceAll('_', ' ')),
              value: key
            }
          }),
          totalCount: statuses.length,
          totalPages: 1
        }
      },
      params: { q: { s: 'status asc' } },
      searchKeys: ['status']
    },
    pinned: false,
    isPrimary: false,
    values: [],
    ...config
  }
}

export {
  PAYMENT_REFERENCE_NUMBER,
  PAYMENT_METHOD,
  PAYMENT_DEPOSIT_ACCOUNT,
  PAYMENT_DEPOSIT_DATE,
  PAYMENT_AMOUNT_RECEIVED,
  PAYMENT_STATUS
}
