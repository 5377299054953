import { useVendors, vendorStatuses } from '@/use/vendors'
import { lowerCase, startCase } from 'lodash'
import { AutocompletePayload, AutocompleteResponse } from '@/types/interfaces/api-v2/autocomplete'
import { FilterResources } from '@/types/enums/filter-types'

const { getClaimStatuses, vendorsAutoComplete } = useVendors()

const CREATED_OPTIONS = [
  {
    id: 'yes',
    text: 'Yes',
    value: true
  },
  {
    id: 'no',
    text: 'No',
    value: false
  }
]

const VENDOR_ONBOARDED_OPTIONS = [
  {
    label: 'Onboarded',
    value: 'true'
  },
  {
    label: 'Non Onboarded',
    value: 'false'
  }
]

const VENDOR_COMPLIANCE_OPTIONS = [
  {
    label: 'Compliant',
    value: 'true'
  },
  {
    label: 'Non Compliant',
    value: 'false'
  }
]

const VENDOR_CIO_EXPRATION_OPTIONS = [
  {
    label: 'Expired',
    value: '0'
  },
  {
    label: 'Expires 15 Days',
    value: '15'
  },
  {
    label: 'Expires 30 Days',
    value: '30'
  },
  {
    label: 'Expires 45 Days',
    value: '45'
  }
]

const vendorsAutoCompleteFetchFn = async (params: AutocompletePayload) => {
  const { data, nextPage } = await vendorsAutoComplete({ ...params }) as {data: AutocompleteResponse[], nextPage: number}
  return {
    data: data.map((item: AutocompleteResponse, index: number) => {
      return {
        id: `${index}-${item.text}`,
        text: item.text,
        value: item.text
      }
    }),
    nextPage
  }
}

const VENDOR_NAME = (config = {}, params = { field: 'name', tab: 'active' }, fetchFn: any = vendorsAutoCompleteFetchFn) => {
  return {
    text: 'Name',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'vendor-name-filter',
      headerText: 'Vendor Name',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['name']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.VENDOR,
    ...config
  }
}

const VENDOR_EMAIL = (config = {}, tab = 'active') => {
  return {
    text: 'Email',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'vendor-email-filter',
      headerText: 'Vendor Email',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn: vendorsAutoCompleteFetchFn,
      params: { field: 'email', tab },
      searchKeys: ['email'],
      tokenSeparators: ['\t', '\n', ',']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.VENDOR,
    ...config
  }
}

const VENDOR_PHONE = (config = {}, tab = 'active') => {
  return {
    text: 'Phone',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'vendor-phone-filter',
      headerText: 'Vendor Phone Number',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn: vendorsAutoCompleteFetchFn,
      params: { field: 'phone_number', tab },
      searchKeys: ['phone_number'],
      tokenSeparators: ['\t', '\n', ',']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.VENDOR,
    ...config
  }
}

const VENDOR_COINS_ID = (config = {}, params = { field: 'vendor_custom_id', tab: 'active' }, fetchFn: any = vendorsAutoCompleteFetchFn) => {
  return {
    text: 'Coins ID',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'vendor-coins-id-filter',
      headerText: 'Vendor Coins ID',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['vendor_custom_id'],
      tokenSeparators: ['\t', '\n', ',']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.VENDOR,
    ...config
  }
}

const VENDOR_STATUS = (config = {}) => {
  return {
    text: 'Status',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'vendor-status-filter',
      headerText: 'Vendor Status',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn: async (params: any) => {
        const data: any[] = []
        vendorStatuses.forEach((status: string) => {
          data.push({
            id: lowerCase(status),
            text: startCase(status),
            value: lowerCase(status)
          })
        })
        return {
          data,
          totalCount: data.length,
          totalPages: 1
        }
      },
      params: { q: { s: 'vendor_status asc' } },
      searchKeys: ['vendor_status']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.VENDOR,
    ...config
  }
}

const VENDOR_INVOICE_CREATED = (config = {}) => {
  return {
    text: 'Invoice Created',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'vendor-invoice-created-filter',
      headerText: 'Vendor Invoice Created',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn: async (params: any) => {
        return {
          data: CREATED_OPTIONS,
          totalCount: CREATED_OPTIONS.length,
          totalPages: 1
        }
      },
      params: { q: { } },
      searchKeys: ['vendor_invoice_created']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.VENDOR,
    ...config
  }
}

const VENDOR_APPLICATION_STATUS_FILTER = (config = {}) => {
  return {
    text: 'Application Status',
    canSelect: false,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'application-status',
      headerText: 'Vendor Application Status',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      params: {},
      searchKeys: ['text'],
      items: [
        { id: 0, value: 0, text: 'Draft' },
        { id: 1, value: 1, text: 'Submitted' },
        { id: 2, value: 2, text: 'Approved' },
        { id: 3, value: 3, text: 'Rejected' }
      ],
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.VENDOR,
    ...config
  }
}

const VENDOR_APPLICATION_CREATED_DATE = (config = {}) => {
  return {
    text: 'Application Created Date',
    canSelect: false,
    popoverProps: {
      height: 'unset',
      slotKey: 'application-status',
      headerText: 'Vendor Application Created Date',
      size: 'md',
      contentType: 'date',
      rowType: 'text',
      params: {},
      searchKeys: ['text'],
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.VENDOR,
    ...config
  }
}

const VENDOR_PLATFORM_ID = (config = {}, tab = 'active') => {
  return {
    text: 'Platform ID',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'vendor-platform-id-filter',
      headerText: 'Vendor Platform ID',
      size: 'lg',
      contentType: 'number',
      rowType: 'text',
      showHeaders: true,
      fetchFn: vendorsAutoCompleteFetchFn,
      params: { field: 'id', tab },
      searchKeys: ['id'],
      tokenSeparators: ['\t', '\n', ',']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.VENDOR,
    ...config
  }
}

const VENDOR_MARKETS = (config = {}, tab = 'active') => {
  return {
    text: 'Markets',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'vendor-markets-filter',
      headerText: 'Vendor Markets',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn: vendorsAutoCompleteFetchFn,
      params: { field: 'markets', tab },
      searchKeys: ['markets']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.VENDOR,
    ...config
  }
}

const VENDOR_REGIONS = (config = {}, tab = 'active') => {
  return {
    text: 'Regions',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'vendor-region-filter',
      headerText: 'Vendor Regions',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn: vendorsAutoCompleteFetchFn,
      filterUnique: true,
      params: { field: 'territory', tab },
      searchKeys: ['name']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.VENDOR,
    ...config
  }
}

const VENDOR_ONBOARDED = (config = {}) => {
  return {
    text: 'On Boarded',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'vendor-on-boarded-filter',
      headerText: 'Vendor On Boarded',
      size: 'md',
      contentType: 'select',
      rowType: 'text',
      showHeaders: true,
      fetchFn: () => {
        return {
          data: VENDOR_ONBOARDED_OPTIONS,
          totalCount: VENDOR_ONBOARDED_OPTIONS.length,
          totalPages: 1
        }
      },
      params: {},
      searchKeys: ['subdomain'],
      operator: [{ text: 'Is', value: '_not_null' }]
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.VENDOR,
    ...config
  }
}

// Missing query
const VENDOR_COI_EXPIRATION = (config = {}) => {
  return {
    text: 'COI Expiration Date',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'vendor-coi-expiration-filter',
      headerText: 'Vendor COI Expiration',
      size: 'md',
      contentType: 'select',
      rowType: 'text',
      showHeaders: true,
      fetchFn: () => {
        return {
          data: VENDOR_CIO_EXPRATION_OPTIONS,
          totalCount: VENDOR_CIO_EXPRATION_OPTIONS.length,
          totalPages: 1
        }
      },
      params: { q: { s: 'id asc' } },
      searchKeys: [],
      operator: [{ text: 'Is', value: '_days_between' }]
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.VENDOR,
    ...config
  }
}

// Missing API
const VENDOR_COVERAGE_AMOUNT = (config = {}, tab = 'active') => {
  return {
    text: 'Coverage Amount',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'vendor-coverage-amount-filter',
      headerText: 'Vendor Coverage Amount',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn: vendorsAutoCompleteFetchFn,
      params: { field: 'coverage', tab },
      searchKeys: []
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.VENDOR,
    ...config
  }
}

const VENDOR_COI_DOCUMENTS = (config = {}, tab = 'active') => {
  return {
    text: 'COI Documents',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'vendor-coi-documents-filter',
      headerText: 'Vendor COI Documents',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn: vendorsAutoCompleteFetchFn,
      params: { field: 'coi_documents', tab },
      searchKeys: ['coi_documents']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.VENDOR,
    ...config
  }
}

const VENDOR_COMPLIANCE = (config = {}) => {
  return {
    text: 'Compliance',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'vendor-compliance-filter',
      headerText: 'Vendor Compliance',
      size: 'md',
      contentType: 'select',
      rowType: 'text',
      showHeaders: true,
      fetchFn: () => {
        return {
          data: VENDOR_COMPLIANCE_OPTIONS,
          totalCount: VENDOR_COMPLIANCE_OPTIONS.length,
          totalPages: 1
        }
      },
      filterUnique: true,
      params: { q: { s: 'id asc' } },
      searchKeys: [],
      operator: [{ text: 'Is', value: '_eq' }]
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.VENDOR,
    ...config
  }
}

const VENDOR_TRADE_NAME = (config = {}, tab = 'active') => {
  return {
    text: 'Trade Name',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'vendor-trade-name-filter',
      headerText: 'Vendor Trade Name',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn: vendorsAutoCompleteFetchFn,
      params: { field: 'trade', tab },
      searchKeys: []
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.VENDOR,
    ...config
  }
}

const VENDOR_SIC_CODE = (config = {}, tab = 'active') => {
  return {
    text: 'SIC Code',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'vendor-sic-code-filter',
      headerText: 'Vendor SIC Code',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn: vendorsAutoCompleteFetchFn,
      params: { field: 'sic_code', tab },
      searchKeys: []
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.VENDOR,
    ...config
  }
}

const VENDOR_CITY = (config = {}, params = { field: 'city', tab: 'active' }, fetchFn: any = vendorsAutoCompleteFetchFn) => {
  return {
    text: 'City',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'vendor-city-filter',
      headerText: 'Vendor City',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: []
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.VENDOR,
    ...config
  }
}

const VENDOR_STATE = (config = {}, params = { field: 'state', tab: 'active' }, fetchFn: any = vendorsAutoCompleteFetchFn) => {
  return {
    text: 'State',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'vendor-state-filter',
      headerText: 'Vendor State',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: []
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.VENDOR,
    ...config
  }
}

const VENDOR_COUNTRY = (config = {}, params = { field: 'country', tab: 'active' }, fetchFn: any = vendorsAutoCompleteFetchFn) => {
  return {
    text: 'Country',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'vendor-country-filter',
      headerText: 'Vendor Country',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: []
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.VENDOR,
    ...config
  }
}

const VENDOR_ZIP_CODE = (config = {}, params = { field: 'zip_code', tab: 'active' }, fetchFn: any = vendorsAutoCompleteFetchFn) => {
  return {
    text: 'Zip Code',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'vendor-zip-code-filter',
      headerText: 'Vendor Zip Code',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: []
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.VENDOR,
    ...config
  }
}

const VENDOR_ADDRESS = (config = {}, params = { field: 'address1', tab: 'active' }, fetchFn: any = vendorsAutoCompleteFetchFn) => {
  return {
    text: 'Address',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'vendor-address-filter',
      headerText: 'Vendor Address',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: []
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.VENDOR,
    ...config
  }
}

const VENDOR_CLAIM_STATUS_FILTER = (config = {}, tab = 'active') => {
  return {
    text: 'Claim Status',
    canSelect: false,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'vendor-claim-status-filter',
      headerText: 'Vendor Claim Status',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn: async (params: any) => {
        const { data, totalPages, totalCount } = await getClaimStatuses({ ...params })
        return {
          data: data?.map((item: any, index: number) => {
            return {
              id: item.id || index,
              text: item.text,
              value: item.text
            }
          }),
          totalCount,
          totalPages
        }
      },
      params: { q: {}, tab },
      searchKeys: ['text']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.VENDOR,
    ...config
  }
}

export {
  VENDOR_NAME,
  VENDOR_EMAIL,
  VENDOR_PHONE,
  VENDOR_COINS_ID,
  VENDOR_STATUS,
  VENDOR_INVOICE_CREATED,
  VENDOR_APPLICATION_STATUS_FILTER,
  VENDOR_APPLICATION_CREATED_DATE,
  VENDOR_PLATFORM_ID,
  VENDOR_MARKETS,
  VENDOR_REGIONS,
  VENDOR_ONBOARDED,
  VENDOR_COI_EXPIRATION,
  VENDOR_COVERAGE_AMOUNT,
  VENDOR_COI_DOCUMENTS,
  VENDOR_COMPLIANCE,
  VENDOR_TRADE_NAME,
  VENDOR_SIC_CODE,
  VENDOR_CITY,
  VENDOR_STATE,
  VENDOR_COUNTRY,
  VENDOR_ZIP_CODE,
  VENDOR_ADDRESS,
  VENDOR_CLAIM_STATUS_FILTER
}
