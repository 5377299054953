import { reactive, ref, computed } from 'vue'
import repositories from '@/repositories'
import { roleColumns } from '@/constants'
import { startCase } from 'lodash'
import { useSession } from './session'
import { AutocompletePayload, AutocompleteResponse } from '@/types/interfaces/api-v2/autocomplete'
type Roles = {
  loading: boolean,
  list: Array<any>,
  latest:any,
  selected: any,
  meta: {
    currentPage: 1,
    nextPage: 2,
    prevPage: null,
    totalCount: 0,
    totalPages: 0
  },
}
const roles = reactive<Roles>({
  loading: false,
  selected: [],
  list: [],
  latest: [],
  meta: {
    currentPage: 1,
    nextPage: 2,
    prevPage: null,
    totalCount: 0,
    totalPages: 0
  }
})

interface LoadRolesParams {
  page: number
  perPage?: any
  q?: any,
  s?: any
}

export type FeatureViewModel = {
  id: number
  active: boolean
  open: boolean
  label: string
  description?: string
  subFeatures?: FeatureViewModel[]
}

export type CategoryViewModel = {
  name: string
  value: FeatureViewModel[]
}

const rolesRef = ref<any>(null)
export const useRoles = (emit:any) => {
  const availableColumns = ref<any[]>(roleColumns)
  const currentColumns = ref<any[]>([])
  const perPage = ref<number>(25)
  const showColSettingDialog = ref<boolean>(false)
  const page = ref<number>(1)
  const sortField = ref<string>('')
  const { session } = useSession()
  const totalRolePermissionCount = ref(0)

  const getRoles = async (params: any) => {
    try {
      const res = await repositories.roles.get(params)
      return {
        data: res.roles,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as any
    } catch (err) {
      return err
    }
  }
  const deleteRoles = async (rolesIds: Array<string>) => {
    try {
      const response = await repositories.roles.delete(rolesIds)
      return response
    } catch (error) {
      return error
    }
  }

  const getCompanyFeaturesPackage = async (q = {}, companyId: number) => {
    try {
      const res = await repositories.roles.getFeatures(q, companyId)
      return {
        data: res.features
      } as any
    } catch (err) {
      return err
    }
  }

  // Below API's are Deprecated

  const loadRoles = async ({ page, perPage, q, s }: LoadRolesParams) => {
    try {
      roles.loading = true
      const res = await repositories.roles.list({ page, perPage, q, s })
      if (page === 1) {
        roles.list = res.roles.length === 0 ? [] : [...res.roles]
        roles.latest = res.roles
      } else {
        const newItems: any[] = []
        res.roles.forEach((eachItem: any) => {
          const existingItemIndex = roles.list.findIndex((role) => role.id === eachItem.id)
          if (existingItemIndex < 0) {
            newItems.push(eachItem)
          }
        })
        roles.list = [...roles.list, ...newItems]
        roles.latest = res.roles
      }
      roles.meta = { ...res.meta }
    } catch (err) {
      console.log(err)
    } finally {
      roles.loading = false
    }
  }
  const search = async ({ page, perPage, q, s }: LoadRolesParams) => {
    try {
      const res = await repositories.roles.list({ page, perPage, q, s })
      return res
    } catch (err) {
      return {}
    }
  }
  const handleSelect = (selected: any) => {
    if (Array.isArray(selected)) {
      roles.list = [...roles.list]
    } else {
      const { item, value } = selected
      const findIndex = roles.list.findIndex((eachItem) => eachItem.id === item.id)
      roles.list[findIndex].selected = value
      roles.list = [...roles.list]
    }
  }
  const handleUpdate = () => {
    page.value = 1
    loadRoles({ page: page.value, perPage: perPage.value })
  }
  const handleInitAvailableColumns = () => {
    availableColumns.value = [...availableColumns.value]
  }
  const handleLoadMoreRoles = () => {
    page.value += 1
    loadRoles({ page: page.value, perPage: perPage.value })
  }
  const handleAppendSearchItem = async (item: any) => {
    await appendRoleItem(item)
    const element = document.getElementById(`role-item-${item.id}`) as HTMLElement
    if (element) {
      const tableRow = element.closest('tr')
      tableRow ? tableRow.classList.add('highlight') : element.classList.add('highlight')
      element.scrollIntoView({ behavior: 'smooth', inline: 'nearest' })
      setTimeout(() => {
        tableRow ? tableRow.classList.remove('highlight') : element.classList.remove('highlight')
      }, 3000)
    }
  }
  const appendRoleItem = (item: any) => {
    if (!roles.list.find((eachItem) => eachItem.id === item.id)) {
      roles.list = [{ ...item }, ...roles.list]
    }
  }
  const handleOpenColumnSetting = () => {
    showColSettingDialog.value = true
  }
  const handleCloseColumnSetting = () => {
    showColSettingDialog.value = false
  }
  const handleSaveColumnSetting = (columns: any[]) => {
    currentColumns.value = [...columns]
    showColSettingDialog.value = false
  }
  const handleSortFieldName = (sortFields: string[]) => {
    if (sortFields.length !== 0) {
      sortField.value = sortFields[0]
      emit('handle:sort', `${sortFields[0] === 'date' ? 'created_at' : sortFields[0] === 'price_per_seat' ? 'package_price_cents' : ''} asc`)
    } else {
      sortField.value = ''
      emit('handle:sort', null)
    }
  }

  const handleSortDesc = (desc: boolean[]) => {
    if (desc.length !== 0 && desc[0] === true && sortField.value !== '') {
      emit('handle:sort', `${sortField.value === 'date' ? 'created_at' : sortField.value === 'price_per_seat' ? 'package_price_cents' : ''} desc`)
    }
  }
  const handleSort = (sortQuery: string) => {
    const q = { s: sortQuery !== null ? sortQuery : {} }
    page.value = 1
    perPage.value = 25
    loadRoles({ page: 1, perPage: perPage.value, q })
  }

  const init = () => {
    loadRoles({ page: 1, perPage: perPage.value })
    handleInitAvailableColumns()
    currentColumns.value = [...availableColumns.value]
  }
  const selectedItems = computed(() => {
    return (roles.list as any[]).filter((item: any) => item.selected)
  })

  // Role Create
  const postRole = async (role: any) => {
    try {
      const response = await repositories.roles.postRole(role)
      return response
    } catch (error) {
      return error
    }
  }

  // Edit Role
  const editRole = async (param: any, id: number) => {
    try {
      const response = await repositories.roles.editRole(param, id)
      return response
    } catch (error) {
      return error
    }
  }

  const rolePermissions = async (availablePermission?: any, query?: object): Promise<CategoryViewModel[]> => {
    if (!session?.loggedUser?.company_id) {
      return []
    }

    const results = await getCompanyFeaturesPackage(query, session?.loggedUser?.company_id)

    if (!results?.data || results?.data?.errors) {
      return []
    }

    return results.data.map((item: any): CategoryViewModel => {
      const key = Object.getOwnPropertyNames(item)[0]
      return {
        name: startCase(key),
        value: item[key].map((data: any): FeatureViewModel[] => {
          totalRolePermissionCount.value = totalRolePermissionCount.value + 1
          const subFeatures = data.subFeatures.map((item: any): FeatureViewModel[] => {
            totalRolePermissionCount.value = totalRolePermissionCount.value + 1
            return {
              active: getAvailablePermissions(availablePermission, item.id),
              ...item
            }
          })
          data.subFeatures = subFeatures
          const active = getAvailablePermissions(availablePermission, data.id)
          return {
            active,
            open: active,
            ...data
          }
        })
      }
    })
  }

  const getAvailablePermissions = (permission:any, id: number) => {
    if (permission?.includes(id)) {
      return true
    }
    return false
  }

  const rolesAutoComplete = async (params: AutocompletePayload) => {
    try {
      const res = await repositories.roles.rolesAutoComplete(params)
      return {
        data: res.results,
        nextPage: res.nextPage
      } as { data: AutocompleteResponse[], nextPage: number }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const rolesSearch = async (params: any) => {
    try {
      const res = await repositories.roles.rolesSearch(params)
      return {
        data: res.roles,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as any
    } catch (err) {
      return err
    }
  }

  return {
    init,
    roles,
    search,
    loadRoles,
    handleInitAvailableColumns,
    handleSaveColumnSetting,
    availableColumns,
    perPage,
    selectedItems,
    showColSettingDialog,
    currentColumns,
    handleSelect,
    handleUpdate,
    handleLoadMoreRoles,
    handleAppendSearchItem,
    handleOpenColumnSetting,
    handleCloseColumnSetting,
    appendRoleItem,
    handleSortDesc,
    handleSortFieldName,
    handleSort,
    getRoles,
    deleteRoles,
    rolesRef,
    getCompanyFeaturesPackage,
    postRole,
    editRole,
    rolePermissions,
    totalRolePermissionCount,
    rolesAutoComplete,
    rolesSearch
  }
}
