import { FilterResources } from '@/types/enums/filter-types'
import { AutocompletePayload } from '@/types/interfaces'
import { AutocompleteResponse } from '@/types/interfaces/api-v2/autocomplete'
import { useTrips } from '@/use/trips'
import { extendedStatuses } from '../../statuses/extendedStatuses.json'

interface FiltersResponse {
  id: string,
  text: string,
  value: string | number
}

const { tripsAutoComplete } = useTrips()

const tripsAutoCompleteFetchFn = async (params: AutocompletePayload) => {
  const { data, nextPage } = await tripsAutoComplete({ ...params }) as { data: AutocompleteResponse[], nextPage: number }
  return {
    data: data.map((item: AutocompleteResponse, index: number) => {
      return {
        id: `${index}-${item.text}`,
        text: item.text,
        value: item.text
      }
    }),
    nextPage
  }
}

const TRIP_DISPATCH_DATE = (config = {}) => {
  return {
    text: 'Dispatch Date',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'trip-dispatch-date-filter',
      headerText: 'Trip Dispatch Date',
      size: 'md',
      contentType: 'date',
      rowType: 'text',
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.TRIP,
    ...config
  }
}

const TRIP_EXPIRATION_DATE = (config = {}) => {
  return {
    text: 'Expiration Date',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'trip-expiration-date-filter',
      headerText: 'Trip Expiration Date',
      size: 'md',
      contentType: 'date',
      rowType: 'text',
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.TRIP,
    ...config
  }
}

const TRIP_ETA_DATE = (config = {}) => {
  return {
    text: 'ETA Date',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'trip-eta-date-filter',
      headerText: 'Trip ETA Date',
      size: 'md',
      contentType: 'date',
      rowType: 'text',
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.TRIP,
    ...config
  }
}

const TRIP_ETC_DATE = (config = {}) => {
  return {
    text: 'ETC Date',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'trip-etc-date-filter',
      headerText: 'Trip ETC Date',
      size: 'md',
      contentType: 'date',
      rowType: 'text',
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.TRIP,
    ...config
  }
}

const TRIP_COMPLETION_DATE = (config = {}) => {
  return {
    text: 'Completion Date',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'trip-completion-date-filter',
      headerText: 'Trip Completion Date',
      size: 'md',
      contentType: 'date',
      rowType: 'text',
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.TRIP,
    ...config
  }
}

const TRIP_CATEGORY = (config = {}, params: any = { field: 'category' }, fetchFn: any = tripsAutoCompleteFetchFn) => {
  return {
    text: 'Trip Category',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'trip-category-filter',
      headerText: 'Trip Category',
      size: 'lg',
      contentType: 'number',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: []
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.TRIP,
    ...config
  }
}

const TRIP_STATUS = (config = {}) => {
  return {
    text: 'Status',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'trip-status-filter',
      headerText: 'Trip Status',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn: async () => {
        const filteredStatuses: FiltersResponse[] = []
        extendedStatuses.forEach(status => {
          filteredStatuses.push({
            id: status.text,
            text: status.text,
            value: status.code
          })
        })
        return {
          data: filteredStatuses,
          totalCount: filteredStatuses.length,
          totalPages: 1
        }
      },
      searchKeys: ['text']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.TRIP,
    ...config
  }
}

export {
  TRIP_DISPATCH_DATE,
  TRIP_EXPIRATION_DATE,
  TRIP_ETA_DATE,
  TRIP_ETC_DATE,
  TRIP_COMPLETION_DATE,
  TRIP_CATEGORY,
  TRIP_STATUS
}
