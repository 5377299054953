import { defineComponent, ref, watch } from 'vue';
export default defineComponent({
  props: {
    value: {
      type: [Array, String, Boolean],
      required: true
    },
    buttons: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    labelVariant: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup: (props, {
    emit
  }) => {
    watch(() => props.value, () => {
      selected.value = props.value;
    });
    const selected = ref(props.value);
    emit('input', selected.value);
    watch(() => selected.value, () => {
      emit('input', selected.value);
    });
    return {
      selected
    };
  }
});