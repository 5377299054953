import {
  INVOICE_NUMBER,
  INVOICE_CUSTOM_NUMBER,
  INVOICE_STATUS,
  INVOICE_DATE_OF_ISSUE,
  INVOICE_DUE_DATE,
  INVOICE_AGREEMENT_CONTRACT_NUMBER,
  INVOICE_AGREEMENT_CUSTOM_NUMBER
} from '@/constants/filters/resources/invoice'

import {
  WEATHER_EVENT,
  WEATHER_EVENT_START_DATE,
  WEATHER_EVENT_END_DATE
} from '@/constants/filters/resources/weatherEvent'

import {
  CLIENT_NAME,
  CLIENT_COUNTRY,
  CLIENT_STATE,
  CLIENT_CITY,
  CLIENT_ZIP,
  CLIENT_ADDRESS
} from '@/constants/filters/resources/client'

import {
  SITE_NAME,
  SITE_COUNTRY,
  SITE_CITY,
  SITE_ZIP,
  SITE_ADDRESS,
  SITE_REGION
} from '@/constants/filters/resources/site'
import { WORK_ORDER_NUMBER, WORK_ORDER_SERVICE, WORK_ORDER_TRADE, WORK_ORDER_STATUS } from '../resources/workOrder'
import { useSession } from '@/use/session'
import { BROKER, CLIENT } from '@/constants/permissions'
import { useOutboundInvoices } from '@/use/invoices/outbound'
import { AutocompleteResponse, AutocompletePayload } from '@/types/interfaces/api-v2/autocomplete'

export const receivableInvoiceFilters = () => {
  const { session } = useSession()
  const { invoicesAutoComplete } = useOutboundInvoices()

  const availableSiteFilters = () => {
    const baseFilters: any = [
    // SITE_COUNTRY({ pinned: false, isPrimary: false }),
    // SITE_CITY({ pinned: false, isPrimary: false }),
    // SITE_ZIP({ pinned: false, isPrimary: false }),
    // SITE_ADDRESS({ pinned: false, isPrimary: false })
    // SITE_REGION({ pinned: false, isPrimary: false })
    ]
    if (session.currentCompanyType === BROKER || session.currentCompanyType === CLIENT) {
      baseFilters.push(SITE_NAME({ pinned: true, isPrimary: true }, { field: 'site', invoiceables: false }, invoiceAutoCompleteFetchFn))
    }
    return baseFilters
  }

  const availableWeatherEventFilters = () => {
    const baseFilters: any = []
    if (session.currentCompanyType === BROKER) {
      baseFilters.push(
        WEATHER_EVENT({ pinned: false, isPrimary: false }),
        WEATHER_EVENT_START_DATE({ pinned: false, isPrimary: false }),
        WEATHER_EVENT_END_DATE({ pinned: false, isPrimary: false })
      )
    }
    return baseFilters
  }

  const availableWorkOrderFilters = () => {
    const baseFilters: any = [
      WORK_ORDER_NUMBER({ pinned: true, isPrimary: true }, { field: 'wo_id', invoiceables: false }, invoiceAutoCompleteFetchFn),
      WORK_ORDER_STATUS({ pinned: true, isPrimary: true })
    ]
    if (session.currentCompanyType === BROKER) {
      baseFilters.push(
        WORK_ORDER_SERVICE({ pinned: false, isPrimary: false }, { field: 'trade_service' }, invoiceAutoCompleteFetchFn),
        WORK_ORDER_TRADE({ pinned: false, isPrimary: false }, { field: 'trade' }, invoiceAutoCompleteFetchFn)
      )
    }
    return baseFilters
  }

  const invoiceAutoCompleteFetchFn = async (params: AutocompletePayload) => {
    const { data, nextPage } = await invoicesAutoComplete(params) as { data: AutocompleteResponse[], nextPage: number }
    return {
      data: data.map((item: AutocompleteResponse, index: number) => {
        return {
          id: `${index}-${item.text}`,
          text: item.text,
          value: item.text
        }
      }),
      nextPage
    }
  }

  return {
    invoices: {
      filters: [
        INVOICE_NUMBER(true, { pinned: true, isPrimary: true }, { field: 'invoice_number' }, invoiceAutoCompleteFetchFn),
        INVOICE_CUSTOM_NUMBER(true, { pinned: false, isPrimary: false }, { field: 'custom_invoice_number' }, invoiceAutoCompleteFetchFn),
        INVOICE_STATUS(true, { pinned: true, isPrimary: true }),
        INVOICE_DATE_OF_ISSUE(true, { pinned: false, isPrimary: false }),
        INVOICE_DUE_DATE(true, { pinned: true, isPrimary: true }),
        INVOICE_AGREEMENT_CONTRACT_NUMBER(true, { pinned: false, isPrimary: false }, { field: 'agreement_number' }, invoiceAutoCompleteFetchFn),
        INVOICE_AGREEMENT_CUSTOM_NUMBER(true, { pinned: false, isPrimary: false }, { field: 'agreement_custom_no' }, invoiceAutoCompleteFetchFn)
      ],
      sortBy: {
        value: 'date_of_issue',
        direction: {
          text: 'Descending',
          value: 'desc'
        },
        items: [
          {
            label: 'Status',
            value: 'status'
          },
          {
            label: 'Due Date',
            value: 'due_date'
          },
          {
            label: 'Date of Issue',
            value: 'date_of_issue'
          },
          {
            label: 'Invoice #',
            value: 'short_invoice_number'
          },
          {
            label: 'Amount Due',
            value: 'payable_balance_cents'
          }
        ]
      },
      fieldMapping: {
        'invoice-number': {
          name: 'short_invoice_number',
          field: 'value'
        },
        'invoice-custom-number': {
          name: 'custom_invoice_number',
          field: 'value'
        },
        'invoice-work-order-number': {
          name: 'work_orders_id',
          field: 'value'
        },
        'invoice-status': {
          name: 'status',
          field: 'value'
        },
        'invoice-date-of-issue': {
          name: 'date_of_issue',
          field: 'value'
        },
        'invoice-due-date': {
          name: 'due_date',
          field: 'value'
        },
        'schedule-service-id': {
          name: 'agreement_contract_id',
          field: 'value'
        },
        'schedule-service-custom-id': {
          name: 'agreement_contract_custom_id',
          field: 'value'
        }
      }
    },
    'weather-events': {
      filters: [
        ...availableWeatherEventFilters()
      ],
      fieldMapping: {
        'weather-event': {
          name: 'event_id',
          field: 'value'
        },
        'weather-event-start-date': {
          name: 'event_start_date',
          field: 'value'
        },
        'weather-event-end-date': {
          name: 'invoice_resources_resource_of_WeatherWorks::StormDetail_type_storm_end',
          field: 'value'
        }
      }
    },
    clients: {
      filters: [
        CLIENT_NAME({ pinned: true, isPrimary: true }, { field: 'client', tab: 'active' }, invoiceAutoCompleteFetchFn)
      // CLIENT_COUNTRY({ pinned: false, isPrimary: false }),
      // CLIENT_STATE({ pinned: false, isPrimary: false }),
      // CLIENT_CITY({ pinned: false, isPrimary: false }),
      // CLIENT_ZIP({ pinned: false, isPrimary: false }),
      // CLIENT_ADDRESS({ pinned: false, isPrimary: false })
      ],
      fieldMapping: {
        'client-name': {
          name: 'company_name',
          field: 'value'
        },
        'client-country': {
          name: 'company_address_country',
          field: 'value'
        },
        'client-state': {
          name: 'company_address_state',
          field: 'value'
        },
        'client-city': {
          name: 'company_address_city',
          field: 'value'
        },
        'client-zip': {
          name: 'company_address_zip_code',
          field: 'value'
        },
        'client-address': {
          name: 'company_address_full_address',
          field: 'value'
        }
      }
    },
    sites: {
      filters: [
        ...availableSiteFilters()
      ],
      fieldMapping: {
        'site-name': {
          name: 'locations_name',
          field: 'value'
        },
        'site-country': {
          name: 'locations_address_country',
          field: 'value'
        },
        'site-city': {
          name: 'locations_address_city',
          field: 'value'
        },
        'site-zip': {
          name: 'locations_address_zip_code',
          field: 'value'
        },
        'site-address': {
          name: 'locations_address_full_address',
          field: 'value'
        },
        'site-region': {
          name: 'locations_cc_territory_name',
          field: 'text'
        }
      }
    },
    'work-orders': {
      filters: [
        ...availableWorkOrderFilters()
      ],
      fieldMapping: {
        'work-order-number': {
          linkingTables: {
            clients: 'work_orders',
            sites: 'work_orders',
            proposals: 'work_order',
            'work-orders': '',
            events: ''
          },
          name: 'work_order_id',
          field: 'value',
          dynamicQueryKeys: {
            workOrdersName: 'id',
            weatherEventsName: 'id'
          },
          rowTypesToQuery: [
            'clients',
            'sites',
            'work-orders',
            'proposals',
            'events'
          ]
        },
        'work-order-service': {
          name: 'work_order_work_order_trade_services_trade_service_name',
          field: 'value'
        },
        'work-order-trade': {
          name: 'work_order_work_order_trades_trade_name',
          field: 'value'
        },
        'work-order-event-end-date': {
          name: 'invoice_resources_resource_of_WeatherWorks::StormDetail_type_storm_end_date',
          field: 'value'
        },
        'work-order-status': {
          name: 'work_order_work_order_status_name',
          field: 'value'
        }
      }
    }
  }
}
