export const onboardingPrivateFlow = [
  { componentName: 'join-company', name: 'Create Company', show: false, value: 'default' },
  { componentName: 'select-company-type', name: 'Create Company', show: false, value: 'default' },
  { componentName: 'create-company', name: 'Create Company', show: true, value: 'company_profile' },
  { componentName: 'create-url', name: 'Create Company', show: false, value: 'company_profile' },
  // stepValue  : To save the current step status to the API a unique step name need to pass to the API
  // isProtected: By using this we can identify the route that are used after company url created in the onboard flow.
  //              Which this is required not to skip any steps until they complete the current step and also to check the route they entered is protected route or not.
  {
    componentName: 'chargebee',
    name: 'Subscribe',
    show: true,
    stepValue: 'subscribe',
    isProtected: true,
    value: 'subscribe'
  },
  {
    componentName: 'select-trades',
    name: 'Select Trades',
    show: true,
    stepValue: 'trades',
    value: 'trades',
    isProtected: true
  },

  // commenting these for beta version
  { componentName: 'w9', name: 'W9', stepValue: 'w9', value: 'w9', show: true, isProtected: true },
  { componentName: 'compliance', name: 'Compliance', show: true, isProtected: true, stepValue: 'coi', value: 'coi', childRoutes: ['insurance/index'] },
  {
    componentName: 'custom-form',
    name: 'Additional Questions',
    show: true,
    stepValue: 'custom-form',
    value: 'custom-form',
    isProtected: true
  },
  {
    componentName: 'invite-team',
    name: 'Invite Team',
    show: true,
    stepValue: 'invite',
    value: 'invite',
    isProtected: true
  },
  {
    componentName: 'create-connected-account',
    name: 'Payments',
    show: true,
    stepValue: 'payments',
    value: 'payments',
    isProtected: true
  }
]
