import { api } from '@/api'
import qs from 'qs'

interface Params {
  q: any
  page: number
  perPage: number
  auth: boolean
}

export default {
  loadDashboardAuthDetails: () => api.$get('/sisense_dashboards/auth'),

  getDashboardList: (params: any) => {
    const parameters: Params = params
    const { q, page, perPage } = parameters
    return api.$get('/sisense_dashboards', {
      params: {
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    })
  }
}
