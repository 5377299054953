import repositories from '@/repositories'
import { baseGetParams, InvoiceParams } from '@/utils/api'
import { useTransactionResources } from '@/use/transactional-resources'
import { useInvoices } from '@/use/invoices'
import { INVOICE_RESOURCE_TYPE } from '@/constants/resource-types'
import { outboundActionData } from '@/constants/actions-by-resource/invoices/outbound'
import { InvoiceEmailParams } from '@/types/interfaces/api-v2/invoice'
import { INVOICE_INCLUDE } from '@/constants/include'
import { AutocompletePayload, AutocompleteResponse } from '@/types/interfaces/api-v2/autocomplete'
import { useDetailsPanel } from '../details-panel'

interface InvoiceUpdateParams {
  invoiceId: string
  updateContent: any,
  include?: string,
  objectScope?: 'both' | 'single' | 'multiple'
}

export const useOutboundInvoices = () => {
  const { invoiceActionModalProps, invoiceActionListenersAndFunctions, SAVED_INVOICE_STATUSES, flattenBatchInvoice } = useInvoices()
  const {
    sharedBasicActions,
    basicOutboundActions,
    resourceModalConfirmationHandler
  } = useTransactionResources()
  const basicInvoiceOutboundActions = [
    ...basicOutboundActions(invoiceActionListenersAndFunctions),
    {
      value: 'receive-payment',
      bulkConfig: {
        type: 'primary'
      },
      actionFn: (invoices: any[], additionalParams: any) => {
        invoiceActionListenersAndFunctions['receive-payment'](
          invoices, additionalParams
        )
      }
    },
    {
      value: 'save',
      bulkConfig: {
      }
    },
    {
      value: 'save-as-draft',
      bulkConfig: {
      }
    },
    {
      value: 'save-and-approve-to-send',
      bulkConfig: {
      }
    },
    {
      value: 'save-and-mark-sent',
      bulkConfig: {
      }
    },
    {
      value: 'save-and-send',
      bulkConfig: {
      }
    },
    {
      value: 'skip',
      bulkConfig: {
      }
    },
    {
      value: 'download',
      bulkConfig: {
      },
      actionFn: (proposals: any[], additionalParams: any) => {
        invoiceActionListenersAndFunctions.download(
          proposals,
          additionalParams,
          true

        )
      },
      noSuccessEmit: true
    },
    {
      value: 'view',
      updateSelectedResource: true,
      actionFn: (invoices: any) => {
        const { openGlobalDetailsPanel } = useDetailsPanel()
        const { outboundInvoiceModalRequiredActions, basicInvoiceOutboundActions } = useOutboundInvoices()
        openGlobalDetailsPanel('uc/resource-details', {
          actions: [
            ...basicInvoiceOutboundActions,
            ...outboundInvoiceModalRequiredActions
          ],
          selectedResources: invoices,
          resourceType: INVOICE_RESOURCE_TYPE,
          canDoubleClickToEdit: true,
          outbound: true
        }, '', { }, { })
      },
      noSuccessEmit: true
    },
    ...sharedBasicActions(invoiceActionListenersAndFunctions)
  ]

  const outboundInvoiceModalRequiredActions = [
    {
      value: 'delete',
      requiresConfirmation: true,
      bulkConfig: {
      },
      modal: invoiceActionModalProps.delete,
      actionFn: async (invoices: any[], additionalParams: any) => {
        await deleteOutboundInvoice(invoices[0].id, 'both')
      }
    },
    {
      value: 'void',
      requiresConfirmation: true,
      canUndo: true,
      bulkConfig: {
        type: 'menu',
        textColor: 'error'
      },
      modal: invoiceActionModalProps.void,
      actionFn: async (invoices: any[], additionalParams: any) => {
        invoices = invoices.map((invoice:any) => {
          if (invoice.props) {
            return invoice.props.invoice
          } else return invoice
        })
        if (invoices.length === 1) {
          await updateInvoice({ invoiceId: invoices[0].id, updateContent: { status: 'void' }, include: INVOICE_INCLUDE, objectScope: 'both' })
        } else {
          resourceModalConfirmationHandler('void', invoices, additionalParams, INVOICE_RESOURCE_TYPE, patchInvoicesBulkUpdate, { directionalActionData: outboundActionData, statuses: SAVED_INVOICE_STATUSES }, true)
        }
      }
    },
    {
      value: 'mark-sent',
      requiresConfirmation: true,
      canUndo: true,
      bulkConfig: {
        type: 'menu'
      },
      modal: invoiceActionModalProps.markSent,
      actionFn: async (invoices: any[], additionalParams: any) => {
        invoices = invoices.map((invoice:any) => {
          if (invoice.props) {
            return invoice.props.invoice
          } else return invoice
        })
        if (invoices.length === 1) {
          await updateInvoice({ invoiceId: invoices[0].id, updateContent: { status: 'sent' }, include: INVOICE_INCLUDE, objectScope: 'both' })
        } else {
          resourceModalConfirmationHandler('mark-sent', invoices, additionalParams, INVOICE_RESOURCE_TYPE, patchInvoicesBulkUpdate, { directionalActionData: outboundActionData, statuses: SAVED_INVOICE_STATUSES }, true)
        }
      }
    },
    {
      value: 'assign-department',
      requiresConfirmation: true,
      canUndo: true,
      bulkConfig: {
        type: 'menu'
      },
      modal: invoiceActionModalProps.assignDepartment,
      actionFn: (invoices: any[], additionalParams: any) => {
        invoices = invoices.map((invoice:any) => {
          if (invoice.props) {
            return invoice.props.invoice
          } else return invoice
        })
        resourceModalConfirmationHandler('assign-department', invoices, additionalParams, INVOICE_RESOURCE_TYPE, invoices.length > 1 ? patchInvoicesBulkUpdate : updateInvoice, { directionalActionData: outboundActionData, statuses: SAVED_INVOICE_STATUSES }, true)
      }
    },
    {
      value: 'approve-to-send',
      requiresConfirmation: true,
      canUndo: true,
      bulkConfig: {
        type: 'menu'
      },
      modal: invoiceActionModalProps.approveToSend,
      actionFn: async (invoices: any[], additionalParams: any) => {
        invoices = invoices.map((invoice:any) => {
          if (invoice.props) {
            return invoice.props.invoice
          } else return invoice
        })
        if (invoices.length === 1) {
          await updateInvoice({ invoiceId: invoices[0].id, updateContent: { status: 'approved_to_send' }, include: INVOICE_INCLUDE, objectScope: 'both' })
        } else {
          resourceModalConfirmationHandler('approve-to-send', invoices, additionalParams, INVOICE_RESOURCE_TYPE, patchInvoicesBulkUpdate, { directionalActionData: outboundActionData, statuses: SAVED_INVOICE_STATUSES }, true)
        }
      }
    }
  ]

  const getInvoices = async (params: any) => {
    try {
      const res: any = await repositories.invoices.get(params)
      return {
        data: res.invoices,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const invoicesSearch = async (params: any) => {
    try {
      const res: any = await repositories.invoices.invoicesSearch(params)
      return {
        data: res.invoices,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const deleteInvoiceWithId = async (invoiceId: string, isVendor: boolean) => {
    try {
      isVendor ? await repositories.invoices.deleteInboundInvoiceWithId(invoiceId) : await repositories.invoices.deleteOutboundInvoiceWithId(invoiceId)
    } catch (error) {
      console.log(error)
      return 'error'
    }
  }

  const deleteOutboundInvoice = async (invoiceId: string, objectScope = 'both'): Promise<{error?: boolean, success?: string}> => {
    try {
      return await repositories.invoices.deleteOutboundInvoice(invoiceId, objectScope)
    } catch (err) {
      return { error: true }
    }
  }

  const getOutboundInvoiceNotes = async (invoiceId: string | number) => {
    try {
      const res = await repositories.invoices.getOutboundInvoiceNotes(invoiceId)
      return res
    } catch (err: any) {
      console.error(err)
    }
  }

  const patchInvoicesBulkUpdate = async (params: any) => {
    try {
      if (!params.query || !params.field || !params.value) throw new Error('(Id array or q) and new status required to call this endpoint')
      const res = await repositories.invoices.patchBulkUpdate(params)
      return res
    } catch (err: any) {
      console.error(err)
      return false
    }
  }

  const postInvoicesSendBulkEmail = async (body: any, objectScope: 'both' | 'multiple' | 'single') => {
    try {
      // if (!ids) throw new Error('Id array required in params')
      const invoiceEmail = await repositories.invoices.postSendBulkEmail(body, objectScope)
      return { data: invoiceEmail.successInvoices || false }
    } catch (err: any) {
      console.error(err)
      return null
    }
  }

  const postInvoiceSendEmail = async (invoiceId: number, invoiceEmail: InvoiceEmailParams, objectScope: string) => {
    try {
      const res = await repositories.invoices.sendEmail(invoiceId, invoiceEmail, objectScope)
      return res
    } catch (err: any) {
      console.error(err)
    }
  }

  const getInvoicesNew = async (params: baseGetParams) => {
    try {
      const res = await repositories.invoices.new(params)
      return res
    } catch (err: any) {
      console.error(err)
    }
  }

  const postBatchInvoices = async (params: baseGetParams, requestBody:any) => {
    try {
      const res = await repositories.invoices.newBatch(params, requestBody)
      return res
    } catch (err: any) {
      console.error(err)
    }
  }

  const getInvoiceById = async (id: number, params: InvoiceParams) => {
    try {
      const res = await repositories.invoices.getById(id, params)
      return { data: res.invoice }
    } catch (err: any) {
      console.error(err)
      return { data: false }
    }
  }

  const postInvoices = async (params: any, requestBody: any) => {
    try {
      let res:any
      if (requestBody.invoice.grouped) {
        res = await repositories.batchInvoices.post(params, requestBody)
        return flattenBatchInvoice(res)
      } else {
        res = await repositories.invoices.post(params, requestBody)
        return res.invoice
      }
    } catch (err: any) {
      console.error(err)
      return false
    }
  }

  const updateInvoice = async ({ invoiceId, updateContent, include, objectScope }: InvoiceUpdateParams) => {
    try {
      let res:any
      if (updateContent.grouped) {
        res = await repositories.batchInvoices.update({ invoiceId, updateContent, include, objectScope })
        return { invoice: flattenBatchInvoice(res) }
      } else {
        return await repositories.invoices.update({ invoiceId, updateContent, include, objectScope })
      }
    } catch (err) {
      console.error(err)
      return false
    }
  }

  const forwardInvoice = async (invoiceId: number, markup: number, addMarkupToInvoice:boolean, include = '') => {
    try {
      const res = await repositories.invoices.forwardInvoice(invoiceId, markup, addMarkupToInvoice, include)
      return res
    } catch (err: any) {
      console.error(err)
      return { data: false }
    }
  }

  // must pass in YYYY/MM/DD format
  const totalInvoiceAmount = async (startEndDate: any) => {
    try {
      return await repositories.invoices.totalAmount(startEndDate)
    } catch (e) {
      console.log(e)
      return false
    }
  }

  const INVOICE_GROUP_BY_CONFIG_OPTIONS = [{ id: 2, name: 'Client' }, { id: 0, name: 'Site' }, { id: 1, name: 'Work Order' }]

  const getBatchInvoiceById = async (invoiceId: string | number, include: string) => {
    try {
      const res = await repositories.batchInvoices.getById(Number(invoiceId), { include })
      return { data: flattenBatchInvoice(res) }
    } catch (err: any) {
      console.error(err)
      return { data: false }
    }
  }

  const invoicesAutoComplete = async (params: AutocompletePayload) => {
    try {
      const res: any = await repositories.invoices.invoicesAutoComplete(params)
      return {
        data: res.results,
        nextPage: res.nextPage
      } as { data: AutocompleteResponse[], nextPage: number }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const fetchWorkOrdersDescription = async (workOrderId: number | string, tripIds: (number | string)[], invoiceId: number | string) => {
    try {
      const res: any = await repositories.workOrders.fetchWorkOrdersDescription(workOrderId, tripIds, invoiceId)
      return {
        data: res.description
      } as { data: string }
    } catch (err) {
      console.log(err)
      return { data: '' }
    }
  }

  const actionableInvoices = async (params: { ids?: number[] | string[], q?: any[], status: string }) => {
    try {
      const res: { actionableIds: number[], nonActionableIds: number[] } = await repositories.invoices.actionableInvoices(params)
      return {
        data: res
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  return {
    deleteInvoiceWithId,
    getInvoices,
    deleteOutboundInvoice,
    updateInvoice,
    patchInvoicesBulkUpdate,
    getOutboundInvoiceNotes,
    postInvoicesSendBulkEmail,
    getInvoicesNew,
    postInvoices,
    getInvoiceById,
    postBatchInvoices,
    forwardInvoice,
    basicInvoiceOutboundActions,
    outboundInvoiceModalRequiredActions,
    totalInvoiceAmount,
    INVOICE_GROUP_BY_CONFIG_OPTIONS,
    postInvoiceSendEmail,
    getBatchInvoiceById,
    invoicesAutoComplete,
    fetchWorkOrdersDescription,
    invoicesSearch,
    actionableInvoices
  }
}
