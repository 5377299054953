var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "uc-button-group d-flex justify-center"
  }, _vm._l(_vm.filteredButtons, function (button, index) {
    return _c('uc-button', _vm._b({
      key: index,
      on: {
        "click": function ($event) {
          _vm.$emit(button.eventName || _vm.buttonEmitName(button.text));
          button.click && button.click();
        }
      }
    }, 'uc-button', {
      ...button
    }, false));
  }), 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };