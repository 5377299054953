import { defineComponent, ref } from 'vue';
export default defineComponent({
  props: {
    location: {
      type: String,
      default: 'top-center',
      validator: val => {
        return ['top-left', 'bottom-left', 'top-right', 'bottom-right', 'top-center', 'bottom-center'].includes(val.toLowerCase());
      }
    }
  },
  setup: (props, {
    emit
  }) => {
    const isOpen = ref(false);
    const state = ref('');
    const text = ref('');
    const prepend = ref('');
    const append = ref('');
    const onClose = ref(null);
    let closeTimeout = null;
    const show = ({
      timeout = 3000,
      color = 'primary',
      message,
      prependIcon = '',
      appendIcon = '',
      onCloseFn = null
    }) => {
      if (!['primary', 'warning', 'error', 'success'].includes(color.toLowerCase())) {
        console.error('Color param does not match a valid color state');
      }
      state.value = color;
      text.value = message;
      prepend.value = prependIcon;
      append.value = appendIcon;
      onClose.value = onCloseFn;
      isOpen.value = true;
      if (timeout) {
        closeTimeout = setTimeout(() => {
          close();
        }, timeout);
      }
    };
    const close = () => {
      closeTimeout && clearTimeout(closeTimeout);
      onClose.value && onClose.value();
      isOpen.value = false;
      emit('close');
    };
    return {
      show,
      isOpen,
      close,
      state,
      text,
      prepend,
      append,
      onClose
    };
  }
});