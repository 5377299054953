import { api } from '@/api'
import { baseGetParams } from '@/utils/api'
import qs from 'qs'
import { AutocompletePayload } from '@/types/interfaces/api-v2/autocomplete'

interface getInboundPurchaseOrdersParams {
  include?: any
  q: any
  page: number
  perPage:number
}

export default {
  get: ({ include, page, q, perPage }: getInboundPurchaseOrdersParams) =>
    api.$get('/inbound_purchase_orders', {
      params: {
        include,
        page,
        per_page: perPage,
        q
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    }),
  getById: (id: number, { include }: baseGetParams) => api.$get(`/inbound_purchase_orders/${id}`, {
    params: {
      include
    },
    paramsSerializer: (params: any) => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  post: (params: any, requestBody: any) => {
    return api.$post('/inbound_purchase_orders', {
      ...requestBody
    }, {
      params,
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  update: (id: number, updatedPurchaseOrder: any) => {
    return api.$patch(`/inbound_purchase_orders/${id}`, {
      include: 'delivery_client,department,purchase_order_assignment',
      params: {
        id
      },
      purchaseOrder: updatedPurchaseOrder
    })
  },
  getPreviewPdfById: (purchaseOrderId: any) => {
    return api.$getBuffer(`/inbound_purchase_orders/${purchaseOrderId}/preview_pdf`, {
      params: {
        download: true
      },
      responseType: 'arraybuffer',
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },
  delete: (purchaseOrderId: string) => {
    return api.$delete(`/inbound_purchase_orders/${purchaseOrderId}`)
  },
  updateStatus: (purchaseOrderId: number, updatedStatus: string) => {
    const payload = {
      purchase_order: {
        status: updatedStatus
      }
    }
    return api.$patch(`/inbound_purchase_orders/${purchaseOrderId}/update_status`, payload)
  },
  inboundPurchaseOrdersAutoComplete: (params: AutocompletePayload) => {
    const { field, terms, predicate, finderParams, page, perPage } = params
    return api.$get('/inbound_purchase_orders/autocomplete', {
      params: {
        field,
        terms,
        predicate,
        finderParams,
        page,
        perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  inboundPurchaseOrdersSearch: ({ include, page, q, perPage }: getInboundPurchaseOrdersParams) => {
    return api.$post('/inbound_purchase_orders/search', {
      include,
      page,
      per_page: perPage,
      q
    },
    {
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  }
}
