import { defineComponent, ref, computed } from 'vue';
import { validate } from '@/utils';
import { useResponsiveness } from '@/use/responsiveness';
import { CHECKBOX_COL, ACTIONS_COL, DRAWER_CONTENT, DRAWER_CONTENT_EXPANDER_COL } from '@/types/interfaces';
export default defineComponent({
  props: {
    fontSize: {
      type: String,
      default: 'lg'
    },
    border: {
      type: Boolean,
      default: false
    },
    headers: {
      type: Array,
      default: () => []
    },
    size: {
      type: String,
      required: true
    },
    canSelect: {
      type: Boolean,
      default: true
    },
    showActions: {
      type: Boolean,
      default: true
    },
    canExpand: {
      type: Boolean,
      default: true
    }
  },
  setup: props => {
    const {
      getFlexRatioByScrenSize
    } = useResponsiveness();
    const textRef = ref(null);
    const getFontSize = size => {
      if (size === 'sm') return 3;
      if (size === 'md') return 2;
      if (size === 'lg') return 1;
    };
    const {
      isEllipses
    } = validate;
    const computedNumberOfColumns = computed(() => {
      return getFlexRatioByScrenSize(props.size, props.headers);
    });
    // alignment function because it may not default to start/left
    return {
      getFontSize,
      textRef,
      isEllipses,
      computedNumberOfColumns,
      CHECKBOX_COL,
      ACTIONS_COL,
      DRAWER_CONTENT,
      DRAWER_CONTENT_EXPANDER_COL
    };
  }
});