import { api } from '@/api'
import { PostWorkOrderStatusChangeQuestionObj } from '@/types/interfaces'
import { WorkOrderBillingStatuses } from '@/types/enums'
import qs from 'qs'

interface Params {
  include?: string
  q?: any
  page?: number
  perPage?: number
}

export default {
  get: (workOrderId: number, statusType: string, { include, q, page, perPage }: Params) => {
    return api.$get(`/work_orders/${workOrderId}/${statusType}/wo_status_change_questions`, {
      params: {
        include,
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },

  bulkGet: (statusType: string, { include, q, page, perPage }: Params) => {
    return api.$get(`/work_orders/${statusType}/wo_status_change_questions/bulk_get`, {
      params: {
        include,
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },

  post: (workOrderId: number, statusType: string, requestBody: PostWorkOrderStatusChangeQuestionObj) => {
    if (statusType === WorkOrderBillingStatuses.READY_FOR_BILLING) {
      return api.$post(
        `/work_orders/${workOrderId}/${statusType}/wo_status_change_questions`,
        { invoiceChangeQuestionForReadyForBillings: requestBody }
      )
    } else {
      return api.$post(
        `/work_orders/${workOrderId}/${statusType}/wo_status_change_questions`,
        { invoiceChangeQuestionForRejectNotReadyForBillings: requestBody }
      )
    }
  },

  patch: (workOrderId: number, workOrderStatusChangeQuestionID: number, statusType: string, requestBody: PostWorkOrderStatusChangeQuestionObj) => {
    if (statusType === WorkOrderBillingStatuses.READY_FOR_BILLING) {
      return api.$patch(
        `/work_orders/${workOrderId}/${statusType}/wo_status_change_questions/${workOrderStatusChangeQuestionID}`,
        { invoiceChangeQuestionForReadyForBillings: requestBody }
      )
    } else {
      return api.$patch(
        `/work_orders/${workOrderId}/${statusType}/wo_status_change_questions/${workOrderStatusChangeQuestionID}`,
        { invoiceChangeQuestionForRejectNotReadyForBillings: requestBody }
      )
    }
  },

  bulkCreate: (workOrderId: number, statusType: string, requestBody: PostWorkOrderStatusChangeQuestionObj[]) => {
    if (statusType === WorkOrderBillingStatuses.READY_FOR_BILLING) {
      return api.$post(
        `/work_orders/${workOrderId}/${statusType}/wo_status_change_questions/bulk_create`,
        { workOrder: { invoiceChangeQuestionForReadyForBillingsAttributes: requestBody } }
      )
    } else {
      return api.$post(
        `/work_orders/${workOrderId}/${statusType}/wo_status_change_questions/bulk_create`,
        { workOrder: { invoiceChangeQuestionForRejectNotReadyForBillingsAttributes: requestBody } }
      )
    }
  },

  bulkUpdate: (workOrderId: number, statusType: string, requestBody: PostWorkOrderStatusChangeQuestionObj[]) => {
    if (statusType === WorkOrderBillingStatuses.READY_FOR_BILLING) {
      return api.$patch(
        `/work_orders/${workOrderId}/${statusType}/wo_status_change_questions/bulk_update`,
        { workOrder: { invoiceChangeQuestionForReadyForBillingsAttributes: requestBody } }
      )
    } else {
      return api.$patch(
        `/work_orders/${workOrderId}/${statusType}/wo_status_change_questions/bulk_update`,
        { workOrder: { invoiceChangeQuestionForRejectNotReadyForBillingsAttributes: requestBody } }
      )
    }
  }
}
