import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    infiniteScroll: {
      type: Boolean,
      default: false
    },
    transition: {
      type: String,
      default: ''
    },
    hasNextPage: {
      type: Boolean,
      default: true
    },
    height: {
      type: [Number, String],
      default: null
    },
    width: {
      type: [Number, String],
      required: true
    },
    fillType: {
      type: String,
      default: 'dynamic'
    },
    backgroundColor: {
      type: String,
      default: 'var(--v-background-base)'
    }
  },
  setup: (props, {
    emit
  }) => {
    const emitClickOutside = () => {
      emit('click-outside');
    };
    return {
      emitClickOutside
    };
  }
});