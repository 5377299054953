import { kebabCase, uniqBy } from 'lodash'
import { onMounted } from 'vue'
// function to help generate nested drawers in a list
export interface nestedDrawerRow {
  props?: Record<string, any>, // props to pass to row wrapper component
  type?: string, // determines row component to render
  selected?: boolean, // nestedDrawerRow is selected
  expanded?: boolean, // nestedDrawerRow is expanded
  rows: nestedDrawerRow[], // nestedDrawerRow has children of the same shape
  page: number, // what page are we on for the child rows
  totalPages: number,
  totalCount: number,
  hasMoreRecords?: boolean,
  id?: number | string,
  firstRow?: boolean,
  lastRow?: boolean,
  fetching?: boolean, // are we fetching more data to prevent double load
  onExpand?(...params: any): void // what Fn to call when this row is expanded (likely populates the rows key of this object)
  onSelect?(...params: any): void // what Fn to call when this row is selected
  onMounted?(...params: any): void
}
export const generateRows = (drawer: nestedDrawerRow, response: any[], type: string, props: any, expandFn: (identifiers: number[], expanded: boolean) => void, selectFn: (identifiers: number[], expanded: boolean) => void) => {
  return response?.map?.((resource: any, index: number):nestedDrawerRow => {
    const row:nestedDrawerRow = {
      props: {
      },
      id: resource.id,
      firstRow: !drawer.rows?.length ? index === 0 : false,
      lastRow: drawer.page === drawer.totalPages && response.length - 1 === index,
      rows: [] as nestedDrawerRow[],
      type,
      selected: drawer.selected || !!resource?.selected,
      expanded: false,
      onExpand: expandFn,
      onSelect: selectFn,
      page: 0,
      totalPages: 0,
      totalCount: 0,
      hasMoreRecords: true
    }
    if (row.props) {
      row.props[kebabCase(type)] = resource
      row.props = {
        ...row.props,
        ...props
      }
    }
    return row
  })
}

export interface Response {
  data: any[],
  totalPages: number,
  totalCount: number,
  nextPage: boolean
}

export interface BaseParams {
  page: number,
  perPage: number,
  q: Record<string, string | number>,
  include: string,
  status?: string
}

// backwards compatible version that does not throw an error when failing.
export const fetchAndGenerateChildData = async (parentData: nestedDrawerRow, additionalParams: any, rowType: string, rowProps: any, fetchFn: any, newSearch = false, rowExpandFn:any = () => null, rowSelectFn:any = () => null, filterUniqueBy = '') => {
  try {
    return await fetchAndGenerateChildDataWithFailure(parentData, additionalParams, rowType, rowProps, fetchFn, newSearch, rowExpandFn, rowSelectFn, filterUniqueBy)
  } catch (err) {
    console.error(err)
    return []
  }
}

// the generate rows function will essentially handle the generation of the correct listeners/props to be binded to the row, but this wrapper
// allows searching, easy infinite scrolling, api calls.
export const fetchAndGenerateChildDataWithFailure = async (parentData: nestedDrawerRow, additionalParams: any, rowType: string, rowProps: any, fetchFn: any, newSearch = false, rowExpandFn:any = () => null, rowSelectFn:any = () => null, filterUniqueBy = '') => {
  if (newSearch) {
    parentData.page = 0
    parentData.rows = []
  }
  let params = {
    page: ++parentData.page,
    perPage: 15,
    q: {}
  }
  params = {
    ...params,
    ...additionalParams
  }

  const { data, totalCount, totalPages, nextPage }: Response = await fetchFn(params) || {}
  parentData.hasMoreRecords = !!nextPage
  if (parentData.page === 1) {
    parentData.totalCount = totalCount
    parentData.totalPages = totalPages
  }

  const rows = generateRows(
  {
    ...parentData,
    selected: parentData.selected || false,
    expanded: false
  } as nestedDrawerRow,
  data,
  rowType,
  rowProps,
  rowExpandFn,
  rowSelectFn
  )

  if (rows?.length) {
    parentData.rows.push(...rows)
  }
  // filter unique values
  if (filterUniqueBy) {
    parentData.rows = uniqBy(parentData.rows, filterUniqueBy)
  }

  return rows as any
}

export const fetchAndGenerateOnMounted = (data: nestedDrawerRow) => {
  onMounted(() => {
    if (data.page === 0) {
      data.onMounted && data.onMounted()
    }
  })
}

export const canInjectIntoTable = async (id: string, fetchFn:any, filterQueryFn:any, params:any) => {
  const newParams = {
    ...params,
    q: {
      ...filterQueryFn(),
      id_eq: id
    }
  }
  const response = await fetchFn(newParams)
  return response.data
}
