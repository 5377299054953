import { ref, reactive } from 'vue'
import repositories from '@/repositories'
import _ from 'lodash'
import { AutocompletePayload, AutocompleteResponse } from '@/types/interfaces/api-v2/autocomplete'

const isActive = ref(true)

const currentUser = ref<any>(null)

type CompanyUsers = {
  loading: boolean,
  list: Array<Record<string, any>>,
  latest: any[],
  meta:any
}

const companyUsers = reactive<CompanyUsers>({
  loading: false,
  list: [],
  latest: [],
  meta: {
    currentPage: 1,
    nextPage: 2,
    prevPage: null,
    totalCount: 0,
    totalPages: 0
  }
})

interface LoadCompanyUsersParams {
  page: number
  perPage: number
  q?: any
  active: boolean
}
export const useCompanyUsers = () => {
  const loadCompanyUsers = async ({ page, perPage, q, active }: LoadCompanyUsersParams) => {
    try {
      companyUsers.loading = true
      isActive.value = active
      const res = active
        ? await repositories.companyUsers.getCompanyUsersList({ page, perPage, q })
        : await repositories.companyUsers.getInactiveCompanyUsersList({ page, perPage, q })
      if (page === 1) {
        companyUsers.list = res.companyUsers.length === 0 ? [] : [...res.companyUsers]
      } else {
        res.companyUsers.length !== 0 && companyUsers.list.push(...res.companyUsers)
      }
      if (!res || !res.companyUsers) {
        throw new Error('couldnt get company users')
      }
      companyUsers.latest = res.companyUsers
      companyUsers.meta = { ...res.meta }
      return res
    } catch (err) {
      console.log(err)
      return false
    } finally {
      companyUsers.loading = false
    }
  }
  const loadCompanyUsersWithoutSaving = async ({ page, perPage, q, active }: LoadCompanyUsersParams) => {
    try {
      return active
        ? await repositories.companyUsers.getCompanyUsersList({ page, perPage, q })
        : await repositories.companyUsers.getInactiveCompanyUsersList({ page, perPage, q })
    } catch (err) {
      console.log(err)
      return {
        companyUsers: [],
        meta: {}
      }
    }
  }
  const deleteCompanyUser = async (userId: number) => {
    try {
      const res = await repositories.companyUsers.deleteCompanyUser(userId)
      companyUsers.list.splice(companyUsers.list.findIndex((user: any) => user.companyUserId === userId), 1)
      return res
    } catch (err) {
      console.log(err)
      return false
    }
  }
  const resendInvitation = async (userId: number) => {
    try {
      const res = await repositories.companyUsers.resendInvitation(userId)
      return res
    } catch (err) {
      console.log(err)
      return false
    }
  }
  const approveCompanyUser = async (userId: number) => {
    try {
      const res = await repositories.companyUsers.approveCompanyUser(userId)
      return res
    } catch (err) {
      console.log(err)
      return false
    }
  }
  const inviteEmployee = async (id:number, data:any) => {
    try {
      companyUsers.loading = true
      const formData = new FormData()
      formData.append('client_id', String(id))
      data.forEach((lineItem:any, index:any) => {
        Object.keys(lineItem).forEach((key) => {
          const snakeCaseKey = _.snakeCase(key)
          formData.append(`company_users_attributes[${index}][${snakeCaseKey}]`, lineItem[key])
        })
        formData.append(`company_users_attributes[${index}][client_invitation]`, 'true')
      })
      const res = await repositories.companyUsers.inviteEmployee(formData)
      if (res && res.success) { return true } else { return false }
    } catch (err) {
      console.log(err)
    } finally {
      companyUsers.loading = false
    }
  }
  const invite = async (data:any) => {
    try {
      companyUsers.loading = true
      const formData = { company: data }
      const res = await repositories.companyUsers.invite(formData)
      if (res && res.success) { return res } else { throw new Error('couldnt invite team members') }
    } catch (err) {
      console.log(err)
    } finally {
      companyUsers.loading = false
    }
  }
  const getCompanyUser = async (id:number) => {
    try {
      currentUser.value = await repositories.companyUsers.getCompanyUser(id)
    } catch (err) {
      return false
    }
  }

  const getCompanyUsers = async (params: any) => {
    try {
      const res = await repositories.companyUsers.getCompanyUsersList(params)
      return {
        data: res.companyUsers,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as any
    } catch (err) {
      console.error(err)
    }
  }

  const teamMembersAutoComplete = async (params: AutocompletePayload) => {
    try {
      const res = await repositories.companyUsers.teamMembersAutoComplete(params)
      return {
        data: res.results,
        nextPage: res.nextPage
      } as { data: AutocompleteResponse[], nextPage: number }
    } catch (err) {
      console.error(err)
      return { data: false }
    }
  }

  const companyUsersSearch = async (params: any) => {
    try {
      const res = await repositories.companyUsers.companyUsersSearch(params)
      return {
        data: res.companyUsers,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as any
    } catch (err) {
      console.error(err)
    }
  }

  return {
    companyUsers,
    loadCompanyUsers,
    loadCompanyUsersWithoutSaving,
    deleteCompanyUser,
    resendInvitation,
    isActive,
    approveCompanyUser,
    inviteEmployee,
    invite,
    currentUser,
    getCompanyUser,
    getCompanyUsers,
    teamMembersAutoComplete,
    companyUsersSearch
  }
}
