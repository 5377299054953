import repositories from '@/repositories'
import { ref } from 'vue'
import { AutocompletePayload, AutocompleteResponse } from '@/types/interfaces/api-v2/autocomplete'

const walletModalRef = ref<any>(null)

export const usePayments = () => {
  const deletePayments = async (params: any) => {
    try {
      const res: any = await repositories.payments.delete(params)
      return res
    } catch (err: any) {
      console.log(err)
      return err?.data
    }
  }
  const getPayments = async (params: any) => {
    try {
      const res: any = await repositories.payments.get(params)
      return {
        data: res.payments,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      }
    } catch (err) {
      console.log(err)
    }
  }

  const getReceivePaymentMethods = async () => {
    try {
      const res = await repositories.payments.receivePaymentMethods()
      return res
    } catch (err: any) {
      console.log(err)
    }
  }

  const postPaymentsReceiveInvoicesPayments = async (
    params: any,
    requestBody: any
  ) => {
    try {
      const res = await repositories.payments.receiveInvoicesPayments(
        params,
        requestBody
      )
      return res
    } catch (err: any) {
      console.error(err)
    }
  }

  const paymentsAutoComplete = async (params: AutocompletePayload) => {
    try {
      const res = await repositories.payments.paymentsAutoComplete(params)
      return {
        data: res.results,
        nextPage: res.nextPage
      } as { data: AutocompleteResponse[], nextPage: number }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const paymentsSearch = async (params: any) => {
    try {
      const res: any = await repositories.payments.paymentsSearch(params)
      return {
        data: res.payments,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      }
    } catch (err) {
      console.log(err)
    }
  }

  return {
    deletePayments,
    getPayments,
    getReceivePaymentMethods,
    postPaymentsReceiveInvoicesPayments,
    walletModalRef,
    paymentsAutoComplete,
    paymentsSearch
  }
}
