import { defineComponent, ref } from 'vue';
export default defineComponent({
  props: {
    intersect: {
      type: Boolean,
      default: false
    }
  },
  setup: (props, {
    emit
  }) => {
    const firstTime = ref(true);
    const emitIntersect = (entries, observer, isIntersecting) => {
      if (props.intersect && !firstTime.value) {
        isIntersecting && emit('intersect');
      }
      if (firstTime.value) {
        firstTime.value = false;
      }
    };
    return {
      emitIntersect
    };
  }
});