import { SEND } from '@/constants/icons'
import { DetailsPanelButtonState } from '@/types/enums'

export const outboundActionData: any[] = [
  {
    name: 'Edit',
    validStatuses: [
      'draft',
      'rejected'
    ],
    primaryAction: [
      'draft',
      'rejected'
    ]
  },
  {
    name: 'Send',
    validStatuses: [
      'draft',
      'rejected'
    ],
    primaryAction: [
    ],
    detailsPanel: DetailsPanelButtonState.Icon,
    buttonIcon: SEND
  },
  {
    name: 'Delete',
    validStatuses: [
      'draft',
      'closed'
    ],
    primaryAction: [
    ],
    textColor: 'error'
  },
  {
    name: 'Approve',
    validStatuses: [
      'sent'
    ],
    primaryAction: [
    ],
    textColor: 'success'
  },
  {
    name: 'Reject',
    validStatuses: [
      'sent'
    ],
    primaryAction: [
    ],
    textColor: 'error'
  },
  {
    name: 'Close',
    validStatuses: [
      'accepted',
      'rejected'
    ],
    primaryAction: [
      'accepted'
    ]
  },
  {
    name: 'Save And Send',
    validStatuses: ['unsaved'],
    primaryAction: [
    ]
  },
  {
    name: 'Save And Mark Sent',
    validStatuses: ['unsaved'],
    primaryAction: [
    ]
  },
  {
    name: 'Save As Draft',
    validStatuses: ['unsaved'],
    primaryAction: [
    ]
  },
  {
    name: 'Save',
    primaryAction: [
      'draft',
      'rejected'
    ],
    validStatuses: [],
    detailsPanel: DetailsPanelButtonState.Only
  },
  {
    name: 'Assign Department',
    validStatuses: ['draft', 'sent', 'rejected', 'accepted', 'approved_to_send'],
    primaryAction: [
    ]
  },
  {
    name: 'Skip',
    primaryAction: [
      'skip'
    ],
    validStatuses: true,
    textColor: 'error',
    detailsPanel: DetailsPanelButtonState.Only
  }
]
