
export enum ExtendedStatus {
  OPEN_UNASSIGNED_WARNING = 'open_unassigned_warning',
  OPEN_UNASSIGNED = 'open_unassigned',
  REJECTED = 'rejected',
  TECHNICIAN_NEVER_ARRIVED = 'technician_never_arrived',
  TECHNICIAN_NEVER_ARRIVED_OR_EXPIRED = 'technician_never_arrived_or_expired',
  ETA_MISSED = 'eta_missed',
  NOT_CHECK_OUT = 'not_check_out',
  IN_PROGRESS = 'in_progress',
  OPEN_ASSIGNED = 'open_assigned',
  MUST_RETURN = 'must_return',
  UNABLE_TO_INSPECT = 'unable_to_inspect',
  OPEN_UNASSIGNED_DISPATCHED = 'open_unassigned_dispatched',
  NEEDS_REVIEW = 'needs_review',
  PENDING_CLOSED = 'pending_closed',
  COMPLETED = 'completed',
  TRIP_VERIFIED = 'trip_verified',
  CLOSED_INCOMPLETE = 'closed_incomplete',
  CANCELED = 'canceled',
  UNSCHEDULED = 'unscheduled'
}
