var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    class: ['uc-button-menu d-flex flex-row align-center overflow-hidden', _vm.hidePadding ? '' : 'px-2'],
    on: {
      "click": function ($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('uc-button', _vm._b({
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.hideButtonOnMobile && Object.keys(_vm.buttonProps).length,
      expression: "!hideButtonOnMobile && Object.keys(buttonProps).length"
    }],
    staticClass: "flex-1",
    class: {
      'uc-button-menu__action': _vm.menuData.rows.length !== 0
    },
    on: {
      "click": function ($event) {
        _vm.buttonProps.click && _vm.buttonProps.click();
        _vm.getEmitName(_vm.buttonProps.eventName || _vm.buttonProps.text);
      }
    }
  }, 'uc-button', {
    ..._vm.buttonProps,
    disabled: _vm.disabled
  }, false)), _vm.menuData.rows.length !== 0 || _vm.hideButtonOnMobile ? [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.hideButtonOnMobile,
      expression: "!hideButtonOnMobile"
    }],
    staticClass: "button-divider"
  }), _c('uc-menu', _vm._g(_vm._b({
    attrs: {
      "content-width": _vm.contentWidth,
      "data": {
        ..._vm.menuData,
        rows: _vm.menuActions
      },
      "alignment": _vm.menuProps.alignment || null,
      "emit-row-text": "",
      "data-cy": 'uc-active-menu'
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on,
        attrs
      }) {
        return [_c('uc-button', _vm._g(_vm._b({
          class: !_vm.hideButtonOnMobile ? 'uc-button-menu__activator' : null
        }, 'uc-button', {
          ..._vm.activatorButtonProps,
          ...attrs,
          disabled: _vm.disabled
        }, false), on))];
      }
    }], null, false, 2724839254)
  }, 'uc-menu', _vm.menuProps, false), _vm.$listeners))] : _vm._e()], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };