import { api } from '@/api'
import qs from 'qs'
import { AttachmentParams } from '@/use/work-order/attachments'

interface GetWorkOrderAttachmentsParams {
  workOrderId?: string
  tripId?: string
  scope?: string
  page?: number
  perPage?: number
  sendScSyncDetails?: boolean
}

export default {
  get: ({ workOrderId, tripId, scope, page, perPage }: GetWorkOrderAttachmentsParams) => {
    return api.$get(`work_orders/${workOrderId}/attachments`, {
      params: {
        scope,
        tripId,
        page,
        perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },
  createWorkOrderAttachment: (params: AttachmentParams, requestBody: FormData) => {
    return api.$post(`/work_orders/${params.workOrderId}/attachments`, requestBody, {
      params: {
        include: 'user',
        send_sc_sync_details: params.sendScSyncDetails
      },
      headers: {
        content_type: 'multipart/form-data'
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  editWorkOrderAttachment: (params: AttachmentParams, requestBody: FormData) => {
    return api.$patch(`/work_orders/${params.workOrderId}/attachments/${params.attachmentId}`, requestBody, {
      params: {
        include: 'user',
        send_sc_sync_details: params.sendScSyncDetails
      },
      headers: {
        content_type: 'multipart/form-data'
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  createWorkOrderFinancialAttachment: (params: AttachmentParams, requestBody: FormData) => {
    return api.$post(`/work_orders/${params.workOrderId}/${params.type}s`, requestBody, {
      params: {
        include: 'user'
      },
      headers: {
        content_type: 'multipart/form-data'
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  editWorkOrderFinancialAttachment: (params: AttachmentParams, requestBody: FormData) => {
    return api.$patch(`/work_orders/${params.workOrderId}/${params.type}s/${params.attachmentId}`, requestBody, {
      params: {
        include: 'user'
      },
      headers: {
        content_type: 'multipart/form-data'
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  createWorkOrderTripAttachment: (params: AttachmentParams, requestBody: FormData) => {
    return api.$post(`/trips/${params.tripId}/attachments`, requestBody,
      {
        params: {
          include: 'user',
          send_sc_sync_details: params.sendScSyncDetails
        },
        headers: {
          content_type: 'multipart/form-data'
        },
        paramsSerializer: (params: any) => {
          return qs.stringify(params, { arrayFormat: 'brackets' })
        }
      })
  },
  editWorkOrderTripAttachment: (params: AttachmentParams, requestBody: FormData) => {
    return api.$patch(`/trips/${params.tripId}/attachments/${params.attachmentId}`, requestBody,
      {
        params: {
          include: 'user',
          send_sc_sync_details: params.sendScSyncDetails
        },
        headers: {
          content_type: 'multipart/form-data'
        },
        paramsSerializer: (params: any) => {
          return qs.stringify(params)
        }
      })
  },
  getWorkOrderTradeAttachment: ({ workOrderId, page, perPage, sendScSyncDetails }: GetWorkOrderAttachmentsParams) => {
    return api.$get(`work_orders/${workOrderId}/trade_documents`, {
      params: {
        page,
        perPage,
        sendScSyncDetails
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  }
}
