import { api } from '@/api'
import qs from 'qs'

export default {
  getInvoiceWithInvoiceId: (id:number) => {
    const invoiceIdObject = { q: { id_eq: id } }
    const invoiceIdObjectStr = qs.stringify(invoiceIdObject)
    return api.$get(`/invoices?${invoiceIdObjectStr}`, {
      params: {
        include: 'company'
      }
    })
  },
  getInvoiceWithClientId: (params:any) => {
    const companyIdObject = { q: params.q }
    const companyIdObjectStr = qs.stringify(companyIdObject)
    return api.$get(`/invoices?${companyIdObjectStr}`, {
      headers: {
      },
      params: {
        include: 'company',
        page: params.page,
        per_page: params.perPage

      }
    })
  }
}
