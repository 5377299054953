import { api } from '@/api'
export default {
  postPayments: (data:any) => {
    const payload = {
      client_id: data.clientId,
      payment_date: data.paymentDate,
      payment_method: data.paymentMethod,
      amount: data.amount,
      deposit_to_account_id: data.depositToAccountId,
      memo: data.memo,
      amount_to_paid: data.amountToApply,
      items: data.items
    }
    api.$post('/payments', { payment: payload })
  }
}
