import { useTheme } from '@/use/theme';
import { computed, defineComponent } from 'vue';
export default defineComponent({
  props: {
    text: {
      type: String,
      required: true
    },
    subText: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    nested: {
      type: Boolean,
      default: false
    },
    isBackRow: {
      type: Boolean,
      default: false
    },
    iconType: {
      type: String,
      default: 'mdi'
    },
    textColor: {
      type: String,
      default: 'text'
    }
  },
  setup: props => {
    const {
      lightModeColors,
      darkModeColors
    } = useTheme();
    const localTextColor = computed(() => !!lightModeColors.value[props.textColor] || !!darkModeColors.value[props.textColor] ? props.textColor : 'text');
    return {
      localTextColor
    };
  }
});