import { api } from '@/api'
import qs from 'qs'
import { PostCompanyMaterialsParams } from '@/types/interfaces/api-v2/company-material'

interface Params {
  include: string
  q: any
  page: number
  perPage: number
}

export default {
  getCompanyMaterials: (params: any) => {
    const parameters: Params = params
    const { include, q, page, perPage } = parameters
    return api.$get('/company_materials', {
      params: {
        include,
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  deleteCompanyMaterialWithId: (materialId: string) => api.$delete(`/company_materials/${materialId}`),
  post: async (params: PostCompanyMaterialsParams) => {
    return await api.$post('/company_materials', { company_material: { ...params } })
  }
}
