import { EQUAL, BETWEEN, LESS_THAN, IN, GREATER_THAN, DATE_BETWEEN, DATE_EQ, DATE_GT, DATE_LT, NOT_IN } from '@/constants/ransack'
import { ref, computed, reactive, Ref } from 'vue'
import { allFilters } from '@/constants/filters/index'

import { cloneDeep, kebabCase } from 'lodash'
import repositories from '@/repositories'
import { FilterTemplateParams } from '@/types/interfaces/api-v2/filterTemplate'
import { generateConditional, getRansackKey } from '@/utils/filter-tools'
import { FilterResources } from '@/types/enums/filter-types'

const currentRansack = ref({})
const filtersForTab = ref<[]>([])

export interface TabFilter {
  field: string,
  selected: any[],
  inject: any[],
  name: string,
  operator: string,
  chip: {
    resource: FilterResources | null,
    canSelect: boolean,
    pinned: boolean,
    isPrimary: boolean,
    text: string,
    popoverProps: any
  }
}

export interface AdvancedFilter {
  resource: { text: string, value: any },
  field: { text: string, value: any },
  comparator: { text: string, value: any },
  value: { text: string, value: any },
  values: Array<any>
}

const MAX_SELECT_COUNT = 100

// shared filters between different tab levels
const globalFilter: { [key: string]: any } = ref({ vendors: <any> [], clients: <any> [], sites: <any> [] })

const filterPanelRadioButtons = [
  {
    color: 'primary',
    value: EQUAL,
    label: 'Equals'
  },
  {
    type: 'date',
    color: 'primary',
    value: DATE_EQ,
    label: 'Equals'
  },
  {
    color: 'primary',
    value: GREATER_THAN,
    label: 'Greater Than'
  },
  {
    color: 'primary',
    value: LESS_THAN,
    label: 'Less Than'
  },
  {
    color: 'primary',
    value: BETWEEN,
    label: 'Between'
  },
  {
    type: 'date',
    color: 'primary',
    value: DATE_GT,
    label: 'Greater than'
  },
  {
    type: 'date',
    color: 'primary',
    value: DATE_LT,
    label: 'Less Than'
  },
  {
    type: 'date',
    color: 'primary',
    value: DATE_BETWEEN,
    label: 'Between'
  },
  {
    type: 'string',
    color: 'primary',
    value: EQUAL,
    label: 'Is'
  },
  {
    type: 'string',
    color: 'primary',
    value: NOT_IN,
    label: 'Is Not'
  },
  {
    type: 'number',
    color: 'primary',
    value: GREATER_THAN,
    label: 'Greater Than'
  },
  {
    type: 'number',
    color: 'primary',
    value: LESS_THAN,
    label: 'Less Than'
  },
  {
    type: 'number',
    color: 'primary',
    value: EQUAL,
    label: 'Equals'
  },
  {
    type: 'number-picker',
    color: 'primary',
    value: EQUAL,
    label: 'Equals'
  },
  {
    type: 'number-picker',
    color: 'primary',
    value: GREATER_THAN,
    label: 'Greater Than'
  },
  {
    type: 'number-picker',
    color: 'primary',
    value: LESS_THAN,
    label: 'Less Than'
  },
  {
    type: 'number-picker',
    color: 'primary',
    value: BETWEEN,
    label: 'Between'
  }
]

export const useFilters = (tabId: Ref<number> | null = null): any => {
  // init temp object so that we can init reactive variable with correct keys. you cannot add keys to reactive / ref object after instantiation
  const filtersByTabCopy = cloneDeep(allFilters())
  const initSelectedFilterResourcesByTab = (f: any) => {
    const keys = Object.keys(f)
    keys.forEach((key: string) => {
      if (f[key].filters?.length) {
        const chips: any[] = f[key].filters // get the chips for the scenario
        f[key].filters = {} // convert array to object
        chips.forEach((chip: any) => {
          const text = chip?.popoverProps?.headerText || chip.text

          // each filter will have an operator, selected values, an internal name (what it maps to in db), and the field we should use (id, text, etc)
          const operator = f[key].fieldMapping?.[kebabCase(text)]?.operator || 'eq'
          let defaultObj = { operator, selected: [], name: '', field: '', inject: [] }
          // for each chip there is a potential array of selected resources to filter by
          defaultObj = {
            ...defaultObj,
            ...f[key].fieldMapping?.[kebabCase(text)],
            chip
          }
          f[key].filters[kebabCase(text)] = cloneDeep(defaultObj)
        })
      } else {
        if (typeof f === 'object' && key in f) initSelectedFilterResourcesByTab(f[key]) // recurse until child of node is an array
      }
    })
  }

  if (tabId) {
    initSelectedFilterResourcesByTab(filtersByTabCopy)
  }
  const selectedFilterResourcesByTab = reactive(filtersByTabCopy) as any

  const templateNames = computed(() => {
    return filterTemplates.value?.data?.map((template: any) => {
      return template.templateName
    }) || []
  })

  const pinnedFilters = ref<any[]>([])
  const filterTemplates = ref<any>({ data: [], page: 0, totalPages: -1 })

  /*
  ======== INITALIZING FILTER OBJECT AND ASSOCIATED VARIABLES ========
  */

  // TODO: OR and AND clauses, grouping, etc
  const createRansackFilterObject = (filterSelections: any) => {
    let keys:any = []
    if (filterSelections.filters) {
      keys = Object.keys(filterSelections.filters)
    }
    const ransack = { } as any
    let index = 0
    keys.forEach((key: string) => {
      const filterObj = filterSelections.filters[key]
      if (filterObj.selected && filterObj.selected.length) {
        const condition = {
          a: {
            0: { name: '', ransacker_args: {} }
          },
          p: '',
          v: ''
        }
        let values = filterObj.selected
        if (Array.isArray(filterObj.selected) && (<any>Object).hasOwn(filterObj.selected[0], 'id')) {
          values = filterObj.selected.map((val: any) => {
            if (filterObj.field === 'id' || filterObj.field === undefined) {
              return val.id
            }
            let newVal = val.props[val.type][filterObj.field]
            if (typeof newVal === 'string' && filterObj.transform) {
              newVal = filterObj.transform(newVal)
            }
            return newVal
          })
        }

        values = Array.isArray(values) ? values : [values]
        if ([BETWEEN, DATE_BETWEEN].includes(filterObj.operator)) {
          ransack[index] = generateConditional({ ...condition }, [values[0]], filterObj.operator.includes('date') ? DATE_GT : GREATER_THAN, filterObj.name || key)
          index++
          ransack[index] = generateConditional({ ...condition }, [values[1]], filterObj.operator.includes('date') ? DATE_LT : LESS_THAN, filterObj.name || key)
        } else {
          ransack[index] = generateConditional({ ...condition }, values, filterObj.operator, filterObj.name || key)
        }
        // add in addl ransack inject obj if it exists after all the ransack[index] is it.
        if (filterObj.inject && filterObj.inject.length) {
          filterObj.inject.forEach((obj: any) => {
            let values = obj.values || []
            if (!values.length && obj.field) {
              values = filterObj.selected.map((val:any) => {
                return val.props[val.type][obj.field]
              })
            }
            const appendedRansackObj = generateConditional({
              a: {
                0: { name: '', ransacker_args: {} }
              },
              p: '',
              v: ''
            }, values, obj.operator, obj.name)
            index += 1
            ransack[index] = { ...appendedRansackObj }
          })
        }
        index++
      }
    })

    currentRansack.value = { g: { 0: { c: ransack } } }
    let sortBy = {}
    if (filterSelections.sortBy) {
      sortBy = `${filterSelections.sortBy.value} ${filterSelections.sortBy.direction.value}`
    }
    return { g: { 0: { c: ransack } }, s: sortBy }
  }

  const currentFilterSelections: any = computed(() => {
    if (!tabId) return
    return selectedFilterResourcesByTab?.[tabId.value].filters
  })

  /*
  ======== FILTER TEMPLATE FUNCTIONS ========
  */

  const transformFilterObjectForDatabase = (name: string, tabId: number, filterObject: any = null) => {
    // clonedeep to break the reference so the deletes below don't impact application
    const filtersObjectToSave = cloneDeep(filterObject || selectedFilterResourcesByTab[tabId])
    // delete mappings and configurations
    // this is important incase we end up changing a mapping (field name changes, bug, etc)
    // we need to grab the latest
    // similarly delete sort by items so that if we add new items users will inherit it
    if (filtersObjectToSave.fieldMapping) delete filtersObjectToSave.fieldMapping
    if (filtersObjectToSave?.sortBy?.items) delete filtersObjectToSave.sortBy.items

    const template = {
      templateName: name,
      filterType: 'applied',
      path: tabId,
      filterConfig: filtersObjectToSave,
      query: {},
      filterConfigOrder: {}
    }
    return template
  }

  const setFilterTemplate = (template: any, tabId: number) => {
    // TODO - fix camelCase returned by API
    if (!template?.filterConfig?.filters || !Object.keys(template.filterConfig?.filters || {}).length) return
    Object.keys(template.filterConfig.filters).forEach((key: string) => {
      selectedFilterResourcesByTab[tabId].filters[kebabCase(key)] = {
        ...selectedFilterResourcesByTab[tabId].filters[kebabCase(key)],
        ...template.filterConfig.filters[key],
        // take chip from local since that has dynamic info like search key, fetch fn, etc that wont be saved in the db
        // use for pinned status from db
        chip: {
          ...selectedFilterResourcesByTab[tabId].filters[kebabCase(key)].chip,
          pinned: template.filterConfig.filters[key].chip.pinned
        }
      }
    })

    if (template.filterConfig.sortBy) {
      selectedFilterResourcesByTab[tabId].sortBy.value = template.filterConfig.sortBy.value
      selectedFilterResourcesByTab[tabId].sortBy.direction = template.filterConfig.sortBy.direction
      if (!selectedFilterResourcesByTab[tabId].sortBy.value) {
        selectedFilterResourcesByTab[tabId].sortBy.value = selectedFilterResourcesByTab[tabId].sortBy.items[0].value
      }
    }
  }

  /*
  ======== TEMPLATE API CALLS ========
  */

  const saveFilterTemplate = async (name: string, tab: number, filterObject: any, templateId: number | null = null) => {
    const template = transformFilterObjectForDatabase(name, tab, filterObject)
    if (templateId) {
      return await updateFilterTemplate(templateId as number, template)
    } else {
      return await createFilterTemplate(template)
    }
  }

  const saveDefaultFilter = async (name: string, tabId: number) => {
    const template = transformFilterObjectForDatabase(name, tabId)
    return await createOrUpdateDefaultFilter(template)
  }

  const getFilterTemplates = async (params: FilterTemplateParams = {}) => {
    try {
      return await repositories.filterTemplates.getFilterTemplates(params)
    } catch (err) {
      console.error(err)
      return { data: false }
    }
  }

  const getAndSetDefaultFilterByTab = async (tabId: number) => {
    try {
      const defaultFilter = await repositories.filterTemplates.getAndSetDefaultFilterByTab(String(tabId))
      setFilterTemplate(defaultFilter.filter, tabId)
    } catch (err) {
      console.error(err)
      return { data: false }
    }
  }

  const createFilterTemplate = async (template: any, params: FilterTemplateParams = {}) => {
    try {
      return await repositories.filterTemplates.createFilterTemplate(template, params)
    } catch (err) {
      console.error(err)
      return { data: false }
    }
  }

  const createOrUpdateDefaultFilter = async (template: any, params: FilterTemplateParams = {}) => {
    try {
      return await repositories.filterTemplates.createDefaultFilter(template, params)
    } catch (err) {
      console.error(err)
      return { data: false }
    }
  }

  const updateFilterTemplate = async (templateId: number, template: any, params: FilterTemplateParams = {}) => {
    try {
      return await repositories.filterTemplates.updateFilterTemplate(templateId, template, params)
    } catch (err) {
      console.error(err)
      return { data: false }
    }
  }

  const deleteFilterTemplate = async (templateId: number, params: FilterTemplateParams = {}) => {
    try {
      return await repositories.filterTemplates.deleteFilterTemplate(templateId, params)
    } catch (err) {
      console.error(err)
      return { data: false }
    }
  }

  const loadFilterTemplates = async () => {
    if (!tabId) return
    const { filters, meta } = await getFilterTemplates({ q: { path_eq: tabId.value, s: 'template_name asc' }, page: filterTemplates.value.page, perPage: 10 })
    filterTemplates.value.data = filters
    filterTemplates.value.totalPages = meta.totalPages
    filterTemplates.value.page++
  }

  const resetFilterTemplates = () => {
    filterTemplates.value.data = []
    filterTemplates.value.totalPages = -1
    filterTemplates.value.page = 0
  }

  return {
    filterPanelRadioButtons,
    currentFilterSelections,
    createRansackFilterObject,
    selectedFilterResourcesByTab,
    currentRansack,
    getRansackKey,
    generateConditional,
    globalFilter,
    saveFilterTemplate,
    setFilterTemplate,
    MAX_SELECT_COUNT,
    getFilterTemplates,
    createFilterTemplate,
    updateFilterTemplate,
    deleteFilterTemplate,
    filterTemplates,
    loadFilterTemplates,
    resetFilterTemplates,
    filtersForTab,
    pinnedFilters,
    createOrUpdateDefaultFilter,
    getAndSetDefaultFilterByTab,
    saveDefaultFilter,
    templateNames
  }
}
