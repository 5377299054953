import {
  TEAM_MEMBER_NAME,
  TEAM_MEMBER_PHONE_NUMBER,
  TEAM_MEMBER_STATUS,
  TEAM_MEMBER_ROLE,
  TEAM_MEMBER_EMAIL,
  TEAM_MEMBER_REGION
} from '@/constants/filters/resources/teamMember'
import { computed } from 'vue'
import { useSession } from '@/use/session'
import { BROKER } from '@/constants/permissions'

const teamMemberStatuses: Record <string, string> = {
  Active: 'User',
  Requesting: 'Requesting',
  'Pending Signup': 'Pending Signup'
}

export const peopleEmployeesFilter = () => {
  const { session } = useSession()

  const availableTeamMemberFilters = computed(() => {
    const baseFilters: any = [
      TEAM_MEMBER_NAME({ pinned: true, isPrimary: true }),
      TEAM_MEMBER_PHONE_NUMBER({ pinned: true, isPrimary: true }),
      // TEAM_MEMBER_STATUS({ pinned: true, isPrimary: true }),
      TEAM_MEMBER_ROLE({ pinned: true, isPrimary: true }),
      TEAM_MEMBER_EMAIL({ pinned: true, isPrimary: true })
    ]
    if (session.currentCompanyType === BROKER) {
      baseFilters.push(TEAM_MEMBER_REGION({ pinned: true, isPrimary: true }))
    }
    return baseFilters
  })

  return {
    filters: [
      ...availableTeamMemberFilters.value
    ],
    sortBy: {
      value: 'user_full_name',
      direction: {
        text: 'Ascending',
        value: 'asc'
      },
      items: [
        {
          label: 'Name',
          value: 'user_full_name'
        },
        {
          label: 'Status',
          value: 'user_status'
        }
      ]
    },
    fieldMapping: {
      'employee-name': {
        name: 'user_full_name',
        field: 'text'
      },
      'employee-phone-number': {
        name: 'user_primary_phone_number',
        field: 'text'
      },
      'employee-status': {
        name: 'user_status',
        field: 'text',
        transform: (text: string) => teamMemberStatuses[text]
      },
      'employee-roles': {
        name: 'role_name',
        field: 'text'
      },
      'employee-email': {
        name: 'user_email',
        field: 'text'
      },
      'employee-region': {
        name: 'territories_name',
        field: 'text'
      }
    }
  }
}
