import { apis } from '@/api'
import { APP_STORE_API_URL } from '@/constants/env'
export default {
  getApps: () => apis.$get('/apps', {
    baseURL: APP_STORE_API_URL
  }),
  getIntegrations: (subdomain: string) => apis.$get(`/integrations/${subdomain}`, {
    baseURL: APP_STORE_API_URL
  })
}
