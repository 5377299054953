import { api } from '@/api'
import qs from 'qs'
import { GetTaxesParams } from '@/use/taxes'

export default {
  getTaxesByLoc: ({ locationId }: GetTaxesParams) => api.$get(`/locations/${locationId}/taxes`),
  getTax: ({ locationId, clientId, addressId, invoiceId, tradeService, q, perPage, page, include, vendorId, objectScope }: GetTaxesParams) => api.$get('/tax', {
    params: {
      clientId,
      locationId,
      invoiceId,
      addressId,
      tradeService,
      vendorId,
      include,
      objectScope,
      q,
      page,
      perPage
    },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  getTaxes: ({ locationId, clientId, addressId, invoiceId, tradeService, q, perPage, page, include, vendorId, objectScope, tab }: GetTaxesParams) => api.$get('/taxes', {
    params: {
      clientId,
      locationId,
      invoiceId,
      addressId,
      tradeService,
      vendorId,
      include,
      objectScope,
      q,
      page,
      perPage,
      tab
    },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  })
}
