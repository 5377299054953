import { defineComponent, computed } from 'vue';
import _ from 'lodash';
export default defineComponent({
  name: 'UcButtonGroup',
  props: {
    buttons: {
      type: Array,
      default: () => []
    }
  },
  setup: props => {
    const filteredButtons = computed(() => {
      return props.buttons.filter(button => !button.hidden);
    });
    const buttonEmitName = buttonText => {
      return _.kebabCase(buttonText);
    };
    return {
      buttonEmitName,
      filteredButtons
    };
  }
});