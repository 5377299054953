export const reviewApplicationFilters = () => {
  return {
    applications: {
      filters: [
        {
          text: 'Application Status',
          canSelect: false,
          popoverProps: {
            height: 'unset',
            slotKey: 'application-status',
            headerText: 'Application Status',
            size: 'lg',
            contentType: 'string',
            rowType: 'text',
            params: {},
            searchKeys: ['text'],
            items: [{ id: 0, text: 'Draft' }, { id: 1, text: 'Submitted' }, { id: 2, text: 'Approved' }, { id: 3, text: 'Rejected' }],
            showHeaders: true
          },
          pinned: true,
          isDefault: true
        },
        {
          text: 'Last Updated',
          canSelect: false,
          popoverProps: {
            height: 'unset',
            slotKey: 'last-updated',
            headerText: 'Last Updated',
            size: 'md',
            contentType: 'date',
            rowType: 'text',
            params: {},
            showHeaders: true
          },
          pinned: true,
          isDefault: true
        }
      ],
      sortBy: {
        value: 'updated_at',
        direction: {
          text: 'Descending',
          value: 'desc'
        },
        items: [
          { label: 'Job #', value: 'survey_template.id' },
          { label: 'Application #', value: 'id' },
          { label: 'Last Updated', value: 'updated_at' }
        ]
      },
      fieldMapping: {
        'application-status': {
          name: 'label',
          field: 'text',
          transform: (text: string) => text.toLowerCase()
        },
        'last-updated': {
          name: 'updated_at'
        }
      }
    }
  }
}
