var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "d-flex"
  }, [_c('transition', {
    attrs: {
      "name": _vm.isFullScreen ? 'slide-forward' : ''
    }
  }, [_vm.isFullScreen ? _c('uc-modal-card', {
    key: _vm.keyRef,
    ref: "appStoreRef",
    staticClass: "fullscreen",
    style: {
      transition: `${_vm.transitionTime}ms all ease`
    },
    attrs: {
      "size": 'none',
      "header-text": 'App Store',
      "header-icon": _vm.ICON_ROCKET,
      "closable": true,
      "persistent": true
    },
    on: {
      "close-card": function ($event) {
        _vm.$emit('close');
        _vm.isFullScreen = false;
      }
    },
    scopedSlots: _vm._u([!_vm.$vuetify.breakpoint.xsOnly && _vm.isSMFullScreen ? {
      key: "header-actions",
      fn: function () {
        return [_c('uc-button', {
          staticClass: "mr-2 px-4",
          attrs: {
            "id": "intercom",
            "text": 'CHAT WITH US',
            "size": 'sm',
            "data-cy": "buy-more-usage",
            "state": 'primary'
          },
          on: {
            "click": function ($event) {
              return _vm.openZendeskWindow();
            }
          }
        })];
      },
      proxy: true
    } : null, {
      key: "content",
      fn: function () {
        return [_vm.$vuetify.breakpoint.mdAndUp && !_vm.isLoading ? _c('uc-tile-container', {
          staticClass: "d-flex flex-1 flex-row flex-start",
          attrs: {
            "no-margin": true,
            "no-padding": true,
            "no-elevation": true,
            "background-color": "--v-layer-secondary-base"
          }
        }, [_c('package-builder-integration-apps', {
          ref: "integrationsTableRef",
          staticClass: "d-flex flex-1",
          attrs: {
            "is-store-modal": true,
            "hide-divider": true,
            "integration-apps": _vm.selectedIntegrationsArray,
            "plan-details": {
              packageIntegrations: _vm.integrationsArray
            }
          }
        })], 1) : !_vm.isLoading ? _c('uc-tabs', {
          staticClass: "d-flex flex-1 overflow-hidden",
          attrs: {
            "value": _vm.panelDetailsTab,
            "tabs": _vm.appStoreTabs,
            "show-divider": false,
            "no-margin": true,
            "scroll": true,
            "prerender-tabs": true,
            "content-background": '--v-layer-secondary-base'
          },
          scopedSlots: _vm._u([{
            key: _vm.kebabCase(_vm.appStoreTabs[0].name),
            fn: function () {
              return [_c('package-builder-integration-apps', {
                ref: "integrationsTableRef",
                staticClass: "d-flex flex-1",
                attrs: {
                  "is-store-modal": true,
                  "hide-divider": true,
                  "integration-apps": _vm.selectedIntegrationsArray,
                  "plan-details": {
                    packageIntegrations: _vm.integrationsArray
                  }
                }
              })];
            },
            proxy: true
          }], null, true)
        }) : _vm._e()];
      },
      proxy: true
    }, _vm.$vuetify.breakpoint.xsOnly && _vm.isSMFullScreen && !_vm.isLoading ? {
      key: "footer",
      fn: function () {
        return [_c('uc-button-group', {
          attrs: {
            "buttons": [{
              text: _vm.headerButtonData.title,
              eventName: 'click',
              state: _vm.headerButtonData.color
            }]
          },
          on: {
            "click": function ($event) {
              return _vm.openZendeskWindow();
            }
          }
        })];
      },
      proxy: true
    } : null], null, true)
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };