import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    quantity: {
      type: [String, Number],
      required: true
    },
    size: {
      type: String,
      default: 'md',
      validator: val => {
        return ['xs', 'sm', 'md', 'lg'].includes(val.toLowerCase());
      }
    },
    hasBackground: {
      type: Boolean,
      default: true
    },
    backgroundColor: {
      type: String,
      default: 'background'
    },
    textPaletteColor: {
      type: String,
      default: 'text'
    }
  },
  setup: () => {
    const getFontSize = size => {
      if (size === 'xs') return 3;
      if (size === 'sm') return 3;
      if (size === 'md') return 2;
      if (size === 'lg') return 1;
    };
    return {
      getFontSize
    };
  }
});