import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fad } from '@fortawesome/pro-duotone-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

import '@fontsource/rubik'
import '@mdi/font/css/materialdesignicons.min.css'
import '@fortawesome/fontawesome-pro/css/all.min.css'

import { useTheme } from '@/use/theme'

import { UcAttachments } from '@/components/uc/attachments'

const { lightModeColors, darkModeColors, reversedPalettes, palettes } = useTheme()

Vue.component('FontAwesomeIcon', FontAwesomeIcon)
library.add(fad, fas, fab, far)

Vue.use(Vuetify, {
  UcAttachments
})

const vuetify = new Vuetify({
  theme: {
    dark: false,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        secondary: '#FFF', // tiles, cards
        white: reversedPalettes.text[palettes.text.length - 1],
        scrollbar: '#AAB2BF',
        ...lightModeColors.value
      },
      dark: {
        white: reversedPalettes.text[reversedPalettes.text.length - 1],
        secondary: '#1D314E',
        scrollbar: '#4C5C74',
        ...darkModeColors.value
      }
    }
  }
})

export default vuetify
