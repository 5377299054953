import { api } from '@/api'
import qs from 'qs'

export interface getCallTypeParams {
  scope?: string
  include?: string
  q?: any
  page?: number
  perPage?: number
}

export default {
  get: ({ scope, include, q, page, perPage }: getCallTypeParams) => {
    return api.$get('/work_orders/call_types', {
      params: {
        scope,
        include,
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: (params:getCallTypeParams) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  }
}
