import menus from './menus.json'
import palette from './palette.json'
import settings from './settings.json'
import { menuList, formRoutes, hideMenuNavigationRoutes } from './navigation_menus.json'
import routes from './public-routes.json'
import { allowedRoutes, allowedRoutesStartsWith } from './allowed_vue_routes'

import {
  clientInvoiceBulkActions,
  vendorInvoiceBulkActions
} from './actions/invoice_bulk.json'
import {
  clientInvoiceListColumns,
  vendorInvoiceListColumns,
  clientInvoiceListColumnsTablet,
  vendorInvoiceListColumnsTablet
} from './columns/invoices.json'
import crewsColumns from './columns/crews.json'
import {
  tradeListColumnTablet,
  tradeListColumnDesktop
} from './columns/trades.json'
import {
  clientsListColumns,
  clientsListColumnsTablet,
  clientsInvoiceDetailColumns
} from './columns/clients.json'

import { invoiceStatuses, invoiceStatusesPending } from './statuses/invoice.json'
import { activeTradesmenListColumns, inactiveTradesmenListColumns, tradesmen, tradesmenViewAllColumn } from './columns/tradesmen.json'

import {
  sentPurchaseOrderListColumns,
  recievedPurchaseOrderListColumns
} from './columns/purchase_orders.json'
import { companyUserColumns } from './columns/company_users.json'
import { itemTypesColumns } from './columns/items_type.json'
import { itemColumns } from './columns/items.json'
import {
  vendorProposalsListColumns,
  clientProposalsListColumns
} from './columns/proposals.json'
import {
  vendorListColumns, vendorListColumnsTablet, vendorComplianceListColumns,
  vendorInvoiceDetailColumns
} from './columns/vendors.json'
import { statuses } from './statuses/statuses.json'

import roleColumns from './columns/roles.json'
import {
  clientPaymentListColumns,
  vendorPaymentListColumns
} from './columns/payments.json'
import { ledgerAccountColumns } from './columns/ledger_accounts.json'
import { regionColumns } from './columns/regions.json'
import { paymentStatuses } from './statuses/paymentStatus.json'
import {
  ratesPerHourColumns,
  ratesPerServiceColumns,
  ratesPerTaskColumns
} from './columns/labor_rates.json'
import { tradeServicesListColumns, tradeServicesTabletListColumns } from './columns/trade-services.json'
import teamMemberColumns from './columns/teamMembers.json'
import {
  comapanyMaterialsColumnDesktop,
  comapanyMaterialsColumnTablet
} from './columns/company_materials.json'
import { tripDetailsColumns } from './columns/workorder_services_trip_details.json'
import { toDoWorkOrderColumns, sentWorkOrderColumns } from './columns/workorders.json'
import assigneeColumns from './columns/assignee.json'
import { workOrderInvoiceStatuses } from './statuses/work_order.json'
import { extendedStatuses } from './statuses/extendedStatuses.json'
import { siteStatuses } from './statuses/sites.json'
import { proposalStatuses } from './statuses/proposals.json'
import { purchaseOrderStatuses } from './statuses/purchase_orders.json'

const { publicRoutes } = routes

const { links, headerMenu } = menus

export {
  palette,
  menuList,
  links,
  headerMenu,
  formRoutes,
  settings,
  publicRoutes,
  clientInvoiceListColumns,
  vendorInvoiceListColumns,
  clientInvoiceListColumnsTablet,
  vendorInvoiceListColumnsTablet,
  tradeListColumnTablet,
  tradeListColumnDesktop,
  crewsColumns,
  clientsListColumns,
  clientsListColumnsTablet,
  clientsInvoiceDetailColumns,
  invoiceStatuses,
  clientInvoiceBulkActions,
  vendorInvoiceBulkActions,
  sentPurchaseOrderListColumns,
  recievedPurchaseOrderListColumns,
  companyUserColumns,
  itemTypesColumns,
  itemColumns,
  vendorListColumns,
  vendorListColumnsTablet,
  vendorComplianceListColumns,
  vendorInvoiceDetailColumns,
  vendorProposalsListColumns,
  clientProposalsListColumns,
  statuses,
  roleColumns,
  clientPaymentListColumns,
  vendorPaymentListColumns,
  paymentStatuses,
  ratesPerHourColumns,
  ratesPerServiceColumns,
  ratesPerTaskColumns,
  comapanyMaterialsColumnDesktop,
  comapanyMaterialsColumnTablet,
  activeTradesmenListColumns,
  inactiveTradesmenListColumns,
  tradeServicesListColumns,
  tradeServicesTabletListColumns,
  ledgerAccountColumns,
  regionColumns,
  teamMemberColumns,
  hideMenuNavigationRoutes,
  tripDetailsColumns,
  assigneeColumns,
  tradesmen,
  tradesmenViewAllColumn,
  toDoWorkOrderColumns,
  sentWorkOrderColumns,
  workOrderInvoiceStatuses,
  extendedStatuses,
  siteStatuses,
  invoiceStatusesPending,
  proposalStatuses,
  purchaseOrderStatuses,
  allowedRoutes,
  allowedRoutesStartsWith
}
