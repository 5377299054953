import { useOutboundPurchaseOrders } from '@/use/purchase-orders/outbound'
import { useInboundPurchaseOrders } from '@/use/purchase-orders/inbound'
import { AutocompletePayload, AutocompleteResponse } from '@/types/interfaces/api-v2/autocomplete'
import { ref } from 'vue'
import { FilterResources } from '@/types/enums/filter-types'

const { purchaseOrdersAutoComplete } = useOutboundPurchaseOrders()
const { inboundPurchaseOrdersAutoComplete } = useInboundPurchaseOrders()
const isOutbound = ref(false)

const purchaseOrderAutoCompleteFetchFn = async (params: AutocompletePayload) => {
  const { data, nextPage } = (isOutbound.value)
    ? await purchaseOrdersAutoComplete({ ...params }) as { data: AutocompleteResponse[], nextPage: number }
    : await inboundPurchaseOrdersAutoComplete({ ...params }) as { data: AutocompleteResponse[], nextPage: number }

  return {
    data: data.map((item: AutocompleteResponse, index: number) => {
      return {
        id: `${index}-${item.text}`,
        text: item.text,
        value: item.text
      }
    }),
    nextPage
  }
}

const poStatuses: Record <string, string> = {
  Draft: 'draft',
  Sent: 'sent',
  Accepted: 'accepted',
  Rejected: 'rejected',
  Closed: 'closed',
  Received: 'sent'
}

const PO_NUMBER = (outbound: boolean, config = {}, params = { field: 'po_number' }, fetchFn: any = purchaseOrderAutoCompleteFetchFn) => {
  isOutbound.value = outbound
  return {
    text: 'Number',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'purchase-order-filter',
      headerText: 'Purchase Order Number',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      predicate: '_cont',
      params,
      searchKeys: ['po_number'],
      tokenSeparators: ['\t', '\n', ',']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.PURCHASE_ORDER,
    ...config
  }
}

const PO_CUSTOM_NUMBER = (outbound: boolean, config = {}, params = { field: 'custom_po_number' }, fetchFn: any = purchaseOrderAutoCompleteFetchFn) => {
  isOutbound.value = outbound
  return {
    text: 'Custom Number',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'purchase-order-custom-number-filter',
      headerText: 'Purchase Order Custom Number',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['custom_po_number'],
      tokenSeparators: ['\t', '\n', ',']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.PURCHASE_ORDER,
    ...config
  }
}

const PO_STATUS = (outbound: boolean, config = {}) => {
  return {
    text: 'Status',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'purchase-order-status-filter',
      headerText: 'Purchase Order Status',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn: async (params: any) => {
        const statuses = Object.keys(poStatuses).map((key: string) => {
          return {
            id: poStatuses[key],
            text: key,
            value: poStatuses[key]
          }
        })

        return {
          data: statuses.filter((status) => outbound ? status.text !== 'Received' : status.text !== 'Sent'),
          totalCount: statuses.length,
          totalPages: 1
        }
      },
      params: { q: { s: 'number asc' } },
      searchKeys: ['number']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.PURCHASE_ORDER,
    ...config
  }
}

const PO_DATE_OF_ISSUE = (outbound: boolean, config = {}) => {
  return {
    text: 'Date of Issue',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'purchase-order-date-of-issue',
      headerText: 'Purchase Order Date of Issue',
      size: 'md',
      contentType: 'date',
      rowType: 'text',
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.PURCHASE_ORDER,
    ...config
  }
}

export {
  PO_NUMBER,
  PO_CUSTOM_NUMBER,
  PO_STATUS,
  PO_DATE_OF_ISSUE
}
