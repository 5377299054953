export enum WorkOrderTypes {
  NON_BILLABLE = 'Non Billable',
  TIME_AND_MATERIALS = 'Time and Materials',
  PER_SERVICE = 'Per Service',
  EQUIPMENT_TASK_TIME_AND_MATERIALS = 'Equipment Task Time and Material',
  PER_TASK = 'Per Task',
  PROPOSAL = 'Proposal',
  NOT_TO_EXCEED = 'NTE',
  PURCHASE_ORDER = 'Purchase Order',
  TECHNICIAN = 'Technician',
  INSPECTION = 'Inspection',
  PER_EVENT = 'Per Event',
}
