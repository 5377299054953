import { apis } from '@/api'
import qs from 'qs'

export default {
  postCheckIn: (params: any) => apis.$post('/company/attendances/check_in', {}, {
    params,
    paramsSerializer: (params: any) => qs.stringify(params)
  }),
  patchCheckOut: (id: string | number, note: string) => apis.$patch(`/company/attendances/${id}/check_out`, {
    attendance: {
      note
    }
  })
}
