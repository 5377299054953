import { ref, computed } from 'vue'
import { PageTab } from '@/constants/top-level-tabs'
import { BROKER, CLIENT, VENDOR, companyRoles } from '@/constants/permissions'
import {
  INVOICE_RESOURCE_TYPE,
  LOCATION,
  ResourceType,
  WO_FETCH_PARAM_TYPE,
  WORK_ORDER,
  TRIP
} from '@/constants/resource-types'
import { IN } from '@/constants/ransack'
import { generateConditional } from '@/utils/filter-tools'
import VueRouter, { Route } from 'vue-router'

// Tabs
const topLevelTabs = ref<PageTab[]>([])
const nestedTabs = ref<Record<string, PageTab[]>>({})
const subNestedTabs = ref<Record<string, PageTab[]>>({})
const universalPanelTabs = ref<PageTab[]>([])
const nestedUniversalPanelTabs = ref<Record<string, PageTab[]>>({})

// Vendor File Manager for compliance edit
const fileManagerModalRef = ref<any>(null)

const pdfViewerRef = ref<any>(null)
// props to pass to custom workflow
const optionalDynamicComponentProps = ref({
  props: {}
})

// component for the custom workflow
const currentWorkflow = ref('invoice-generation')
const inWorkflow = ref(false)

// enter / exit from the default bottom panel list view.
const setWorkflow = (newWorkflow: string) => {
  if (!newWorkflow) return
  inWorkflow.value = true
  currentWorkflow.value = newWorkflow
}
const exitWorkflow = () => {
  inWorkflow.value = false
}
const getDefaultTabIndex = (defaultTab: string) => {
  return topLevelTabs.value.findIndex(
    (tab: { name: string }) => tab.name === defaultTab
  )
}
// bottom tab selections, universal panel tab selections, and whether we should apply the relevant bottom section filters
const thirdLevelTab = ref(0)
const universalPanelTab = ref(0)
const secondLevelUniversalPanelTab = ref(0)
const pricingModalRef = ref<any>(null)
const wizardProfileModalRef = ref<any>(null)

const appStoreModalRef = ref<any>(null)

// Profile wizard ref
const companyWizardRef = ref<any>(null)

// Vendor Invite Modal Ref
const createVendorRef = ref<any>(null)

// Client Invite Modal Ref
const createClientRef = ref<any>(null)

// Team Member Invite Modal Ref
const createTeamMemberRef = ref<any>(null)

// Role Create Modal Ref
const createRoleRef = ref<any>(null)

// pagination / raw list data for pins
const clients = ref({
  data: [],
  page: 0,
  totalPages: 1
})
const vendors = ref({
  data: [],
  page: 0,
  totalPages: 1
})
const sites = ref({
  data: [],
  page: 0,
  totalPages: 1
})
const trucks = ref<any>({
  data: [] as any,
  page: 0,
  totalPages: 1
})

// layers that are activated in the map-layer-filter component
const clientsSelected = ref(false)
const vendorsSelected = ref(false)
const sitesSelected = ref(false)
const trucksSelected = ref(false)

// determine which marker types to destructure into the final marker array
const markers = computed(() => {
  const clientMarkers = clientsSelected.value ? clients.value.data : []
  const vendorMarkers = vendorsSelected.value ? vendors.value.data : []
  const locationMarkers = sitesSelected.value ? sites.value.data : []
  const truckMarkers = trucksSelected.value ? trucks.value.data : []
  return [
    ...locationMarkers,
    ...vendorMarkers,
    ...clientMarkers,
    ...truckMarkers
  ]
})

const MAX_PINS = 750
const DEFAULT_RESOURCE_PANEL_WIDTH = '389px'

// control Map pins loader.
const pinsLoading = ref(false)

// make relevant map area filter state variables available globally
const showSearchAgainButton = ref(false)
const mapAreaFilterActivated = ref(false)

// Screen Code
const deviceCode = ref('lg')

// control height of bottom panel from global state
const transitioning = ref(false)
const bottomSectionStartHeight = '40px'
const bottomSectionMediumHeight = '50%'
const bottomSectionMaxHeight = '100%'
const topSectionPanelSize = ref<string>(DEFAULT_RESOURCE_PANEL_WIDTH)
const currentBottomSectionHeight = ref(bottomSectionMediumHeight)

const setBottomHeight = (size: string) => {
  transitioning.value = true
  if (size === 'sm') {
    currentBottomSectionHeight.value = bottomSectionStartHeight
    exitWorkflow()
  } else if (size === 'md') {
    currentBottomSectionHeight.value = bottomSectionMediumHeight
  } else if (size === 'lg') {
    currentBottomSectionHeight.value = bottomSectionMaxHeight
  }
}

const companyType = ref<companyRoles | ''>('')

const isProduction = computed(() => {
  if (
    process.env.VUE_APP_MODE === 'production' ||
    process.env.VUE_APP_MODE === 'preproduction'
  ) {
    return true
  } else {
    return false
  }
})

// This is used to conserve WO page query parameters that are important for state management
const clearUnusedQueryParameters = (router: VueRouter, route: Route) => {
  const query = {} as {selectedTab?: string, state?: string}
  if (route.query.selectedTab) {
    query.selectedTab = route.query.selectedTab as string
  }
  if (route.query.state) {
    query.state = route.query.state as string
  }
  router.replace({
    path: route.path,
    query,
    params: route.params
  })
}

const isBroker = computed(() => companyType.value === BROKER)
const isVendor = computed(() => companyType.value === VENDOR)
const isClient = computed(() => companyType.value === CLIENT)

/**
 * Get the q filters for the current resources that we're visiting, but they should be applicable for the given target
 * resource type. For example: Invoices get filtered differently than regular resources.
 *
 * @param targetType
 */
const getTopLevelResourceQFilter = (resourceType: ResourceType | null, targetType?: ResourceType, id?: number | string | null) => {
  if (!id) { return {} }
  if (resourceType === WORK_ORDER) {
    if (targetType === INVOICE_RESOURCE_TYPE) {
      return {
        resource_id_eq: id,
        resource_type_eq: WO_FETCH_PARAM_TYPE
      }
    } else if (targetType === WORK_ORDER) {
      return {
        id_eq: id
      }
    } else if (targetType === LOCATION) {
      return {
        work_orders_id_eq: id
      }
    } else {
      return {
        work_order_id_eq: id
      }
    }
  } else if (resourceType === VENDOR) {
    if (targetType === TRIP) {
      return {
        cc_trip_contractor_id_eq: id
      }
    } else if (targetType === WORK_ORDER) {
      return {
        trips_cc_trip_contractor_id_eq: id
      }
    } else if (targetType === VENDOR) {
      return {
        id_eq: id
      }
    } else {
      return {
        vendor_id_eq: id
      }
    }
  } else if (resourceType === CLIENT) {
    if (targetType === TRIP) {
      return {
        work_order_company_id_eq: id
      }
    } else if (targetType === CLIENT) {
      return {
        id_eq: id
      }
    } else {
      return {
        company_id_eq: id
      }
    }
  } else {
    return {}
  }
}

const BASE_CONDITION = Object.freeze({
  a: {
    0: { name: '', ransacker_args: {} }
  },
  p: '',
  v: ''
})

/**
 * This is the same as #getTopLevelResourceQFilter except it generates the conditional query params for ransack
 * conditional combiners
 *
 * @param targetType
 *
 * @see #getTopLevelResourceQFilter
 */
const getTopLevelResourceAsRansackConditional = (resourceId: number | string | null, parentResourceType?: ResourceType | null, targetType?: ResourceType) => {
  if (!resourceId) return null
  if (parentResourceType === WORK_ORDER) {
    if (targetType === INVOICE_RESOURCE_TYPE) {
      // todo: implement this when we have a use case, to correctly filter the invoice resource type based on conditionals
      return null
    } else if (targetType === WORK_ORDER) {
      return generateConditional({ ...BASE_CONDITION }, [resourceId], IN, 'id')
    } else if (targetType === LOCATION) {
      return generateConditional({ ...BASE_CONDITION }, [resourceId], IN, 'work_orders_id')
    } else {
      return generateConditional({ ...BASE_CONDITION }, [resourceId], IN, 'work_order_id')
    }
  } else {
    return null
  }
}

/**
 * @see #getTopLevelResourceQFilter
 */
const getComputedTopLevelResourceQFilter = (resourceType: ResourceType | null, targetType?: ResourceType, id?: number | null) => {
  return computed(() => {
    if (!resourceType) return
    return getTopLevelResourceQFilter(resourceType, targetType, Number(id))
  })
}

export const useApp = () => {
  return {
    setWorkflow,
    currentWorkflow,
    inWorkflow,
    exitWorkflow,
    optionalDynamicComponentProps,
    universalPanelTab,
    markers,
    clients,
    vendors,
    sites,
    trucks,
    clientsSelected,
    secondLevelUniversalPanelTab,
    vendorsSelected,
    sitesSelected,
    trucksSelected,
    MAX_PINS,
    showSearchAgainButton,
    mapAreaFilterActivated,
    bottomSectionMaxHeight,
    bottomSectionMediumHeight,
    bottomSectionStartHeight,
    setBottomHeight,
    transitioning,
    currentBottomSectionHeight,
    deviceCode,
    pinsLoading,
    companyType,
    pdfViewerRef,
    DEFAULT_RESOURCE_PANEL_WIDTH,
    topSectionPanelSize,
    thirdLevelTab,
    isProduction,
    pricingModalRef,
    topLevelTabs,
    nestedTabs,
    universalPanelTabs,
    nestedUniversalPanelTabs,
    getDefaultTabIndex,
    subNestedTabs,
    fileManagerModalRef,
    wizardProfileModalRef,
    companyWizardRef,
    createVendorRef,
    createTeamMemberRef,
    createRoleRef,
    appStoreModalRef,
    createClientRef,
    clearUnusedQueryParameters,
    isBroker,
    isVendor,
    isClient,
    getTopLevelResourceQFilter,
    getComputedTopLevelResourceQFilter,
    getTopLevelResourceAsRansackConditional
  }
}
