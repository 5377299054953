import repositories from '@/repositories'
import { usePurchaseOrders } from '.'
import { PO_RESOURCE_TYPE } from '@/constants/resource-types'
import { AutocompletePayload, AutocompleteResponse } from '@/types/interfaces/api-v2/autocomplete'
import { useDetailsPanel } from '../details-panel'
interface PurchaseOrderUpdateParams {
  purchaseOrderId: string
  updateContent: any,
  include?: string
}
export const useInboundPurchaseOrders = () => {
  const { purchaseOrderActionModalProps, shared } = usePurchaseOrders()

  const basicPurchaseOrderInboundActions = [
    ...shared(false)

  ]

  /*
    API FUNCTIONS
  */
  const getInboundPurchaseOrders = async (params: any) => {
    try {
      const res = await repositories.inboundPurchaseOrders.get(params)
      return {
        data: res.purchaseOrders,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as any
    } catch (err) {
      console.error(err)
      return false
    }
  }

  const getInboundPurchaseOrderById = async (id: number, params: any) => {
    try {
      const res = await repositories.inboundPurchaseOrders.getById(id, { include: params })
      return { data: res.purchaseOrder }
    } catch (err: any) {
      console.error(err)
      return { data: false }
    }
  }

  const postInboundPurchaseOrders = async (params: any, requestBody: any) => {
    try {
      const res = await repositories.inboundPurchaseOrders.post(params, requestBody)
      return res.purchaseOrder
    } catch (err: any) {
      console.error(err)
    }
  }

  const deleteInboundPurchaseOrder = async (purchaseOrderId: string) => {
    try {
      return await repositories.inboundPurchaseOrders.delete(purchaseOrderId)
    } catch (err) {
      return false
    }
  }

  const getInboundPurchaseOrderPreviewPdfById = async (id: number) => {
    try {
      const res = await repositories.inboundPurchaseOrders.getPreviewPdfById(id)
      return res
    } catch (err: any) {
      console.error(err)
      return false
    }
  }

  const updateInboundPurchaseOrder = async ({ purchaseOrderId, updateContent, include }: PurchaseOrderUpdateParams) => {
    try {
      const res = await repositories.purchaseOrders.update({ purchaseOrderId, updateContent, include })
      return res
    } catch (err) {
      console.error(err)
      return false
    }
  }

  const updateInboundPurchaseOrderStatus = async (id: number, updatedStatus: string) => {
    try {
      const res = await repositories.inboundPurchaseOrders.updateStatus(id, updatedStatus)
      return res
    } catch (err: any) {
      console.error(err)
      return false
    }
  }

  const updateInboundPOStatus = async (params: any) => {
    const { purchaseOrderId, updateContent } = params
    try {
      const res = await repositories.inboundPurchaseOrders.updateStatus(purchaseOrderId, updateContent.status === 'approved' ? 'accepted' : updateContent.status)
      return res
    } catch (err: any) {
      console.error(err)
      return false
    }
  }

  const inboundPurchaseOrdersAutoComplete = async (params: AutocompletePayload) => {
    try {
      const res = await repositories.inboundPurchaseOrders.inboundPurchaseOrdersAutoComplete(params)
      return {
        data: res.results,
        nextPage: res.nextPage
      } as { data: AutocompleteResponse[], nextPage: number }
    } catch (err) {
      console.error(err)
      return { data: false }
    }
  }

  const inboundPurchaseOrdersSearch = async (params: any) => {
    try {
      const res = await repositories.inboundPurchaseOrders.inboundPurchaseOrdersSearch(params)
      return {
        data: res.purchaseOrders,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as any
    } catch (err) {
      console.error(err)
      return false
    }
  }

  const purchaseOrderInboundModalRequiredActions = [
    {
      value: 'close',
      requiresConfirmation: true,
      modal: purchaseOrderActionModalProps(false).close,
      actionFn: async (purchaseOrders: any[], additionalParams: any) => {
        await updateInboundPOStatus({ purchaseOrderId: purchaseOrders[0].id, updateContent: { status: 'closed' } })
      }
    },
    {
      value: 'approve',
      requiresConfirmation: true,
      modal: purchaseOrderActionModalProps(false).approve,
      actionFn: async (purchaseOrders: any[], additionalParams: any) => {
        await updateInboundPOStatus({ purchaseOrderId: purchaseOrders[0].id, updateContent: { status: 'approved', note: additionalParams?.additionalDataFromActionModal?.note } })
      }
    },
    {
      value: 'evaluate',
      requiresConfirmation: true,
      modal: purchaseOrderActionModalProps(false).evaluate,
      actionFn: async (purchaseOrders: any[], additionalParams: any) => {
        await updateInboundPOStatus({ purchaseOrderId: purchaseOrders[0].id, updateContent: { status: 'evalute', note: additionalParams?.additionalDataFromActionModal?.note } })
      }

    },
    {
      value: 'reject',
      requiresConfirmation: true,
      modal: purchaseOrderActionModalProps(false).reject,
      actionFn: async (purchaseOrders: any[], additionalParams: any) => {
        await updateInboundPOStatus({ purchaseOrderId: purchaseOrders[0].id, updateContent: { status: 'rejected', note: additionalParams?.additionalDataFromActionModal?.note } })
      }
    },
    {
      value: 'view',
      updateSelectedResource: true,
      actionFn: (purchaseOrders: any) => {
        const { openGlobalDetailsPanel } = useDetailsPanel()
        openGlobalDetailsPanel('uc/resource-details', {
          actions: [
            // Deleting bulkConfig for hiding bulk action for the purchase order
            ...basicPurchaseOrderInboundActions.map((action: any) => {
              const temp = Object.assign({}, action)
              delete temp.bulkConfig
              return temp
            }),
            ...purchaseOrderInboundModalRequiredActions
          ],
          selectedResources: purchaseOrders,
          resourceType: PO_RESOURCE_TYPE,
          canDoubleClickToEdit: true,
          outbound: false
        }, '', { }, { })
      },
      noSuccessEmit: true
    }
  ]

  return {
    getInboundPurchaseOrders,
    deleteInboundPurchaseOrder,
    getInboundPurchaseOrderPreviewPdfById,
    updateInboundPurchaseOrder,
    updateInboundPurchaseOrderStatus,
    updateInboundPOStatus,
    getInboundPurchaseOrderById,
    postInboundPurchaseOrders,
    purchaseOrderInboundModalRequiredActions,
    basicPurchaseOrderInboundActions,
    inboundPurchaseOrdersAutoComplete,
    inboundPurchaseOrdersSearch
  }
}
