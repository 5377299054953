import { CLIENT_ADDRESS, CLIENT_CITY, CLIENT_COUNTRY, CLIENT_NAME, CLIENT_STATE, CLIENT_ZIP } from '../resources/client'
import { INVOICE_CUSTOM_NUMBER, INVOICE_DATE_OF_ISSUE, INVOICE_DUE_DATE, INVOICE_EXTERNAL_REGION, INVOICE_NUMBER, INVOICE_RECEIVED_DATE, INVOICE_STATUS } from '../resources/invoice'
import { PROPOSAL_CUSTOM_NAME, PROPOSAL_DATE_OF_ISSUE, PROPOSAL_EXPIRATION_DATE, PROPOSAL_NUMBER, PROPOSAL_STATUS } from '../resources/proposal'
import { PO_CUSTOM_NUMBER, PO_DATE_OF_ISSUE, PO_NUMBER, PO_STATUS } from '../resources/purchaseOrder'
import { SITE_ADDRESS, SITE_CITY, SITE_COUNTRY, SITE_CUSTOM_ID, SITE_IN_CARE_OF, SITE_NAME, SITE_REGION, SITE_STATE, SITE_ZIP } from '../resources/site'
import { TRIP_CATEGORY, TRIP_COMPLETION_DATE, TRIP_DISPATCH_DATE, TRIP_ETA_DATE, TRIP_ETC_DATE, TRIP_EXPIRATION_DATE, TRIP_STATUS } from '../resources/trip'
import { VENDOR_ADDRESS, VENDOR_CITY, VENDOR_COINS_ID, VENDOR_COUNTRY, VENDOR_NAME, VENDOR_STATE, VENDOR_ZIP_CODE } from '../resources/vendor'
import { WORK_ORDER_BILLING_METHOD, WORK_ORDER_CALL, WORK_ORDER_CLIENT_CONTACT, WORK_ORDER_CLOSE_DATE, WORK_ORDER_CREATED_DATE, WORK_ORDER_CUSTOM_STATUS, WORK_ORDER_DEPARTMENT_BRANCH, WORK_ORDER_DUE_DATE, WORK_ORDER_EXPIRATION_DATE, WORK_ORDER_FOLLOW_UP_DATE, WORK_ORDER_GENERATION_DATE, WORK_ORDER_JOB_NAME, WORK_ORDER_NUMBER, WORK_ORDER_PRIORITY, WORK_ORDER_SCHEDULED_SERVICE_NUMBER, WORK_ORDER_SERVICE, WORK_ORDER_START_DATE, WORK_ORDER_STATUS, WORK_ORDER_TASK, WORK_ORDER_TRADE, WORK_ORDER_VENDOR_STATUS, WORK_ORDER_WEATHER_EVENT_WW_ID } from '../resources/workOrder'
import { WEATHER_EVENT_END_DATE, WEATHER_EVENT_START_DATE } from '../resources/weatherEvent'
import { TEAM_MEMBER_LOCATION_EMPLOYEE, TEAM_MEMBER_LOCATION_EMPLOYEE_TRADE, TEAM_MEMBER_NAME, TEAM_MEMBER_REGION, TEAM_MEMBER_WORK_ORDER_MANAGER } from '../resources/teamMember'
import { AutocompletePayload } from '@/types/interfaces'
import { AutocompleteResponse } from '@/types/interfaces/api-v2/autocomplete'
import { EXTERNAL_REFERENCES_EXTERNAL_ID, EXTERNAL_REFERENCES_INTEGRATION_PLATFORM } from '../resources/externalReferences'
import { useWorkOrders } from '@/use/work-orders'
import { useInboundWorkOrders } from '@/use/inbound-work-orders'
import { useApp } from '@/use/app'
import { BROKER } from '@/constants/permissions'

export const allTripsFilters = () => {
  const { WorkOrdersAutoComplete } = useWorkOrders()
  const { inboundWorkOrdersAutoComplete } = useInboundWorkOrders()
  const { companyType } = useApp()

  const workOrdersAutoCompleteFetchFn = async (params: AutocompletePayload) => {
    const { data, nextPage } = companyType.value !== BROKER
      ? await inboundWorkOrdersAutoComplete({ ...params }) as { data: AutocompleteResponse[], nextPage: number }
      : await WorkOrdersAutoComplete({ ...params }) as { data: AutocompleteResponse[], nextPage: number }
    return {
      data: data.map((item: AutocompleteResponse, index: number) => {
        return {
          id: `${index}-${item.text}`,
          text: item.text,
          value: item.text
        }
      }),
      nextPage
    }
  }

  return {
    filters: [
      WORK_ORDER_CUSTOM_STATUS({ pinned: true, isPrimary: true }, workOrdersAutoCompleteFetchFn),
      WORK_ORDER_STATUS({ pinned: true, isPrimary: true }),
      WORK_ORDER_NUMBER({ pinned: true, isPrimary: true }, { field: 'id', invoiceables: false }, workOrdersAutoCompleteFetchFn),
      // Site Name filter Needed added in filters sheet
      WORK_ORDER_SERVICE({ pinned: true, isPrimary: true }, { field: 'trade_service_name' }, workOrdersAutoCompleteFetchFn),
      TRIP_STATUS({ pinned: true, isPrimary: true }),
      // Work Order Without Trips
      // My Work Orders
      WORK_ORDER_START_DATE({ pinned: false, isPrimary: false }),
      WORK_ORDER_CREATED_DATE({ pinned: false, isPrimary: false }),
      WORK_ORDER_DUE_DATE({ pinned: false, isPrimary: false }),
      WORK_ORDER_GENERATION_DATE({ pinned: false, isPrimary: false }),
      WORK_ORDER_CLOSE_DATE({ pinned: false, isPrimary: false }),
      WORK_ORDER_EXPIRATION_DATE({ pinned: false, isPrimary: false }),
      WORK_ORDER_FOLLOW_UP_DATE({ pinned: false, isPrimary: false }),
      WORK_ORDER_PRIORITY({ pinned: false, isPrimary: false }, { field: 'priority' }, workOrdersAutoCompleteFetchFn),
      WORK_ORDER_BILLING_METHOD({ pinned: false, isPrimary: false }, { field: 'work_order_type' }, workOrdersAutoCompleteFetchFn),
      WORK_ORDER_CALL({ pinned: false, isPrimary: false }, { field: 'call_type' }, workOrdersAutoCompleteFetchFn),
      WORK_ORDER_CLIENT_CONTACT({ pinned: false, isPrimary: false }, workOrdersAutoCompleteFetchFn),
      WORK_ORDER_JOB_NAME({ pinned: false, isPrimary: false }, { field: 'job_name' }, workOrdersAutoCompleteFetchFn),
      WORK_ORDER_DEPARTMENT_BRANCH({ pinned: false, isPrimary: false }, { field: 'department_or_branch' }, workOrdersAutoCompleteFetchFn),
      WORK_ORDER_TRADE({ pinned: false, isPrimary: false }, { field: 'trade_name' }, workOrdersAutoCompleteFetchFn),
      WORK_ORDER_TASK({ pinned: false, isPrimary: false }, { field: 'task_name' }, workOrdersAutoCompleteFetchFn),
      WORK_ORDER_SCHEDULED_SERVICE_NUMBER({ pinned: false, isPrimary: false }, { field: 'agreement_contract_id' }, workOrdersAutoCompleteFetchFn),
      CLIENT_NAME({ pinned: false, isPrimary: false }, { field: 'client_name', tab: 'active' }, workOrdersAutoCompleteFetchFn),
      CLIENT_COUNTRY({ pinned: false, isPrimary: false }, { field: 'client_country', tab: 'active' }, workOrdersAutoCompleteFetchFn),
      CLIENT_STATE({ pinned: false, isPrimary: false }, { field: 'client_state', tab: 'active' }, workOrdersAutoCompleteFetchFn),
      CLIENT_CITY({ pinned: false, isPrimary: false }, { field: 'client_city', tab: 'active' }, workOrdersAutoCompleteFetchFn),
      CLIENT_ZIP({ pinned: false, isPrimary: false }, { field: 'client_zip', tab: 'active' }, workOrdersAutoCompleteFetchFn),
      CLIENT_ADDRESS({ pinned: false, isPrimary: false }, { field: 'client_address', tab: 'active' }, workOrdersAutoCompleteFetchFn),
      SITE_NAME({ pinned: false, isPrimary: false }, { field: 'site_name', invoiceables: false }, workOrdersAutoCompleteFetchFn),
      SITE_IN_CARE_OF({ pinned: false, isPrimary: false }, { field: 'site_care_of', tab: 'active' }, workOrdersAutoCompleteFetchFn),
      SITE_COUNTRY({ pinned: false, isPrimary: false }, { field: 'site_country', tab: 'active' }, workOrdersAutoCompleteFetchFn),
      SITE_STATE({ pinned: false, isPrimary: false }, { field: 'site_state', tab: 'active' }, workOrdersAutoCompleteFetchFn),
      SITE_CITY({ pinned: false, isPrimary: false }, { field: 'site_city', tab: 'active' }, workOrdersAutoCompleteFetchFn),
      SITE_ZIP({ pinned: false, isPrimary: false }, { field: 'site_zip', tab: 'active' }, workOrdersAutoCompleteFetchFn),
      SITE_ADDRESS({ pinned: false, isPrimary: false }, { field: 'site_address', tab: 'active' }, workOrdersAutoCompleteFetchFn),
      SITE_CUSTOM_ID({ pinned: false, isPrimary: false }, { field: 'site_custom_id', tab: 'active' }, workOrdersAutoCompleteFetchFn),
      SITE_REGION({ pinned: false, isPrimary: false }, { field: 'site_region', invoiceables: false }, workOrdersAutoCompleteFetchFn),
      TRIP_DISPATCH_DATE({ pinned: false, isPrimary: false }),
      TRIP_EXPIRATION_DATE({ pinned: false, isPrimary: false }),
      TRIP_ETA_DATE({ pinned: false, isPrimary: false }),
      TRIP_ETC_DATE({ pinned: false, isPrimary: false }),
      TRIP_COMPLETION_DATE({ pinned: false, isPrimary: false }),
      TRIP_CATEGORY({ pinned: false, isPrimary: false }),
      TEAM_MEMBER_NAME({ pinned: false, isPrimary: false }, { field: 'team_member' }, workOrdersAutoCompleteFetchFn),
      TEAM_MEMBER_REGION({ pinned: false, isPrimary: false }),
      TEAM_MEMBER_LOCATION_EMPLOYEE({ pinned: false, isPrimary: false }, { field: 'location_tm' }, workOrdersAutoCompleteFetchFn),
      TEAM_MEMBER_LOCATION_EMPLOYEE_TRADE({ pinned: false, isPrimary: false }, { field: 'location_tm_trade' }, workOrdersAutoCompleteFetchFn),
      TEAM_MEMBER_WORK_ORDER_MANAGER({ pinned: false, isPrimary: false }, { field: 'location_manager' }, workOrdersAutoCompleteFetchFn),
      VENDOR_NAME({ pinned: false, isPrimary: false }, { field: 'vendor_name', tab: 'active' }, workOrdersAutoCompleteFetchFn),
      VENDOR_COUNTRY({ pinned: false, isPrimary: false }, { field: 'vendor_country', tab: 'active' }, workOrdersAutoCompleteFetchFn),
      VENDOR_STATE({ pinned: false, isPrimary: false }, { field: 'vendor_state', tab: 'active' }, workOrdersAutoCompleteFetchFn),
      VENDOR_CITY({ pinned: false, isPrimary: false }, { field: 'vendor_city', tab: 'active' }, workOrdersAutoCompleteFetchFn),
      VENDOR_ZIP_CODE({ pinned: false, isPrimary: false }, { field: 'vendor_zip', tab: 'active' }, workOrdersAutoCompleteFetchFn),
      VENDOR_ADDRESS({ pinned: false, isPrimary: false }, { field: 'vendor_address', tab: 'active' }, workOrdersAutoCompleteFetchFn),
      WORK_ORDER_VENDOR_STATUS({ pinned: false, isPrimary: false }),
      VENDOR_COINS_ID({ pinned: false, isPrimary: false }, { field: 'vendor_coins_id', tab: 'active' }, workOrdersAutoCompleteFetchFn),
      PO_NUMBER(false, { pinned: false, isPrimary: false }, { field: 'po_id' }, workOrdersAutoCompleteFetchFn),
      PO_STATUS(false, { pinned: false, isPrimary: false }),
      PO_DATE_OF_ISSUE(false, { pinned: false, isPrimary: false }),
      PO_CUSTOM_NUMBER(false, { pinned: false, isPrimary: false }, { field: 'po_custom_number' }, workOrdersAutoCompleteFetchFn),
      PROPOSAL_NUMBER(true, { pinned: false, isPrimary: false }, { field: 'proposal_id' }, workOrdersAutoCompleteFetchFn),
      PROPOSAL_DATE_OF_ISSUE({ pinned: false, isPrimary: false }),
      PROPOSAL_EXPIRATION_DATE({ pinned: false, isPrimary: false }),
      PROPOSAL_STATUS(true, { pinned: false, isPrimary: false }),
      PROPOSAL_CUSTOM_NAME(true, { pinned: false, isPrimary: false }, { field: 'custom_proposal_name' }, workOrdersAutoCompleteFetchFn),
      INVOICE_NUMBER(true, { pinned: false, isPrimary: false }, { field: 'inv_id' }, workOrdersAutoCompleteFetchFn),
      INVOICE_CUSTOM_NUMBER(true, { pinned: false, isPrimary: false }, { field: 'inv_custom_id' }, workOrdersAutoCompleteFetchFn),
      INVOICE_STATUS(true, { pinned: false, isPrimary: false }),
      INVOICE_DATE_OF_ISSUE(true, { pinned: false, isPrimary: false }),
      INVOICE_DUE_DATE(true, { pinned: false, isPrimary: false }),
      INVOICE_RECEIVED_DATE({ pinned: false, isPrimary: false }),
      INVOICE_EXTERNAL_REGION(true, { pinned: false, isPrimary: false }, { field: 'inv_region' }, workOrdersAutoCompleteFetchFn),
      WORK_ORDER_WEATHER_EVENT_WW_ID({ pinned: false, isPrimary: false }),
      WEATHER_EVENT_START_DATE({ pinned: false, isPrimary: false }),
      WEATHER_EVENT_END_DATE({ pinned: false, isPrimary: false }),
      EXTERNAL_REFERENCES_INTEGRATION_PLATFORM({ pinned: false, isPrimary: false }),
      EXTERNAL_REFERENCES_EXTERNAL_ID({ pinned: false, isPrimary: false })
    ],
    sortBy: {
      value: 'work_order_id',
      direction: {
        text: 'Descending',
        value: 'desc'
      },
      items: [
        {
          label: 'Status',
          value: 'extended_status'
        },
        {
          label: 'Expiration Date',
          value: 'expiration_date'
        },
        {
          label: 'Work Order #',
          value: 'work_order_id'
        }
      ]
    },
    fieldMapping: {
      'work-order-custom-status': {
        name: 'work_order_work_order_status_name',
        field: 'value'
      },
      'work-order-status': {
        name: 'work_order_extended_status_state',
        field: 'value'
      },
      'work-order-number': {
        name: 'work_order_id',
        field: 'value'
      },
      // Site Name filter mapping Needed added in filters sheet
      'work-order-service': {
        name: 'trade_services',
        field: 'value'
      },
      'trip-status': {
        name: 'extended_status_code',
        field: 'value'
      },
      // Work Order Without Trips
      // My Work Orders
      'work-order-start-date': {
        name: 'start_date',
        field: 'value'
      },
      'work-order-created-date': {
        name: 'work_order_created_at',
        field: 'value'
      },
      'work-order-due-date': {
        name: 'work_order_expiration_date',
        field: 'value'
      },
      'work-order-generation-date': {
        name: 'work_order_creation_date',
        field: 'value'
      },
      'work-order-close-date': {
        name: 'work_order_close_date',
        field: 'value'
      },
      'work-order-expiration-date': {
        name: 'work_order_expiration_date',
        field: 'value'
      },
      'work-order-follow-up-date': {
        name: 'work_order_follow_up_at',
        field: 'value'
      },
      'work-order-priority': {
        name: 'work_order_priority',
        field: 'value'
      },
      'work-order-billing-method': {
        name: 'work_order_work_order_type',
        field: 'value'
      },
      'work-order-call': {
        name: 'work_order_call_type',
        field: 'value'
      },
      'work-order-client-contact': {
        name: 'work_order_client_contact_full_name',
        field: 'value'
      },
      'work-order-job-name': {
        name: 'work_order_work_order_name',
        field: 'value'
      },
      'work-order-department-branch': {
        name: 'work_order_cu_cc_departments_name',
        field: 'value'
      },
      'work-order-trade': {
        name: 'trade_name',
        field: 'value'
      },
      'work-order-task': {
        name: 'tasks_name',
        field: 'value'
      },
      'scheduled-service-number': {
        name: 'work_order_agreement_contract_id',
        field: 'value'
      },
      'client-name': {
        name: 'work_order_company_name',
        field: 'value'
      },
      'client-country': {
        name: 'work_order_company_headquarter_address_country',
        field: 'value'
      },
      'client-state': {
        name: 'work_order_company_headquarter_address_state',
        field: 'value'
      },
      'client-city': {
        name: 'work_order_company_headquarter_address_city',
        field: 'value'
      },
      'client-zip': {
        name: 'work_order_company_headquarter_address_zip_code',
        field: 'value'
      },
      'client-address': {
        name: 'work_order_company_headquarter_address_address1',
        field: 'value'
      },
      'site-name': {
        name: 'locations_name',
        field: 'value'
      },
      'site-in-care-of': {
        name: 'locations_billing_address_in_care_of',
        field: 'value'
      },
      'site-country': {
        name: 'locations_address_country',
        field: 'value'
      },
      'site-state': {
        name: 'locations_address_state',
        field: 'value'
      },
      'site-city': {
        name: 'locations_address_city',
        field: 'value'
      },
      'site-zip': {
        name: 'locations_address_zip_code',
        field: 'value'
      },
      'site-address': {
        name: 'locations_address_address1',
        field: 'value'
      },
      'site-custom-id': {
        name: 'locations_coins_connection_external_id',
        field: 'value'
      },
      'site-region': {
        name: 'locations_cc_territory_name',
        field: 'value'
      },
      'trip-dispatch-date': {
        name: 'dispatch_time',
        field: 'value'
      },
      'trip-expiration-date': {
        name: 'expiration_date',
        field: 'value'
      },
      'trip-eta-date': {
        name: 'eta_date',
        field: 'value'
      },
      'trip-etc-date': {
        name: 'etc_date',
        field: 'value'
      },
      'trip-completion-date': {
        name: 'expiration_date',
        field: 'value'
      },
      'trip-category': {
        name: 'trip_category_name',
        field: 'value'
      },
      'employee-name': {
        name: 'team_members_full_name',
        field: 'value'
      },
      'employee-region': {
        name: 'team_members_cc_user_territories_name',
        field: 'value'
      },
      'location-employee': {
        name: 'locations_location_users_user_full_name',
        field: 'value'
      },
      'location-employee-trade': {
        name: 'locations_location_users_trade_name',
        field: 'value'
      },
      'work-order-manager': {
        name: 'work_order_work_order_users_user_full_name',
        field: 'value'
      },
      'vendor-name': {
        name: 'cc_trip_contractor_name',
        field: 'value'
      },
      'vendor-country': {
        name: 'cc_trip_contractor_headquarter_address_country',
        field: 'value'
      },
      'vendor-state': {
        name: 'cc_trip_contractor_headquarter_address_state',
        field: 'value'
      },
      'vendor-city': {
        name: 'cc_trip_contractor_headquarter_address_city',
        field: 'value'
      },
      'vendor-zip-code': {
        name: 'cc_trip_contractor_headquarter_address_zip_code',
        field: 'value'
      },
      'vendor-address': {
        name: 'cc_trip_contractor_headquarter_address_address1',
        field: 'value'
      },
      'vendor-status': {
        name: 'work_order_vendors_status_status_code',
        field: 'value'
      },
      'vendor-coins-id': {
        name: 'cc_trip_contractor_vendor_coins_connection_external_id',
        field: 'value'
      },
      'purchase-order-number': {
        name: 'purchase_order_po_number',
        field: 'value'
      },
      'purchase-order-status': {
        name: 'purchase_order_status',
        field: 'value'
      },
      'purchase-order-date-of-issue': {
        name: 'purchase_order_date_of_issue',
        field: 'value'
      },
      'purchase-order-custom-number': {
        name: 'purchase_order_custom_po_number',
        field: 'value'
      },
      'proposal-number': {
        name: 'proposals_estimate_number',
        field: 'value'
      },
      'proposal-date-of-issue': {
        name: 'proposals_date_of_issue',
        field: 'value'
      },
      'proposal-expiration-date': {
        name: 'proposals_proposal_date',
        field: 'value'
      },
      'proposal-status': {
        name: 'proposals_status',
        field: 'value'
      },
      'proposal-custom-name': {
        name: 'proposals_custom_proposal_name',
        field: 'value'
      },
      'invoice-number': {
        name: 'invoices_short_invoice_number',
        field: 'value'
      },
      'invoice-custom-number': {
        name: 'work_order_annexed_invoices_custom_id',
        field: 'value'
      },
      'invoice-status': {
        name: 'invoices_status',
        field: 'value'
      },
      'invoice-date-of-issue': {
        name: 'invoices_date_of_issue',
        field: 'value'
      },
      'invoice-due-date': {
        name: 'invoices_due_date',
        field: 'value'
      },
      'invoice-received-date': {
        name: 'invoices_draft_sent_at',
        field: 'value'
      },
      'invoice-external-region': {
        name: 'locations_cc_territory_name',
        field: 'value'
      },
      'weather-event-ww-id': {
        name: 'event_id',
        field: 'value'
      },
      'weather-event-start-date': {
        name: 'storms_start',
        field: 'value'
      },
      'weather-event-end-date': {
        name: 'storms_end',
        field: 'value'
      },
      'integration-platform': {
        name: 'external_connections_provider',
        field: 'value'
      },
      'external-id': {
        name: 'external_connections_id',
        field: 'value'
      }
    }
  }
}
