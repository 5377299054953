export const analyticsPayableFilters = () => {
  return {
    payable: {
      filters: [
      // ...
      ],
      sortBy: {
        value: 'id',
        direction: {
          text: 'Ascending',
          value: 'asc'
        },
        items: []
      },
      fieldMapping: {
        'date-of-issue': { name: 'date_of_issue' },
        'custom-invoice': { name: 'custom_invoice_number', field: 'text' },
        'invoice-number': { name: 'short_invoice_number', field: 'text' },
        'due-date': { name: 'due_date' },
        clients: { name: 'company_id', field: 'id' },
        vendors: { name: 'vendor_id', field: 'id' },
        sites: { name: 'locations_id', field: 'id' }
      }
    }
  }
}
