import { api } from '@/api'
import qs from 'qs'

interface GetSiteListParams {
  perPage: number
  page: number
  q?: any
  s?: any
}

// interface SiteUpdateParams {
//   siteId: string
//   updateContent: any
// }

export default {
  getLocationList: ({ perPage, page, q, s }: GetSiteListParams) => api.$get('/locations', {
    params: {
      include: 'address',
      page,
      per_page: perPage,
      q,
      s
    },
    paramsSerializer: params => {
      return qs.stringify(params)
    }
  }),
  getMyLocationList: ({ perPage, page, q, s }: GetSiteListParams) => api.$get('/locations', {
    params: {
      include: 'vendor, attachments',
      page,
      per_page: perPage,
      q,
      s
    },
    paramsSerializer: params => {
      return qs.stringify(params)
    }
  })

}
