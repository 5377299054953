import { api } from '@/api'
import qs from 'qs'
import { InvoiceParams, BatchInvoiceUpdateParams } from '@/utils/api'
import { BatchInvoice } from '@/types/interfaces'

export default {
  get: ({ include, objectScope, q, page, perPage }:InvoiceParams) => api.$get('/batch_invoices', {
    params: {
      include,
      object_scope: objectScope,
      q,
      page,
      per_page: perPage
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  post: (params: any, requestBody: { invoice: BatchInvoice }) => {
    return api.$post('/batch_invoices', {
      batch_invoice: requestBody.invoice
    }, {
      params,
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  getById: (id: number, { include }:InvoiceParams) => api.$get(`/batch_invoices/${id}`, {
    params: {
      include
    },
    paramsSerializer: (params: any) => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  delete: (invoiceId: string) => {
    return api.$delete(`/batch_invoices/${invoiceId}`)
  },
  update: ({ invoiceId, updateContent, include, objectScope }:BatchInvoiceUpdateParams) => {
    return api.$patch(`/batch_invoices/${invoiceId}`, { batch_invoice: updateContent }, {
      params: {
        objectScope,
        include
      },
      paramsSerializer: (params:any) => {
        return qs.stringify(params)
      }
    })
  }
}
