import { api } from '@/api'
import { SubscriptionData } from '@/use/chargebeeSubscriptions'
import qs from 'qs'
export default {
  postChargeBeeSubscriptions: (param: SubscriptionData) => api.$post('/chargebee_subscriptions', {
    subscription_id: param.subscriptionId,
    package_id: param.packageId,
    ip_address: param.ipAddress,
    subscription_created_at: param.subscriptionCreatedAt,
    customer_id: param.customerId,
    cf_user_id: param.userId
  }),
  getSubscriptions: ({ q, include }: any) => api.$get('/chargebee_subscriptions', {
    params: {
      q,
      include: include || 'package,package/package_usage_notifications'
    },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  getValidSubscription: ({ q, include }: any) => api.$get('/chargebee_subscriptions/recent_valid_subscription', {
    params: {
      q,
      include: include || 'package,package/package_usage_notifications'
    },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  })
}
