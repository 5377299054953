import { SEGMENT_WRITE_API_KEY } from '@/constants/env'
import _ from 'lodash'
import { useSession } from '@/use/session'

declare global {
  interface Window {
   analytics: any
  }
}

export const useSegment = () => {
  // https://segment.com/docs/connections/spec/page/
  const trackPage = (name: string) => {
    if (!SEGMENT_WRITE_API_KEY) {
      return
    }
    try {
      window.analytics.page(name)
    } catch {
      console.error('Failed to track page')
    }
  }

  // track event in segment
  // events will have a name
  // and take a properties object
  // with meta data
  // example:
  // choosing a package on two diff pages
  // eventName="select package", properties = "{newPlan: professional, oldPlan: free}"
  // bulk invoicing
  // eventName="generate invoice", properties = "{totalClients: 10, totalWO: 30}"
  // select company type
  // eventName="select company type", properties: {companyType: "broker"}
  // https://segment.com/docs/connections/spec/track/
  const trackEvent = (eventName:string, properties: any) => {
    if (!SEGMENT_WRITE_API_KEY) {
      return
    }
    try {
      const segmentProperties: any = {}
      Object.keys(properties).forEach((key: any) => {
        segmentProperties[_.kebabCase(key)] = properties[key]
      })
      window.analytics.track(_.kebabCase(eventName), segmentProperties)
    } catch {
      console.error('Failed to track event')
    }
  }

  // track user in segment
  // name, userId, email, phone, companyId, subdomain
  const identifyUser = () => {
    if (!SEGMENT_WRITE_API_KEY) {
      return
    }
    const { session } = useSession()
    try {
      if (session.loggedUser.id) {
        window.analytics.identify(session.loggedUser?.id, {
          name: session.loggedUser?.fullName,
          email: session.loggedUser?.email,
          createdAt: session.loggedUser?.createdAt
        })
      }
      if (session.currentCompany.id) {
        window.analytics.group(session.currentCompany.id, { name: session.currentCompany.name })
      }
    } catch (err) {
      console.error('Failed to initalized segment', err)
    }
  }
  return {
    trackPage,
    trackEvent,
    identifyUser
  }
}
