import { api } from '@/api'
import qs from 'qs'

interface GetTradeListParams {
  perPage?: number
  page?: number
  q?: any
  include?: string
}

export default {
  get: ({ perPage, page, q, include }: GetTradeListParams) => api.$get('/trades', {
    params: {
      page,
      per_page: perPage,
      q,
      include
    },
    paramsSerializer: params => {
      return qs.stringify(params)
    }
  }),
  bulkDestory: (params: any) => api.$delete('/trades/bulk_destroy', {
    params: {
      ids: params
    }
  }),
  bulkCreate: (items: any[]) => api.$post('/trades/bulk_create', {
    trades: items
  }),
  getSuggestions: () => api.$get('/trades/trade_suggestions'),
  getTradeServices: ({ perPage, page, q, include }: GetTradeListParams) => api.$get('/trade_services', {
    params: {
      page,
      per_page: perPage,
      q,
      include
    },
    paramsSerializer: (params: any) => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  })
}
