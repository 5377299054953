import { useCrews } from '@/use/crews'
import { AutocompletePayload, AutocompleteResponse } from '@/types/interfaces/api-v2/autocomplete'

const { crewsAutoComplete } = useCrews()

const CREW_NAME = (config = {}) => {
  return {
    text: 'Name',
    canSelect: false,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'crew-name-filter',
      headerText: 'Crew Name',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn: async (params: AutocompletePayload) => {
        const { data, nextPage } = await crewsAutoComplete({ ...params }) as {data: AutocompleteResponse[], nextPage: number}
        return {
          data: data.map((item: AutocompleteResponse, index: number) => {
            return {
              id: `${index}-${item.text}`,
              text: item.text,
              value: item.text
            }
          }),
          nextPage
        }
      },
      params: { field: 'name' },
      searchKeys: ['name']
    },
    pinned: true,
    isPrimary: true,
    values: [],
    ...config
  }
}

export {
  CREW_NAME
}
