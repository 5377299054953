import repositories from '@/repositories'

export const useCompanyClassifications = () => {
  const get = async (params:any) => {
    try {
      const response = await repositories.companyClassifications.get(params)
      return response
    } catch (error) {
      console.log(error)
    }
  }
  const getClassifications = async (params:any) => {
    try {
      const response = await repositories.companyClassifications.get(params)
      return response
    } catch (error) {
      console.log(error)
    }
  }

  return {
    get,
    getClassifications
  }
}
