import { api } from '@/api'
import { PostTravelTimeAdjustment } from '@/use/travel-time-adjustments'

export default {
  post: (travelTimeLogId: number, params: PostTravelTimeAdjustment, include = '') => {
    return api.$post(`/travel_time_logs/${travelTimeLogId}/travel_time_adjustments`, params, {
      params: {
        include
      }
    })
  }
}
