import { api } from '@/api'
import qs from 'qs'

interface getContactsParams {
  scope?: string
  include?: string
  q?: any
  page?: number
  perPage?: number
  locationId?: number | string,
  clientId?: number | string
}

export default {
  getContactUsers: ({ perPage, page, q, include }: getContactsParams) => api.$get('/users', {
    params: {
      per_page: perPage,
      page,
      q,
      include
    },
    paramsSerializer: params => {
      return qs.stringify(params)
    }
  }),
  getLocationContacts: ({ locationId, perPage, page, q, include }: getContactsParams) => api.$get(`locations/${locationId}/locations_users`, {
    params: {
      per_page: perPage,
      page,
      q,
      include
    },
    paramsSerializer: params => {
      return qs.stringify(params)
    }
  })

}
