var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('uc-tile-container', {
    staticClass: "d-flex flex-1 flex-column px-4 py-10 align-self-center",
    attrs: {
      "no-margin": true,
      "no-elevation": true,
      "no-padding": true,
      "background-color": "--v-layer-secondary-base"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column align-center"
  }, [_setup.logo ? _c('uc-img-tag', {
    attrs: {
      "src": _setup.logo,
      "alt": "tm-image",
      "width": 80,
      "height": 80,
      "rounded": ""
    }
  }) : _vm._e(), _c('uc-text', {
    staticClass: "my-4",
    attrs: {
      "size": 1,
      "type": "body",
      "text": _vm.title,
      "bold": true
    }
  }), _c('uc-button', {
    attrs: {
      "text": _vm.actionText,
      "size": "sm",
      "type": "text"
    },
    on: {
      "click": _setup.performAction
    }
  })], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };