import { AxiosRequestConfig } from 'axios'
import { httpClient, httpClientSecure } from './http'

export const api = {
  request: httpClient.request,
  get: httpClient.get,
  delete: httpClient.delete,
  post: httpClient.post,
  put: httpClient.put,
  patch: httpClient.patch,
  $request: async (
    config: AxiosRequestConfig
  ) => (await httpClient.request(config))?.data,
  $get: async (
    url: string,
    config?: AxiosRequestConfig
  ) => (await httpClient.get(url, config))?.data,
  $getBuffer: async (
    url: string,
    config?: AxiosRequestConfig
  ) => (await httpClient.get(url, config))?.request?.response,
  $delete: async (
    url: string,
    config?: AxiosRequestConfig
  ) => (await httpClient.delete(url, config))?.data,
  $post: async (
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ) => (await httpClient.post(url, data, config))?.data,
  $put: async (
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ) => (await httpClient.put(url, data, config))?.data,
  $patch: async (
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ) => (await httpClient.patch(url, data, config))?.data
}

export const apis = {
  request: httpClientSecure.request,
  get: httpClientSecure.get,
  delete: httpClientSecure.delete,
  post: httpClientSecure.post,
  put: httpClientSecure.put,
  patch: httpClientSecure.patch,
  $request: async (
    config: AxiosRequestConfig
  ) => (await httpClientSecure.request(config))?.data,
  $get: async (
    url: string,
    config?: AxiosRequestConfig
  ) => {
    return (await httpClientSecure.get(url, config))?.data
  },
  $delete: async (
    url: string,
    config?: AxiosRequestConfig
  ) => (await httpClientSecure.delete(url, config))?.data,
  $post: async (
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ) => (await httpClientSecure.post(url, data, config))?.data,
  $put: async (
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ) => (await httpClientSecure.put(url, data, config))?.data,
  $patch: async (
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ) => (await httpClientSecure.patch(url, data, config))?.data
}
