import repositories from '@/repositories'
import { ref } from 'vue'
import { User } from '@/types/interfaces/api-v2/user'
import { Company } from '@/types/interfaces/api-v2/company'
import { startCase } from 'lodash'
import { WorkOrder } from '@/types/interfaces'
import { useSession } from './session'
import { ExtendedStatus } from '@/types/enums'

export interface AttachmentType {
  name: string
  id?: number | string
  type: string
}
export interface AttachedDocument {
  id: number | string
  attachmentUrl: string
  name: string
  downloadUrl?: string,
  user?: User,
  company?: Company
  formattedFileName?: string
  canDelete?: boolean
}
export interface AttachmentCategoryType {
  name: string
}
export interface AttachmentResourceType {
  name: string
  tripId?: number | string
  categories?: AttachmentCategoryType[]
}

export interface AttachmentSelectedResourceInfo {
  type: AttachmentResourceType
  category: AttachmentCategoryType
}

export interface CreateWorkOrderAttachment {
  id?: number | string
  name: string
  description: string
  makeFileAvailableToClient?: boolean
  makeFileAvailableToVendor?: boolean
  remoteAttachmentUrl?: string
  attachment?: AttachedDocument[]
  attachedFile?: any
  attachmentType?: AttachmentType
  user?: User
  attachmentUrl?: string
  formattedFileName?: string
  downloadUrl?: string
  canSyncToSc?: boolean
  isSyncedToSc?: boolean
  syncedToSc?: boolean
  documents?: AttachedDocument[]
  resourceType?: string
  createdAt?: Date | string
  status?: string
  totalCents?: number
  resourceTypeInfo?: AttachmentSelectedResourceInfo
  canDelete?: boolean
  sendToSc?: boolean
}

export interface WorkOrderAttachment extends CreateWorkOrderAttachment {
  company?: Company
  isAttachmentFromWO: boolean
  isEdit: boolean
  fileType: string
}

export interface WorkOrderAnnexedAttachment extends CreateWorkOrderAttachment {
  id: number | string
  resourceType: string
  customId?: string
  estimateNumber?: string
  poNumber?: string
  invoiceDocuments?: WorkOrderAttachment[]
  proposalDocuments?: WorkOrderAttachment[]
  purchaseOrderDocuments?: WorkOrderAttachment[]
}

interface WorkOrderWithAttachment extends WorkOrder {
  isAttachmentFromWO?: boolean
  attachment?: AttachedDocument | AttachedDocument[]
  isEdit?: boolean
}

const workOrdersAttachmentTable = ref<any>(null)
const tripAttachmentsTable = ref<any>(null)

export const useAttachments = () => {
  const getAttachments = async (params: any) => {
    try {
      const res = await repositories.attachments.get(params)
      return {
        data: res.attachments,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const postAttachments = async (params: any) => {
    try {
      const res = await repositories.attachments.post(params)
      return {
        data: res[params.responseKey]
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const patchAttachment = async (params: any) => {
    try {
      const res = await repositories.attachments.patch(params)
      return {
        data: res[params.responseKey]
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const deleteAttachments = async (params:any) => {
    try {
      return await repositories.attachments.delete(params)
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const syncAttachment = async (params: any) => {
    try {
      return await repositories.attachments.syncAttachment(params)
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getWorkOrderTradeAttachment = async (params: any) => {
    try {
      const res = await repositories.attachments.getWorkOrderTradeAttachment(params)
      return {
        data: res.workOrderTradeDocuments,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const createWorkOrderTypeAttachmentObject = (document: AttachedDocument, selectedResourceInfo: AttachmentSelectedResourceInfo, attachmentType: AttachmentType) => {
    // Add Attachment Resource Info
    return {
      ...document,
      attachmentType,
      resourceTypeInfo: selectedResourceInfo,
      documents: [
        {
          attachmentUrl: document.attachmentUrl,
          name: document.formattedFileName,
          downloadUrl: document.downloadUrl,
          id: document.id,
          company: document.company
        }
      ]
    } as WorkOrderAttachment
  }

  // function is to transform and return attachment Array from WO details (for client/vendor -> invoice, PO & proposal)
  // WorkOrderWithAttachment will be the response
  const transformClientVendorAttachments = (workOrder: WorkOrderWithAttachment, attachmentType: AttachmentType, selectedResource: WorkOrder, isClientOrVendorFinancial: boolean) => {
    const { session } = useSession()
    let attachmentArray: WorkOrderAttachment[] = []
    if (
      (selectedResource?.vendorId === session.currentCompany.id &&
        attachmentType.type === 'client') ||
    (selectedResource?.vendorId !== session.currentCompany.id &&
      attachmentType.type === 'vendor')
    ) {
      workOrder?.clientAnnexedInvoices?.forEach((e: WorkOrderAnnexedAttachment) => {
        attachmentArray = createInvoiceAttachmentObject(attachmentArray, e, attachmentType, isClientOrVendorFinancial)
      })
      workOrder?.clientAnnexedProposals?.forEach((e: WorkOrderAnnexedAttachment) => {
        attachmentArray = createProposalAttachmentObject(attachmentArray, e, attachmentType, isClientOrVendorFinancial)
      })
      workOrder?.clientAnnexedPurchaseOrders?.forEach((e: WorkOrderAnnexedAttachment) => {
        attachmentArray = createPurchaseOrderAttachmentObject(attachmentArray, e, attachmentType, isClientOrVendorFinancial)
      })
    } else if (
      (selectedResource?.vendorId === session.currentCompany.id &&
        attachmentType.type === 'vendor') ||
      (selectedResource?.vendorId !== session.currentCompany.id &&
      attachmentType.type === 'client')
    ) {
      workOrder?.vendorAnnexedInvoices?.forEach((e: WorkOrderAnnexedAttachment) => {
        attachmentArray = createInvoiceAttachmentObject(attachmentArray, e, attachmentType, isClientOrVendorFinancial)
      })
      workOrder?.vendorAnnexedProposals?.forEach((e: WorkOrderAnnexedAttachment) => {
        attachmentArray = createProposalAttachmentObject(attachmentArray, e, attachmentType, isClientOrVendorFinancial)
      })
      workOrder?.vendorAnnexedPurchaseOrders?.forEach((e: WorkOrderAnnexedAttachment) => {
        attachmentArray = createPurchaseOrderAttachmentObject(attachmentArray, e, attachmentType, isClientOrVendorFinancial)
      })
    }
    return attachmentArray
  }

  const createAndGetAttachmentObject = ({
    id,
    documents,
    createdAt,
    customId,
    status,
    description,
    estimateNumber,
    totalCents,
    poNumber,
    resourceType,
    canDelete
  }: WorkOrderAnnexedAttachment, attachmentType: AttachmentType, isClientOrVendorFinancial: boolean) => {
    const docs = documents?.map((attach) => {
      return {
        attachmentUrl: attach.attachmentUrl,
        name: attach.formattedFileName,
        id: attach.id,
        downloadUrl: attach.downloadUrl,
        user: attach.user,
        company: attach.company
      }
    }) as AttachedDocument[]

    // Add Attachment Resource Info
    const resourceTypeDetails = {
      type: { name: 'Work Order' },
      category: {
        name: `${startCase(attachmentType.type)} ${getCategory(
          resourceType
        )}`
      }
    } as AttachmentSelectedResourceInfo

    const object = {
      id,
      attachmentType: attachmentTypeDetails(resourceType, attachmentType, isClientOrVendorFinancial),
      name: estimateNumber || customId || poNumber,
      description,
      createdAt,
      totalCents: (totalCents || 0) / 100,
      status: startCase(status),
      documents: docs,
      resourceType,
      makeFileAvailableToClient: attachmentType.type === 'client',
      makeFileAvailableToVendor: attachmentType.type === 'vendor',
      resourceTypeInfo: resourceTypeDetails,
      canDelete: canDelete || false
    } as WorkOrderAnnexedAttachment
    return object
  }

  const attachmentTypeDetails = (resourceType: string, attachmentType: AttachmentType, isClientOrVendorFinancial: boolean) => {
    if (isClientOrVendorFinancial) {
      return {
        type: attachmentType.type,
        name: `${startCase(attachmentType.type)} ${getCategory(
          resourceType
        )}`
      } as AttachmentType
    } else {
      return attachmentType
    }
  }

  const getCategory = (resource: string) => {
    if (resource === 'annexed_invoices') {
      return 'Invoice'
    } else if (resource === 'annexed_proposals') {
      return 'Proposal'
    } else if (resource === 'annexed_purchase_orders') {
      return 'Purchase Order'
    }
  }

  const createInvoiceAttachmentObject = (
    attachmentArray: Array<WorkOrderAttachment>,
    attachment: WorkOrderAnnexedAttachment,
    attachmentType: AttachmentType,
    isClientOrVendorFinancial: boolean
  ) => {
    const attachmentObject = {
      id: attachment.id,
      documents: attachment.invoiceDocuments,
      createdAt: attachment.createdAt,
      customId: attachment.customId,
      status: attachment.status,
      description: attachment.description,
      totalCents: attachment.totalCents,
      resourceType: 'annexed_invoices',
      canDelete: attachment.canDelete
    } as WorkOrderAnnexedAttachment
    const formattedAttachment = createAndGetAttachmentObject(
      attachmentObject,
      attachmentType,
      isClientOrVendorFinancial
    ) as WorkOrderAttachment
    if (formattedAttachment) attachmentArray.push(formattedAttachment)
    return attachmentArray
  }

  const createProposalAttachmentObject = (
    attachmentArray: Array<WorkOrderAttachment>,
    attachment: WorkOrderAnnexedAttachment,
    attachmentType: AttachmentType,
    isClientOrVendorFinancial: boolean
  ) => {
    const attachmentObject = {
      id: attachment.id,
      documents: attachment.proposalDocuments,
      createdAt: attachment.createdAt,
      estimateNumber: attachment.estimateNumber,
      status: attachment.status,
      description: attachment.description,
      totalCents: attachment.totalCents,
      resourceType: 'annexed_proposals',
      canDelete: attachment.canDelete
    } as WorkOrderAnnexedAttachment
    const formattedAttachment = createAndGetAttachmentObject(
      attachmentObject,
      attachmentType,
      isClientOrVendorFinancial
    ) as WorkOrderAttachment
    if (formattedAttachment) attachmentArray.push(formattedAttachment)
    return attachmentArray
  }

  const createPurchaseOrderAttachmentObject = (
    attachmentArray: Array<WorkOrderAttachment>,
    attachment: WorkOrderAnnexedAttachment,
    attachmentType: AttachmentType,
    isClientOrVendorFinancial: boolean
  ) => {
    const attachmentObject = {
      id: attachment.id,
      documents: attachment.purchaseOrderDocuments,
      createdAt: attachment.createdAt,
      estimateNumber: attachment.poNumber,
      status: attachment.status,
      resourceType: 'annexed_purchase_orders',
      canDelete: attachment.canDelete
    } as WorkOrderAnnexedAttachment
    const formattedAttachment = createAndGetAttachmentObject(
      attachmentObject,
      attachmentType,
      isClientOrVendorFinancial
    ) as WorkOrderAttachment
    if (formattedAttachment) attachmentArray.push(formattedAttachment)
    return attachmentArray
  }

  const tripExtendedStatusIsVerifiedOrCancelled = (trip: { extendedStatus: ExtendedStatus }) => {
    return (trip?.extendedStatus === ExtendedStatus.TRIP_VERIFIED || trip?.extendedStatus === ExtendedStatus.CANCELED)
  }

  return {
    getAttachments,
    postAttachments,
    deleteAttachments,
    patchAttachment,
    syncAttachment,
    getWorkOrderTradeAttachment,
    workOrdersAttachmentTable,
    transformClientVendorAttachments,
    createWorkOrderTypeAttachmentObject,
    tripAttachmentsTable,
    tripExtendedStatusIsVerifiedOrCancelled
  }
}

export const attachmentRequestResponseKeys: {
  [key: string]: {
    requestPayloadKey: string;
    responseKey: string;
  };
} = {
  invoices: { requestPayloadKey: 'attachment', responseKey: 'invoiceAttachment' },
  proposals: { requestPayloadKey: 'proposal_document', responseKey: 'proposalDocument' },
  work_orders: { requestPayloadKey: 'work_order_attachment', responseKey: 'workOrderAttachment' }
}
