import {
  CREW_NAME
} from '@/constants/filters/resources/crew'

export const peopleCrewFilters = () => {
  return {
    filters: [
      CREW_NAME({ pinned: true, isPrimary: true })
    ],
    sortBy: {
      value: 'name',
      direction: {
        text: 'Ascending',
        value: 'asc'
      },
      items: [
        {
          label: 'Name',
          value: 'name'
        }
      ]
    },
    fieldMapping: {
      'crew-name': {
        name: 'name',
        field: 'text'
      }
    }
  }
}
