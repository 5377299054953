import { api } from '@/api'
import qs from 'qs'
import { InvoiceParams, BatchInvoiceUpdateParams } from '@/utils/api'

export default {
  getById: (id: number, { include }:InvoiceParams) => api.$get(`/inbound_batch_invoices/${id}`, {
    params: {
      include
    },
    paramsSerializer: (params: any) => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  update: ({ invoiceId, updateContent, include, objectScope }:BatchInvoiceUpdateParams) => {
    return api.$patch(`/inbound_batch_invoices/${invoiceId}`, { batch_invoice: updateContent }, {
      params: {
        objectScope,
        include
      },
      paramsSerializer: (params:any) => {
        return qs.stringify(params)
      }
    })
  }
}
