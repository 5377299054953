export const brokerTemplateFilters = () => {
  return {
    application: {
      filters: [
        {
          text: 'Template Status',
          canSelect: false,
          popoverProps: {
            height: 'unset',
            slotKey: 'template-status',
            headerText: 'Template Status',
            size: 'lg',
            contentType: 'string',
            rowType: 'text',
            params: {},
            searchKeys: ['text'],
            items: [
              { id: 0, text: 'Active' },
              { id: 1, text: 'Inactive' },
              { id: 2, text: 'Draft' }
            ],
            showHeaders: true
          },
          pinned: true,
          isPrimary: true
        },
        {
          text: 'Created At',
          canSelect: false,
          popoverProps: {
            height: 'unset',
            slotKey: 'template-created-at',
            headerText: 'Created At',
            size: 'md',
            contentType: 'date',
            rowType: 'text',
            params: {},
            searchKeys: ['text'],
            showHeaders: true
          },
          pinned: true,
          isPrimary: true
        }
      ],
      sortBy: {
        value: 'created_at',
        direction: {
          text: 'Descending',
          value: 'desc'
        },
        items: [
          { label: 'Job #', value: 'id' },
          { label: 'Created At', value: 'created_at' },
          { label: 'Status', value: 'status' }
        ]
      },
      fieldMapping: {
        'template-status': {
          name: 'status',
          field: 'text',
          transform: (text: string) => text.toLowerCase()
        },
        'created-at': {
          name: 'created_at'
        }
      }
    }
  }
}
