import { BROKER, CLIENT, VENDOR } from './permissions'
import { ICON_MULTIPLE_CASH, MOVING_TRUCK, CART_OUTLINE, PAYMENT_BILL, TOOLS, ICON_PEOPLE, ICON_VENDOR, ICON_CLIENT } from './icons'
import { TopLevelTabs } from '@/types/enums/'

export const tabsByEnv = {
  development: [TopLevelTabs.RECEIVABLE_TAB, TopLevelTabs.PAYABLE_TAB, TopLevelTabs.RECRUITMENT_TAB, TopLevelTabs.EXPLORE_TAB, TopLevelTabs.SALES_TAB, TopLevelTabs.PURCHASING_TAB, TopLevelTabs.MATERIALS_TAB, TopLevelTabs.TRIPS_TAB],
  prestaging: [TopLevelTabs.RECEIVABLE_TAB, TopLevelTabs.PAYABLE_TAB, TopLevelTabs.RECRUITMENT_TAB, TopLevelTabs.EXPLORE_TAB, TopLevelTabs.SALES_TAB, TopLevelTabs.PURCHASING_TAB, TopLevelTabs.MATERIALS_TAB, TopLevelTabs.TRIPS_TAB],
  preproduction: [TopLevelTabs.RECEIVABLE_TAB, TopLevelTabs.PAYABLE_TAB, TopLevelTabs.EXPLORE_TAB, TopLevelTabs.NETWORK_TAB, TopLevelTabs.SALES_TAB, TopLevelTabs.PURCHASING_TAB, TopLevelTabs.MATERIALS_TAB, TopLevelTabs.TRIPS_TAB],
  staging: [TopLevelTabs.RECEIVABLE_TAB, TopLevelTabs.PAYABLE_TAB, TopLevelTabs.RECRUITMENT_TAB, TopLevelTabs.EXPLORE_TAB, TopLevelTabs.SALES_TAB, TopLevelTabs.PURCHASING_TAB, TopLevelTabs.MATERIALS_TAB, TopLevelTabs.TRIPS_TAB],
  production: [TopLevelTabs.RECEIVABLE_TAB, TopLevelTabs.PAYABLE_TAB, TopLevelTabs.EXPLORE_TAB, TopLevelTabs.NETWORK_TAB, TopLevelTabs.SALES_TAB, TopLevelTabs.PURCHASING_TAB, TopLevelTabs.MATERIALS_TAB, TopLevelTabs.TRIPS_TAB]
} as any

export interface FeatureAccess {
  feature: string
  subFeature: string
}
export interface PageTab {
    name: string,
    prependIcon?: string,
    appendIcon?: string,
    companyTypes: string[],
    featureAccess: FeatureAccess[],
    quantity?: string,
    tabFilter?: object,
    workOrderFilter?: object
    id: number
  }

export const TRIPS_TAB = {
  name: TopLevelTabs.TRIPS_TAB,
  prependIcon: MOVING_TRUCK,
  companyTypes: [BROKER, VENDOR, CLIENT],
  featureAccess: [{ feature: 'work_orders', subFeature: '' }],
  id: 1
}
export const RECEIVABLE_TAB = {
  name: TopLevelTabs.RECEIVABLE_TAB,
  prependIcon: 'mdi-account',
  companyTypes: [BROKER, VENDOR],
  featureAccess: [{ feature: 'invoice', subFeature: '' }],
  id: 2
}
export const PAYABLE_TAB = {
  name: TopLevelTabs.PAYABLE_TAB,
  prependIcon: 'mdi-account',
  companyTypes: [BROKER, CLIENT],
  featureAccess: [{ feature: 'invoice', subFeature: '' }],
  id: 3
}
export const RECRUITMENT_TAB = {
  name: TopLevelTabs.RECRUITMENT_TAB,
  prependIcon: 'mdi-account-search',
  companyTypes: [BROKER, VENDOR],
  featureAccess: [{ feature: 'vendors', subFeature: '' }],
  id: 4
}
export const PURCHASING_TAB = {
  name: TopLevelTabs.PURCHASING_TAB,
  prependIcon: 'mdi-cart-outline',
  companyTypes: [BROKER, VENDOR],
  featureAccess: [{ feature: 'purchase_order', subFeature: '' }],
  id: 5
}
export const NETWORK_TAB = {
  name: TopLevelTabs.NETWORK_TAB,
  prependIcon: 'mdi-graph',
  companyTypes: [BROKER, VENDOR, CLIENT],
  featureAccess: [{ feature: 'vendors', subFeature: '' }, { feature: 'team_members', subFeature: '' }, { feature: 'crew', subFeature: '' }, { feature: 'roles', subFeature: '' }, { feature: 'customers', subFeature: '' }, { feature: 'sites', subFeature: '' }],
  id: 6
}
export const SALES_TAB = {
  name: TopLevelTabs.SALES_TAB,
  prependIcon: ICON_MULTIPLE_CASH,
  companyTypes: [BROKER, VENDOR, CLIENT],
  featureAccess: [{ feature: 'proposal', subFeature: '' }],
  id: 7
}
export const EXPLORE_TAB = {
  name: TopLevelTabs.EXPLORE_TAB,
  prependIcon: 'mdi-compass',
  companyTypes: [BROKER, VENDOR, CLIENT],
  featureAccess: [{ feature: 'invoice', subFeature: '' }, { feature: 'proposal', subFeature: '' }, { feature: 'payments', subFeature: '' }, { feature: 'purchase_order', subFeature: '' }],
  id: 8
}
export const MATERIALS_TAB = {
  name: TopLevelTabs.MATERIALS_TAB,
  prependIcon: CART_OUTLINE,
  companyTypes: [BROKER, VENDOR, CLIENT],
  featureAccess: [{ feature: 'work_orders', subFeature: '' }],
  id: 9
}
export const ORDERS_TAB = {
  name: TopLevelTabs.ORDERS_TAB,
  prependIcon: TOOLS,
  companyTypes: [BROKER, VENDOR, CLIENT],
  featureAccess: [{ feature: 'work_orders', subFeature: '' }],
  id: 68
}
export const PAYMENT_TAB = {
  name: TopLevelTabs.PAYMENT_TAB,
  prependIcon: PAYMENT_BILL,
  companyTypes: [BROKER, VENDOR, CLIENT],
  featureAccess: [{ feature: 'payments', subFeature: '' }, { feature: 'made_payments', subFeature: '' }],
  id: 69
}

/*
  NETWORK TAB
*/
export const PEOPLE_TAB = {
  name: TopLevelTabs.PEOPLE,
  prependIcon: ICON_PEOPLE,
  companyTypes: [BROKER, VENDOR, CLIENT],
  featureAccess: [{ feature: 'team_members', subFeature: '' }, { feature: 'crew', subFeature: '' }, { feature: 'roles', subFeature: '' }],
  id: 35
}

export const VENDORS_TAB = {
  name: TopLevelTabs.VENDORS,
  prependIcon: ICON_VENDOR,
  companyTypes: [BROKER, CLIENT],
  featureAccess: [{ feature: 'vendors', subFeature: '' }],
  id: 36
}
export const CLIENTS_TAB = {
  name: TopLevelTabs.CLIENTS,
  prependIcon: ICON_CLIENT,
  companyTypes: [BROKER, VENDOR],
  featureAccess: [{ feature: 'customers', subFeature: '' }],
  id: 37
}

export const allTopLevelTabs = [
  ORDERS_TAB,
  TRIPS_TAB,
  RECEIVABLE_TAB,
  PAYABLE_TAB,
  RECRUITMENT_TAB,
  PURCHASING_TAB,
  CLIENTS_TAB,
  VENDORS_TAB,
  PEOPLE_TAB,
  SALES_TAB,
  EXPLORE_TAB,
  MATERIALS_TAB
]
