import { api } from '@/api'
import qs from 'qs'
import { MentionParams } from '@/use/mention'

export default {
  getTaggableUsers: (params: MentionParams) => {
    return api.$get('/mentions', {
      params: {
        ...params,
        perPage: 25
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  }
}
