import { api } from '@/api'
import qs from 'qs'

export default {
  getExternalRegions: () => api.$get('/agreements/external_regions', {
    params: {},
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  })
}
