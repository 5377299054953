import { defineComponent, computed, ref } from 'vue';
export default defineComponent({
  props: {
    type: {
      type: String,
      required: true
    },
    bold: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      required: true
    },
    text: {
      type: [String, Number],
      default: ''
    },
    lineHeight: {
      type: String,
      default: '1.0'
    },
    invertTheme: {
      type: Boolean,
      default: false
    },
    lighten: {
      type: Boolean,
      default: false
    },
    darken: {
      type: Boolean,
      default: false
    },
    ellipses: {
      type: Boolean,
      default: false
    },
    lineNum: {
      type: String,
      default: 'unset'
    },
    paletteColor: {
      type: String,
      default: ''
    },
    overflowWrap: {
      type: String,
      default: ''
    }
  },
  setup: props => {
    // validate the chosen type then give a helpful error message for the dev :)
    if (!['headline', 'title', 'body'].includes(props.type)) {
      console.error('you are not setting the type of this component correctly, please choose one of the following: headline, title, body');
    }
    const styleObject = ref({
      fontSize: 0,
      fontWeight: 0,
      lineHeight: props.lineHeight,
      overflow: '',
      textOverflow: '',
      whiteSpace: '',
      overflowWrap: props.overflowWrap,
      display: props.ellipses && props.lineNum !== 'unset' ? '-webkit-box !important' : '',
      '-webkit-line-clamp': props.lineNum,
      '-webkit-box-orient': 'vertical'
    });
    const styleMap = {
      headline: {
        sizes: {
          1: '6rem',
          2: '3.75rem',
          3: '3rem'
        },
        boldable: false
      },
      title: {
        sizes: {
          1: '2.125rem',
          2: '1.75rem',
          3: '1.375rem',
          4: '1.25rem'
        },
        boldable: true
      },
      body: {
        sizes: {
          1: '1rem',
          2: '0.875rem',
          3: '0.75rem'
        },
        boldable: true
      }
    };
    const typeSizeStyle = styleMap[props.type].sizes[props.size];
    // validate the chosen size then give a helpful error message for the dev :)
    if (!typeSizeStyle) {
      const sizesForType = Object.keys(styleMap[props.type].sizes);
      let availableSizeString = '';
      sizesForType.forEach((size, index) => {
        availableSizeString += size;
        if (index !== sizesForType.length - 1) {
          availableSizeString += ', ';
        }
      });
      console.error(`you chose an size that does not correspond to your chosen type, please choose one of the following for ${props.type}: ${availableSizeString}`);
    }
    styleObject.value.fontSize = styleMap[props.type].sizes[props.size];
    const classes = computed(() => [props.invertTheme && 'uc-text--invert-theme', props.lighten && 'uc-text--lighten', props.darken && 'uc-text--darken', props.paletteColor && `${props.paletteColor}--text`]);
    if (props.ellipses) {
      styleObject.value.overflow = 'hidden';
      styleObject.value.textOverflow = 'ellipsis';
      styleObject.value.whiteSpace = props.lineNum !== 'unset' ? 'normal' : 'nowrap';
    }
    const boldedFontWeight = computed(() => {
      if (props.bold && styleMap[props.type].boldable) {
        return 500;
      } else if (props.type === 'headline' && props.size === 3) {
        return 500;
      } else {
        return 'normal';
      }
    });
    return {
      styleObject,
      classes,
      boldedFontWeight
    };
  }
});