import "core-js/modules/es.array.push.js";
import { defineComponent, computed, ref, watch } from 'vue';
import { useSession } from '@/use/session';
import { useApp } from '@/use/app';
import { BROKER } from '@/constants/permissions';
import { ACCOUNT_CASH, ICON_ROCKET } from '@/constants/icons';
import { useUsersCompanies } from '@/use/users-companies';
import { kebabCase } from 'lodash';
import { INTEGRATIONS_HEADER } from '@/constants/headers/integrations';
import { IS_RAEL_APP } from '@/constants/env';
import { useZendesk } from '@/use/zendesk';
export default defineComponent({
  props: {},
  setup: (props, {
    emit
  }) => {
    const {
      currentUsersCompany
    } = useUsersCompanies();
    const {
      session
    } = useSession();
    const panelDetailsTab = ref(0);
    const isLoading = ref(false);
    const {
      hasAccess
    } = useSession();
    const isFullScreen = ref(false);
    const {
      companyType
    } = useApp();
    const {
      activateZendesk
    } = useZendesk();
    const appStoreRef = ref(null);
    const isSMFullScreen = ref(true);
    const keyRef = ref('1');
    const integrationsTableRef = ref(null);
    const show = () => {
      isFullScreen.value = true;
    };
    const transitionTime = ref('300');
    const selectedIntegrationsArray = ref([]);
    const integrationsArray = ref([]);
    const appStoreTabs = ref([{
      name: 'Store Apps',
      prependIcon: `mdi-${ICON_ROCKET}`
    }]);
    watch(() => currentUsersCompany.value, value => {
      if (value) {
        getCurrentCompanyIntegrations();
      }
    });
    const getCurrentCompanyIntegrations = () => {
      var _currentUsersCompany$, _currentUsersCompany$2, _session$currentCompa;
      integrationsArray.value = currentUsersCompany === null || currentUsersCompany === void 0 ? void 0 : (_currentUsersCompany$ = currentUsersCompany.value) === null || _currentUsersCompany$ === void 0 ? void 0 : (_currentUsersCompany$2 = _currentUsersCompany$.package) === null || _currentUsersCompany$2 === void 0 ? void 0 : _currentUsersCompany$2.packageIntegrations;
      const requestedIntegrations = session === null || session === void 0 ? void 0 : (_session$currentCompa = session.currentCompany) === null || _session$currentCompa === void 0 ? void 0 : _session$currentCompa.requestedIntegrations;
      integrationsArray.value.forEach(item => {
        if (requestedIntegrations.includes(item.name)) {
          selectedIntegrationsArray.value.push(item);
        }
      });
    };
    const handleLoader = event => {
      isLoading.value = event;
      emit('loader', event);
    };
    // headerButtonData - Property to get header button title
    const headerButtonData = computed(() => {
      const status = {
        color: 'primary',
        title: 'CHAT WITH US'
      };
      return status;
    });
    const saveSelectedIntegrations = appData => {
      emit('select-integration', appData.item);
    };
    const openZendeskWindow = () => {
      if (IS_RAEL_APP === 'true') activateZendesk();
    };
    return {
      show,
      close,
      transitionTime,
      isFullScreen,
      hasAccess,
      companyType,
      BROKER,
      headerButtonData,
      isSMFullScreen,
      ACCOUNT_CASH,
      keyRef,
      handleLoader,
      isLoading,
      appStoreRef,
      integrationsArray,
      panelDetailsTab,
      appStoreTabs,
      kebabCase,
      INTEGRATIONS_HEADER,
      saveSelectedIntegrations,
      integrationsTableRef,
      ICON_ROCKET,
      selectedIntegrationsArray,
      openZendeskWindow
    };
  }
});