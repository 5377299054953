import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "uc-rows-headers d-flex flex-column overflow-hidden rounded"
  }, [_c('uc-tile-container', {
    class: `row flex-row pt-1 pb-1`,
    attrs: {
      "no-padding": true,
      "no-margin": true,
      "background-color": "--v-layer-base",
      "no-elevation": true
    }
  }, [_c('div', {
    staticClass: "d-flex pl-2 pr-4 overflow-hidden",
    style: `width: 100%`
  }, [_vm.canSelect ? _c('uc-rows-cell-wrapper', {
    attrs: {
      "size": _vm.size,
      "column": _vm.CHECKBOX_COL
    }
  }) : _vm._e(), _c('div', {
    staticClass: "d-flex align-center flex-1 overflow-hidden"
  }, [_c('div', {
    staticClass: "d-flex align-center overflow-hidden",
    style: {
      flex: _vm.computedNumberOfColumns
    }
  }, _vm._l(_vm.headers, function (header, index) {
    return _c('uc-rows-cell-wrapper', {
      key: index,
      attrs: {
        "column": header,
        "size": _vm.size
      }
    }, [_c('uc-tooltip', {
      attrs: {
        "disabled": _vm.isEllipses(_vm.$refs.textRef),
        "text": header.prependIconTooltip || header.label,
        "position": header.prependIconTooltipPlacement || 'top'
      }
    }, [header.prependIcon ? _c(VIcon, {
      staticClass: "mr-1"
    }, [_vm._v(" " + _vm._s(header.prependIcon) + " ")]) : _vm._e()], 1), _c('uc-tooltip', {
      attrs: {
        "disabled": _vm.isEllipses(_vm.$refs.textRef),
        "text": header.label,
        "position": "top"
      }
    }, [header.label !== '' ? _c('uc-text', {
      ref: "textRef",
      refInFor: true,
      attrs: {
        "size": _vm.getFontSize(_vm.fontSize),
        "type": "body",
        "ellipses": true,
        "text": header.label,
        "palette-color": header.paletteColor || '',
        "bold": true
      }
    }) : _vm._e()], 1), _c('uc-tooltip', {
      attrs: {
        "disabled": _vm.isEllipses(_vm.$refs.textRef),
        "text": header.appendIconTooltip || header.label,
        "position": header.appendIconTooltipPlacement || 'top'
      }
    }, [header.appendIcon !== '' ? _c(VIcon, {
      staticClass: "ml-1",
      attrs: {
        "size": header.appendIconSize || 'sm'
      }
    }, [_vm._v(" " + _vm._s(header.appendIcon) + " ")]) : _vm._e()], 1), _vm._t("append-icon-content")], 2);
  }), 1), _vm.showActions ? _c('uc-rows-cell-wrapper', {
    attrs: {
      "size": _vm.size,
      "column": _vm.ACTIONS_COL
    }
  }, [_c('uc-tooltip', {
    attrs: {
      "disabled": _vm.isEllipses(_vm.$refs.textRef),
      "text": _vm.ACTIONS_COL.label,
      "position": "top"
    }
  }, [_vm.ACTIONS_COL.prependIcon && _vm.ACTIONS_COL.prependIcon.startsWith('mdi') ? _c(VIcon, [_vm._v(" " + _vm._s(_vm.prependIcon) + " ")]) : _vm._e(), _vm.ACTIONS_COL.label !== '' ? _c('uc-text', {
    ref: "textRef",
    attrs: {
      "size": _vm.getFontSize(_vm.fontSize),
      "type": 'body',
      "ellipses": true,
      "text": _vm.ACTIONS_COL.label,
      "palette-color": _vm.ACTIONS_COL.paletteColor || '',
      "bold": true
    }
  }) : _vm._e(), _vm.ACTIONS_COL.appendIcon !== '' && _vm.ACTIONS_COL.appendIcon.startsWith('mdi') ? _c(VIcon, [_vm._v(" " + _vm._s(_vm.appendIcon) + " ")]) : _vm._e()], 1)], 1) : _vm._e()], 1), _vm.canExpand ? _c('uc-rows-cell-wrapper', {
    staticClass: "mr-2",
    attrs: {
      "column": _vm.DRAWER_CONTENT_EXPANDER_COL,
      "size": _vm.size
    }
  }) : _vm._e()], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };