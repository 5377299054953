import { api } from '@/api'

export default {
  get: (stripeVersion: boolean, vendorId: number) => {
    let apiEndpoint = `/stripe/connected_account?stripe_version=${stripeVersion}`
    if (vendorId > 0) { apiEndpoint = apiEndpoint + `&vendor_id=${vendorId}` }
    return api.$get(apiEndpoint)
  },
  create: () => {
    return api.$post('/stripe/connected_account')
  },
  onboardingLink: () => {
    return api.$post('/stripe/connected_account/onboarding_link')
  },
  status: () => {
    return api.$get('/stripe/connected_account/status')
  },
  createPaymentIntent: (params: object) => {
    return api.$post('/stripe/payments/create_payment_intent', params)
  }
}
