import { VOverlay } from 'vuetify/lib/components/VOverlay';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VOverlay, {
    attrs: {
      "z-index": "999",
      "absolute": !_vm.fullScreen,
      "value": _vm.show
    }
  }, [_vm._t("default")], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };