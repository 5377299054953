import { apis } from '@/api'
import qs from 'qs'

interface GetWorkOrderServicesSortByWorkorder {
  perPage?: number
  include?: string
  page?: number
  q?: any
  woServiceId?: number
  params?: any
}

export default {
  get: ({ perPage, include, page, q }: GetWorkOrderServicesSortByWorkorder) => apis.$get('/work_order_services', {
    params: {
      include,
      page,
      per_page: perPage,
      q
    },
    paramsSerializer: params => {
      return qs.stringify(params)
    }
  }),
  getWorkOrderServicesSortByWorkorder: ({ perPage, page, q }: GetWorkOrderServicesSortByWorkorder) => apis.$get('/work_order_services/group/job', {
    params: {
      include: '',
      page,
      perPage,
      q
    },
    paramsSerializer: params => {
      return qs.stringify(params)
    }
  }),
  getWorkOrderServicesSortByEmployee: ({ perPage, page, q }: GetWorkOrderServicesSortByWorkorder) => apis.$get('/work_order_services/group/employee', {
    params: {
      include: '',
      page,
      perPage,
      q
    },
    paramsSerializer: params => {
      return qs.stringify(params)
    }
  }),
  getWorkOrderServiceAdjustmentsByServiceId: ({ perPage, page, q, include, woServiceId }: GetWorkOrderServicesSortByWorkorder) => apis.$get(`/work_order_services/${woServiceId}/service_adjustments`, {
    params: {
      include,
      page,
      perPage,
      q
    },
    paramsSerializer: params => {
      return qs.stringify(params)
    }
  }),
  getWorkOrderService: ({ perPage, include, page, q, woServiceId }: GetWorkOrderServicesSortByWorkorder) => apis.$get(`/work_order_services/${woServiceId}`, {
    params: {
      include,
      page,
      per_page: perPage,
      q
    },
    paramsSerializer: params => {
      return qs.stringify(params)
    }
  }),
  patch: ({ include, woServiceId, params }: GetWorkOrderServicesSortByWorkorder) => apis.$patch(`/work_order_services/${woServiceId}`, {
    service: params,
    include
  })
}
