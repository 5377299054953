import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "uc-list-row-base d-flex flex-column"
  }, [_c('uc-tile-container', _vm._g({
    ref: "containerRef",
    staticClass: "row flex-column pt-1 pb-1 rounded-0",
    class: [_vm.$vuetify.theme.dark && 'uc-list-row-base--dark row--dark extended-row--dark', {
      'last-row': _vm.isTile || _vm.lastRow
    }, {
      'first-row': _vm.isTile || _vm.firstRow
    }, _vm.backgroundColor, {
      [`${_vm.backgroundColor}-hover`]: _vm.hovering || _vm.isExpanded && !_vm.isTile
    }, {
      [`${_vm.backgroundColor}-selected`]: _vm.isSelected
    }, {
      [`${_vm.backgroundColor}-active`]: _vm.isSelected && (_vm.isExpanded && !_vm.isTile || _vm.hovering)
    }, _vm.isTile ? 'ma-1' : 'ma-0', _vm.size === 'card' ? 'mb-1 rounded' : ''],
    attrs: {
      "no-padding": true,
      "no-elevation": true
    },
    on: {
      "mouseenter": function ($event) {
        _vm.hasHover && (_vm.hovering = true);
      },
      "mouseleave": function ($event) {
        _vm.hovering = false;
      }
    }
  }, _vm.$listeners), [_vm.size !== 'card' ? _c('div', {
    class: ['first-row px-2 align-center overflow-hidden fill-height d-flex'],
    style: `width: 100%; height: ${_vm.height};`
  }, [_vm.canSelect ? _c('uc-rows-cell-wrapper', {
    attrs: {
      "size": _vm.nonCardSize,
      "column": _vm.CHECKBOX_COL
    }
  }, [_c(VCheckbox, {
    staticClass: "checkbox mr-2",
    attrs: {
      "input-value": _vm.isSelected,
      "disabled": _vm.selectionDisabled,
      "hide-details": "",
      "data-cy": "uc-list-row-base-checkbox"
    },
    on: {
      "change": function ($event) {
        return _vm.$emit('toggle-select');
      },
      "click": function ($event) {
        $event.stopPropagation();
      }
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "d-flex align-center flex-1 overflow-hidden fill-height",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.emitRowClick();
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-center overflow-hidden",
    style: {
      flex: _vm.computedNumberOfColumns
    },
    attrs: {
      "data-cy": "row-base"
    },
    on: {
      "dblclick": function ($event) {
        $event.stopPropagation();
        return _vm.$emit('row-dblclick');
      }
    }
  }, _vm._l(_vm.columnDefs.headers, function (column, index) {
    return _c('uc-rows-cell-wrapper', {
      key: index,
      attrs: {
        "size": _vm.nonCardSize,
        "column": column
      }
    }, [_vm._t(column.templateName)], 2);
  }), 1), _vm.showActions ? _c('uc-rows-cell-wrapper', {
    staticClass: "overflow-hidden",
    attrs: {
      "size": _vm.nonCardSize,
      "column": _vm.ACTIONS_COL
    }
  }, [_vm._t(_vm.ACTIONS_COL.templateName)], 2) : _vm._e()], 1), _vm.canExpand ? _c('uc-rows-cell-wrapper', {
    staticClass: "mr-2",
    attrs: {
      "data-cy": "list-row-drawer-expander",
      "column": _vm.DRAWER_CONTENT_EXPANDER_COL,
      "size": _vm.nonCardSize
    }
  }, [_vm.loading ? _c(VProgressCircular, {
    attrs: {
      "size": 24,
      "indeterminate": "",
      "color": "primary"
    }
  }) : _c('uc-icon', {
    class: ['transition3Sec', _vm.isExpanded ? 'transformRotate180' : ''],
    attrs: {
      "value": _vm.DRAWER_CLOSED,
      "size": "sm"
    },
    on: {
      "click": function ($event) {
        !_vm.isExpanded && !_vm.beenExpanded && _vm.setLoading();
        _vm.$emit('toggle-expand');
      }
    }
  })], 1) : _vm._e(), _vm.canRemove ? _c('uc-rows-cell-wrapper', {
    attrs: {
      "size": _vm.nonCardSize,
      "column": _vm.REMOVE_COL
    }
  }, [_vm.canRemove ? _c(VIcon, {
    on: {
      "click": function ($event) {
        return _vm.$emit('remove');
      }
    }
  }, [_vm._v(" mdi-close ")]) : _vm._e()], 1) : _vm._e(), _vm.canAdd ? _c('uc-rows-cell-wrapper', {
    attrs: {
      "size": _vm.nonCardSize,
      "column": _vm.ADD_COL
    }
  }, [_vm.canAdd ? _c(VIcon, {
    on: {
      "click": function ($event) {
        return _vm.$emit('add');
      }
    }
  }, [_vm._v(" mdi-plus ")]) : _vm._e()], 1) : _vm._e()], 1) : _c('div', {
    class: ['overflow-hidden fill-width']
  }, [_c('uc-list-row-base-card', _vm._b({
    on: {
      "row-click": _vm.emitRowClick,
      "toggle-select": function ($event) {
        return _vm.$emit('toggle-select');
      },
      "row-dblclick": function ($event) {
        return _vm.$emit('row-dblclick');
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.columnDefs.headers, function (column) {
      return {
        key: column.templateName,
        fn: function () {
          return [_vm._t(column.templateName)];
        },
        proxy: true
      };
    }), _vm.showActions && _vm.size === 'card' ? {
      key: _vm.ACTIONS_COL.templateName,
      fn: function () {
        return [_vm._t(_vm.ACTIONS_COL.templateName)];
      },
      proxy: true
    } : null], null, true)
  }, 'uc-list-row-base-card', _vm.$props, false))], 1), _c(VExpandTransition, [_vm.isExpanded ? _c('div', {
    staticClass: "d-flex flex-column fill-width overflow-hidden"
  }, [_vm.columnDefs.drawer ? _c('div', {
    staticClass: "d-flex align-center px-2 py-1 flex-1 overflow-hidden fill-height"
  }, [_c('div', {
    staticClass: "d-flex align-center overflow-hidden",
    style: {
      flex: _vm.computedNumberDrawerOfColumns
    },
    attrs: {
      "data-cy": "row-base"
    },
    on: {
      "dblclick": function ($event) {
        $event.stopPropagation();
        return _vm.$emit('row-dblclick');
      }
    }
  }, _vm._l(_vm.columnDefs.drawer, function (column, index) {
    return _c('uc-rows-cell-wrapper', {
      key: index,
      attrs: {
        "size": _vm.size,
        "column": column
      }
    }, [_vm._t(column.templateName)], 2);
  }), 1)]) : _vm._t("drawer-content")], 2) : _vm._e()]), _vm.showFooter ? _c('div', {
    staticClass: "d-flex flex-column fill-width px-2"
  }, [_c('uc-divider'), _vm.columnDefs.footer ? _c('div', {
    staticClass: "d-flex flex-row last-row flex-1 align-center overflow-hidden fill-height"
  }, [_c('div', {
    staticClass: "d-flex align-center overflow-hidden",
    style: {
      flex: _vm.computedNumberDrawerOfColumns
    }
  }, _vm._l(_vm.columnDefs.footer, function (column, index) {
    return _c('uc-rows-cell-wrapper', {
      key: index,
      attrs: {
        "size": _vm.size,
        "column": column
      }
    }, [_vm._t(column.templateName)], 2);
  }), 1)]) : _c('div', {
    staticClass: "d-flex flex-row flex-1 align-center overflow-hidden fill-height"
  }, [_vm._t("footer")], 2)], 1) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };