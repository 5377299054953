import { VListItem } from 'vuetify/lib/components/VList';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import Intersect from 'vuetify/lib/directives/intersect';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VListItem, {
    directives: [{
      def: Intersect,
      name: "intersect",
      rawName: "v-intersect",
      value: _vm.emitIntersect,
      expression: "emitIntersect"
    }],
    staticClass: "uc-list-row-skeleton"
  }, [_c(VSkeletonLoader, {
    staticClass: "my-4",
    attrs: {
      "type": "text@2"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };