import { VImg } from 'vuetify/lib/components/VImg';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VImg, {
    class: _vm.rounded ? 'roundedCorner' : '',
    attrs: {
      "src": _vm.src,
      "alt": _vm.alt,
      "width": _vm.width,
      "height": _vm.height,
      "contain": _vm.contain
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };