import axios, {
  InternalAxiosRequestConfig,
  AxiosResponse
} from 'axios'
import { useSession } from '@/use/session'

import {
  addCaseConverterForRequest,
  // addErrorNotifier,
  addCaseConverterForResponse,
  addBaseURL,
  addAuthorization,
  applyPlugins
} from './plugins'

axios.defaults.withCredentials = true
export const httpClient = axios.create({})
export const httpClientSecure = axios.create({})

httpClient.interceptors.request
  .use((req: InternalAxiosRequestConfig) => {
    return applyPlugins(
      [
        addBaseURL,
        addCaseConverterForRequest,
        addAuthorization
      ],
      req
    )
  })
httpClientSecure.interceptors.request
  .use((req: InternalAxiosRequestConfig) => {
    const plugins = [
      addCaseConverterForRequest,
      addAuthorization
    ]
    if (!req.baseURL) {
      plugins.unshift(addBaseURL)
    }
    const applied = applyPlugins(
      plugins,
      req
    )
    return applied
  })

httpClientSecure.interceptors.response
  .use((res: AxiosResponse) => applyPlugins(
    [
      addCaseConverterForResponse
    ],
    res
  ), (err: any) => {
    if (err?.response?.status === 401 && err?.response?.data?.code === 'unauthenticated') {
      const { session, logout } = useSession()
      if (session.loggedIn) {
        logout(true)
      } else {
        session.loggedIn = false
        session.loggedUser = {}
      }
      throw err.response
    }
    if (err?.response?.status === 400 || err?.response?.status > 401) {
      throw err.response
    }
  })

httpClient.interceptors.response
  .use((res: AxiosResponse) => applyPlugins(
    [
      addCaseConverterForResponse
    ],
    res
  ), (err: any) => {
    // if we get a 401 and unauth error message, wipe the session data
    if (err?.response?.status === 401 && err?.response?.data?.code === 'unauthenticated') {
      const { session, logout } = useSession()
      if (session.loggedIn) {
        logout(true)
      } else {
        session.loggedIn = false
        session.loggedUser = {}
      }
      throw err.response
    }
    if (err?.response?.status === 400 || err?.response?.status > 401) {
      throw err.response
    }
  })
