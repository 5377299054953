import {
  ROLES_NAME_FILTER
} from '@/constants/filters/resources/role'

export const peopleRolesFilter = () => {
  return {
    filters: [
      ROLES_NAME_FILTER({ pinned: true, isPrimary: true })
    ],
    sortBy: {
      value: 'name',
      direction: {
        text: 'Ascending',
        value: 'asc'
      },
      items: [
        {
          label: 'Name',
          value: 'name'
        }
      ]
    },
    fieldMapping: {
      'roles-name': { name: 'name', field: 'text' }
    }
  }
}
