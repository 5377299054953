import Vue, { PluginObject } from 'vue'
import moment from 'moment'

type VueMoment = PluginObject<undefined>

const MomentPlugin: VueMoment = {
  install (Vue) {
    Object.defineProperties(Vue.prototype, {
      $moment: {
        get () {
          return moment
        }
      }
    })
  }
}

Vue.use(MomentPlugin)

export default MomentPlugin
