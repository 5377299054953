import { generateInvoicesFilters } from '@/constants/filters/tabs/generate-invoices'
import { attentionInvoiceFilters } from '@/constants/filters/tabs/attention-invoices'
import { generateVendorInvoiceFilters } from '@/constants/filters/tabs/generate-vendor-invoices'
import { pendingApprovalFilters } from '@/constants/filters/tabs/pending-approval'
import { payInvoiceFilters } from '@/constants/filters/tabs/pay'
import { sentProposalFilters } from '@/constants/filters/tabs/sent-proposals'
import { recievedProposalFilters } from '@/constants/filters/tabs/received-proposals'
import { payableInvoiceFilters } from '@/constants/filters/tabs/payable-invoices'
import { receivableInvoiceFilters } from '@/constants/filters/tabs/receiveable-invoices'
import { madePaymentFilters } from '@/constants/filters/tabs/made-payments'
import { receivedPaymentFilters } from '@/constants/filters/tabs/received-payments'
import { recievedPurchaseOrderFilters } from '@/constants/filters/tabs/received-po'
import { sentPurchaseOrderFilters } from '@/constants/filters/tabs/sent-po'
import { toBeSentOutboundPurchaseOrderFilters } from '@/constants/filters/tabs/outbound-to-be-sent-po'
import { outboundActionRequiredPurchaseOrderFilters } from '@/constants/filters/tabs/outbound-action-required-po'
import { inboundActionRequiredPurchaseOrderFilters } from '@/constants/filters/tabs/inbound-action-required-po'
import { allInvoiceFilters } from '@/constants/filters/tabs/all-invoices'
import { analyticsPayableFilters } from '@/constants/filters/tabs/analytics-payable'
import { vendorApplicationFilters } from '@/constants/filters/tabs/vendor-applications'
import { brokerTemplateFilters } from '@/constants/filters/tabs/broker-templates'
import { reviewApplicationFilters } from '@/constants/filters/tabs/review-applications'
import { analyticsReceivableFilters } from '@/constants/filters/tabs/analytics-receivable'
import { paidInvoiceFilters } from '@/constants/filters/tabs/paid-invoices'
import { inboundToBeSentProposalFilters } from '@/constants/filters/tabs/inbound-to-be-sent-proposals'
import { outboundToBeSentProposalFilters } from '@/constants/filters/tabs/outbound-to-be-sent-proposals'
import { inboundActionRequiredProposalFilters } from '@/constants/filters/tabs/inbound-action-required-proposals'
import { outboundActionRequiredProposalFilters } from '@/constants/filters/tabs/outbound-action-required-proposals'
import { inboundConvertProposalFilters } from '@/constants/filters/tabs/inbound-convert-proposals'
import { outboundCovertProposalFilters } from '@/constants/filters/tabs/outbound-convert-proposals'
import { operationFailureFilters } from '@/constants/filters/tabs/operations-failure'
import { mustScheduleFilters } from '@/constants/filters/tabs/must-schedule'
import { verificationRequiredFilters } from '@/constants/filters/tabs/verification-required'
import { inProgressFilters } from '@/constants/filters/tabs/in-progress'
import { allTripsFilters } from '@/constants/filters/tabs/all-trips'
import { draftTripsFilters } from '@/constants/filters/tabs/draft-trips'
import { workOrderFilters } from '@/constants/filters/tabs/work-orders'

// import vendorsActive from '@/constants/filters/tabs/vendors-active'
// import vendorsBlocked from '@/constants/filters/tabs/vendors-blocked'
// import vendorsPending from '@/constants/filters/tabs/vendors-pending'

// not-applied filters
import { activeClientsFilters } from '@/constants/filters/tabs/not-applied/clients-active'
import { blockedClientsFilters } from '@/constants/filters/tabs/not-applied/clients-blocked'
import { pendingClientsFilters } from '@/constants/filters/tabs/not-applied/clients-pending'
import { peopleCrewFilters } from '@/constants/filters/tabs/not-applied/peoples-crews'
import { peopleRolesFilter } from '@/constants/filters/tabs/not-applied/peoples-roles'
import { peopleEmployeesFilter } from '@/constants/filters/tabs/not-applied/peoples-employees'

import { activeVendorsFilters } from '@/constants/filters/tabs/not-applied/vendors-active'
import { blockedVendorsFilters } from '@/constants/filters/tabs/not-applied/vendors-blocked'
import { pendingVendorsFilters } from '@/constants/filters/tabs/not-applied/vendors-pending'

import { allOutboundPurchaseOrderFilters } from './tabs/outbound-all-po'
import { allInboundPurchaseOrderFilters } from './tabs/inbound-all-po'
import { allReceivableInvoiceFilters } from './tabs/receiveable-all-invoices'
import { allPayableInvoiceFilters } from './tabs/payable-all-invoices'

import { kebabCase } from 'lodash'

import {
  exploreInboundInvoicesTab,
  exploreOutboundInvoicesTab,
  explorePaymentsTab,
  exploreBillPaymentsTab,
  exploreSentProposalsTab,
  exploreReceivedProposalsTab,
  exploreInboundPurchaseOrdersTab,
  exploreOutboundPurchaseOrdersTab,
  GENERATE_INVOICES_TAB,
  ALL_RECEIVABLE_INVOICES_TAB,
  ATTENTION_INVOICES_TAB,
  ANALYTICS_RECEIVABLE_TAB,
  ALL_PAYABLE_INVOICES_TAB,
  PENDING_APPROVAL_TAB,
  BULK_PAY_TAB,
  ANALYTICS_PAYABLE_TAB,
  VENDOR_APPLICATIONS_TAB,
  BROKER_TEMPLATES_TAB,
  REVIEW_APPLICATIONS_TAB,
  TEAM_MEMBERS_TAB,
  ROLES_TAB,
  CREW_TAB,
  ACTIVE_VENDORS_TAB,
  PENDING_VENDORS_TAB,
  BLOCKED_VENDORS_TAB,
  ACTIVE_CLIENTS_TAB,
  PENDING_CLIENTS_TAB,
  BLOCKED_CLIENTS_TAB,
  ALL_PROPOSALS_SENT_TAB,
  ALL_PROPOSALS_RECEIVED_TAB,
  TO_BE_SENT_PROPOSALS_TAB,
  PROPOSALS_SENT_TAB,
  PROPOSALS_RECEIVED_TAB,
  PROPOSAL_SENT_ACTION,
  PROPOSAL_RECEIVED_ACTION,
  PROPOSAL_SENT_CONVERT_WORK_ORDER,
  PROPOSAL_RECEIVED_CONVERT_WORK_ORDER,
  PURCHASE_ORDER_DRAFT_SENT_TAB,
  ALL_SENT_PURCHASE_ORDERS_TAB,
  ALL_RECEIVED_PURCHASE_ORDERS_TAB,
  PURCHASE_ORDER_REQUIRES_ACTION_RECEIVED_TAB,
  PURCHASE_ORDER_REQUIRES_ACTION_SENT_TAB,
  ALL_TRIPS_TAB,
  IN_PROGRESS_TAB,
  VERIFICATION_REQUIRED_TAB,
  MUST_SCHEDULE_TAB,
  OPERATIONS_FAILURE_TAB,
  DRAFT_TRIPS_TAB,
  GENERATE_VENDOR_INVOICES_TAB,
  PAYMENTS_TAB,
  MADE_PAYMENTS_TAB
} from '@/constants/nested-tabs'
import { ORDERS_TAB } from '../top-level-tabs'
import { outboundProposalFilters } from './tabs/outbound-all-proposals'
import { allInboundProposalsFilters } from './tabs/inbound-all-proposals'

export const allFilters = () => {
  return {
  // WORK ORDERS
    [ORDERS_TAB.id]: workOrderFilters(),
    // RECEIVABLES
    [GENERATE_INVOICES_TAB.id]: generateInvoicesFilters(),
    [ALL_RECEIVABLE_INVOICES_TAB.id]: allReceivableInvoiceFilters(),
    [ATTENTION_INVOICES_TAB.id]: attentionInvoiceFilters(),
    [ANALYTICS_RECEIVABLE_TAB.id]: analyticsReceivableFilters(),
    // PAYABLES
    [GENERATE_VENDOR_INVOICES_TAB.id]: generateVendorInvoiceFilters(),
    [ALL_PAYABLE_INVOICES_TAB.id]: allPayableInvoiceFilters(),
    [PENDING_APPROVAL_TAB.id]: pendingApprovalFilters(),
    [BULK_PAY_TAB.id]: payInvoiceFilters(),
    [ANALYTICS_PAYABLE_TAB.id]: analyticsPayableFilters(),
    // RECRUIT
    [VENDOR_APPLICATIONS_TAB.id]: vendorApplicationFilters(),
    [BROKER_TEMPLATES_TAB.id]: brokerTemplateFilters(),
    [REVIEW_APPLICATIONS_TAB.id]: reviewApplicationFilters(),
    // EXPLORE OUT OF SCOPE FOR NOW
    [kebabCase(exploreSentProposalsTab)]: sentProposalFilters(),
    [kebabCase(exploreReceivedProposalsTab)]: recievedProposalFilters(),
    [kebabCase(exploreOutboundInvoicesTab)]: receivableInvoiceFilters(),
    [kebabCase(exploreInboundInvoicesTab)]: payableInvoiceFilters(),
    [kebabCase(explorePaymentsTab)]: receivedPaymentFilters(),
    [kebabCase(exploreBillPaymentsTab)]: madePaymentFilters(),
    [kebabCase(exploreInboundPurchaseOrdersTab)]: recievedPurchaseOrderFilters(),
    [kebabCase(exploreOutboundPurchaseOrdersTab)]: sentPurchaseOrderFilters(),
    // EMPLOYEES
    [TEAM_MEMBERS_TAB.id]: peopleEmployeesFilter(),
    [ROLES_TAB.id]: peopleRolesFilter(),
    [CREW_TAB.id]: peopleCrewFilters(),
    // VENDORS
    [ACTIVE_VENDORS_TAB.id]: activeVendorsFilters(),
    [PENDING_VENDORS_TAB.id]: pendingVendorsFilters(),
    [BLOCKED_VENDORS_TAB.id]: blockedVendorsFilters(),
    // CLIENTS
    [ACTIVE_CLIENTS_TAB.id]: activeClientsFilters(),
    [PENDING_CLIENTS_TAB.id]: pendingClientsFilters(),
    [BLOCKED_CLIENTS_TAB.id]: blockedClientsFilters(),
    // PROPOSALS
    [ALL_PROPOSALS_SENT_TAB.id]: outboundProposalFilters(),
    [ALL_PROPOSALS_RECEIVED_TAB.id]: allInboundProposalsFilters(),
    [PROPOSALS_SENT_TAB.id]: outboundToBeSentProposalFilters(),
    [PROPOSALS_RECEIVED_TAB.id]: inboundToBeSentProposalFilters(),
    [PROPOSAL_SENT_ACTION.id]: outboundActionRequiredProposalFilters(),
    [PROPOSAL_RECEIVED_ACTION.id]: inboundActionRequiredProposalFilters(),
    [PROPOSAL_SENT_CONVERT_WORK_ORDER.id]: outboundCovertProposalFilters(),
    [PROPOSAL_RECEIVED_CONVERT_WORK_ORDER.id]: inboundConvertProposalFilters(),
    // PURCHASE ORDERS
    [ALL_SENT_PURCHASE_ORDERS_TAB.id]: allOutboundPurchaseOrderFilters(),
    [ALL_RECEIVED_PURCHASE_ORDERS_TAB.id]: allInboundPurchaseOrderFilters(),
    [PURCHASE_ORDER_DRAFT_SENT_TAB.id]: toBeSentOutboundPurchaseOrderFilters(),
    [PURCHASE_ORDER_REQUIRES_ACTION_SENT_TAB.id]: outboundActionRequiredPurchaseOrderFilters(),
    [PURCHASE_ORDER_REQUIRES_ACTION_RECEIVED_TAB.id]: inboundActionRequiredPurchaseOrderFilters(),
    // TRIPS
    [OPERATIONS_FAILURE_TAB.id]: operationFailureFilters(),
    [MUST_SCHEDULE_TAB.id]: mustScheduleFilters(),
    [VERIFICATION_REQUIRED_TAB.id]: verificationRequiredFilters(),
    [IN_PROGRESS_TAB.id]: inProgressFilters(),
    [ALL_TRIPS_TAB.id]: allTripsFilters(),
    [DRAFT_TRIPS_TAB.id]: draftTripsFilters(),
    // PAYMENTS
    [PAYMENTS_TAB.id]: receivedPaymentFilters(),
    [MADE_PAYMENTS_TAB.id]: madePaymentFilters()
  }
}
