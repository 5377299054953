import { api } from '@/api'
import qs from 'qs'

export default {
  getAccessibleModules: (id: number) =>
    api.$get(`/users/companies/${id}/accessible_modules`),
  listAccessibleFeatures: ({ perPage, page, q, include }: any) => api.$get('/users/companies/accessible_features', {
    params: {
      include,
      page,
      perPage,
      q
    },
    paramsSerializer: params => {
      return qs.stringify(params)
    }
  })
}
