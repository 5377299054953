var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    ref: "cellWrapperRef",
    staticClass: "uc-rows-cell-wrapper",
    class: _vm.computedSlotVisilibity.classes,
    style: _vm.computedSlotVisilibity.style
  }, [_vm._t("default")], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };