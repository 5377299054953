import { VImg } from 'vuetify/lib/components/VImg';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "app-store-image-display rounded-xl d-flex align-center justify-center",
    class: `elevation-${_vm.elevation}`,
    style: {
      height: _vm.size + 'px',
      width: _vm.size + 'px',
      background: _vm.backdropColor
    }
  }, [_c(VImg, {
    attrs: {
      "contain": true,
      "height": "90%",
      "src": _vm.image
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };