import { api } from '@/api'
import qs from 'qs'

export default {
  get: async ({ include, perPage, page, q }: any) => {
    const res = await api.get('/package_categories', {
      params: {
        include,
        perPage,
        page,
        q
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
    return res.data.packageCategories
  }
}
