import { api } from '@/api'
import qs from 'qs'

interface GetTaskListParams {
  perPage?: number
  page?: number
  q?: any
  include?: string
}

export default {
  get: ({ perPage, page, q, include }: GetTaskListParams) => api.$get('/tasks', {
    params: {
      page,
      per_page: perPage,
      q,
      include
    },
    paramsSerializer: params => {
      return qs.stringify(params)
    }
  })
}
