import { api } from '@/api'
import { ServiceAdjustmentParams } from '@/use/service-adjustment'

export default {
  getChangeReasons: (workOrderServiceId: number) => api.$get(`/work_order_services/${workOrderServiceId}/service_adjustments/change_reasons`),

  getHoursDescriptions: (workOrderServiceId: number) => api.$get(`/work_order_services/${workOrderServiceId}/service_adjustments/hours_descriptions`),

  post: (adjustmentParams: ServiceAdjustmentParams) => api.$post('/work_order_services/service_adjustments', adjustmentParams)
}
