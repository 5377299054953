import { defineComponent, computed, ref } from 'vue';
import { useResponsiveness } from '@/use/responsiveness';
export default defineComponent({
  props: {
    column: {
      type: Object,
      default: () => {}
    },
    size: {
      type: String,
      required: true
    }
  },
  setup: props => {
    const cellWrapperRef = ref(null);
    const {
      getSlotVisibility
    } = useResponsiveness();
    const computedSlotVisilibity = computed(() => {
      return getSlotVisibility(props.column, props.size);
    });
    return {
      cellWrapperRef,
      computedSlotVisilibity
    };
  }
});