import { api } from '@/api'
import qs from 'qs'
import { AutocompletePayload } from '@/types/interfaces/api-v2/autocomplete'

interface GetCompanyUsersListParams {
  perPage?: number;
  page: number;
  q?: any;
  include?: string;
}

export default {
  getCompanyUsersList: ({ perPage, page, q }: GetCompanyUsersListParams) => api.$get('/company_users', {
    params: {
      include: 'company,role,user,user/image,company_user_rate,territories,additional_informations,sisense_dashboard_role_features,departments',
      page,
      perPage,
      q
    },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  get: ({ perPage, page, q, include }: GetCompanyUsersListParams) => api.$get('/company_users', {
    params: {
      include,
      page,
      per_page: perPage,
      q
    },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),

  getById: (id: number, { include }: GetCompanyUsersListParams) => api.$get(`/company_users/${id}`, {
    params: {
      include
    },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),

  post: (params: any, requestBody: any) => {
    return api.$post('/company_users', {
      company_user: { ...requestBody }
    }, {
      params,
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  patch: (params : any, teamMemberId: any, requestBody: any) => {
    return api.$patch(`/company_users/${teamMemberId}`, { ...requestBody }, {
      params,
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: (params:any) => {
        return qs.stringify(params)
      }
    })
  },
  // get Logbook details of company user
  getLogbook: ({ userId, date, type, perPage, page, q, include }: any) => {
    return api.$get('/logbook', {
      params: {
        userId,
        date,
        type,
        include,
        page,
        per_page: perPage,
        q
      },

      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },

  getCompanyUser: (id:number) => api.$get(`/company_users/${id}`),
  getCompanyUserRoles: () => api.$get('roles'),
  deleteCompanyUser: (userId: number) => api.$delete(`/company_users/${userId}`),
  getInactiveCompanyUsersList: ({ perPage, page, q }: GetCompanyUsersListParams) => api.$get('/company_users/inactive', {
    params: {
      include: 'company,role,user,user/image',
      page,
      perPage,
      q
    },
    paramsSerializer: params => {
      return qs.stringify(params)
    }
  }),
  resendInvitation: (userId: number) => api.$patch(`/company_users/${userId}/resend_invitation`),
  approveCompanyUser: (userId: number) => api.$patch(`/company_users/${userId}/approve`),
  inviteEmployee: (data:FormData) => {
    return api.$post('/company_users/invite_employee', data, {
      headers: {
        content_type: 'multipart/form-data'
      }
    })
  },
  invite: (data:any) => {
    return api.$post('/company_users/invite', data)
  },
  downloadSampleExcel: () => {
    const link = document.createElement('a')
    link.href = window.location.origin + '/api/v2/company_users/download_sample_excel'
    link.setAttribute(
      'download',
      'sample_template.xlsx'
    )
    link.click()
    link.remove()
  },
  teamMembersAutoComplete: (params: AutocompletePayload) => {
    const { field, terms, predicate, page, perPage } = params
    return api.$get('/company_users/autocomplete', {
      params: {
        field,
        terms,
        predicate,
        page,
        perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  companyUsersSearch: ({ perPage, page, q }: GetCompanyUsersListParams) => {
    return api.$post('/company_users/search', {
      include: 'company,role,user,user/image,company_user_rate,territories,additional_informations,sisense_dashboard_role_features,departments',
      page,
      perPage,
      q
    },
    {
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  }
}
