import { defineComponent, computed, ref } from 'vue';
export default defineComponent({
  props: {
    avatar: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: '40'
    },
    name: {
      type: String,
      default: ''
    },
    avatarStyle: {
      type: String,
      default: ''
    },
    largeFontSize: {
      type: Boolean,
      default: false
    },
    smallFontSize: {
      type: Boolean,
      default: false
    },
    lazySrc: {
      type: Boolean,
      default: false
    },
    nameFormatType: {
      type: String,
      default: ''
    },
    rounded: {
      type: String,
      default: '50%'
    },
    backgroundColor: {
      type: String,
      default: '--v-background-base'
    }
  },
  setup(props) {
    const isErrorImage = ref(false);
    const concatFirstAndLastNameInitials = computed(() => {
      if (props !== null && props !== void 0 && props.name) {
        var _lastName;
        const fullName = props.name.split(' ');
        const firstName = fullName.shift();
        let lastName = '';
        if (props.nameFormatType === 'first-second-name') {
          lastName = fullName[0] || '';
        } else {
          lastName = fullName.pop();
        }
        return ((firstName === null || firstName === void 0 ? void 0 : firstName.charAt(0)) || '') + (((_lastName = lastName) === null || _lastName === void 0 ? void 0 : _lastName.charAt(0)) || '');
      } else {
        return '';
      }
    });
    const errorImage = () => {
      isErrorImage.value = true;
    };
    return {
      concatFirstAndLastNameInitials,
      errorImage,
      isErrorImage
    };
  }
});