import _ from 'lodash';
import { defineComponent, ref, watch } from 'vue';
export default defineComponent({
  props: {
    data: {
      type: Object,
      required: true
    },
    closeOnContentClick: {
      type: Boolean,
      default: false
    },
    contentWidth: {
      type: [Number, String],
      required: true
    },
    backgroundColor: {
      type: String,
      default: '--v-background-base'
    },
    dataCy: {
      type: String,
      default: ''
    },
    emitRowText: {
      type: Boolean,
      default: false
    },
    contentClass: {
      type: String,
      default: ''
    },
    alignment: {
      type: String,
      default: null
    }
  },
  setup: (props, {
    emit
  }) => {
    const isMenuOpen = ref(false);
    const rowData = ref(props.data);
    const genListenerObject = () => {
      const ret = {};
      rowData.value.rows = rowData.value.rows.map(row => {
        ret[getEmitName(row.eventName || row.text)] = () => {
          return emit(getEmitName(row.eventName || row.text));
        };
        row.eventName = getEmitName(row.eventName || row.text);
        return row;
      });
      return ret;
    };
    const getEmitName = buttonText => {
      return _.kebabCase(buttonText);
    };
    const listenerObject = ref(props.emitRowText ? genListenerObject() : {});
    watch(() => _.cloneDeep(props.data), () => {
      rowData.value = props.data;
      listenerObject.value = props.emitRowText ? genListenerObject() : {};
    });
    const handleRowClick = ({
      closeOnClick = true
    }) => {
      if (closeOnClick && !props.closeOnContentClick) {
        isMenuOpen.value = false;
      }
    };
    return {
      listenerObject,
      rowData,
      isMenuOpen,
      handleRowClick
    };
  }
});