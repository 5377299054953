import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from 'vue-auto-routing'
import { createRouterLayout } from 'vue-router-layout'
import { useSession } from '@/use/session'
import { useCompanies } from '@/use/companies'
import { useOnboarding } from '@/use/onboarding'
import {
  // DOMAIN,
  DEFAULT_SUBDOMAIN,
  CURRENT_SUBDOMAIN,
  HIGHER_SUBDOMAIN
} from '@/constants/env'
import { useUsersCompanies } from '@/use/users-companies'

/**
 * if you want to add a new route, you need to rebuild the node_modules entirely and yarn install.
 *
 * ALL ENGINEERS WILL HAVE TO REBUILD THEIR NODE MODULES!
 *
 * This is accurate as of Dec 11, 2023, REMOVE THIS COMMENT WHEN IT IS NO LONGER TRUE!!!!
 */
Vue.use(VueRouter)

const RouterLayout = createRouterLayout((layout) => {
  return import('@/layouts/' + layout + '.vue')
})

routes.push({
  name: 'rails', // TODO: split to notFound and rails once rails routes added to vue using rake tasks
  path: '*',
  component: () => import('@/pages/_name/index.vue')
})
const appPathRoute = routes.find((route: any) => {
  return route.name === 'app-path'
})
if (appPathRoute) {
  appPathRoute.path = 'app*'
}

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: RouterLayout,
      children: routes.filter(route => !route.meta?.disabled)
    }
  ],
  scrollBehavior: function (to) {
    if (to.hash) {
      return { selector: to.hash }
      // Or for Vue 3:
      // return {el: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  }
})

const navigationGuards = [
  {
    route: '/app-store',
    validate: () => {
      const { hasAccess } = useSession()
      return hasAccess('subscription_manager')
    }
  },
  {
    route: '/app-store/',
    validate: () => {
      const { hasAccess } = useSession()
      return hasAccess('subscription_manager')
    }
  },
  {
    route: '/work-orders',
    validate: () => {
      const { hasAccess } = useSession()
      return hasAccess('work_orders')
    }
  },
  {
    route: '/work-orders/',
    validate: () => {
      const { hasAccess } = useSession()
      return hasAccess('work_orders')
    }
  },
  {
    route: '/trips',
    validate: () => {
      const { hasAccess } = useSession()
      return hasAccess('work_orders')
    }
  },
  {
    route: '/trips/',
    validate: () => {
      const { hasAccess } = useSession()
      return hasAccess('work_orders')
    }
  },
  {
    route: '/receivables',
    validate: () => {
      const { hasAccess } = useSession()
      return hasAccess('invoice')
    }
  },
  {
    route: '/receivables/',
    validate: () => {
      const { hasAccess } = useSession()
      return hasAccess('invoice')
    }
  },
  {
    route: '/payables',
    validate: () => {
      const { hasAccess } = useSession()
      return hasAccess('invoice')
    }
  },
  {
    route: '/payables/',
    validate: () => {
      const { hasAccess } = useSession()
      return hasAccess('invoice')
    }
  },
  {
    route: '/purchase-orders',
    validate: () => {
      const { hasAccess } = useSession()
      return hasAccess('purchase_order')
    }
  },
  {
    route: '/purchase-orders/',
    validate: () => {
      const { hasAccess } = useSession()
      return hasAccess('purchase_order')
    }
  },
  {
    route: '/proposals',
    validate: () => {
      const { hasAccess } = useSession()
      return hasAccess('proposal')
    }
  },
  {
    route: '/proposals/',
    validate: () => {
      const { hasAccess } = useSession()
      return hasAccess('proposal')
    }
  },
  {
    route: '/employees',
    validate: () => {
      const { hasAccess } = useSession()
      return hasAccess('crew') || hasAccess('team_members') || hasAccess('roles')
    }
  },
  {
    route: '/employees/',
    validate: () => {
      const { hasAccess } = useSession()
      return hasAccess('crew') || hasAccess('team_members') || hasAccess('roles')
    }
  },
  {
    route: '/clients',
    validate: () => {
      const { hasAccess } = useSession()
      return hasAccess('customers')
    }
  },
  {
    route: '/clients/',
    validate: () => {
      const { hasAccess } = useSession()
      return hasAccess('customers')
    }
  },
  {
    route: '/payments',
    validate: () => {
      const { hasAccess } = useSession()
      return hasAccess('payments')
    }
  },
  {
    route: '/vendors',
    validate: () => {
      const { hasAccess } = useSession()
      return hasAccess('vendors')
    }
  },
  {
    route: '/vendors/',
    validate: () => {
      const { hasAccess } = useSession()
      return hasAccess('vendors')
    }
  }
]

const guardNavigations = (to: string, from: string, next: any) => {
  const found: any = navigationGuards.find((guard: any) => {
    return guard.route === to
  })
  if (found) {
    if (found.validate()) {
      // if (to.indexOf('app-store') < 0 && !allowedRoutes.includes(to) && !allowedRoutes.includes(to + '/') && !allowedRoutesStartsWith.some((url) => to.startsWith(url))) {
      //   window.location.replace(window.location.origin + to)
      // }
      next()
    } else {
      if (from === '/') next('/')
      else {
        return false
      }
    }
  } else {
    next()
  }
}

const { getInvitedCompanyDetails } = useCompanies()
const { getUserCompanies } = useUsersCompanies()

const loadInvitedCompanyDetails = async (token: string | null) => {
  const companyDetails = await getInvitedCompanyDetails({
    token,
    include: 'contacts'
  })
  const { onboardingState } = useOnboarding()
  if (companyDetails?.id) {
    onboardingState.token = token
    onboardingState.companyInfo.id = companyDetails?.id
    onboardingState.companyInfo.companyType = companyDetails?.roles?.length > 0
      ? companyDetails.roles.includes('contractor') ||
            companyDetails.roles.includes('vendor')
        ? 'provider'
        : 'requester'
      : 'requester'
    return true
  } else {
    onboardingState.token = ''
    onboardingState.companyInfo.companyType = ''
    return false
  }
}

const viewportMeta = document.querySelector('meta[name="viewport"]')
if (viewportMeta) {
  viewportMeta.setAttribute(
    'content',
    'width=device-width initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0'
  )
  setTimeout(() => {
    viewportMeta.setAttribute('content', 'initial-scale=1.0')
  }, 100)
}

export const getValidSubdomain = async () => {
  const { session } = useSession()
  const res = await getUserCompanies({
    page: 1,
    perPage: 1,
    q: { id_eq: session?.loggedUser?.company_id }
  })
  // get me the company of my companyId
  // did i get a company
  if (res?.companies?.length > 0) {
    const subdomain = res.companies[0].subdomain
    const companyId = res.companies[0].id

    const userDetails = {
      ...session.loggedUser,
      company_id: companyId,
      subdomain
    }
    session.loggedUser = userDetails
  } else {
    session.loggedUser.subdomain = null
    session.loggedUser.company_id = null
  }
}
router.beforeEach(async (to: any, from, next) => {
  if (to.path === '/sso') {
    next()
    return
  }
  const { session, createSubdomainURL, getUserProfile } = useSession()
  let validSubdomain: any = true
  if (to?.query?.token) {
    const { onboardingState } = useOnboarding()
    if (onboardingState.token !== to.query.token) {
      // it will check for the previous token not matches the new token in case of login page or its from the claim company redirect token
      await loadInvitedCompanyDetails(to.query.token)
    }
  }

  // if we are coming from "start location" (manually change URL, refresh, subdomain switch) get userProfileData
  // this will fetch accessible features to see if I am logged in and have access to page
  if (from === VueRouter.START_LOCATION) {
    validSubdomain = await getUserProfile()
  }
  // if the page doesnt require auth
  // if im not logged in and my page requires a default subdomain and I'm not on it, redirect to default subdomain
  // else continue and individual page will handle routing logic
  if (!to.meta.requiresAuth) {
    if (!session.loggedIn && to.meta.requiresDefaultDomain && !to.meta.requiresCompany && CURRENT_SUBDOMAIN !== DEFAULT_SUBDOMAIN && !to?.query?.invitation_token) {
      window.location.replace(createSubdomainURL(DEFAULT_SUBDOMAIN, to.path))
    } else if (!session.loggedIn && to.meta.requiresDefaultDomain && to.meta.requiresCompany && CURRENT_SUBDOMAIN === HIGHER_SUBDOMAIN) {
      window.location.replace(createSubdomainURL(DEFAULT_SUBDOMAIN, to.path))
    } else {
      next()
    }
  // else if page is enabled but subdomain is invalid continue, each page will handle redirect
  } else if (!validSubdomain) {
    next()
  // else if page requires auth and im logged in and i have a valid subdomain check i have access to URL
  } else if (to.meta.requiresAuth && session.loggedIn) {
    guardNavigations(to.path, from.path, next)
  // else (valid sub, requires auth, not logged in) go to login
  } else {
    next('/login')
  }
})

export default router
