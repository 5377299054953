import { defineComponent, ref, watch } from 'vue';
export default defineComponent({
  props: {
    value: {
      type: [Array, Boolean],
      required: true
    },
    checkboxes: {
      type: Array,
      required: true
    },
    canSelectAll: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    itemValue: {
      type: String,
      default: 'value'
    },
    itemText: {
      type: String,
      default: 'label'
    },
    required: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    customLabel: {
      type: Boolean,
      default: false
    }
  },
  setup: (props, {
    emit
  }) => {
    const selected = ref(props.value);
    const localCheckboxes = ref(props.checkboxes);
    const allSelected = ref(selected.value.length === props.checkboxes.length);
    const updateSelectAll = () => {
      if (allSelected.value) {
        selected.value = props.checkboxes.map(check => check.value);
      } else {
        selected.value = [];
      }
      emit('change', selected.value);
      emit('input', selected.value);
    };
    watch(() => props.checkboxes, () => {
      localCheckboxes.value = props.checkboxes;
    }, {
      deep: true
    });
    watch(() => props.value, () => {
      selected.value = props.value;
    }, {
      deep: true
    });
    const hasError = ref(false);
    const handleChange = val => {
      selected.value = val;
      allSelected.value = selected.value.length === props.checkboxes.length;
      if (selected.value.length === 0 && props.required) {
        hasError.value = true;
      } else hasError.value = false;
      emit('change', selected.value);
      emit('input', selected.value);
    };
    return {
      selected,
      updateSelectAll,
      allSelected,
      localCheckboxes,
      handleChange,
      hasError
    };
  }
});