import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('uc-modal', {
    attrs: {
      "closable": true,
      "modal-card-props": {
        headerText: _setup.headerText(),
        size: _setup.ModalWidthSize.LG
      },
      "persistent": ""
    },
    on: {
      "modal-closed": function ($event) {
        return _setup.closeModal();
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('uc-overlay', {
          attrs: {
            "show": _setup.loading
          }
        }, [_c(VProgressCircular, {
          attrs: {
            "indeterminate": "",
            "size": "64"
          }
        })], 1), _setup.woHeaders.data ? _c('div', {
          staticClass: "d-flex flex-row gap-md overflow-hidden"
        }, [_c('uc-tile-container', {
          staticClass: "d-flex flex-column flex-1 overflow-auto",
          attrs: {
            "no-elevation": true,
            "background-color": "--v-layer-secondary-base"
          }
        }, [_setup.hideAttachmentUI ? _c('uc-text', {
          staticClass: "align-center my-2",
          attrs: {
            "size": 1,
            "bold": "",
            "type": "body",
            "text": "Generate Report By"
          }
        }) : _vm._e(), _setup.hideAttachmentUI ? _c('uc-select', {
          staticClass: "py-1 uc-select",
          attrs: {
            "label": "Generate Report By",
            "label-variant": "",
            "item-text": "name",
            "item-value": "name",
            "items": _setup.INVOICE_GROUP_BY_CONFIG_OPTIONS,
            "required": "",
            "hide-details": ""
          },
          model: {
            value: _setup.generateReportBy,
            callback: function ($$v) {
              _setup.generateReportBy = $$v;
            },
            expression: "generateReportBy"
          }
        }) : _vm._e(), _c('uc-radio-group', {
          class: !_setup.hideAttachmentUI ? 'mb-4' : 'my-4',
          attrs: {
            "buttons": _setup.exportFormatRadiosBtn,
            "label": "Report Format",
            "label-variant": false
          },
          model: {
            value: _setup.reportFormat,
            callback: function ($$v) {
              _setup.reportFormat = $$v;
            },
            expression: "reportFormat"
          }
        }), !_setup.hideAttachmentUI ? _c('div', [_c('uc-text', {
          staticClass: "align-center my-2",
          attrs: {
            "size": 1,
            "bold": "",
            "type": "body",
            "text": "Select Template"
          }
        }), _c('uc-select', {
          key: _setup.keyIndex,
          staticClass: "py-1 mb-2 uc-select",
          attrs: {
            "label": "Select Template",
            "label-variant": "",
            "item-text": "name",
            "item-value": "name",
            "required": "",
            "hide-details": "",
            "fetch-fn": _setup.getTripReportTemplates
          },
          on: {
            "change": _setup.updateSelectedTemplateWoHeaders
          },
          model: {
            value: _setup.selectedReportTemplate,
            callback: function ($$v) {
              _setup.selectedReportTemplate = $$v;
            },
            expression: "selectedReportTemplate"
          }
        }), _c('uc-text', {
          staticClass: "align-center my-2",
          attrs: {
            "size": 1,
            "bold": "",
            "type": "body",
            "text": "Template Name"
          }
        }), _c('uc-input', {
          attrs: {
            "label": "Template Name",
            "label-variant": "",
            "required": "",
            "placeholder": "Name your template...",
            "rules": [_setup.emptyFileName, _setup.moreThanOnePeriod, _setup.containsInvalidCharacter]
          },
          model: {
            value: _setup.templateName,
            callback: function ($$v) {
              _setup.templateName = $$v;
            },
            expression: "templateName"
          }
        }), _c('uc-checkbox', {
          staticClass: "my-0",
          attrs: {
            "label": "User Specific Template",
            "hide-details": ""
          },
          model: {
            value: _setup.userSpecific,
            callback: function ($$v) {
              _setup.userSpecific = $$v;
            },
            expression: "userSpecific"
          }
        }), _c('div', {
          staticClass: "my-2 d-flex justify-end"
        }, [_c('uc-button', {
          attrs: {
            "disabled": _setup.disableSaveTemplate,
            "width": "150px",
            "text": "Save as template"
          },
          on: {
            "click": function ($event) {
              return _setup.saveTemplate();
            }
          }
        })], 1)], 1) : _vm._e()], 1), _c('uc-tile-container', {
          staticClass: "d-flex flex-column flex-1 overflow-hidden",
          attrs: {
            "no-elevation": true,
            "background-color": "--v-layer-secondary-base"
          }
        }, [_c('uc-text', {
          staticClass: "align-center my-2",
          attrs: {
            "size": 1,
            "bold": "",
            "type": "body",
            "text": "Include In Report"
          }
        }), _c('div', {
          staticClass: "flex-column d-flex overflow-y-auto overflow-x-hidden"
        }, [_c('div', {
          staticClass: "w-100 mt-1"
        }, [_c('div', [_c('div', {
          staticClass: "d-flex flex-1 flex-row justify-space-between align-center py-3"
        }, [_c('uc-checkbox', {
          attrs: {
            "label": _setup.woHeaders.data.trip.label,
            "hide-details": "",
            "remove-margin": true
          },
          on: {
            "input": function ($event) {
              return _setup.selectTripSubTypes();
            }
          },
          model: {
            value: _setup.woHeaders.data.trip.selected,
            callback: function ($$v) {
              _vm.$set(_setup.woHeaders.data.trip, "selected", $$v);
            },
            expression: "woHeaders.data.trip.selected"
          }
        }), _c('div', {
          on: {
            "click": function ($event) {
              _setup.woHeaders.data.trip.open = !_setup.woHeaders.data.trip.open;
            }
          }
        }, [_c('uc-icon', {
          attrs: {
            "value": _setup.woHeaders.data.trip.open ? _setup.DRAWER_CLOSED : _setup.DRAWER_OPENED
          }
        })], 1)], 1), _c('div', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _setup.woHeaders.data.trip.open,
            expression: "woHeaders.data.trip.open"
          }]
        }, _vm._l(_setup.woHeaders.data.trip.types, function (tripTypes) {
          return _c('div', {
            key: tripTypes.id,
            staticClass: "ml-5"
          }, [tripTypes.label === 'Check in Photos Report' ? _c('div', [_c('div', {
            staticClass: "w-100 mt-1"
          }, [_c('div', [_c('div', {
            staticClass: "d-flex flex-1 flex-row justify-space-between align-center"
          }, [_c('uc-checkbox', {
            attrs: {
              "label": tripTypes.label,
              "hide-details": "",
              "remove-margin": true
            },
            model: {
              value: tripTypes.selected,
              callback: function ($$v) {
                _vm.$set(tripTypes, "selected", $$v);
              },
              expression: "tripTypes.selected"
            }
          }), _c('div', {
            on: {
              "click": function ($event) {
                tripTypes.open = !tripTypes.open;
              }
            }
          }, [_c('uc-icon', {
            attrs: {
              "value": tripTypes.open ? _setup.DRAWER_CLOSED : _setup.DRAWER_OPENED
            }
          })], 1)], 1), _c('div', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: tripTypes.open,
              expression: "tripTypes.open"
            }]
          }, [_c('uc-radio-group', {
            staticClass: "ml-5",
            attrs: {
              "buttons": _setup.photosArr,
              "label-variant": false
            },
            on: {
              "input": _setup.photoVersionChanged
            },
            model: {
              value: _setup.photoVersion,
              callback: function ($$v) {
                _setup.photoVersion = $$v;
              },
              expression: "photoVersion"
            }
          })], 1)])])]) : _c('div', [_c('uc-checkbox', {
            staticClass: "flex-1 my-0",
            attrs: {
              "remove-margin": true,
              "label": _vm.$removeHTML(tripTypes.label),
              "hide-details": ""
            },
            model: {
              value: tripTypes.selected,
              callback: function ($$v) {
                _vm.$set(tripTypes, "selected", $$v);
              },
              expression: "tripTypes.selected"
            }
          })], 1)]);
        }), 0)])]), _c('div', {
          staticClass: "w-100 mt-1"
        }, [_c('div', [_c('div', {
          staticClass: "d-flex flex-row flex-1 flex-row justify-space-between align-center py-3"
        }, [_c('uc-checkbox', {
          attrs: {
            "label": _setup.woHeaders.data.report.label,
            "hide-details": "",
            "remove-margin": true
          },
          on: {
            "input": function ($event) {
              return _setup.selectReportSubTypes();
            }
          },
          model: {
            value: _setup.woHeaders.data.report.selected,
            callback: function ($$v) {
              _vm.$set(_setup.woHeaders.data.report, "selected", $$v);
            },
            expression: "woHeaders.data.report.selected"
          }
        }), _c('div', {
          on: {
            "click": function ($event) {
              _setup.woHeaders.data.report.open = !_setup.woHeaders.data.report.open;
            }
          }
        }, [_c('uc-icon', {
          attrs: {
            "value": _setup.woHeaders.data.report.open ? _setup.DRAWER_CLOSED : _setup.DRAWER_OPENED
          }
        })], 1)], 1), _c('div', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _setup.woHeaders.data.report.open,
            expression: "woHeaders.data.report.open"
          }]
        }, [_c('div', {
          staticClass: "w-100"
        }, [_c('div', [_c('div', {
          staticClass: "d-flex flex-row flex-1 justify-space-between align-center"
        }, [_c('uc-checkbox', {
          staticClass: "ml-5",
          attrs: {
            "label": _setup.woHeaders.data.equipment.label,
            "hide-details": "",
            "remove-margin": true
          },
          on: {
            "input": function ($event) {
              return _setup.selectEquipmentSubTypes();
            }
          },
          model: {
            value: _setup.woHeaders.data.equipment.selected,
            callback: function ($$v) {
              _vm.$set(_setup.woHeaders.data.equipment, "selected", $$v);
            },
            expression: "woHeaders.data.equipment.selected"
          }
        }), _c('div', {
          on: {
            "click": function ($event) {
              _setup.woHeaders.data.equipment.open = !_setup.woHeaders.data.equipment.open;
            }
          }
        }, [_c('uc-icon', {
          attrs: {
            "value": _setup.woHeaders.data.equipment.open ? _setup.DRAWER_CLOSED : _setup.DRAWER_OPENED
          }
        })], 1)], 1), _c('div', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _setup.woHeaders.data.equipment.open,
            expression: "woHeaders.data.equipment.open"
          }]
        }, _vm._l(_setup.woHeaders.data.equipment.types, function (equipTypes, eqpType) {
          return _c('div', {
            key: eqpType,
            staticClass: "ml-5"
          }, [equipTypes.label === 'Deficiency' ? _c('div', [_c('div', {
            staticClass: "w-100 mt-1"
          }, [_c('div', [_c('div', {
            staticClass: "d-flex flex-row flex-1 justify-space-between align-center"
          }, [_c('uc-checkbox', {
            staticClass: "ml-5",
            attrs: {
              "label": equipTypes.label,
              "hide-details": "",
              "remove-margin": true
            },
            on: {
              "input": function ($event) {
                return _setup.selectDeficiencySubTypes($event);
              }
            },
            model: {
              value: equipTypes.selected,
              callback: function ($$v) {
                _vm.$set(equipTypes, "selected", $$v);
              },
              expression: "equipTypes.selected"
            }
          }), _c('div', {
            on: {
              "click": function ($event) {
                equipTypes.open = !equipTypes.open;
              }
            }
          }, [_c('uc-icon', {
            attrs: {
              "value": equipTypes.open ? _setup.DRAWER_CLOSED : _setup.DRAWER_OPENED
            }
          })], 1)], 1), _c('div', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: equipTypes.open,
              expression: "equipTypes.open"
            }]
          }, _vm._l(_setup.woHeaders.data.deficiencyColorTags, function (deficiencyColorTag) {
            return _c('div', {
              key: deficiencyColorTag.id,
              staticClass: "ml-10"
            }, [_c('uc-checkbox', {
              staticClass: "flex-1 my-0",
              attrs: {
                "remove-margin": true,
                "label": deficiencyColorTag.label,
                "hide-details": ""
              },
              model: {
                value: deficiencyColorTag.selected,
                callback: function ($$v) {
                  _vm.$set(deficiencyColorTag, "selected", $$v);
                },
                expression: "deficiencyColorTag.selected"
              }
            })], 1);
          }), 0)])])]) : _c('div', [_c('uc-checkbox', {
            staticClass: "flex-1 my-0 ml-5",
            attrs: {
              "label": equipTypes.label,
              "remove-margin": true,
              "hide-details": ""
            },
            model: {
              value: equipTypes.selected,
              callback: function ($$v) {
                _vm.$set(equipTypes, "selected", $$v);
              },
              expression: "equipTypes.selected"
            }
          })], 1)]);
        }), 0)])]), _c('div', {
          staticClass: "w-100 mt-1"
        }, [_c('div', [_c('div', {
          staticClass: "d-flex flex-row flex-1 justify-space-between align-center"
        }, [_c('uc-checkbox', {
          staticClass: "ml-5",
          attrs: {
            "label": _setup.woHeaders.data.otherEquipmentReport.label,
            "hide-details": "",
            "remove-margin": true
          },
          on: {
            "input": _setup.selectOtherEquipmentSubTypes
          },
          model: {
            value: _setup.woHeaders.data.otherEquipmentReport.selected,
            callback: function ($$v) {
              _vm.$set(_setup.woHeaders.data.otherEquipmentReport, "selected", $$v);
            },
            expression: "woHeaders.data.otherEquipmentReport.selected"
          }
        }), _c('div', {
          on: {
            "click": function ($event) {
              _setup.woHeaders.data.otherEquipmentReport.open = !_setup.woHeaders.data.otherEquipmentReport.open;
            }
          }
        }, [_c('uc-icon', {
          attrs: {
            "value": _setup.woHeaders.data.otherEquipmentReport.open ? _setup.DRAWER_CLOSED : _setup.DRAWER_OPENED
          }
        })], 1)], 1), _c('div', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _setup.woHeaders.data.otherEquipmentReport.open,
            expression: "woHeaders.data.otherEquipmentReport.open"
          }]
        }, [_vm._l(_setup.woHeaders.data.otherEquipmentReport.types, function (otherEquipTypes, eqType) {
          return _c('div', {
            key: eqType,
            staticClass: "ml-10"
          }, [_c('uc-checkbox', {
            staticClass: "flex-1 my-0",
            attrs: {
              "label": otherEquipTypes.label,
              "hide-details": "",
              "remove-margin": true
            },
            on: {
              "input": _setup.selectOtherEquipmentSubTypes
            },
            model: {
              value: otherEquipTypes.selected,
              callback: function ($$v) {
                _vm.$set(otherEquipTypes, "selected", $$v);
              },
              expression: "otherEquipTypes.selected"
            }
          })], 1);
        }), !_setup.hideAttachmentUI ? _c('div', _vm._l(_setup.woHeaders.surveyAndSurveyTemplateData, function (data) {
          return _c('div', {
            key: data.surveyId,
            staticClass: "ml-10"
          }, [_c('uc-checkbox', {
            staticClass: "flex-1 my-0",
            attrs: {
              "label": data.surveyTemplateName,
              "hide-details": "",
              "remove-margin": true
            },
            model: {
              value: data.selected,
              callback: function ($$v) {
                _vm.$set(data, "selected", $$v);
              },
              expression: "data.selected"
            }
          }), _c('uc-text', {
            staticClass: "ml-10",
            attrs: {
              "size": 2,
              "type": "body",
              "text": `Trip# ${data.tripPosition} - Service# ${data.serviceId}`
            }
          })], 1);
        }), 0) : _vm._e()], 2)])])])])])])], 1)], 1) : _vm._e()];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function () {
        return [_c('uc-button-group', {
          attrs: {
            "buttons": [{
              type: 'outlined',
              text: 'CANCEL',
              size: 'md',
              eventName: 'cancel'
            }, {
              type: 'primary',
              text: _setup.hideAttachmentUI ? 'Save' : 'Download',
              size: 'md',
              eventName: 'generateReport'
            }]
          },
          on: {
            "cancel": _setup.closeModal,
            "generateReport": function ($event) {
              _setup.getSelectedWoReportLabels();
              _vm.bulkWorkOrderReport ? _setup.saveWoReportInSession() : _setup.callGenerateReportOrBackgroundTask();
              _setup.closeModal();
            }
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _setup.attachmentWorkOrderDetailModal,
      callback: function ($$v) {
        _setup.attachmentWorkOrderDetailModal = $$v;
      },
      expression: "attachmentWorkOrderDetailModal"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };