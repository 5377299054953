import {
  INVOICE_NUMBER,
  INVOICE_CUSTOM_NUMBER,
  INVOICE_STATUS,
  INVOICE_DATE_OF_ISSUE,
  INVOICE_DUE_DATE,
  INVOICE_AGREEMENT_CONTRACT_NUMBER,
  INVOICE_AGREEMENT_CUSTOM_NUMBER
} from '@/constants/filters/resources/invoice'

import {
  CLIENT_NAME,
  CLIENT_COUNTRY,
  CLIENT_STATE,
  CLIENT_CITY,
  CLIENT_ZIP,
  CLIENT_ADDRESS
} from '@/constants/filters/resources/client'

import {
  SITE_NAME,
  SITE_COUNTRY,
  SITE_CITY,
  SITE_ZIP,
  SITE_ADDRESS,
  SITE_REGION
} from '@/constants/filters/resources/site'

import {
  WEATHER_EVENT,
  WEATHER_EVENT_START_DATE
} from '@/constants/filters/resources/weatherEvent'
import { WORK_ORDER_BILLING_METHOD, WORK_ORDER_EXTERNAL_REGION, WORK_ORDER_NUMBER, WORK_ORDER_SERVICE, WORK_ORDER_TRADE, WORK_ORDER_CUSTOM_STATUS, WORK_ORDER_STATUS } from '../resources/workOrder'
import { computed } from 'vue'
import { BROKER, CLIENT } from '@/constants/permissions'
import { useSession } from '@/use/session'
import { useOutboundInvoices } from '@/use/invoices/outbound'
import { AutocompletePayload, AutocompleteResponse } from '@/types/interfaces/api-v2/autocomplete'
import { useApp } from '@/use/app'

export const attentionInvoiceFilters = () => {
  const { session } = useSession()
  const { invoicesAutoComplete } = useOutboundInvoices()

  const availableWorkOrderFilters = () => {
    const baseFilters: any = [
      WORK_ORDER_NUMBER({ pinned: true, isPrimary: true }, { field: 'wo_id', invoiceables: false }, invoiceAutoCompleteFetchFn),
      WORK_ORDER_BILLING_METHOD({ pinned: false, isPrimary: false }, { field: 'billing_method' }, invoiceAutoCompleteFetchFn),
      WORK_ORDER_STATUS({ pinned: false, isPrimary: false })
    ]
    if (session.currentCompanyType === BROKER) {
      baseFilters.push(
        WORK_ORDER_SERVICE({ pinned: false, isPrimary: false }, { field: 'trade_service' }, invoiceAutoCompleteFetchFn),
        WORK_ORDER_TRADE({ pinned: false, isPrimary: false }, { field: 'trade' }, invoiceAutoCompleteFetchFn),
        WORK_ORDER_EXTERNAL_REGION({ pinned: false, isPrimary: false }, invoiceAutoCompleteFetchFn)
      )
    }
    return baseFilters
  }

  const availableSiteFilters = () => {
    const baseFilters: any = [
    // SITE_COUNTRY({ pinned: false, isPrimary: false }),
    // SITE_CITY({ pinned: false, isPrimary: false }),
    // SITE_ZIP({ pinned: false, isPrimary: false }),
    // SITE_ADDRESS({ pinned: false, isPrimary: false })
    ]
    if (session.currentCompanyType === BROKER || session.currentCompanyType === CLIENT) {
      baseFilters.push(
        SITE_NAME({ pinned: true, isPrimary: true }, { field: 'site', invoiceables: false }, invoiceAutoCompleteFetchFn),
        SITE_REGION({ pinned: false, isPrimary: false }, { field: 'site_region', invoiceables: false }, invoiceAutoCompleteFetchFn)
      )
    }
    return baseFilters
  }

  const availableWeatherEventFilters = () => {
    const baseFilters: any = []
    if (session.currentCompanyType === BROKER) {
      baseFilters.push(
        WEATHER_EVENT({ pinned: false, isPrimary: false }),
        WEATHER_EVENT_START_DATE({ pinned: false, isPrimary: false })
      )
    }
    return baseFilters
  }

  const invoiceAutoCompleteFetchFn = async (params: AutocompletePayload) => {
    params.finderParams = { status_not_in: ['paid', 'void'] }
    const { data, nextPage } = await invoicesAutoComplete(params) as { data: AutocompleteResponse[], nextPage: number }
    return {
      data: data.map((item: AutocompleteResponse, index: number) => {
        return {
          id: `${index}-${item.text}`,
          text: item.text,
          value: item.text
        }
      }),
      nextPage
    }
  }

  return {
    filters: [
      INVOICE_NUMBER(true, { pinned: true, isPrimary: true }, { field: 'invoice_number' }, invoiceAutoCompleteFetchFn),
      INVOICE_CUSTOM_NUMBER(true, { pinned: false, isPrimary: false }, { field: 'custom_invoice_number' }, invoiceAutoCompleteFetchFn),
      INVOICE_STATUS(true, { pinned: false, isPrimary: false }),
      INVOICE_DATE_OF_ISSUE(true, { pinned: false, isPrimary: false }),
      INVOICE_DUE_DATE(true, { pinned: true, isPrimary: true }),
      INVOICE_AGREEMENT_CONTRACT_NUMBER(true, { pinned: false, isPrimary: false }, { field: 'agreement_number' }, invoiceAutoCompleteFetchFn),
      INVOICE_AGREEMENT_CUSTOM_NUMBER(true, { pinned: false, isPrimary: false }, { field: 'agreement_custom_no' }, invoiceAutoCompleteFetchFn),
      CLIENT_NAME({ pinned: true, isPrimary: true }, { field: 'client', tab: 'active' }, invoiceAutoCompleteFetchFn),
      ...availableSiteFilters(),
      ...availableWeatherEventFilters(),
      ...availableWorkOrderFilters()
    ],
    sortBy: {
      value: 'date_of_issue',
      direction: {
        text: 'Descending',
        value: 'desc'
      },
      items: [
        {
          label: 'Status',
          value: 'status'
        },
        {
          label: 'Due Date',
          value: 'due_date'
        },
        {
          label: 'Date of Issue',
          value: 'date_of_issue'
        },
        {
          label: 'Invoice #',
          value: 'short_invoice_number'
        },
        {
          label: 'Amount Due',
          value: 'payable_balance_cents'
        }
      ]
    },
    fieldMapping: {
      'invoice-number': {
        name: 'short_invoice_number',
        field: 'value'
      },
      'invoice-custom-number': {
        name: 'custom_invoice_number',
        field: 'value'
      },
      'invoice-work-order-number': {
        name: 'resource_id',
        field: 'value',
        inject: {
          clients: {
            name: 'resource_type',
            operator: 'eq',
            values: 'WorkOrder'
          }
        }
      },
      'invoice-status': {
        name: 'status',
        field: 'value'
      },
      'invoice-date-of-issue': {
        name: 'date_of_issue',
        field: 'value'
      },
      'invoice-due-date': {
        name: 'due_date',
        field: 'value'
      },
      'schedule-service-id': {
        name: 'agreement_contract_id',
        field: 'value'
      },
      'schedule-service-custom-id': {
        name: 'agreement_contract_custom_id',
        field: 'value'
      },
      'client-name': {
        name: 'company_name',
        field: 'value'
      },
      'client-country': {
        name: 'company_headquarter_address_country',
        field: 'value'
      },
      'client-state': {
        name: 'company_headquarter_address_state',
        field: 'value'
      },
      'client-city': {
        name: 'company_headquarter_address_city',
        field: 'value'
      },
      'client-zip': {
        name: 'company_headquarter_address_zip_code',
        field: 'value'
      },
      'client-address': {
        name: 'company_headquarter_address_formatted_address',
        field: 'value'
      },
      'site-name': {
        name: 'locations_name',
        field: 'value'
      },
      'site-country': {
        name: 'location_address_country',
        field: 'value'
      },
      'site-city': {
        name: 'location_address_city',
        field: 'value'
      },
      'site-zip': {
        name: 'location_address_zip_code',
        field: 'value'
      },
      'site-address': {
        name: 'location_address_full_address',
        field: 'value'
      },
      'site-region': {
        name: 'locations_cc_territory_name',
        field: 'text'
      },
      'weather-event': {
        linkingTables: {
          clients: 'work_orders_trips_storm_details',
          sites: 'storm_details',
          proposals: 'work_orders_trips_storm_details',
          'work-orders': 'trips_storm_details',
          events: ''
        },
        name: 'id',
        field: 'id',
        dynamicQueryKeys: {
          workOrdersName: 'trips_storm_details_id',
          weatherEventsName: 'event_id'
        },
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'weather-event-start-date': {
        linkingTables: {
          clients: 'work_orders_trips_storm_details',
          sites: 'storm_details',
          proposals: 'work_orders_trips_storm_details',
          'work-orders': 'trips_storm_details',
          events: ''
        },
        name: 'start',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'trips_storm_details',
          weatherEventsName: ''
        },
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'work-order-number': {
        name: 'work_order_id',
        field: 'value'
      },
      'work-order-service': {
        name: 'work_order_work_order_trade_services_trade_service_name',
        field: 'value'
      },
      'work-order-trade': {
        name: 'work_order_work_order_trades_trade_name',
        field: 'value'
      },
      'work-order-billing-method': {
        name: 'work_order_work_order_type',
        field: 'value'
      },
      'external-region': {
        name: 'work_order_vendor_client_agreements_external_region',
        field: 'value'
      },
      'work-order-status': {
        name: 'work_order_work_order_status_name',
        field: 'value'
      }
    }
  }
}
