export enum AttachmentAction {
  DELETE = 'delete',
  SYNC = 'sync',
  DOWNLOAD = 'download'
}

export enum AttachmentWorkOrderCategory {
  PHOTO_OR_DOCUMENT = 'Photo or Document',
  CLIENT_INVOICE = 'Client Invoice',
  CLIENT_PROPOSAL = 'Client Proposal',
  CLIENT_PURCHASE_ORDER = 'Client Purchase Order',
  VENDOR_INVOICE = 'Vendor Invoice',
  VENDOR_PROPOSAL = 'Vendor Proposal',
  VENDOR_PURCHASE_ORDER = 'Vendor Purchase Order'
}

export enum AttachmentTripCategory {
  PHOTO = 'Photo',
  ATTACHMENT_FROM_WO = 'Attachments from Work Order',
  INSPECTION_FORM = 'Inspection Form',
  REPAIR_FORM = 'Repair Form',
  OTHER = 'Other',
  INVOICE = 'Invoice',
  PROPOSAL = 'Proposal'
}
