import { apis } from '@/api'
import qs from 'qs'

interface GetTradesmenListParams {
  perPage: number
  page: number
  q?: any
}

interface GetTradesmenParams {
  id:number
  q?: any
}

interface UpdateTradesmenParams {
  id:number,
  dispatchboardStatus:boolean
}

interface CrewsParams {
  q?:any,
}

export default {
  getActiveTradesmenList: ({ perPage, page, q }: GetTradesmenListParams) => apis.$get('/tradesmen', {
    params: {
      include: '',
      page,
      perPage,
      q
    },
    paramsSerializer: params => {
      return qs.stringify(params)
    }
  }),
  getInactiveTradesmenList: ({ perPage, page, q }: GetTradesmenListParams) => apis.$get('/tradesmen', {
    params: {
      include: '',
      page,
      perPage,
      q
    },
    paramsSerializer: params => {
      return qs.stringify(params)
    }
  }),
  getTradesmenList: ({ perPage, page, q }: GetTradesmenListParams) => apis.$get('/tradesmen', {
    params: {
      include: '',
      page,
      perPage,
      q
    },
    paramsSerializer: params => {
      return qs.stringify(params)
    }
  }),
  getTradesMenRoles: () => apis.$get('roles'),
  getTradesmen: ({ id, q }: GetTradesmenParams) => apis.$get(`/tradesmen/${id}`, {
    params: {
      include: '',
      q
    },
    paramsSerializer: params => {
      return qs.stringify(params)
    }
  }),
  updateTradesmenCalender: ({ id, dispatchboardStatus }: UpdateTradesmenParams) => apis.$patch(`/tradesmen/${id}`, { dispatch_board_status: dispatchboardStatus }),

  getCrews: ({ q }:CrewsParams) => apis.$get('/crews', {
    params: {
      include: 'tradesmens',
      q
    },
    paramsSerializer: params => {
      return qs.stringify(params)
    }
  })
}
