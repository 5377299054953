export enum RequiredActionActions {
  ACCEPT = 'accept',
  ADJUST = 'adjust',
  APPROVE = 'approve',
  ASSIGN = 'assign',
  CHECK_IN = 'check_in',
  CREATE_TRIP = 'create_trip',
  DISPATCH = 'dispatch',
  DOWNLOAD = 'download',
  EDIT = 'edit',
  GENERATE_INVOICE = 'generate_invoice',
  PAY = 'pay',
  REASSIGN = 'reassign',
  RECEIVE_PAYMENT = 'receive_payment',
  REMIND_VENDOR = 'remind_vendor',
  REVIEW = 'review',
  SEND = 'send',
  VERIFY = 'verify',
}
