export enum DerivedItemResourceTypes {
  EQUIPMENT_TASK_RATE = 'EquipmentTaskRate',
  EVENT_TASK_RATE = 'EventTaskRate',
  EVENT_TASK_RATE_RANGE = 'EventTaskRateRange',
  EVERY_RATE_RANGE = 'EveryRateRange',
  EVERY_TASK_RATE_RANGE = 'EveryTaskRateRange',
  ITEM = 'Item',
  EVENT_RATE_RANGE = 'EventRateRange',
  SERVICE_RATE = 'ServiceRate',
  TASK_RATE = 'TaskRate',
  LABOR_RATE = 'LaborRate'
}
