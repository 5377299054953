import { reactive } from 'vue'

type Coordinates = {
  latitude: number,
  longitude: number
}

const currentLocation = reactive<Coordinates>({
  latitude: 0,
  longitude: 0
})

export const useCurrentLocation = () => {
  function distance (lat2: number, lon2: number) {
    const p = 0.017453292519943295
    const c = Math.cos
    const a = 0.5 - c((lat2 - currentLocation.latitude) * p) / 2 +
    c(currentLocation.latitude * p) * c(lat2 * p) *
    (1 - c((lon2 - currentLocation.longitude) * p)) / 2
    return 7917.6 * Math.asin(Math.sqrt(a))
  }

  const success = () => {
    const position = {
      coords: {
        latitude: 37.7692,
        longitude: 122.4481
      }
    }
    currentLocation.latitude = position.coords.latitude
    currentLocation.longitude = position.coords.longitude
  }
  const getCurrentLocation = () => {
    success()
  }

  return {
    currentLocation,
    getCurrentLocation,
    distance
  }
}
