import { apis, api } from '@/api'
import qs from 'qs'

interface Params {
  include: string
  q: any
  page: number
  perPage: number
  id: number
}

interface getCustomStatusParams {
  scope?: string
  include?: string
  q?: any
  page?: number
  perPage?: number,
}

export default {
  getExtended: () => apis.$get('/work_order_statuses', {}),
  getAllActiveWoStatuses: (params: any) => {
    const parameters: Params = params
    const { include, q, page, perPage } = parameters
    return apis.$get('/work_order_statuses/all_active_wo_statuses', {
      params: {
        include,
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getCustomStatuses: ({ scope, include, q, page, perPage }: getCustomStatusParams) => {
    return api.$get('/work_order_statuses/custom_statuses', {
      params: {
        scope,
        include,
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  }
}
