import repositories from '@/repositories'
import { PROPOSAL_RESOURCE_TYPE } from '@/constants/resource-types'
import { useProposals } from '../proposals'
import { PROPOSAL_INCLUDE_DERIVED_ITEM } from '@/constants/include'
import { AutocompletePayload, AutocompleteResponse } from '@/types/interfaces/api-v2/autocomplete'
import { useDetailsPanel } from '../details-panel'
export const useInboundProposals = () => {
  const { shared, proposalActionModalProps } = useProposals()
  const basicProposalInboundActions = [
    ...shared(false)

  ]
  const getInboundProposals = async (params: any) => {
    try {
      const res = await repositories.inboundProposals.get(params)
      return {
        data: res.proposals,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const postInboundProposalsSendEmail = async (body: any) => {
    try {
      // if (!ids) throw new Error('Id array required in params')
      const res = await repositories.inboundProposals.sendInboundEmail(body)
      // if (proposalEmail.backgroundTaskId) {
      //   addBackgroundTask(proposalEmail.backgroundTaskId)
      // } else {
      //   console.error('Background Task ID not received  from send email response, unable to create background task')
      // }
      return { data: res.proposalEmail.success || false }
    } catch (err: any) {
      console.error(err)
      return { data: false }
    }
  }
  const updateInboundProposalStatus = async (params:any) => {
    try {
      return await repositories.inboundProposals.updateInboundProposalStatus(params)
    } catch (err) {
      console.error(err)
      return false
    }
  }
  const updateInboundProposals = async (params: any) => {
    try {
      return await repositories.inboundProposals.patchInbound(params)
    } catch (err) {
      console.error(err)
      return false
    }
  }

  const postInboundProposals = async (params: any, requestBody: any) => {
    try {
      const res:any = await repositories.inboundProposals.post(params, requestBody)
      return res.proposal
    } catch (err) {
      console.error(err)
    }
  }
  const newInboundV2Proposals = async (params: any, requestBody: any) => {
    try {
      const res:any = repositories.inboundProposals.newV2(params, requestBody)
      return res
    } catch (err) {
      console.error(err)
    }
  }
  const bulkDestroyInboundProposals = async (params: any) => {
    try {
      if (!params.query) throw new Error('(Id array or q) is required to call this endpoint')
      const res = await repositories.inboundProposals.inboundBulkDestroy(params)
      return res
    } catch (err: any) {
      console.error(err)
      return false
    }
  }
  const deleteInboundProposal = async (proposalId: string, objectScope = 'both') => {
    try {
      const res = await repositories.inboundProposals.deleteInboundProposal(proposalId, objectScope)
      return res
    } catch (err) {
      return false
    }
  }
  const getInboundProposalById = async (id: number, include = '') => {
    try {
      const res = await repositories.inboundProposals.getById(id, { include })
      return { data: res.proposal }
    } catch (err: any) {
      console.error(err)
      return { data: false }
    }
  }
  const inboundProposalsAutoComplete = async (params: AutocompletePayload) => {
    try {
      const res = await repositories.inboundProposals.inboundProposalsAutoComplete(params)
      return {
        data: res.results,
        nextPage: res.nextPage
      } as { data: AutocompleteResponse[], nextPage: number }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }
  const proposalInboundModalRequiredActions = [
    {
      value: 'close',
      requiresConfirmation: true,
      modal: proposalActionModalProps(false).close,
      actionFn: async (proposals: any[], additionalParams: any) => {
        await updateInboundProposalStatus({ action: 'close', proposalId: proposals[0].id, reason: additionalParams.additionalDataFromActionModal.note, include: PROPOSAL_INCLUDE_DERIVED_ITEM })
      }
    },
    {
      value: 'approve',
      requiresConfirmation: true,
      modal: proposalActionModalProps(false).approve,
      actionFn: async (proposals: any[], additionalParams: any) => {
        await updateInboundProposalStatus({ action: 'approve', proposalId: proposals[0].id, reason: additionalParams.additionalDataFromActionModal.note, include: PROPOSAL_INCLUDE_DERIVED_ITEM })
      }
    },
    {
      value: 'evaluate',
      requiresConfirmation: true,
      modal: proposalActionModalProps(false).evaluate,
      actionFn: async (proposals: any[], additionalParams: any) => {
        await updateInboundProposalStatus({ action: 'evaluate', proposalId: proposals[0].id, reason: additionalParams.additionalDataFromActionModal.note, include: PROPOSAL_INCLUDE_DERIVED_ITEM })
      }

    },
    {
      value: 'reject',
      requiresConfirmation: true,
      modal: proposalActionModalProps(false).reject,
      actionFn: async (proposals: any[], additionalParams: any) => {
        await updateInboundProposalStatus({ action: 'decline', proposalId: proposals[0].id, reason: additionalParams.additionalDataFromActionModal.note, include: PROPOSAL_INCLUDE_DERIVED_ITEM, annexedPoNumber: additionalParams.additionalDataFromActionModal['annexed-po-number'] })
      }
    },
    {
      value: 'delete',
      requiresConfirmation: true,
      modal: proposalActionModalProps(false).delete,
      actionFn: async (proposals: any[], additionalParams: any) => {
        await deleteInboundProposal(proposals[0].id)
      }
    },
    {
      value: 'save'
    },
    {
      value: 'save-and-approve'
    },
    {
      value: 'save-and-receive'
    },
    {
      value: 'view',
      updateSelectedResource: true,
      actionFn: (proposals: any) => {
        const { openGlobalDetailsPanel } = useDetailsPanel()
        openGlobalDetailsPanel('uc/resource-details', {
          actions: [
            // Deleting bulkConfig for hiding bulk action for the proposal
            ...basicProposalInboundActions.map((item: any) => {
              const temp = Object.assign({}, item)
              delete temp.bulkConfig
              return temp
            }),
            ...proposalInboundModalRequiredActions
          ],
          selectedResources: proposals,
          resourceType: PROPOSAL_RESOURCE_TYPE,
          canDoubleClickToEdit: true,
          outbound: false
        }, '', { }, { })
      },
      noSuccessEmit: true
    }
  ]

  const inboundProposalsSearch = async (params: any) => {
    try {
      const res = await repositories.inboundProposals.inboundProposalsSearch(params)
      return {
        data: res.proposals,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }
  return {
    basicProposalInboundActions,
    updateInboundProposalStatus,
    bulkDestroyInboundProposals,
    getInboundProposals,
    updateInboundProposals,
    postInboundProposalsSendEmail,
    postInboundProposals,
    proposalInboundModalRequiredActions,
    getInboundProposalById,
    deleteInboundProposal,
    inboundProposalsAutoComplete,
    newInboundV2Proposals,
    inboundProposalsSearch
  }
}
