import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    size: {
      type: String,
      default: 'md',
      validator: val => {
        return ['xs', 'sm', 'md', 'lg'].includes(val.toLowerCase());
      }
    },
    value: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    state: {
      type: String,
      default: ''
    },
    tooltipPosition: {
      type: String,
      default: 'none'
    },
    tooltipText: {
      type: String,
      default: ''
    },
    fontAwesomeIcon: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    classes: {
      type: String,
      default: ''
    }
  },
  setup: props => {
    return {};
  }
});