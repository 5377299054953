export enum ProposalStatuses {
  DRAFT = 'draft',
  READY_FOR_REVIEW = 'ready_for_review',
  SENT = 'sent',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  EVALUATING = 'evaluating',
  REJECTED_CLOSED = 'rejected_closed',
  ACCEPTED_INVOICED = 'accepted_invoiced',
  ACCEPTED_WO = 'accepted_wo'
}
