import { api } from '@/api'

interface Params {
  report_type: string
  wo_invoice_report: boolean
  work_order_tab: string
  report_format: string
  query: string
  source: string
  work_order_ids: number[]
}

export default {
  exportWoReport: (params: Params, include?: string) => {
    return api.$post('/work_order_reports',
      { work_order_report: { ...params }, include }
    )
  }
}
