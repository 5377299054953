import repositories from '@/repositories'
import { baseGetResponse } from '@/utils/api'
import { AutocompletePayload, AutocompleteResponse } from '@/types/interfaces/api-v2/autocomplete'
export const useInboundWorkOrders = () => {
  const getInboundWorkOrders = async (params: any) => {
    try {
      const res = await repositories.inboundWorkOrders.get(params)
      return {
        data: res.workOrders,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages
      } as baseGetResponse
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getInboundWorkOrderTripsById = async (params: any) => {
    try {
      const res = await repositories.workOrders.getTripsById(params)
      return {
        data: res.trips,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages
      } as baseGetResponse
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const inboundWorkOrdersAutoComplete = async (params: AutocompletePayload) => {
    try {
      const res = await repositories.inboundWorkOrders.inboundWorkOrdersAutoComplete(params)
      return {
        data: res.results,
        nextPage: res.nextPage
      } as { data: AutocompleteResponse[], nextPage: number }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }
  return {
    getInboundWorkOrders,
    getInboundWorkOrderTripsById,
    inboundWorkOrdersAutoComplete
  }
}
