import { defineComponent, onBeforeUnmount, onMounted, ref } from 'vue';
export default defineComponent({
  props: {
    borderColor: {
      type: String,
      default: 'transparent'
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    noElevation: {
      type: Boolean,
      default: false
    },
    noMargin: {
      type: Boolean,
      default: false
    },
    backgroundColor: {
      type: String,
      default: '--v-layer-base'
    },
    padding: {
      type: String,
      default: 'pa-4'
    }
  },
  setup: (props, {
    emit
  }) => {
    const tileRef = ref(null);
    const end = () => {
      transitioning.value = false;
      emit('transition-ended');
    };
    const start = () => {
      transitioning.value = true;
      emit('transition-started');
    };
    onMounted(() => {
      tileRef.value.addEventListener('transitionend', end);
      tileRef.value.addEventListener('transitionstart', start);
    });
    onBeforeUnmount(() => {
      tileRef.value.removeEventListener('transitionend', end);
      tileRef.value.removeEventListener('transitionstart', start);
    });
    const transitioning = ref(false);
    return {
      tileRef,
      transitioning
    };
  }
});