import { SUCCESS, ERROR } from '@/constants/icons'
export const actionToastProps = {
  success: {
    state: 'success',
    icon: SUCCESS

  },
  error: {
    state: 'error',
    icon: ERROR
  },
  partialSuccess: {
    state: 'warning',
    icon: ERROR
  }
}

export const ADDL_TRANSITION_TIME_FOR_TOAST = 2500
